import i18next from 'i18next';

i18next.addResources('es_CL', 'ComboCreation', {
  drink: 'Bebida',
  finish: 'Finalizar',
  next: 'Siguiente',
  pizza: 'Pizza',
  side: 'Acompañamiento',
  dessert: 'Postre',
  stepTitle: 'Selecciona tu {{step}}',
  stepTitlePlural: 'Selecciona tus {{step}}',
  createCombo: 'Arma tu combo',
  complement: 'Complementos',
  extraSauce: 'Salsa extra',
  optional: 'Agrega más'
});

i18next.addResources('es_ES', 'ComboCreation', {
  drink: 'Bebida',
  finish: 'Finalizar',
  next: 'Siguiente',
  pizza: 'Pizza',
  side: 'Entrantes',
  dessert: 'Postre',
  stepTitle: 'Selecciona tu {{step}}',
  stepTitlePlural: 'Selecciona tus {{step}}',
  createCombo: 'Crea tu combo',
  complement: 'Complementos',
  extraSauce: 'Salsa extra',
  optional: 'Agrega más'
});

i18next.addResources('pt_PT', 'ComboCreation', {
  drink: 'Bebida',
  finish: 'Terminar',
  next: 'Próxima',
  pizza: 'Pizza',
  side: 'Iniciantes',
  dessert: 'Sobremesas',
  stepTitle: 'Selecione seu {{step}}',
  stepTitlePlural: 'Selecione seu {{step}}',
  createCombo: 'Crie seu combo',
  complement: 'Complementa',
  extraSauce: 'Molho extra',
  optional: 'Adicione mais'
});
