import { isBase, getIngredientsWithPrice } from '~utils/pizza';
import { CUSTOM_HALF_OPTIONS } from '~components/CustomizableOption/constants';

import { INGREDIENT_API_TEMPLATE } from './constants';

const BASE = 'base';

export const separateIngredientsAndBases = data =>
  Object.keys(data).reduce(
    (acc, key) => {
      if (key.includes(BASE)) {
        return { ...acc, bases: { ...acc.bases, [key]: data[key] } };
      }

      return { ...acc, ingredients: { ...acc.ingredients, [key]: data[key] } };
    },
    { bases: {}, ingredients: {} }
  );

export const parseIngredients = ingredients =>
  ingredients.reduce((acc, item) => {
    acc[item.category] = acc[item.category] || [];
    acc[item.category].push({ ...item, text: item.name });

    return acc;
  }, {});

export const completeIngredientData = ingredient => ({
  ...INGREDIENT_API_TEMPLATE,
  ...ingredient
});

export const getDefaultSelections = ({ sizes, crusts, ingredients, restrictions, selectedVariant }) => {
  const selectedSize = selectedVariant?.size
    ? { id: selectedVariant.size, name: selectedVariant.size }
    : sizes[0];
  const selectedCrust = selectedVariant?.crust
    ? { id: selectedVariant.crust, name: selectedVariant.crust }
    : crusts.find(crust => restrictions[selectedSize.id].includes(crust.id));
  const selectedBases = ingredients.filter(i => isBase(i));
  const selectedIngredients = ingredients.filter(i => !isBase(i));

  return {
    sizes: [selectedSize],
    crusts: [selectedCrust],
    bases: selectedBases,
    ingredients: selectedIngredients
  };
};

export const defaultIngredientSelection = ingredients => {
  const existingCategories = {};

  return ingredients.reduce((result, ingredient) => {
    if (!existingCategories[ingredient.category]) {
      result.push({ ...ingredient, half: CUSTOM_HALF_OPTIONS.whole });
    }
    existingCategories[ingredient.category] = true;

    return result;
  }, []);
};

export const selectedIngredientsPerHalf = selections => {
  let half1Amount = 0;
  let half2Amount = 0;

  const ingredients = getIngredientsWithPrice(selections);

  ingredients.forEach(ingredient => {
    const quantity = ingredient.quantity || 1;

    half1Amount +=
      ingredient.half === CUSTOM_HALF_OPTIONS.whole || ingredient.half === CUSTOM_HALF_OPTIONS.half1
        ? quantity
        : 0;

    half2Amount +=
      ingredient.half === CUSTOM_HALF_OPTIONS.whole || ingredient.half === CUSTOM_HALF_OPTIONS.half2
        ? quantity
        : 0;
  });

  return { half1Amount, half2Amount };
};
