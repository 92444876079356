import i18next from 'i18next';

i18next.addResources('es_CL', 'NPS', {
  headerTitle: 'Valoramos tu opinión, queremos mejorar',
  recommendation: '¿Qué tan probable es que nos recomiendes a tus amigos y familiares?',
  didGetOrder: '¿Recibiste el pedido completo?',
  didGetOnTime: '¿Lo recibiste en el tiempo prometido?',
  yes: 'Sí',
  no: 'No',
  correctTemperature: '¿Tenía la temperatura correcta?',
  localState: '¿Qué te parecio el estado del local?',
  tooGood: 'Muy bueno',
  tooBad: 'Muy malo',
  improve: 'Nos interesa tu experiencia con nuestro servicio. Ayúdanos a mejorarlo.',
  comments: 'Tus comentarios',
  textareaPlaceholder: 'Cuéntanos tu experiencia hoy...',
  accept: 'Aceptar',
  thanksTime: '¡Gracias por tu tiempo!',
  opinion: 'Tomaremos en cuenta tu opinión para seguir mejorando nuestros servicios.',
  seeYouSoon: 'Esperamos verte pronto.',
  alreadyFilled: 'Esta encuesta fue contestada previamente.'
});

i18next.addResources('es_ES', 'NPS', {
  headerTitle: 'Valoramos tu opinión, queremos mejorar',
  recommendation: '¿Qué tan probable es que nos recomiendes a tus amigos y familiares?',
  didGetOrder: '¿Recibiste el pedido completo?',
  didGetOnTime: '¿Lo recibiste en el tiempo prometido?',
  yes: 'Sí',
  no: 'No',
  correctTemperature: '¿Tenía la temperatura correcta?',
  localState: '¿Qué te parecio el estado del local?',
  tooGood: 'Muy bueno',
  tooBad: 'Muy malo',
  improve: 'Nos interesa tu experiencia con nuestro servicio. Ayúdanos a mejorarlo.',
  comments: 'Tus comentarios',
  textareaPlaceholder: 'Cuéntanos tu experiencia hoy...',
  accept: 'Aceptar',
  thanksTime: '¡Gracias por tu tiempo!',
  opinion: 'Tomaremos en cuenta tu opinión para seguir mejorando nuestros servicios.',
  seeYouSoon: 'Esperamos verte pronto.',
  alreadyFilled: 'Esta encuesta fue contestada previamente.'
});

i18next.addResources('pt_PT', 'NPS', {
  headerTitle: 'Nós valorizamos a sua opinião, queremos melhorar',
  recommendation: 'Qual a probabilidade de você nos recomendar a seus amigos e familiares?',
  didGetOrder: 'Você recebeu o pedido completo?',
  didGetOnTime: 'Você recebeu na hora prometida?',
  yes: 'Sim',
  no: 'Não',
  correctTemperature: 'Eu tive a temperatura certa?',
  localState: 'O que você achou do estado do lugar?',
  tooGood: 'Muito bom',
  tooBad: 'Muito mau',
  improve: 'Estamos interessados ​​na sua experiência com o nosso serviço. Ajude-nos a melhorar.',
  comments: 'Seus comentários',
  textareaPlaceholder: 'Conte-nos sua experiência hoje...',
  accept: 'Aceitar',
  thanksTime: 'Obrigado pelo seu tempo!',
  opinion:
    'Vamos levar em consideração a sua opinião a qual agradecemos , com o intuito de melhorarmos os nossos serviços!.',
  seeYouSoon: 'Esperamos vê-la/o em breve!',
  alreadyFilled: 'Esta pesquisa foi respondida anteriormente.'
});
