import { PAYMENT_METHODS, CARD_PAYMENT_METHODS_ONLINE } from '~constants/paymentMethods';

import { COUNTRY_CARD_IS_ONLINE } from './constants';

export const normalizePaymentMethod = (currentPaymentMethod, onlinePayment) => {
  if (currentPaymentMethod === PAYMENT_METHODS.card) {
    return COUNTRY_CARD_IS_ONLINE && onlinePayment ? PAYMENT_METHODS.online : PAYMENT_METHODS.card;
  } else if (currentPaymentMethod === PAYMENT_METHODS.cash) {
    return PAYMENT_METHODS.cash;
  } else if (currentPaymentMethod === CARD_PAYMENT_METHODS_ONLINE.ingenico) {
    return PAYMENT_METHODS.online;
  }

  return PAYMENT_METHODS.online;
};

export const normalizeGiftCards = (giftCards, user) =>
  giftCards.map(giftCard => ({
    ...giftCard,
    buyerEmail: user?.email,
    buyerName: giftCard?.buyer?.name,
    receiverName: giftCard?.receiver?.name,
    receiverEmail: giftCard?.receiver?.email
  }));
