import i18next from 'i18next';

i18next.addResources('es_CL', 'OfferCompletion', {
  addToCart: 'Añadir al carrito',
  offerCompletionMessageStart: 'Agrega ',
  offerCompletionMessageEnd: ' por solo {{price}} más y desbloquea una promoción.',
  adviseCoupon: 'Al aceptar, se invalida el uso de cupones.',
  offerCompletionMessageEndFree: ' gratis y desbloquea una promoción.'
});

i18next.addResources('pt_PT', 'OfferCompletion', {
  addToCart: 'Adicionar ao carrinho',
  offerCompletionMessageStart: 'Adiciona ',
  offerCompletionMessageEnd: ' por apenas {{price}} e desbloquea uma promoção',
  adviseCoupon: 'Ao aceitar, o uso de cupons é invalidado.',
  offerCompletionMessageEndFree: ' grátis e desbloquea uma promoção'
});
