import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'Timer', {
  endsIn: 'Termina en {{time}}'
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'Timer', {
  endsIn: 'Termina em {{time}}'
});
