import i18next from 'i18next';

i18next.addResources('es_CL', 'SelectTimeDispatch', {
  titleModal: 'Programar hora',
  selectDaySubtitle: 'Seleccionar un día',
  selectTimeSubtitle: 'Seleccionar hora',
  selectButton: 'Seleccionar',
  defaultTimeOption: 'Elige una hora',
  today: 'Hoy',
  tomorrow: 'Mañana',
  labelSelectorDispatch: 'Selector de horario para despacho',
  later: 'Más tarde',
  asSoonAsPossible: 'Cuanto antes',
  futureOrderWarning: 'Esta opción solo admite pagos digitales'
});

i18next.addResources('es_CR', 'SelectTimeDispatch', {});

i18next.addResources('es_ES', 'SelectTimeDispatch', {});

i18next.addResources('pt_PT', 'SelectTimeDispatch', {
  titleModal: 'Programar hora',
  selectDaySubtitle: 'Seleccionar um dia',
  selectTimeSubtitle: 'Seleccionar uma hora',
  selectButton: 'Selecionar',
  defaultTimeOption: 'Escolhe uma hora',
  today: 'Hoje',
  tomorrow: 'Amanhã',
  labelSelectorDispatch: 'Selector de tempo para o despacho',
  later: 'Mais tarde',
  asSoonAsPossible: 'O mais depressa possível',
  futureOrderWarning: 'Esta opção só aceita pagamentos digitail'
});
