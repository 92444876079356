import i18next from 'i18next';

i18next.addResources('es_CL', 'TipSelection', {
  helpDelivery: 'Agrega una propina',
  addTip: 'Sin los repartidores, tu pedido no llegaría a ti. ¡Agradéceles con una propina!',
  noTip: 'Por ahora no',
  anotherAmount: 'Otro monto'
});

i18next.addResources('pt_PT', 'TipSelection', {
  helpDelivery: 'Adicionar uma dica',
  addTip: 'Sem os entregadores, seu pedido não chegaria até você. Agradeça-os com uma gorjeta!',
  noTip: 'Não agora',
  anotherAmount: 'Outro valor'
});
