import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';
import { PRODUCT_CATEGORIES } from '~screens/Dashboard/screens/Home/components/Menu/constants';

import { ITEM_CATEGORIES, NOUN_NUMBER, VARIANT_TYPES, VERBS_TENSES } from './constants';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'UpsellingCategories', {
  [PRODUCT_CATEGORIES.PIZZA]: 'pizza'
});

i18next.addResourceBundle(LANGUAGE_KEYS.CHILE, 'UpsellingOption', {
  verbs: {
    [VARIANT_TYPES.SIZE]: {
      [VERBS_TENSES.PRESENT]: 'Agranda',
      [VERBS_TENSES.PAST]: 'Agrandaste'
    },
    [VARIANT_TYPES.CRUST]: {
      [VERBS_TENSES.PRESENT]: 'Agrega',
      [VERBS_TENSES.PAST]: 'Agregaste'
    },
    [VARIANT_TYPES.CRUST_AND_DOUGH]: {
      [VERBS_TENSES.PRESENT]: 'Mejora',
      [VERBS_TENSES.PAST]: 'Mejoraste'
    }
  },
  products: {
    [PRODUCT_CATEGORIES.PIZZA]: {
      [NOUN_NUMBER.SINGULAR]: 'tu pizza {{name}}',
      [NOUN_NUMBER.PLURAL]: 'tus pizzas {{name}}'
    },
    [PRODUCT_CATEGORIES.BYOP]: {
      [NOUN_NUMBER.SINGULAR]: 'tu {{name}}',
      [NOUN_NUMBER.PLURAL]: 'tus {{name}}'
    }
  },
  eachOne: 'cada una',
  description: {
    [VARIANT_TYPES.SIZE]: {
      [ITEM_CATEGORIES.SINGLE]:
        '{{verb}} {{productSpecification}} a <bold>{{variantName}} por sólo {{price}}</bold> {{eachOne}}',
      [ITEM_CATEGORIES.OFFER]:
        '{{verb}} {{productSpecification}} a <bold>{{variantName}} por sólo {{price}}</bold> {{eachOne}}'
    },
    [VARIANT_TYPES.CRUST]: {
      [ITEM_CATEGORIES.SINGLE]: '{{verb}} <bold>{{variantName}} por sólo {{price}}</bold> {{eachOne}}',
      [ITEM_CATEGORIES.OFFER]:
        '{{verb}} <bold>{{variantName}} a {{productSpecification}} por sólo {{price}}</bold> {{eachOne}}'
    },
    [VARIANT_TYPES.CRUST_AND_DOUGH]: {
      [ITEM_CATEGORIES.SINGLE]: '{{verb}} a <bold>{{variantName}} por sólo {{price}}</bold> {{eachOne}}',
      [ITEM_CATEGORIES.OFFER]:
        '{{verb}} {{productSpecification}} a <bold>{{variantName}} por sólo {{price}}</bold> {{eachOne}}'
    }
  },
  crustVariant: '{{crust}} con {{crustVariant}}'
});

i18next.addResourceBundle(LANGUAGE_KEYS.SPAIN, 'UpsellingOption', {
  verbs: {
    [VARIANT_TYPES.SIZE]: {
      [VERBS_TENSES.PRESENT]: 'Convierte',
      [VERBS_TENSES.PAST]: 'Convertiste'
    },
    [VARIANT_TYPES.CRUST]: {
      [VERBS_TENSES.PRESENT]: 'Añade',
      [VERBS_TENSES.PAST]: 'Añadiste'
    },
    [VARIANT_TYPES.CRUST_AND_DOUGH]: {
      [VERBS_TENSES.PRESENT]: 'Mejora',
      [VERBS_TENSES.PAST]: 'Mejoraste'
    }
  },
  products: {
    [PRODUCT_CATEGORIES.PIZZA]: {
      [NOUN_NUMBER.SINGULAR]: 'tu pizza {{name}}',
      [NOUN_NUMBER.PLURAL]: 'tus pizzas {{name}}'
    }
  },
  eachOne: 'cada una',
  description: {
    [VARIANT_TYPES.SIZE]: {
      [ITEM_CATEGORIES.SINGLE]:
        '{{verb}} {{productSpecification}} en tamaño <bold>{{variantName}} por sólo {{price}}</bold> {{eachOne}}',
      [ITEM_CATEGORIES.OFFER]:
        '{{verb}} {{productSpecification}} en tamaño <bold>{{variantName}} por sólo {{price}}</bold> {{eachOne}}'
    },
    [VARIANT_TYPES.CRUST]: {
      [ITEM_CATEGORIES.SINGLE]:
        '{{verb}} tu pizza con el <bold>{{variantName}} por sólo {{price}}</bold> {{eachOne}}',
      [ITEM_CATEGORIES.OFFER]:
        '{{verb}} tu pizza <bold>{{variantName}} con el {{productSpecification}} por sólo {{price}}</bold> {{eachOne}}'
    },
    [VARIANT_TYPES.CRUST_AND_DOUGH]: {
      [ITEM_CATEGORIES.SINGLE]:
        '{{verb}} tu pizza <bold>{{variantName}} por sólo {{price}}</bold> {{eachOne}}',
      [ITEM_CATEGORIES.OFFER]:
        '{{verb}} {{productSpecification}} <bold>{{variantName}} por sólo {{price}}</bold> {{eachOne}}'
    }
  },
  crustVariant: 'añadiendo {{crustVariant}}'
});

i18next.addResourceBundle(LANGUAGE_KEYS.PORTUGAL, 'UpsellingOption', {
  verbs: {
    [VARIANT_TYPES.SIZE]: {
      [VERBS_TENSES.PRESENT]: 'Leva',
      [VERBS_TENSES.PAST]: 'Alterastes'
    },
    [VARIANT_TYPES.CRUST]: {
      [VERBS_TENSES.PRESENT]: 'Pede',
      [VERBS_TENSES.PAST]: 'Pediste'
    },
    [VARIANT_TYPES.CRUST_AND_DOUGH]: {
      [VERBS_TENSES.PRESENT]: 'melhore',
      [VERBS_TENSES.PAST]: 'melhorou'
    }
  },
  products: {
    [PRODUCT_CATEGORIES.PIZZA]: {
      [NOUN_NUMBER.SINGULAR]: 'a tua pizza {{name}}',
      [NOUN_NUMBER.PLURAL]: 'à sua pizzas {{name}}'
    }
  },
  eachOne: 'cada uma',
  description: {
    [VARIANT_TYPES.SIZE]: {
      [ITEM_CATEGORIES.SINGLE]:
        '{{verb}} {{productSpecification}} para <bold>{{variantName}} por apenas {{price}}</bold> {{eachOne}}',
      [ITEM_CATEGORIES.OFFER]:
        '{{verb}} {{productSpecification}} para <bold>{{variantName}} por apenas {{price}}</bold> {{eachOne}}'
    },
    [VARIANT_TYPES.CRUST]: {
      [ITEM_CATEGORIES.SINGLE]: '{{verb}} <bold>{{variantName}} por apenas {{price}}</bold> {{eachOne}}',
      [ITEM_CATEGORIES.OFFER]:
        '{{verb}} <bold>{{variantName}} para {{productSpecification}} por apenas {{price}}</bold> {{eachOne}}'
    },
    [VARIANT_TYPES.CRUST_AND_DOUGH]: {
      [ITEM_CATEGORIES.SINGLE]: '{{verb}} para <bold>{{variantName}} por apenas {{price}}</bold> {{eachOne}}',
      [ITEM_CATEGORIES.OFFER]:
        '{{verb}} {{productSpecification}} para <bold>{{variantName}} por apenas {{price}}</bold> {{eachOne}}'
    }
  },
  crustVariant: '{{crust}} com {{crustVariant}}'
});
