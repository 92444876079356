import i18next from 'i18next';

i18next.addResources('es_CL', 'RecoverPassword', {
  title: '¿Olvidaste tu contraseña?',
  verifyAccount: 'Verificar Cuenta',
  instructions: 'Introduce tu email y te enviaremos los pasos a seguir',
  errorMessage: 'No tenemos registrado tu email, compruébalo e inténtalo de nuevo',
  sendButton: 'Enviar'
});

i18next.addResources('es_CR', 'RecoverPassword', {
  errorMessage: 'No tenemos registrado tu email, revisalo e inténtalo de nuevo'
});

i18next.addResources('es_ES', 'RecoverPassword', {
  title: '¿Olvidaste tu contraseña?',
  errorMessage: 'No tenemos registrado tu email, compruébalo e inténtalo de nuevo',
  sendButton: 'Enviar'
});

i18next.addResources('pt_PT', 'RecoverPassword', {
  title: 'Esqueceste-te da tua senha?',
  verifyAccount: 'Verificar Conta',
  instructions: 'Digite seu e-mail e nós lhe enviaremos os passos a seguir',
  errorMessage: 'Não temos o teu email registrado, verifica e tenta de novo',
  sendButton: 'Enviar'
});

i18next.addResources('es_PA', 'RecoverPassword', {
  errorMessage: 'No tenemos registrado tu email, revísalo e inténtalo de nuevo'
});
