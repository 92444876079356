import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

import { ReactComponent as CheckGreen } from '~assets/circle-check.svg';
import CustomButton from '~components/CustomButton';

import styles from './styles.module.scss';

export const ApplyPromo = ({ actualPromo, handleCheckPromo }) => {
  const shoppingCartLoading = useSelector(state => state.home?.shoppingCartLoading);
  const promosLoading = useSelector(state => state.promoSelling?.promosLoading);

  const handleCheckboxChange = (promo, event) => {
    event.stopPropagation();
    handleCheckPromo(promo, event.target.checked);
  };

  return (
    <div
      key={actualPromo.id}
      className={cn(styles.container, {
        [styles.loading]: shoppingCartLoading || promosLoading
      })}
      onClick={() => handleCheckPromo(actualPromo, !actualPromo.checked)}>
      <CheckGreen />
      <p className={styles.textApply}>{t('PromoSelling:appliedPromo')}</p>
      <CustomButton
        type="button"
        textClassName={styles.textUndo}
        onClick={e => handleCheckboxChange(actualPromo, e)}
        text={t('PromoSelling:undoPromo')}
        btnClassType="link"
        textCapitalize
      />
    </div>
  );
};

ApplyPromo.propTypes = {
  actualPromo: PropTypes.object,
  handleCheckPromo: PropTypes.func
};
