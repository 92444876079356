import { isArray } from './array';

/*
  This is a greedy and fast deep comparition between two objects and have some restrictions.
  For a more generic comparition a formal version should be implemented.

  Restrictions:
    1. This function doesn't work when there are methods as attributes.
    2. The order of properties is important.
*/
export const isEquals = (a, b) => JSON.stringify(a) === JSON.stringify(b);

export const isEmptyObject = value =>
  value && value.constructor === Object && Object.keys(value).length === 0;

export const objectHasKey = (object, key) => !!Object.keys(object).find(objectKey => objectKey === key);

export const omit = (obj, keys) => {
  if (!obj) {
    return {};
  }
  if (!keys || keys.length === 0) {
    return obj;
  }
  return keys.reduce(
    (acc, key) => {
      delete acc[key];
      return acc;
    },
    { ...obj }
  );
};

export const sumValues = (obj = {}, keys = []) => keys.reduce((acc, key) => acc + (obj?.[key] || 0), 0);

export const addFields = (objects = {}, fields = {}) =>
  isArray(objects) ? objects.map(obj => ({ ...obj, ...fields })) : { ...objects, ...fields };

export const getValue = (obj = {}, key, defaultKey) => obj[key] || obj[defaultKey];

export const compareObjectsByKeys = (obj1, obj2, keys) => keys.every(key => obj1?.[key] === obj2?.[key]);
