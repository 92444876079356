import i18next from 'i18next';

i18next.addResources('es_CL', 'SauceSection', {
  order: 'Tu pedido incluye ',
  freeSauceQuantity: '{{quantity}} salsas de ajo gratuitas.',
  iWantThem: '¡Las quiero!',
  addMoreSauces: 'Si quieres más, añádelas aquí:'
});

i18next.addResources('pt_PT', 'SauceSection', {
  order: 'A tua encomenda inclui ',
  freeSauceQuantity: '{{quantity}} molhos de alho gratuitos.',
  iWantThem: 'Eu quero!',
  addMoreSauces: 'Se queres mais, adiciona-os aqui:'
});
