import i18next from 'i18next';

i18next.addResources('es_CL', 'ElectronicReceiptForm', {
  commercialName: 'Nombre o razón social',
  identificationType: 'Tipo de identificación',
  identity: 'Selecciona una opción',
  identificationNumber: 'Número de identificación',
  identificationNumberLabel: 'Número de identificación',
  commercialPhone: 'Teléfono',
  faxNumber: 'Número de fax',
  tradeName: 'Nombre comercial',
  commercialEmail: 'Correo',
  physicalId: 'Cédula Física',
  legalId: 'Cédula Jurídica',
  dimex: 'Dimex',
  nite: 'NITE',
  idNIT: 'NIT',
  nif: 'NIF',
  chooseMain: 'Elegir como principal',
  chooseReceipt: 'Quiero recibir factura electrónica',
  changeOption: 'Elegir otra opción',
  modify: 'Modificar',
  save: 'Guardar',
  delete: 'Eliminar empresa',
  select: 'Seleccionar esta empresa',
  selectCompany: 'Seleccione una empresa',
  addCompany: 'Agregar empresa',
  noCompanies: 'No tienes empresas agregadas aún.',
  maxCompaniesReached: 'Lo sentimos! Alcanzó el número máximo de compañías.',
  commercialTextAddress: 'Dirección',
  commercialCity: 'Ciudad',
  commercialPostalCode: 'Código Postal',
  invalidNIF: 'El NIF ingresado es inválido',
  invalidNIT: 'El NIT ingresado es inválido'
});

i18next.addResources('pt_PT', 'ElectronicReceiptForm', {
  commercialName: 'Nome ou nome da empresa',
  identificationType: 'Tipo de identificação',
  identity: 'Selecione uma opção',
  identificationNumber: 'Número de identificação (NIF)',
  identificationNumberLabel: 'Número de identificação',
  commercialPhone: 'Número de telefone',
  faxNumber: 'Número de fax',
  tradeName: 'Nome comercial',
  commercialEmail: 'Correio eletrônico',
  physicalId: 'ID físico',
  legalId: 'Cédula Jurídica',
  chooseMain: 'Escolha como principal',
  chooseReceipt: 'Quero receber uma fatura eletrônica',
  changeOption: 'Escolha outra opção',
  modify: 'Modificar',
  save: 'Salvar',
  delete: 'Eliminar empresa',
  select: 'Selecione esta empresa',
  selectCompany: 'Selecione uma empresa',
  addCompany: 'Adicione empresa',
  noCompanies: 'Você ainda não adicionou empresas.',
  maxCompaniesReached: 'Sentimos muito! Atingiu o número máximo de empresas',
  commercialTextAddress: 'Direção',
  commercialCity: 'Cidade',
  commercialPostalCode: 'Código postal',
  nif: 'NIF',
  invalidNIF: 'O NIF inserido é inválido'
});

i18next.addResources('es_GT', 'ElectronicReceiptForm', {
  tradeName: 'Nombre cliente'
});

i18next.addResources('es_PA', 'ElectronicReceiptForm', {
  idNIT: 'RUC',
  nif: 'RUC',
  invalidNIT: 'El RUC ingresado es inválido',
  invalidNIF: 'El RUC ingresado es inválido'
});
