/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';

import store from '../redux/store';

import { startIntercom } from '~services/IntercomService';
import LocalStorageService from '~services/LocalStorageService';
import { apiSetup } from '~config/api';
import { ANALYTICS_COOKIE } from '~screens/Dashboard/components/CookiesPrivacy/components/CookieSettings/const';
import Theme from '~config/MaterialUIStyles/Theme';

import '../scss/application.scss';

import { EVENT_CHANGE_SETTINGS_COOKIE } from '~constants/cookies';
import { isGDPRCountry } from '~utils/cookieSettings';

import Routes from './components/Routes';
import ErrorBoundary from './components/ErrorBoundary/index';
import {
  loadAnalyticsScripts,
  renderHeadAnalyticsScripts,
  renderJsonLDSeoScript,
  renderPushAlert,
  weglotScript
} from './utils';

const getCookiesAccept = LocalStorageService.getCookiesAccept();

class App extends Component {
  state = {
    cookieAccept: getCookiesAccept
  };

  onChangeSettingsCookie = () => {
    this.setState({ cookieAccept: LocalStorageService.getCookiesAccept }, () => {
      loadAnalyticsScripts();
    });
  };

  componentDidMount() {
    apiSetup(store.dispatch);
    startIntercom();
    loadAnalyticsScripts();
    window.addEventListener(EVENT_CHANGE_SETTINGS_COOKIE, this.onChangeSettingsCookie);
  }

  componentWillUnmount() {
    window.removeEventListener(EVENT_CHANGE_SETTINGS_COOKIE, this.onChangeSettingsCookie);
  }

  render() {
    const { cookieAccept } = this.state;

    return (
      <ThemeProvider theme={Theme}>
        {(cookieAccept?.[ANALYTICS_COOKIE] || !isGDPRCountry()) && renderHeadAnalyticsScripts()}
        {renderJsonLDSeoScript()}
        {renderPushAlert()}
        {weglotScript()}
        <Provider store={store}>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </Provider>
      </ThemeProvider>
    );
  }
}

App.defaultProps = {
  loading: false
};

export default App;
