export const flattenMenu = menu =>
  menu?.reduce((acc, item) => {
    let flattenedVariants = {};
    const { menuableType, menuableId, variants, menuable } = item;

    if (variants) {
      flattenedVariants = flattenMenu(
        variants.map(variant => ({ ...variant, category: menuable?.category }))
      );
    }

    return {
      ...acc,
      ...flattenedVariants,
      [`${menuableType}${menuableId}`]: { menuableId, menuable, menuableType, data: { ...item } }
    };
  }, {});

export const flattenCombos = offers =>
  offers.reduce(
    (acc, { offerType, id, ...rest }) => ({
      ...acc,
      [`${offerType}${id}`]: { offerType, id, data: { offerType, id, ...rest } }
    }),
    {}
  );
