/* eslint-disable camelcase */
import { DISPATCH_METHODS } from '~constants/address';
import { addAtIndex } from '~utils/array';
import { BRAND } from '~constants/analytics';
import { DIMENSIONS } from '~utils/analytics';
import { getValidAmount } from '~utils/number';
import { sumValues } from '~utils/object';
import { capitalizeFirstLetter } from '~utils/string';

export const isGiftCard = item => !!item?.isGiftCard;
export const onlyGiftCards = (items = []) => items.every(isGiftCard);
export const someGiftCard = (items = []) => items.some(isGiftCard);

export const getShoppingCartTotal = shoppingCart =>
  shoppingCart?.giftCard?.code
    ? sumValues(shoppingCart, ['subtotal', 'delivery', 'taxCharge'])
    : shoppingCart.total;

export const validateDispatchMethod = (shoppingCartItems = [], dispatchMethodSelected) =>
  onlyGiftCards(shoppingCartItems) ? DISPATCH_METHODS.IN_STORE : dispatchMethodSelected;

export const addProductToShoppingCart = (items = [], product) => {
  const itemsClone = [...items];
  const index = itemsClone.findIndex(({ menuableId }) => menuableId === product?.menuableId);
  return index >= 0
    ? addAtIndex(itemsClone, index, {
        quantity: itemsClone[index]?.quantity + 1
      })
    : [...itemsClone, product];
};

// GTM EVENTS

export const mapProductToGTMData = ({
  name,
  offerId,
  productMenuableId,
  menuableId,
  price,
  offerType,
  category,
  variantName,
  groupProducts,
  quantity,
  addedFrom,
  currency
}) => ({
  name,
  id: String(offerId || productMenuableId || menuableId),
  price,
  category: offerType || category,
  variant: variantName || name,
  quantity: groupProducts ? groupProducts.length : quantity,
  dimension9: DIMENSIONS[addedFrom],
  brand: BRAND,
  ...(currency && { currency })
});

export const mapGiftCartToGA4Data = ({ amount, price, currency }) => ({
  item_name: 'gift card',
  item_id: '-',
  price: String(price),
  item_category: 'giftcard',
  item_variant: String(amount),
  quantity: 1,
  item_brand: BRAND,
  item_category2: DIMENSIONS.giftcard,
  ...(currency && { currency })
});

export const mapProductToGA4Data = ({
  name,
  offerId,
  productMenuableId,
  menuableId,
  price,
  offerType,
  category,
  variantName,
  quantity,
  addedFrom,
  currency,
  total,
  variant
}) => ({
  item_name: name,
  item_id: String(offerId || productMenuableId || menuableId),
  price: offerId ? total : price,
  item_category: capitalizeFirstLetter(offerType || category),
  quantity: quantity || 1,
  item_brand: BRAND,
  item_category2: DIMENSIONS[addedFrom],
  ...(currency && { currency }),
  ...(!offerId && { item_variant: variantName || variant || name })
});

export const mapGiftCartToGTMData = ({ amount, price, currency }) => ({
  name: 'gift card',
  id: '-',
  price,
  category: 'giftcard',
  variant: amount,
  quantity: 1,
  dimension9: DIMENSIONS.giftcard,
  brand: BRAND,
  ...(currency && { currency })
});

export const mapItemsToGTMData = items =>
  items.map(product => (product.isGiftCard ? mapGiftCartToGTMData(product) : mapProductToGTMData(product)));

export const mapItemsToGA4Data = items =>
  items.map(product => (product.isGiftCard ? mapGiftCartToGA4Data(product) : mapProductToGA4Data(product)));

export const getValidAmountForDelivery = shoppingCart =>
  getValidAmount(shoppingCart?.subtotal) - getValidAmount(shoppingCart?.discount);
