import i18next from 'i18next';

i18next.addResources('es_CL', 'NotFound', {
  notFound: '404',
  notFoundText: 'Página no encontrada'
});

i18next.addResources('pt_PT', 'NotFound', {
  notFound: '404',
  notFoundText: 'Página não encontrada'
});
