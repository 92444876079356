import i18next from 'i18next';

i18next.addResources('es_CL', 'CodeVerificationStep', {
  title: 'Verificación',
  description: 'Introduce el código de verificación que enviamos al correo ',
  warning: 'Si no ves el código en tu bandeja de entrada, revisa la carpeta de correo no deseado.',
  resendCode: 'Reenviar código',
  login: 'Continuar',
  tryOtherEmail: 'Intentar con otro correo',
  sentIn: ' en',
  CustomChallengeError: 'El código introducido no es correcto',
  NotAuthorizedException:
    'Has alcanzado el límite de intentos. Solicita un nuevo código para poder continuar.'
});

i18next.addResources('pt_PT', 'CodeVerificationStep', {
  title: 'Verificação',
  description: 'Introduz o código de verificação que enviámos para ',
  warning: 'Se não encontrares o código na tua caixa de entrada, verifica a pasta de spam.',
  resendCode: 'Reenviar código',
  login: 'Entrar',
  tryOtherEmail: 'Tentar com outro e-mail',
  sentIn: ' em',
  CustomChallengeError: 'O código introduzido é incorreto',
  NotAuthorizedException: 'Atingiste o limite de tentativas. Solicita um novo código para poderes continuar.'
});
