import { STATUS_CODES } from './status';

const DEFAULT_INTERVAL = 1000;
const DEFAULT_TIMEOUT = 60000;

const pollResource = ({ service, interval, timeout, shouldRetry = () => true }) => {
  const endTime = new Date().getTime() + timeout;
  const pollingRequest = (resolve, reject) => {
    service()
      .then(response => {
        const hasTimedOut = new Date().getTime() >= endTime;
        const defaultShouldPoll = response.status === STATUS_CODES.NO_CONTENT && !hasTimedOut;

        if (!shouldRetry(response)) {
          resolve({ ...response, abortRetries: true });
        } else if (defaultShouldPoll) {
          setTimeout(pollingRequest, interval, resolve, reject);
        } else if (hasTimedOut && response.ok) {
          resolve({ data: { code: STATUS_CODES.TIMEOUT }, abortRetries: true });
        } else {
          resolve(response);
        }
      })
      .catch(resolve);
  };

  return new Promise(pollingRequest);
};

/**
 * Make a service pollable
 * @param {Object} options Options object
 * @param {Function} options.request Async function that works as a request
 * @param {Function} options.polleableService The service that will be polled
 * @param {Number} options.interval The time in ms in which the interval will be executed
 * @param {Number} options.timeout The time in ms in which the requests will be aborted
 * @param {Function} options.shouldRetry Function that returns either true or false. Will be used to decide whether the request will be retried or not.
 * @returns {Promise} Polling promise.
 */
const poll = async ({
  request,
  polleableService,
  interval = DEFAULT_INTERVAL,
  timeout = DEFAULT_TIMEOUT,
  shouldRetry
}) => {
  if (request) {
    const response = await request;

    return response.ok
      ? pollResource({
          service: () => polleableService(response.data),
          interval,
          timeout,
          shouldRetry
        })
      : response;
  }

  return pollResource({
    service: () => polleableService,
    interval,
    timeout,
    shouldRetry
  });
};

const singlePoll = (polleableService, data, interval = DEFAULT_INTERVAL, timeout = DEFAULT_TIMEOUT) =>
  pollResource({
    service: () => polleableService(data),
    interval,
    timeout
  });

export default { poll, singlePoll };
