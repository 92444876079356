import api from '~config/api';

const INGREDIENTS_PATH = '/v1/ingredients';

const IngredientsService = {
  getIngredients: storeId => {
    const path = storeId ? `${INGREDIENTS_PATH}?store_id=${storeId}` : INGREDIENTS_PATH;

    return api.get(path);
  }
};

export default IngredientsService;
