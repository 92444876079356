import { productTypes } from '~constants/products';

const PRODUCTS_TO_SHOW = 2;

export const calculateClosestToRedeem = (entryLoyalties, userPoints) => {
  const loyalties = [...entryLoyalties];

  if (!userPoints) {
    return loyalties.slice(0, PRODUCTS_TO_SHOW);
  }
  let unredeemable = loyalties.filter(item => item.points > userPoints);

  if (unredeemable.length === 0) {
    unredeemable = loyalties
      .reverse()
      .map(loyalty => ({ ...loyalty, isAvailable: true }))
      .slice(0, PRODUCTS_TO_SHOW);
  } else if (unredeemable.length === 1) {
    unredeemable = loyalties
      .reverse()
      .map((loyalty, index) => ({ ...loyalty, isAvailable: index !== 0 }))
      .slice(0, PRODUCTS_TO_SHOW);
  } else {
    unredeemable = unredeemable.slice(0, PRODUCTS_TO_SHOW);
  }

  return unredeemable;
};

// TODO: Remove one backend implements filtering items
export const loyaltyEqualsMenuItem = (loyalty, item) => {
  switch (loyalty.menuableType) {
    case productTypes.PIZZA_VARIANT: {
      return (
        item.menuableType === productTypes.PIZZA &&
        item.variants?.some(
          variant =>
            variant.menuableId === loyalty.menuableId &&
            loyalty.crust === variant.menuable.crust &&
            loyalty.size === variant.menuable.size
        )
      );
    }
    case productTypes.COMPLEMENT_VARIANT: {
      return (
        item.menuableType === productTypes.COMPLEMENT &&
        item.variants?.some(variant => variant.menuableId === loyalty.menuableId)
      );
    }
    default:
      return item.menuableId === loyalty.menuableId && item.menuableType === loyalty.menuableType;
  }
};
