import i18next from 'i18next';

i18next.addResources('es_CL', 'PapaPoints', {
  pageTitle: "Gana Papa Puntos | Papa John's Chile",
  pageDescription:
    "Gana Papa Puntos con tus compras en Papa John's y consigue descuentos y regalos en tus próximas consumiciones en nuestras pizzerías y pizzería onine.",
  currentTab: 'Estado actual',
  exchangeTab: 'Historial de puntos',
  exchangeBtn: 'Canjear',
  points: '{{quantity}} puntos',
  headerImg: 'Imagen título',
  description: 'Únete y acumula 1 PAPA PUNTO por cada $1.000 en pedidos online',
  descriptionHighlighted: '¡CANJÉALOS POR PIZZA Y MUCHO MÁS!',
  loginSession: 'INICIA SESIÓN',
  startSession: 'REGISTRATE',
  dateTable: 'Fecha',
  noCurrentSubsidiary: 'Selecciona una tienda para poder canjear Papa Puntos',
  descriptionTable: 'Descripción',
  papaPointsTable: 'Papa Puntos',
  papaPointsAvailable: '{{quantity}} Puntos acumulados',
  papaPointsAvailableUpdated: 'Tu última compra: {{- date}}',
  papaPointsExchanged: '{{symbol}} {{quantity}} Puntos',
  papaPointsExchangedAvailable: 'Disponible para canjear',
  papaPointsExchangedInfo: '{{quantity}} Papa Puntos más'
});

i18next.addResources('es_CR', 'PapaPoints', {
  description: 'Únete y acumula 1 PAPA PUNTO por cada 1000₡ en pedidos online',
  pageTitle: "Papa Puntos | Papa John's Costa Rica",
  loginSession: 'INICIÁ SESIÓN'
});

i18next.addResources('es_ES', 'PapaPoints', {
  pageTitle: "Papa Puntos | Papa John's",
  description: 'Únete y acumula 1 PAPA PUNTO por cada 1€ en pedidos online'
});

i18next.addResources('pt_PT', 'PapaPoints', {
  pageTitle: "Papa Pontos | Papa John's",
  pageDescription: 'Saiba mais sobre o nosso programa de pontos de batata',
  currentTab: 'Estado atual',
  exchangeTab: 'Histórico de pontos',
  exchangeBtn: 'Resgatar',
  points: '{{quantity}} pontos',
  headerImg: 'Imagem do título',
  description: 'Junte-se e acumule 1 PAPA PUNTO por cada € 1 em pedidos online',
  descriptionHighlighted: 'MUDE-OS PARA A PIZZA E MUITO MAIS!',
  loginSession: 'START SESSION',
  startSession: 'REGISTO',
  dateTable: 'Data',
  noCurrentSubsidiary: 'Selecione uma loja para resgatar o Papa Puntos',
  descriptionTable: 'Descrição',
  papaPointsTable: 'Papa Pontos',
  papaPointsAvailable: '{{quantity}} Pontos acumulados',
  papaPointsAvailableUpdated: 'Sua última compra: {{- date}}',
  papaPointsExchanged: '{{symbol}} {{quantity}} Pontos',
  papaPointsExchangedAvailable: 'Disponível para resgatar',
  papaPointsExchangedInfo: '{{quantity}} Papa Pontos mais'
});

i18next.addResources('es_PA', 'PapaPoints', {
  pageTitle: "Papa Puntos | Papa John's Panama",
  description: 'Únete y acumula 10 PAPA PUNTOS por cada $1 en pedidos online'
});

i18next.addResources('es_GT', 'PapaPoints', {
  pageTitle: "Papa Puntos | Papa John's Guatemala",
  description: 'Únete y acumula 1 PAPA PUNTO por cada Q en pedidos online'
});
