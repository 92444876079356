const CHILE_EUROPE_IDS = {
  PHONE: 114098725674,
  STORE: 360007435214,
  RECEIPT: 114098697193,
  DESCRIPTION: 114096562673,
  EMAIL: 360001637513,
  NAME: 114098725514,
  LABEL: 360006507473,
  PART_TIME: 1500006487641
};

const CENTER_AMERICA_IDS = {
  PHONE: 360026425213,
  STORE: 360026693634,
  RECEIPT: 360026454254,
  DESCRIPTION: 360025781254,
  EMAIL: 360026426133,
  NAME: 360026424993,
  LABEL: 360026425253,
  PART_TIME: 1500006487641
};

export const CONTACT_FORM_IDS = {
  cl: CHILE_EUROPE_IDS,
  es: CHILE_EUROPE_IDS,
  pt: CHILE_EUROPE_IDS,
  pa: CENTER_AMERICA_IDS,
  cr: CENTER_AMERICA_IDS,
  gt: CENTER_AMERICA_IDS
};

export const PART_TIME_VALUE = 'no_aplica';
