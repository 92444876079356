import { BASE_ERROR } from '~constants/apiErrors';

export const errorNormalizer = response => {
  if (!response?.ok) {
    const responseBase = response || { data: null };
    const errorBase = responseBase.data?.errors ? responseBase.data.errors : responseBase.data;

    response.data = errorBase?.code ? errorBase : BASE_ERROR;
  }
};
