export const MONITOR_VIEW = 'monitorView';

export const MONITOR_VIEWS = {
  empty: 'empty',
  pizzaQuickSelection: 'pizzaQuickSelection',
  offerStatus: 'offerStatus',
  finalStep: 'finalStep',
  ingredientsList: 'ingredientsList',
  complementsSelection: 'complementsSelection',
  byop: 'byop'
};
