import { t } from 'i18next';

// TODO: Obtain from a global source
import { PRODUCT_CATEGORIES } from '~screens/Dashboard/screens/Home/components/Menu/constants';

export const separateIntoCategories = items =>
  items.reduce(
    (acc, item) => ({
      ...acc,
      [item.category]: [...(acc[item.category] || []), item]
    }),
    {}
  );

export const buildOfferDescription = ({ description, groupProducts }) => {
  const texts = [description];
  const categories = separateIntoCategories(groupProducts);

  Object.keys(categories).forEach(categoryName => {
    if (categoryName !== PRODUCT_CATEGORIES.PIZZA) {
      texts.push(`${t(`Menu:${categoryName}`)}:`);
    }
    categories[categoryName].forEach(product => {
      if (categoryName === PRODUCT_CATEGORIES.PIZZA) {
        texts.push(`${product.name}:`);
        texts.push(`${product.variantName}.`);
        texts.push(product.description);
      } else {
        texts.push(`${product.name} - ${product.variantName}`);
      }
    });
  });

  return texts.filter(Boolean).join('\n');
};
