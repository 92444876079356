import i18next from 'i18next';

i18next.addResources('es_CL', 'DeliveryOrPickUp', {
  local: 'Retiro en local',
  near: 'Retiro en punto',
  delivery: 'Delivery',
  seeMap: 'Indicaciones',
  readyIn: 'Entrega: ',
  iconTime: 'Ícono tiempo',
  iconMap: 'Ícono mapa',
  minAprox: 'min aprox',
  noData: 'Fuera del área de cobertura',
  noDataMessage: 'Oops, parece que la dirección ingresada se encuentra fuera de nuestra área de cobertura.',
  pickStore: 'Selecciona una tienda',
  pickStoreMessage: 'Para obtener tu pedido selecciona una tienda',
  hotspotPickup: 'Punto en espacio público',
  hotspotPapaCar: 'Delivery a tu auto',
  indications: 'Indicaciones',
  hotspotAdvice: 'Para retirar presenta tu confirmación de la orden',
  papaCarLicensePlate: 'JAAE12',
  papaCarDescription: 'Color, marca, etc',
  papaCarLicensePlateLabel: 'Placa patente',
  papaCarDescriptionLabel: 'Características del auto',
  noPickUpButton: 'Cambiar a delivery',
  tryAnotherAddress: 'Intenta con otra dirección',
  deliveryWarning: 'Sin delivery a tu ubicación por el momento, pero puedes pedir con retiro en local.',
  scheduleOrder: 'Programar pedido',
  searchPickUpPlaceholder: 'Ingresa una dirección o localidad'
});

i18next.addResources('es_ES', 'DeliveryOrPickUp', {
  delivery: 'Domicilio',
  near: 'A recoger',
  scheduleOrder: 'Programa tu pedido',
  deliveryWarning:
    '¡Vaya! De momento las pizzas con los ingredientes más frescos no llegan a tu dirección. Puedes optar por recoger tu pedido en tienda.'
});

i18next.addResources('pt_PT', 'DeliveryOrPickUp', {
  local: 'Para levantamento no local',
  near: 'Take Away',
  delivery: 'Domicílio',
  seeMap: 'Indicações',
  readyIn: 'Entrega: ',
  iconTime: 'Ícone do tempo',
  iconMap: 'Ícone do mapa',
  minAprox: 'min aprox',
  noData: 'Fora da área de cobertura',
  noDataMessage: 'Oops, parece que o endereço inserido está fora da nossa área de cobertura.',
  pickStore: 'Selecione uma loja',
  pickStoreMessage: 'Para receber seu pedido, selecione uma loja',
  hotspotPickup: 'Ponto no espaço público',
  indications: 'Indicações',
  hotspotAdvice: 'Para levantar o teu pedido apresenta a confirmação de pedido efectuado no teu e-mail !',
  tryAnotherAddress: 'Tente com outro endereço',
  deliveryWarning:
    'Oh! Por enquanto as pizzas com os ingredientes mais frescos não chegam à tua morada. Podes optar por recolher a tua encomenda na loja.',
  scheduleOrder: 'Agendar pedido',
  searchPickUpPlaceholder: 'Nome e número da rua'
});

i18next.addResources('es_CR', 'DeliveryOrPickUp', {
  delivery: 'Domicilio',
  pickStore: 'Elegí una tienda',
  pickStoreMessage: 'Para obtener tu pedido elegí una tienda'
});

i18next.addResources('es_PA', 'DeliveryOrPickUp', {
  local: 'Retiro en restaurante'
});
