import i18next from 'i18next';

i18next.addResources('es_CL', 'IngredientReplacement', {
  customizationOptions: 'Opciones de personalización',
  warning: '¡Vaya! Ahora mismo no nos queda',
  options: '. Elige una de las siguientes opciones.',
  continue: 'Continuar sin ',
  replacement: 'Reemplazar Ingrediente'
});

i18next.addResources('pt_PT', 'IngredientReplacement', {
  customizationOptions: 'Opções de personalização',
  warning: 'Ops! No momento, não temos',
  options: '. Escolha uma das seguintes opções.',
  continue: 'Continuar sem ',
  replacement: 'Substituir ingrediente'
});
