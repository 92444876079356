import i18next from 'i18next';

i18next.addResources('es_CL', 'Bases', {
  chooseAnOption: 'Debes elegir una opción'
});

i18next.addResources('es_ES', 'Bases', {
  chooseAnOption: 'Elige una opción'
});

i18next.addResources('pt_PT', 'Bases', {
  chooseAnOption: 'Você deve escolher uma opção'
});

i18next.addResources('es_CR', 'Bases', {
  chooseAnOption: 'Este campo es obligatorio'
});
