import { useEffect, useState } from 'react';

export const useClickedOutside = ref => {
  const [clickedOutside, setClickedOutside] = useState(false);

  useEffect(() => {
    const handleClickOutside = event => {
      setClickedOutside(ref.current && !ref.current.contains(event.target));
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return clickedOutside;
};
