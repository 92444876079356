import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'GiftCard', {
  to: 'Para:',
  digitalDelivery: 'Entrega digital.',
  disclaimer: 'El destinatario recibirá la Gift Card luego de confirmado el pago.'
});

i18next.addResources(LANGUAGE_KEYS.SPAIN, 'GiftCard', {
  disclaimer: 'El destinatario recibirá la Gift Card dentro de unos minutos.'
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'GiftCard', {
  disclaimer: 'O destinatário receberá o Gift Card dentro de uns minutos.'
});
