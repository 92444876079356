import i18next from 'i18next';

i18next.addResources('es_CL', 'Cart', {
  continuePayment: 'Continuar al pago',
  yourOrder: 'Tu orden',
  emptyCartText: 'Empieza a seleccionar tus productos',
  emptyCartButtonText: 'Eliminar todos los productos',
  emptyCartConfirmText: '¿Estás seguro?',
  emptyCartConfirmButtonText: 'Sí',
  emptyCartCancelButtonText: 'No',
  showCart: 'Revisar el pedido'
});

i18next.addResources('es_ES', 'Cart', {
  continuePayment: 'Continuar con el pago',
  yourOrder: 'Mi Carrito'
});

i18next.addResources('es_CR', 'Cart', {
  emptyCartText: 'Empezá a seleccionar productos'
});

i18next.addResources('pt_PT', 'Cart', {
  continuePayment: 'Continuar a pagar',
  yourOrder: 'O Meu Carrinho',
  emptyCartText: 'Faça o seu pedido',
  emptyCartButtonText: 'Remover todos os produtos',
  emptyCartConfirmText: 'Tem certeza?',
  emptyCartConfirmButtonText: 'Sim',
  emptyCartCancelButtonText: 'No',
  showCart: 'Rever o pedido'
});
