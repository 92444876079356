const websiteName = process.env.REACT_APP_WEBSITE_NAME;
const googleOptimizeKey = process.env.REACT_APP_GOOGLE_OPTIMIZE_ID;
const pushAlertKey = process.env.REACT_APP_PUSH_ALERT_ID;
export const WEGLOT_API_KEY = process.env.REACT_APP_WEGLOT_API_KEY || '';

export const GOOGLE_OPTIMIZE_SCRIPT = `var go = document.createElement("script"),
go_s = document.getElementsByTagName("script")[0];
go.type = "text/javascript";
go.src = "https://www.googleoptimize.com/optimize.js?id=${googleOptimizeKey}";
go_s.parentNode.insertBefore(go, go_s);`;

export const ANTI_FLICKER_SCRIPT = `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};(a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;})(window,document.documentElement,'async-hide','dataLayer',4000,{'${websiteName}':true});`;

export const PUSH_ALERT = `(function(d, t) {
    var g = d.createElement(t),
    s = d.getElementsByTagName(t)[0];
    g.src = "https://cdn.pushalert.co/integrate_${pushAlertKey}.js";
    s.parentNode.insertBefore(g, s);
}(document, "script"));`;
