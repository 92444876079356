import i18next from 'i18next';

i18next.addResources('es_CL', 'papaPoints', {
  seeMoreBenefits: 'Ver más beneficios',
  makeYourOrder: 'Haz tu pedido',
  knowMore: 'Saber más',
  exchange: 'Canjear',
  joinHere: '¡Únete aquí!',
  buyAndGetPoints: 'Acumula Papa Puntos con tus compras',
  startUsingPoints: 'Empieza a acumular Papa Puntos',
  freeDelivery:
    'No pierdas este beneficio exclusivo para tu nivel. Tu primer pedido hoy tiene delivery gratis.',
  joinAndGetPoints:
    'Únete y acumula puntos con tus compras para canjearlos por pizzas, acompañamientos y más.'
});
