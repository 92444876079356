/* eslint-disable max-params */
/* eslint-disable max-lines */
import { completeTypes, createTypes, withPostFailure, withPostSuccess } from 'redux-recompose';
import { push } from 'connected-react-router';

import { addCartItem } from '~utils/shoppingCart';
import IngredientsService from '~services/Ingredients/service';
import PizzasService from '~services/Pizzas/service';
import { deserializer } from '~services/baseSerializers';
import ShoppingCartService from '~services/ShoppingCart/service';
import { actionCreators as homeActions } from '~screens/Dashboard/screens/Home/redux/actions';
import { completeIngredientData } from '~components/CustomPizza/utils';
import { CUSTOM_HALF_OPTIONS } from '~components/CustomizableOption/constants';
import { EXTERNAL_OFFER_TARGET } from '~screens/Dashboard/screens/Home/redux/constants';
import { isBase } from '~utils/pizza';
import { actionCreators as offerActions } from '~components/ModifyProductModals/screens/ComboCreation/redux/actions';
import { createOfferItem } from '~components/ModifyProductModals/screens/ComboCreation/utils';
import { PRODUCT_TYPES } from '~screens/Dashboard/screens/Home/components/Menu/constants';

import {
  CUSTOM_PIZZA_CONFIG_TARGET,
  HELPERS_TARGET,
  PRICES_TARGET,
  OFFER_TARGET,
  SELECTED_OFFER_ID_TARGET,
  SELECTIONS_TARGET,
  BASE_PIZZA_TARGET,
  BASE_PIZZA_DATA_TARGET,
  SELECTED_VARIANT_TARGET,
  EDITING_CART_ITEM_INDEX_TARGET,
  PIZZA_SPECIALTY_DATA,
  DISABLE_GET_PRICES_TARGET,
  INGREDIENT_ACTIONS,
  halfStoreIdentifier,
  HALF_TO_SIDE,
  PARSER_PIZZA_DATA,
  PRICE_IS_UPDATED_TARGET
} from './constants';
import {
  getPizzaCartItem,
  getOfferCartItem,
  getSelectedMenuableIngredients,
  resetSelectedPizzaHalfIdData,
  completeWithDefaultBases,
  getRestrictionsFromPizza,
  flattenIngredientsWithHalves,
  removeHalfIngredients,
  addDefaultIngredientsToCartItem,
  mergePizzaBases,
  mergePizzaByHalvesBases,
  formatModifiedIngredients,
  sendOfferToGTM
} from './utils';

const completedTypes = completeTypes(
  [
    'GET_INGREDIENTS',
    'GET_PIZZA_PRICE',
    'GET_BASE_PIZZA',
    'GET_OFFER',
    'GET_OFFER_PIZZAS',
    'GET_PARSER_PIZZA_DATA',
    'GET_PIZZA_SPECIALTY_DATA'
  ],
  [
    'CLEAR_ALL',
    'SET_CUSTOM_PIZZA_CONFIG',
    'SET_HELPERS',
    'CLEAR_PIZZA_PRICE',
    'CLEAR_INGREDIENT_DATA',
    'SET_SELECTIONS',
    'ADD_ELEMENT',
    'REMOVE_ELEMENT',
    'SET_CUSTOM_PIZZA_DATA',
    'EDIT_ELEMENT_HALF',
    'EDIT_ELEMENT_QUANTITY',
    'SET_EDITING_CART_ITEM_INDEX',
    // TODO: Refactor to move the next 2 actions to an action/reducer pair in ComboCreation
    'SET_SELECTED_OFFER_ID',
    'SET_SELECTED_VARIANT',
    'SET_DISABLE_GET_PRICES',
    'FORCE_BASE_PIZZA',
    'CLEAR_PARSER_PIZZA_DATA',
    'SET_PRICE_IS_UPDATED'
  ]
);

export const actions = createTypes(completedTypes, '@@CUSTOM_PIZZA');

export const actionCreators = {
  getPrice:
    (stepId, menuableId, variantId, { onFailure = () => undefined, onSuccess = () => undefined } = {}) =>
    (dispatch, getState) => {
      const state = getState();
      const {
        searchStore: { currentSubsidiary, dispatchType },
        customPizza: { disableGetPrices }
      } = state;

      const externalOffer = state.home[EXTERNAL_OFFER_TARGET];

      if (disableGetPrices) {
        return;
      }

      const items = [getPizzaCartItem(state, stepId, menuableId, variantId)];
      dispatch({
        type: actions.GET_PIZZA_PRICE,
        target: PRICES_TARGET,
        service: ShoppingCartService.getPrices,
        payload: {
          items,
          dispatchMethod: dispatchType,
          storeId: currentSubsidiary.id,
          ...(externalOffer && { offerId: externalOffer })
        },
        injections: [
          withPostSuccess((...args) => {
            const newState = getState();
            onSuccess(newState, ...args);
          }),
          withPostFailure(onFailure)
        ]
      });
    },
  getOfferPrice:
    (offer, isOrderBuilder = false) =>
    (dispatch, getState) => {
      const state = getState();
      const {
        searchStore: { currentSubsidiary, dispatchType },
        customPizza: { disableGetPrices },
        customPizza
      } = state;
      const externalOffer = state.home[EXTERNAL_OFFER_TARGET];
      const { currentUser } = state.auth;
      const { wizard } = state;

      if (disableGetPrices) {
        return;
      }

      const offerCartItem = createOfferItem(offer, customPizza, wizard);
      const items = getOfferCartItem(state, offerCartItem);

      dispatch({
        type: actions.GET_PIZZA_PRICE,
        target: PRICES_TARGET,
        service: ShoppingCartService.getPrices,
        payload: {
          storeId: currentSubsidiary.id,
          dispatchMethod: dispatchType,
          items: [items],
          externalUserId: currentUser?.externalId,
          ...(externalOffer && { offerId: externalOffer }),
          isOrderBuilder
        }
      });
    },
  clearPrice: () => ({
    type: actions.CLEAR_PIZZA_PRICE,
    target: PRICES_TARGET,
    payload: null
  }),
  setHelpers: helpers => ({
    type: actions.SET_HELPERS,
    target: HELPERS_TARGET,
    payload: helpers
  }),
  getIngredients: () => (dispatch, getState) => {
    const { translations } = getState().sizesAndCrusts.data;
    dispatch({
      type: actions.GET_INGREDIENTS,
      target: CUSTOM_PIZZA_CONFIG_TARGET,
      service: IngredientsService.getIngredients,
      payload: state => state.searchStore.currentSubsidiary?.id,
      successSelector: response => {
        const { data } = deserializer.serialize(response);
        const newData = {
          data,
          translations
        };
        return deserializer.serialize(newData);
      }
    });
  },
  addElement: ({ element, stepId, elementType = element.category, uniqueness }) => ({
    type: actions.ADD_ELEMENT,
    target: SELECTIONS_TARGET,
    payload: { element, elementType, stepId, uniqueness }
  }),
  removeElement: ({ element, stepId, elementType = element.category }) => ({
    type: actions.REMOVE_ELEMENT,
    target: SELECTIONS_TARGET,
    payload: { element, elementType, stepId }
  }),
  editElementHalf: ({ element, stepId, elementType = element.category }) => ({
    type: actions.EDIT_ELEMENT_HALF,
    target: SELECTIONS_TARGET,
    payload: { element, elementType, stepId }
  }),
  editElementQuantity: ({ element, stepId, elementType = element.category, half }) => ({
    type: actions.EDIT_ELEMENT_QUANTITY,
    target: SELECTIONS_TARGET,
    payload: { element, elementType, stepId, half }
  }),
  setSelections: selections => ({
    type: actions.SET_SELECTIONS,
    target: SELECTIONS_TARGET,
    payload: selections
  }),
  clearAll: () => ({
    type: actions.CLEAR_ALL
  }),
  setCustomPizzaConfig: config => dispatch => {
    dispatch({
      type: actions.SET_CUSTOM_PIZZA_CONFIG,
      target: CUSTOM_PIZZA_CONFIG_TARGET,
      payload: config
    });
  },
  parserPizzaData:
    (menuableId, menuableType = PRODUCT_TYPES.pizza) =>
    (dispatch, getState) => {
      const state = getState();
      const storeId = state.searchStore?.currentSubsidiary?.id;
      const dispatchMethod = state.searchStore?.dispatchType;

      dispatch({
        type: actions.GET_PARSER_PIZZA_DATA,
        target: PARSER_PIZZA_DATA,
        service: PizzasService.parserPizza,
        successSelector: ({ data }) => deserializer.serialize(data),
        payload: { menuableId, storeId, menuableType, dispatchMethod }
      });
    },
  clearParserPizzaData: () => ({
    type: actions.CLEAR_PARSER_PIZZA_DATA,
    target: PARSER_PIZZA_DATA
  }),
  setCustomPizzaData: data => dispatch => {
    dispatch({
      type: actions.SET_CUSTOM_PIZZA_DATA,
      target: BASE_PIZZA_DATA_TARGET,
      payload: data
    });
  },
  resetSelections: (stepId, half) => (dispatch, getState) => {
    const { selections } = getState().customPizza;

    return dispatch(
      actionCreators.setSelections({
        ...selections,
        [stepId]: {
          ...selections?.[stepId],
          ingredients: removeHalfIngredients(selections?.[stepId]?.ingredients, half),
          bases: removeHalfIngredients(selections?.[stepId]?.bases, half)
        }
      })
    );
  },
  setDefaultEditPizzaValues:
    ({ menuable, stepId, half = CUSTOM_HALF_OPTIONS.whole, cartIndex = null, onSuccess, modifyFromCart }) =>
    (dispatch, getState) => {
      const state = getState();
      const {
        searchStore: { currentSubsidiary, dispatchType: dispatchMethod }
      } = state;

      dispatch({
        type: actions.GET_BASE_PIZZA,
        target: BASE_PIZZA_TARGET,
        service: PizzasService.showPizza,
        successSelector: response => ({
          ...deserializer.serialize(response.data),
          restrictions: getRestrictionsFromPizza(menuable)
        }),
        payload: { menuableId: menuable?.menuableId, storeId: currentSubsidiary.id, dispatchMethod },
        injections: [
          // TODO: Found a better way to calculate all ingredients price
          withPostSuccess((_, response) => {
            const { config } = getState().customPizza;
            const data = deserializer.serialize(response.data);
            const ingredients = data?.ingredients
              .filter(i => !isBase(i))
              .map(i => ({
                ...completeIngredientData(i),
                half
              }));

            const currentBases = data?.ingredients
              .filter(i => isBase(i))
              .map(i => ({
                ...completeIngredientData(i),
                half
              }));

            const bases = completeWithDefaultBases(currentBases, config, half);

            const halfId = halfStoreIdentifier(half);
            const defaultIngredients = config[BASE_PIZZA_DATA_TARGET]?.defaultIngredients;

            // I have to do this before the selections are done
            // cartIndex can be 0
            if (cartIndex !== null && cartIndex !== undefined) {
              dispatch(actionCreators.setEditingCartItemIndex(cartIndex));
            }

            dispatch(
              actionCreators.setCustomPizzaData({
                ...config[BASE_PIZZA_DATA_TARGET],
                [halfId]: menuable.menuableId,
                defaultIngredients: {
                  ...config[BASE_PIZZA_DATA_TARGET]?.defaultIngredients,
                  [menuable.menuableId]: {
                    ...config[BASE_PIZZA_DATA_TARGET]?.defaultIngredients?.[menuable.menuableId],
                    [half]: data.ingredients.map(i => ({ ...i, half }))
                  },
                  ...resetSelectedPizzaHalfIdData(defaultIngredients, half)
                }
              })
            );

            if (onSuccess) {
              onSuccess();
            }
            dispatch(
              actionCreators.setPizzaCreationSelections({
                menuable,
                stepId,
                ingredients,
                bases,
                half,
                modifyFromCart
              })
            );
          })
        ]
      });
    },
  /* eslint-disable complexity */
  setPizzaCreationSelections:
    ({
      menuable,
      stepId,
      ingredients = [],
      bases = [],
      half = CUSTOM_HALF_OPTIONS.whole,
      cartIndex = null,
      onSuccess,
      modifyFromCart
    }) =>
    (dispatch, getState) => {
      const { selections, config } = getState().customPizza;
      const completeMenuableWithDefault = {
        ...menuable,
        halves: {
          ...menuable.halves,
          right: { ...menuable.halves?.right, ingredients: [...(menuable.halves?.right?.ingredients || [])] },
          left: { ...menuable.halves?.left, ingredients: [...(menuable.halves?.left?.ingredients || [])] }
        }
      };

      if (modifyFromCart && half !== CUSTOM_HALF_OPTIONS.whole) {
        completeMenuableWithDefault.halves[HALF_TO_SIDE[half]].ingredients = addDefaultIngredientsToCartItem(
          completeMenuableWithDefault.halves[HALF_TO_SIDE[half]].ingredients,
          ingredients,
          half
        );
      }

      const { menuableIngredients, menuableBases } = getSelectedMenuableIngredients(
        completeMenuableWithDefault,
        config.ingredients,
        selections ? [...(selections[stepId]?.ingredients || []), ...(selections[stepId]?.bases || [])] : []
      );
      let crustsAndSizes = {};

      if (menuable.size && menuable.crust) {
        const crusts = config.crusts.filter(crust => crust.id === menuable.crust) || [];
        const sizes = config.sizes.filter(size => size.id === menuable.size);

        crustsAndSizes = { crusts, sizes };
      }

      const selectedIngredients = flattenIngredientsWithHalves([
        ...(selections?.[stepId]?.ingredients?.filter(i => i.half !== half) || []),
        ...menuableIngredients.filter(i => i.action !== INGREDIENT_ACTIONS.REMOVE),
        ...(modifyFromCart
          ? []
          : ingredients.filter(i =>
              menuableIngredients.every(
                selectedIngredient =>
                  selectedIngredient.id !== i.id || selectedIngredient.action !== INGREDIENT_ACTIONS.REMOVE
              )
            ))
      ]);

      const selectedBases = modifyFromCart
        ? mergePizzaByHalvesBases(
            menuableBases,
            selections?.[stepId]?.bases,
            bases,
            half === CUSTOM_HALF_OPTIONS.half2
          )
        : mergePizzaBases(menuableBases, bases, selections?.[stepId]?.bases);
      dispatch(
        actionCreators.setSelections({
          ...selections,
          [stepId]: {
            ...selections?.[stepId],
            ingredients: selectedIngredients,
            bases: selectedBases,
            ...crustsAndSizes,
            allElements: [...selectedIngredients, ...selectedBases]
          }
        })
      );

      // cartIndex can be 0
      if (cartIndex !== null && cartIndex !== undefined) {
        dispatch(actionCreators.setEditingCartItemIndex(cartIndex));
      }
      if (onSuccess) {
        onSuccess();
      }
    },
  setDisableGetPrices: disable => ({
    type: actions.SET_DISABLE_GET_PRICES,
    target: DISABLE_GET_PRICES_TARGET,
    payload: disable
  }),
  setEditingCartItemIndex: index => ({
    type: actions.SET_EDITING_CART_ITEM_INDEX,
    target: EDITING_CART_ITEM_INDEX_TARGET,
    payload: index
  }),
  clearIngredientData: () => ({
    type: actions.CLEAR_INGREDIENT_DATA,
    target: SELECTIONS_TARGET
  }),
  addPizzaToCart:
    (stepId, finishedPizza, onSuccess, quantity = 1, replaceCartQuantity = false) =>
    (dispatch, getState) => {
      const state = getState();
      const {
        home: { shoppingCart },
        customPizza: { editingCartItemIndex }
      } = state;
      const pizzaItem = finishedPizza || getPizzaCartItem(state, stepId);
      let items = [...shoppingCart.items];

      if (editingCartItemIndex !== null) {
        if (items[editingCartItemIndex].quantity === 1) {
          items = [...items.slice(0, editingCartItemIndex), ...items.slice(editingCartItemIndex + 1)];
        } else {
          items[editingCartItemIndex] = {
            ...items[editingCartItemIndex],
            quantity: items[editingCartItemIndex].quantity
          };
        }
      }

      const addIndex =
        editingCartItemIndex !== null && editingCartItemIndex !== undefined
          ? editingCartItemIndex
          : undefined;
      const selectedItems = addCartItem(items, pizzaItem, addIndex, false, quantity, replaceCartQuantity);

      dispatch(homeActions.getCartPrices(selectedItems, { onSuccess }));
    },
  // TODO: Refactor to move this next 3 action creators to a action/reducer pair in ComboCreation
  setSelectedOfferId: offerId => ({
    type: actions.SET_SELECTED_OFFER_ID,
    target: SELECTED_OFFER_ID_TARGET,
    payload: offerId
  }),
  addComboToCart: (newOfferItem, path, editingId) => async (dispatch, getState) => {
    const state = getState();
    const {
      home: { shoppingCart }
    } = state;

    const newOfferCartItem = getOfferCartItem(state, newOfferItem);

    let items = [...shoppingCart.items];

    if (editingId) {
      const editedItemIndex = items.findIndex(item => item.uuid === editingId);
      if (items[editedItemIndex].quantity === 1) {
        items = [...items.slice(0, editedItemIndex), ...items.slice(editedItemIndex + 1)];
      } else {
        items[editedItemIndex] = {
          ...items[editedItemIndex],
          quantity: items[editedItemIndex].quantity - 1
        };
      }
    }

    const selectedItems = addCartItem(items, newOfferCartItem);

    await dispatch(homeActions.getCartPrices(selectedItems));

    const newState = getState();
    const {
      home: { shoppingCart: newShoppingCart }
    } = newState;

    const singleOffer = newShoppingCart.items.find(({ offerId }) => offerId === newOfferCartItem.offerId);
    sendOfferToGTM(singleOffer, newShoppingCart.currency);

    if (path) {
      dispatch(push(path));
    }
  },
  addCompleteComboToCart:
    ({ items: combo, cartIndex = undefined, canReplace }) =>
    (dispatch, getState) => {
      const {
        home: { shoppingCart }
      } = getState();
      const items = [...shoppingCart.items];
      const selectedItems = addCartItem(items, combo, cartIndex, canReplace);
      dispatch(homeActions.getCartPrices(selectedItems));
      dispatch(offerActions.modifyPromoBuilderOffer(null));
    },
  getOfferPizzas: (pizzaIds, onSuccess, storeId, dispatchMethod) => ({
    type: actions.GET_OFFER_PIZZAS,
    target: OFFER_TARGET,
    service: PizzasService.showPizzas,
    payload: { pizzaIds, storeId, dispatchMethod },
    successSelector: response => deserializer.serialize(response.data),
    injections: withPostSuccess((dispatch, response) => {
      const pizzaData = deserializer.serialize(response).data;

      if (onSuccess) {
        onSuccess(pizzaIds.map(pizzaId => pizzaData[pizzaId]));
      }
    })
  }),
  setSelectedVariant: selectedVariant => ({
    type: actions.SET_SELECTED_VARIANT,
    target: SELECTED_VARIANT_TARGET,
    payload: selectedVariant
  }),
  setForceBasePizza: pizza => ({
    type: actions.FORCE_BASE_PIZZA,
    target: BASE_PIZZA_TARGET,
    payload: pizza
  }),
  setModifyPizzaPromo: (pizza, storeId, dispatchMethod) => ({
    type: actions.GET_BASE_PIZZA,
    target: BASE_PIZZA_TARGET,
    service: PizzasService.showPizza,
    successSelector: response => {
      const {
        ingredients: originalIngredients,
        variants,
        freeExtras
      } = deserializer.serialize(response).data;
      const hasVariants = !!pizza.variants?.length;
      const pizzaRestrictions = hasVariants ? pizza : response.data;

      const diffIngredients = formatModifiedIngredients({
        currentIngredients: pizza.ingredients,
        originalIngredients
      });

      return {
        ...pizza,
        restrictions: getRestrictionsFromPizza(pizzaRestrictions),
        ingredients: diffIngredients || deserializer.serialize(originalIngredients),
        originalIngredients,
        ...(!hasVariants && { variants }),
        ...(!pizza?.freeExtras && { freeExtras })
      };
    },
    payload: { menuableId: pizza.menuableId, storeId, dispatchMethod }
  }),
  getPizzaSpecialtyData: ({ pizzaIds, storeId, dispatchMethod }) => ({
    type: actions.GET_PIZZA_SPECIALTY_DATA,
    target: PIZZA_SPECIALTY_DATA,
    service: PizzasService.showPizzas,
    payload: { pizzaIds, storeId, dispatchMethod },
    successSelector: response => deserializer.serialize(response.data)
  }),
  setPriceIsUpdated: isUpdated => ({
    type: actions.SET_PRICE_IS_UPDATED,
    target: PRICE_IS_UPDATED_TARGET,
    payload: isUpdated
  })
};
