import api, { contactFormApi } from '~config/api';

import { formatContactForm, formatFranchisesContactForm } from './utils';

const SUCCESS_REQUEST = 200;
const ERROR_REQUEST = 300;
const contactFormUrl = process.env.REACT_APP_ZENDESK_URL;

const ContactFormService = {
  sendContactForm: json => {
    contactFormApi.setHeaders({
      'Content-Type': 'application/json'
    });

    return contactFormApi.post('/requests.json', formatContactForm(json));
  },
  sendAttachment: newFiles => {
    const blob = new Blob(newFiles, { type: 'image/jpg' });

    const request = new XMLHttpRequest();

    return new Promise(resolve => {
      request.onreadystatechange = () => {
        if (request.status >= SUCCESS_REQUEST && request.status < ERROR_REQUEST) {
          resolve({
            ...JSON.parse(request.responseText),
            ok: SUCCESS_REQUEST
          });
        }
      };

      request.open('POST', `${contactFormUrl}uploads.json?filename=onlys`, true);

      request.send(blob);
    });
  },
  sendFranchisesContactForm: json => api.post('/v1/users/contacts', formatFranchisesContactForm(json))
};

export default ContactFormService;
