import { CLIENT_PAGES } from '~constants/pages';
import { COUNTRIES, CURRENT_COUNTRY_ID } from '~constants/environment';

export const INGENICO_BODY_REQUEST = {
  urls: {
    acceptUrl: `${window.location.origin}${CLIENT_PAGES.PURCHASE_CONTROL.path}/`,
    cancelUrl: `${window.location.origin}${CLIENT_PAGES.PURCHASE_CONTROL.path}/`,
    exceptionUrl: `${window.location.origin}${CLIENT_PAGES.PURCHASE_CONTROL.path}/`,
    declineUrl: `${window.location.origin}${CLIENT_PAGES.PURCHASE_CONTROL.path}/`
  }
};

export const OFFLINE_RETRY_TIMES = 2;

const CARD_IS_ONLINE = {
  [COUNTRIES.cl]: false,
  [COUNTRIES.es]: true,
  [COUNTRIES.pt]: false,
  [COUNTRIES.pa]: false,
  [COUNTRIES.cr]: false,
  [COUNTRIES.gt]: false
};

export const COUNTRY_CARD_IS_ONLINE = CARD_IS_ONLINE[CURRENT_COUNTRY_ID];

export const NO_DIRECTION = 'No direction';

export const ORDER_ENDPOINT = '/v1/orders';

export const ORDER_CALLCENTER_ENDPOINT = '/v1/call_center/orders';

export const HEADERS = {
  USER_EMAIL: 'X-USER-EMAIL'
};
