import api from '~config/api';

const PictureService = {
  dealPictures: ({ dispatchMethod, category, offerId, storeId }) =>
    api.get('/v1/deal_pictures', {
      dispatch_method: dispatchMethod, // eslint-disable-line camelcase
      category,
      store_id: storeId, // eslint-disable-line camelcase
      ...(offerId && { offer_id: offerId }) // eslint-disable-line camelcase
    })
};

export default PictureService;
