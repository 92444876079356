/* eslint-disable max-lines */
// TODO: Try to break this file into small pieces
import {
  completeTypes,
  createTypes,
  withPostSuccess,
  withPostFailure,
  withSuccess,
  withPostFetch
} from 'redux-recompose';
import { t } from 'i18next';
import { push } from 'connected-react-router';
import withFailure from 'redux-recompose/lib/injections/withFailure';

import AuthService from '~services/Auth/service';
import CallCenterService from '~services/Callcenter/service';
import { CLIENT_PAGES, CALL_CENTER_PAGES } from '~constants/pages';
import { RENDER_CALL_CENTER, SAVE_GUEST_USER_DATA_ENABLED } from '~constants/environment';
import cognitoErrors from '~constants/errors';
import LocalStorageService from '~services/LocalStorageService';
import { deserializer as baseDeserializer } from '~services/baseSerializers';
import { serializer, currentUserDeserealizer } from '~services/Auth/serializers';
import { actionCreators as modalActions } from '~redux/Modal/actions';
import { actionCreators as actionPapaPoints } from '~redux/PapaPoints/actions';
import { actionCreators as homeActions } from '~screens/Dashboard/screens/Home/redux/actions';
import { actionCreators as searchStoreActions } from '~redux/SearchStore/actions';
import { actionCreators as orderActions } from '~redux/Orders/actions';
import { actionCreators as notificationActions } from '~redux/Notification/actions';
import { USER_ADRESSES } from '~redux/SearchStore/constants';
import {
  LOGIN,
  REGISTER_MODAL,
  CHANGE_PHONE_MODAL,
  NEW_GUEST_USER_MODAL,
  CHANGE_INFO_USER_MODAL,
  COMPLETE_SIGNIN_MODAL
} from '~redux/Modal/constants';
import { setTokenHeader } from '~config/api';
import { cognitoUserPool } from '~config/cognito';
import { getSocialLoginBody } from '~services/Auth/utils';
import { SHOPPING_CART_TARGET } from '~screens/Dashboard/screens/Home/redux/constants';
import { NOTIFICATION_DANGER } from '~redux/Notification/constants';
import { setCookie, deleteCookie } from '~utils/cookies';
import { getQueryString, getQueryParams } from '~utils/parseUrl';
import { COOKIE_EXPIRE_DAYS } from '~screens/Dashboard/screens/Home/constants';
import { parseLatLon } from '~utils/address';
import { sendGTMEvent, EVENT, eventCategory, eventAction, eventLabel } from '~utils/analytics';
import { mapUpdateMeUser } from '~mappers/user';
import { mercantilEnabled } from '~utils/paymentMethods';
import { LOGIN_PASSWORDLESS_ENABLED } from '~constants/features';

import {
  CURRENT_USER_TARGET,
  REGISTER_TARGET,
  ADDRESS_TARGET,
  CODE_TARGET,
  RESEND_SMS_TARGET,
  RECOVER_PASSWORD_TARGET,
  VERIFY_RECOVER_PASSWORD_TARGET,
  GUEST_USER_TARGET,
  X_GUEST_TOKEN,
  MAX_LAST_ADDRESSES,
  IS_PASSWORD_UPDATED,
  IS_UPDATING_CURRENT_USER,
  IS_SOCIAL_USER,
  PAYMENT_METHOD,
  CARD_PAYMENT_METHOD,
  CALLCENTER_GUEST_USER_TARGET,
  GUEST_USER_DATA_COOKIE
} from './constants';

const completedTypes = completeTypes(
  [
    'LOGIN',
    'LOGIN_ONBOARDING',
    'REGISTER',
    'SET_ADDRESS',
    'SEND_CODE',
    'RESEND_SMS',
    'RECOVER_PASSWORD',
    'VERIFY_RECOVER_PASSWORD',
    'CREATE_GUEST_USER',
    'GET_GUEST_USER',
    'GET_CURRENT_USER',
    'UPDATE_USER_DATA',
    'UPDATE_PASSWORD',
    'SET_CALLCENTER_GUEST_USER',
    'GET_CALLCENTER_GUEST_USER',
    'CREATE_CALLCENTER_GUEST_USER'
  ],
  [
    'LOGOUT',
    'CHECK_AUTH',
    'RESET_CODE',
    'RESET_AUTH',
    'RESET_CODE_VALIDATION',
    'CLEAR_USER_DATA',
    'SET_VALUE'
  ]
);

export const actions = createTypes(completedTypes, '@@AUTH');

export const privateActionCreators = {
  clearUserData: target => ({
    type: actions.CLEAR_USER_DATA,
    target
  }),
  setAddressFailure: payload => ({
    type: actions.SET_ADDRESS_FAILURE,
    target: ADDRESS_TARGET,
    payload
  }),
  setAddressSuccess: payload => ({
    type: actions.SET_ADDRESS_SUCCESS,
    target: ADDRESS_TARGET,
    payload
  }),
  createGuestUserFailure: payload => ({
    type: actions.CREATE_GUEST_USER_FAILURE,
    target: GUEST_USER_TARGET,
    payload
  }),
  createGuestUserSuccess: payload => ({
    type: actions.CREATE_GUEST_USER_SUCCESS,
    target: GUEST_USER_TARGET,
    payload
  }),
  getGuestUserFailure: payload => ({
    type: actions.GET_GUEST_USER_FAILURE,
    target: GUEST_USER_TARGET,
    payload
  }),
  getCallCenterGuestUserSucess: payload => ({
    type: actions.GET_CALLCENTER_GUEST_USER_SUCCESS,
    target: CALLCENTER_GUEST_USER_TARGET,
    payload
  }),
  getCallCenterGuestUserFailure: payload => ({
    type: actions.GET_CALLCENTER_GUEST_USER_FAILURE,
    target: CALLCENTER_GUEST_USER_TARGET,
    payload
  })
};

const loginInjections = (actionCreators, { isPasswordless } = {}) => [
  withPostFetch(dispatch => {
    dispatch(orderActions.clearAllOrderData());
  }),
  withPostSuccess((dispatch, response, state) => {
    if (RENDER_CALL_CENTER) {
      LocalStorageService.setCallCenter(true);
      dispatch(push(CALL_CENTER_PAGES.NEW_ORDER.path));
      return;
    }

    if (!isPasswordless && response.data.phone_number) {
      dispatch(modalActions.closeModal(LOGIN));
    }
    dispatch(actionCreators.getCurrentUser());
    dispatch(searchStoreActions.getUserAddress());
    dispatch(homeActions.getPaymentCards(mercantilEnabled(state.searchStore?.currentSubsidiary)));
    const { currentUser } = state.auth;
    setCookie(CURRENT_USER_TARGET, currentUser.email, COOKIE_EXPIRE_DAYS);
  })
];

export const actionCreators = {
  loginService: authData => ({
    type: actions.LOGIN,
    target: CURRENT_USER_TARGET,
    service: AuthService.login,
    payload: serializer.serialize(authData),
    successSelector: response => baseDeserializer.serialize(response.data),
    injections: loginInjections(actionCreators)
  }),
  loginPasswordless: data => ({
    type: actions.LOGIN,
    target: CURRENT_USER_TARGET,
    service: () => ({ ok: true, data }),
    successSelector: response => baseDeserializer.serialize(response.data),
    injections: loginInjections(actionCreators, { isPasswordless: true })
  }),
  logout: () => dispatch => {
    AuthService.logout();
    LocalStorageService.removeAddresses();
    LocalStorageService.removeCallCenter();
    LocalStorageService.removeCallCenterUser();
    deleteCookie(PAYMENT_METHOD);
    deleteCookie(CARD_PAYMENT_METHOD);
    deleteCookie(CURRENT_USER_TARGET);
    dispatch(homeActions.clearRedeemCode());
    dispatch(homeActions.clearCart());
    dispatch(orderActions.clearAllOrderData());
    dispatch(searchStoreActions.resetSubsidiary());
    dispatch(actionCreators.clearUserData());
    dispatch({ type: actions.CHECK_AUTH });
    dispatch(searchStoreActions.clearUserAddresses());
    dispatch(searchStoreActions.clearUserCompanies());
    dispatch(
      searchStoreActions.setSelectedCompanyForReceipt({ company: null, checkAllowReceiptCompany: false })
    );
    dispatch(homeActions.clearPaymentCards());
    dispatch(push(CLIENT_PAGES.HOME.path));
    setTokenHeader(null);
  },
  getLocalStorageData: () => async dispatch => {
    const cognitoUser = await cognitoUserPool.getCurrentUser();
    const guestUser = LocalStorageService.getGuestUser();
    const socialUser = LocalStorageService.getSocialUser();
    const addresses = LocalStorageService.getAddresses();
    const callCenter = LocalStorageService.getCallCenter();
    if (cognitoUser) {
      cognitoUser.getSession((_, session) => {
        if (session && session.isValid()) {
          dispatch({
            type: actions.LOGIN_SUCCESS,
            target: CURRENT_USER_TARGET,
            payload: { ...baseDeserializer.serialize(session.idToken.payload) }
          });
          setTokenHeader(session.idToken.jwtToken);
          if (!callCenter) {
            const onSuccess = LOGIN_PASSWORDLESS_ENABLED
              ? user => {
                  if (!user.isComplete) {
                    dispatch(dispatch(modalActions.openModal(COMPLETE_SIGNIN_MODAL)));
                  }
                }
              : undefined;
            dispatch(actionCreators.getCurrentUser({ onSuccess }));
          }
        }
      });
      dispatch(privateActionCreators.getGuestUserFailure());
    } else if (socialUser?.token) {
      setTokenHeader(socialUser.token);
      dispatch({
        type: actions.SET_VALUE,
        target: IS_SOCIAL_USER,
        payload: true
      });
      dispatch(actionCreators.getCurrentUser());
    } else if (guestUser?.token) {
      setTokenHeader(guestUser.token);
      dispatch(actionCreators.getCurrentUser({ isGuest: true }));
    } else {
      dispatch(privateActionCreators.getGuestUserFailure());
      dispatch({
        type: actions.GET_CURRENT_USER_FAILURE,
        target: CURRENT_USER_TARGET
      });
    }

    if (addresses) {
      dispatch(
        privateActionCreators.setAddressSuccess({
          ...addresses,
          current: { ...addresses.current, ...parseLatLon(addresses.current) }
        })
      );
    } else {
      dispatch(privateActionCreators.setAddressFailure());
    }
  },
  signUp: newUserData => ({
    type: actions.REGISTER,
    target: REGISTER_TARGET,
    service: AuthService.signUp,
    payload: serializer.serialize(newUserData),
    successSelector: response => baseDeserializer.serialize(response.data),
    injections: [
      withPostSuccess(() => {
        sendGTMEvent(EVENT.eventPJ, {
          eventActionCategory: eventCategory.microconversion,
          action: eventAction.validRegister,
          label: eventLabel.newRegister
        });
      })
    ]
  }),
  subscribeNewsletter: newUserData => ({
    type: actions.SUBSCRIBE_NEWSLETTER,
    service: AuthService.subscribeNewsletter,
    payload: newUserData
  }),
  getCurrentUser:
    (
      { isGuest, onSuccess, onFailure } = {
        isGuest: false
      }
    ) =>
    (dispatch, getState) =>
      dispatch({
        type: actions.GET_CURRENT_USER,
        target: CURRENT_USER_TARGET,
        service: AuthService.getCurrentUser,
        payload: cognitoUserPool.getCurrentUser(),
        injections: [
          withPostFailure(response => {
            dispatch(actionCreators.logout({ redirectToLanding: false }));
            onFailure?.(response.problem);
          }),
          withSuccess((_, response) => {
            const state = getState();
            const deserializedData = currentUserDeserealizer.serialize(response.data);
            const pointsSpent = state.home[SHOPPING_CART_TARGET].items.reduce(
              (accumulate, currentValue) => currentValue.pointsSpent + accumulate,
              0
            );
            const currentPoints = deserializedData?.totalPoints - pointsSpent;

            dispatch(actionPapaPoints.setCurrentPoints(currentPoints));
            dispatch(actionCreators.setUserData(deserializedData, isGuest));

            if (!state.auth.address?.current) {
              dispatch(actionCreators.setAddress(deserializedData.address));
            }
            onSuccess?.(deserializedData);
          })
        ]
      }),
  setUserData: (data, isGuest, isCallCenter) => dispatch => {
    if (isGuest) {
      dispatch({
        type: actions.GET_GUEST_USER_SUCCESS,
        target: GUEST_USER_TARGET,
        payload: data
      });
      dispatch(privateActionCreators.clearUserData(CURRENT_USER_TARGET));
    } else if (isCallCenter) {
      dispatch({
        type: actions.SET_CALLCENTER_GUEST_USER,
        target: CALLCENTER_GUEST_USER_TARGET,
        payload: data
      });
      dispatch(actionCreators.setValue(`${CALLCENTER_GUEST_USER_TARGET}Loading`, false));
    } else {
      dispatch({
        type: actions.GET_CURRENT_USER_SUCCESS,
        target: CURRENT_USER_TARGET,
        payload: data
      });
      dispatch(privateActionCreators.clearUserData(GUEST_USER_TARGET));

      LocalStorageService.removeGuestUser();
    }
  },
  clearUserData: () => dispatch => {
    dispatch(privateActionCreators.clearUserData(CURRENT_USER_TARGET));
    dispatch(privateActionCreators.clearUserData(GUEST_USER_TARGET));
    LocalStorageService.removeSocialUser();
    dispatch({
      type: actions.SET_VALUE,
      target: IS_SOCIAL_USER,
      payload: false
    });
  },
  clearGuestOnCurrentUserData: () => dispatch => {
    dispatch(privateActionCreators.clearUserData(CURRENT_USER_TARGET));
  },
  setCompanyCallCenterUser: (company, callCenterUser) => dispatch => {
    const newState = { ...callCenterUser, company };
    LocalStorageService.setCallCenterUser(newState);
    dispatch(privateActionCreators.getCallCenterGuestUserSucess(newState));
  },
  setAddress: address => (dispatch, getState) => {
    const localStorageAddresses = LocalStorageService.getAddresses();
    const addresses = { current: address, history: [] };
    const userAddresses = getState().searchStore[USER_ADRESSES];
    const isAddressSaved = userAddresses?.filter(item => item.textAddress === address.textAddress).length;

    if (!isAddressSaved) {
      addresses.history.push(address);
    }

    if (localStorageAddresses?.history) {
      localStorageAddresses.history.forEach(item => {
        if (item.textAddress !== address.textAddress && addresses.history.length < MAX_LAST_ADDRESSES) {
          addresses.history.push(item);
        }
      });
    }

    if (!address) {
      dispatch(privateActionCreators.setAddressFailure());
      LocalStorageService.removeAddresses();

      return;
    }

    LocalStorageService.setAddresses(addresses);
    dispatch(
      privateActionCreators.setAddressSuccess({
        ...addresses,
        current: { ...addresses.current, ...parseLatLon(addresses.current) }
      })
    );
  },
  clearAddress: () => dispatch => {
    dispatch(privateActionCreators.setAddressFailure());
    LocalStorageService.removeAddresses();
  },
  sendVerificationCode: (code, userData) => ({
    type: actions.SEND_CODE,
    target: CODE_TARGET,
    service: AuthService.sendCode,
    payload: { code, username: userData.email },
    successSelector: response => baseDeserializer.serialize(response.data),
    failureSelector: response => baseDeserializer.serialize(response.problem),
    injections: [
      withPostSuccess((dispatch, _, state) => {
        dispatch(actionCreators.loginService(userData));
        if (state.modal[`${REGISTER_MODAL}ModalIsOpen`]) {
          dispatch(modalActions.closeModal(REGISTER_MODAL));
        }
      })
    ]
  }),
  resendSMS: email => ({
    type: actions.RESEND_SMS,
    target: RESEND_SMS_TARGET,
    service: AuthService.resendSMS,
    payload: email,
    successSelector: response => baseDeserializer.serialize(response.data),
    injections: [
      withPostSuccess(dispatch => {
        dispatch({
          type: actions.RESET_CODE,
          target: `${CODE_TARGET}Error`
        });
      })
    ]
  }),
  recoverPassword: email => ({
    type: actions.RECOVER_PASSWORD,
    target: RECOVER_PASSWORD_TARGET,
    service: AuthService.recoverPassword,
    payload: email
  }),
  socialLogin: data => ({
    type: actions.LOGIN,
    target: CURRENT_USER_TARGET,
    service: AuthService.socialLogin,
    payload: getSocialLoginBody(data),
    injections: [
      withPostSuccess((dispatch, response, state) => {
        const userData = { ...response.data, token: response.data.tokens.id_token };

        LocalStorageService.setSocialUser(userData);
        dispatch({
          type: actions.SET_VALUE,
          target: IS_SOCIAL_USER,
          payload: true
        });
        dispatch(orderActions.clearAllOrderData());
        dispatch(actionCreators.getCurrentUser());
        dispatch(searchStoreActions.getUserAddress());

        dispatch(homeActions.getPaymentCards(mercantilEnabled(state.searchStore?.currentSubsidiary)));

        const { search } = state.router.location;
        const queryParams = getQueryParams(search);

        delete queryParams.code;
        dispatch(push({ search: getQueryString(queryParams) }));
      }),
      withPostFailure((dispatch, response, state) => {
        const { search } = state.router.location;
        const queryParams = getQueryParams(search);

        delete queryParams.code;
        dispatch(push({ search: getQueryString(queryParams) }));
        dispatch(notificationActions.show({ message: response.problem, type: NOTIFICATION_DANGER }));
      })
    ]
  }),
  setNewPassword: (email, password, code) => ({
    type: actions.VERIFY_RECOVER_PASSWORD,
    target: VERIFY_RECOVER_PASSWORD_TARGET,
    service: AuthService.setNewPassword,
    payload: { email, password, code }
  }),
  resetCodeValidation: () => ({ type: actions.RESET_CODE_VALIDATION }),
  resetAuth: () => ({ type: actions.RESET_AUTH }),
  checkIsGuestUser: userData => ({
    type: actions.CREATE_GUEST_USER,
    target: GUEST_USER_TARGET,
    service: AuthService.createGuestUser,
    payload: userData,
    injections: [
      withSuccess((dispatch, response) => {
        dispatch(privateActionCreators.createGuestUserSuccess({ ...userData?.user, ...response.data }));
      })
    ]
  }),
  createGuestUser: userData => ({
    type: actions.CREATE_GUEST_USER,
    target: GUEST_USER_TARGET,
    service: AuthService.createGuestUser,
    payload: userData,
    injections: [
      withSuccess((dispatch, response) => {
        const token = response.headers[X_GUEST_TOKEN];

        if (!token) {
          dispatch(privateActionCreators.createGuestUserFailure({ error: t('Home:noUserToken') }));
          return;
        }

        setTokenHeader(token);
        LocalStorageService.setGuestUser({ token });
        SAVE_GUEST_USER_DATA_ENABLED &&
          setCookie(
            GUEST_USER_DATA_COOKIE,
            JSON.stringify({
              name: userData?.user?.name,
              phone: userData?.user?.phone,
              email: userData?.user?.email
            })
          );
        dispatch(privateActionCreators.createGuestUserSuccess({ ...userData?.user, ...response.data }));
      }),
      withPostFailure((dispatch, response) => {
        dispatch(
          privateActionCreators.createGuestUserFailure({ error: t(`APIErrors:e${response.data?.code}`) })
        );
      })
    ]
  }),
  updateUser: (data, onSuccess) => ({
    type: actions.UPDATE_USER_DATA,
    service: AuthService.updateUser,
    payload: mapUpdateMeUser(data),
    target: IS_UPDATING_CURRENT_USER,
    successSelector: () => true,
    failureSelector: response => ({
      fields: response.data?.fields,
      problem: response.data?.description || response.problem
    }),
    injections: [
      withPostSuccess((dispatch, response) => {
        const deserializedData = baseDeserializer.serialize(response.data);

        dispatch(actionCreators.setUserData(deserializedData));
        dispatch(modalActions.closeModal(CHANGE_PHONE_MODAL));
        onSuccess?.();
      })
    ]
  }),
  updateCallCenterUser: ({ externalId, data, callCenterUser, onSuccess }) => ({
    type: actions.UPDATE_USER_DATA,
    service: CallCenterService.updateUser,
    payload: { externalId, data },
    target: IS_UPDATING_CURRENT_USER,
    successSelector: () => true,
    failureSelector: response => {
      const result = response.data?.description || response.problem;

      return cognitoErrors[result] || result;
    },
    injections: [
      withPostSuccess((dispatch, response) => {
        const deserializedData = baseDeserializer.serialize(response.data);
        dispatch(actionCreators.setUserData(deserializedData, false, true));
        dispatch(modalActions.closeModal(CHANGE_INFO_USER_MODAL));

        const newState = {
          ...callCenterUser,
          name: deserializedData.name,
          phone: deserializedData.phone,
          email: deserializedData.email
        };
        LocalStorageService.setCallCenterUser(newState);
        dispatch(privateActionCreators.getCallCenterGuestUserSucess(newState));
        onSuccess?.();
      })
    ]
  }),
  updatePassword: data => ({
    type: actions.UPDATE_PASSWORD,
    service: AuthService.updatePassword,
    payload: data,
    target: IS_PASSWORD_UPDATED
  }),
  clearTarget: (target, payload) => dispatch =>
    dispatch({
      type: actions.SET_VALUE,
      target,
      payload
    }),
  createCallCenterGuest: (userData, onSuccess) => ({
    type: actions.SET_CALLCENTER_GUEST_USER,
    target: CALLCENTER_GUEST_USER_TARGET,
    service: AuthService.createGuestUser,
    payload: userData,
    injections: [
      withPostSuccess((dispatch, response) => {
        const payload = baseDeserializer.serialize(response.data);
        LocalStorageService.setCallCenterUser(payload);
        dispatch({
          type: actions.SET_VALUE,
          target: CALLCENTER_GUEST_USER_TARGET,
          payload
        });
        onSuccess?.(payload);
      })
    ]
  }),
  getCallCenterUserByPhone: (userPhone, onSuccess) => ({
    type: actions.GET_CALLCENTER_GUEST_USER,
    target: CALLCENTER_GUEST_USER_TARGET,
    service: CallCenterService.getUserByPhone,
    payload: userPhone,
    injections: [
      withSuccess((dispatch, response) => {
        const callCenterUser = baseDeserializer.serialize(response.data);
        LocalStorageService.setCallCenterUser(callCenterUser);
        dispatch(privateActionCreators.getCallCenterGuestUserSucess(callCenterUser));
        onSuccess?.(callCenterUser);
      }),
      withFailure(dispatch => {
        dispatch(modalActions.openModal(NEW_GUEST_USER_MODAL));
        dispatch(actionCreators.setValue(`${CALLCENTER_GUEST_USER_TARGET}Loading`, false));
      })
    ]
  }),
  cleanCallCenterGuestUser: () => dispatch =>
    dispatch(actionCreators.clearTarget(CALLCENTER_GUEST_USER_TARGET)),
  setValue: (target, payload) => ({
    type: actions.SET_VALUE,
    target,
    payload
  })
};
