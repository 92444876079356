import i18next from 'i18next';

i18next.addResources('es_CL', 'QuickOrder', {
  title: '¡Estás a 1 click de tu pedido!',
  subtitle: 'Hola {{username}}, revisa la siguiente información antes de confirmar tu compra:',
  pay: 'Pago',
  paymentMethods: 'Medios de pago',
  tip: 'Propina'
});

i18next.addResources('pt_PT', 'QuickOrder', {
  title: 'Você está a 1 clique do seu pedido!',
  subtitle: 'Olá {{username}}, verifique as informações a seguir antes de confirmar sua compra:',
  pay: 'Pagamento',
  paymentMethods: 'Métodos de pagamento',
  tip: 'Gorjeta'
});

i18next.addResources('es_ES', 'QuickOrder', {
  title: '¡Estás a 1 clic de tu pedido!'
});
