import i18next from 'i18next';

i18next.addResources('es_CL', 'Checkout', {
  addMoreProducts: 'Agregar más productos',
  pageTitle: "Pizzas | Finalizar Pago | Papa John's Chile",
  pageDescription: 'Checkout y confirma tu pedido de pizza.',
  yourProducts: 'Tus productos',
  foundPromos: '¡Encontramos promociones para ti!',
  backToMenu: 'Volver al menú',
  paymentMethodError: 'Debes seleccionar un método de pago',
  popUp3dsMessage:
    'Para usar la autenticación 3DS, debes permitir el uso de ventanas emergentes en el sitio.',
  notesPlaceholder: 'Notas para la tienda',
  notesLabel: 'Notas para la tienda'
});

i18next.addResources('es_ES', 'Checkout', {
  pageTitle: "Pizzas | Finalizar Pago | Papa John's España",
  pageDescription: 'Checkout y confirma tu pedido de pizza.',
  backToMenu: 'Volver al menú',
  notesPlaceholder: 'Notas para la tienda',
  notesLabel: 'Notas para la tienda',
  addMoreProducts: 'Añadir más productos'
});

i18next.addResources('pt_PT', 'Checkout', {
  addMoreProducts: 'Adicionar mais produtos',
  pageTitle: "Pizzas | Fazer Pagamento | Papa John's Portugal",
  pageDescription: 'Checkout e confirma o teu pedido.',
  yourProducts: 'Seus produtos',
  foundPromos: '¡Encontramos promoções para você!',
  backToMenu: 'Voltar ao menú',
  popUp3dsMessage: 'Para utilizar a autenticação 3DS, deve permitir a utilização de janelas pop-up no site.',
  notesPlaceholder: 'Notas para a loja',
  notesLabel: 'Notas para a loja'
});

i18next.addResources('es_CR', 'Checkout', {
  pageTitle: "Pizzas | Finalizar Pago | Papa John's Costa Rica"
});

i18next.addResources('es_PA', 'Checkout', {
  pageTitle: "Pizzas | Finalizar Pago | Papa John's Panama"
});

i18next.addResources('pt_PT', 'FiscalDataText', {
  pt: 'Caso necessite de factura com dados fiscais envie os mesmos para o e-mail <a class="text-caribbean-green" href="mailto:apoiocliente@ppjportugal.pt">apoiocliente@ppjportugal.pt</a> Enviaremos a sua factura para o e-mail indicado num prazo máximo de 5 dias úteis'
});

i18next.addResources('es_GT', 'Checkout', {
  pageTitle: "Pizzas | Finalizar Pago | Papa John's Guatemala"
});
