export const isString = string => typeof string === 'string' || string instanceof String;

export const capitalizeFirstLetter = string =>
  string?.[0]?.toUpperCase().concat(string.slice(1).toLowerCase());

export const textToKebab = string =>
  string
    ?.replace(/([a-z])([A-Z])/g, '$1-$2')
    ?.replace(/[\s_]+/g, '-')
    ?.toLowerCase();

export const getNumberFromString = string => string?.match(/\d/g).join('');

export const dropNCharacters = n => text => text.slice(n, text.length);

export const takeSubstringAfter = (character, string) => {
  const takeSubstring = dropNCharacters(string.indexOf(character) + 1);
  return takeSubstring(string);
};

export const takeIdFromUrl = path => {
  const splitted = path.split('-');
  return splitted[splitted.length - 1];
};

export const normalizeString = str =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[.,'/]/g, '');

export const getStringOrScript = string => string || '-';

export const snakeToCamel = s => s?.replace(/(_\w)/g, match => match[1].toUpperCase());
