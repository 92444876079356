import i18next from 'i18next';

i18next.addResources('es_CL', 'ClientHeader', {
  local: 'Retiro en local',
  delivery: 'Despacho a',
  deliveryStore: 'Despacho desde tienda',
  deliveryStoreAddress: 'Dirección de la tienda',
  changeAddress: 'Modificar dirección',
  changeUserInfo: 'Modificar usuario',
  buttonCancelOrder: 'cancelar orden',
  backToMenu: 'Volver al catálogo'
});
