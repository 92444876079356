import Immutable from 'seamless-immutable';
import { createReducer, completeState } from 'redux-recompose';

import LocalStorageService from '~services/LocalStorageService';

import { UPSELLING_TARGETS } from './constants';
import { actions } from './actions';
import { onSetVariants } from './effects';

const initialStateDescription = {
  [UPSELLING_TARGETS.VARIANTS]: LocalStorageService.getUpsellingVariants() || {}
};

const initialState = completeState(initialStateDescription, [UPSELLING_TARGETS.VARIANTS]);

const reducerDescription = {
  [actions.SET_UPSELLING_VARIANTS]: onSetVariants
};

const reducer = createReducer(new Immutable(initialState), reducerDescription);

export default reducer;
