import { completeTypes, createTypes, withFailure, withSuccess } from 'redux-recompose';
import { push } from 'connected-react-router';

import { createCartItemData, getNonPizzaGroupId } from '../utils';

import { CUSTOM_HALF_OPTIONS } from '~components/CustomizableOption/constants';
import OfferService from '~services/Offers/service';
import { deserializer } from '~services/baseSerializers';
import { productTypes } from '~constants/products';
import { actionCreators as customPizzaActions } from '~redux/CustomPizza/actions';
import { ingredientsFromPizzaData } from '~redux/CustomPizza/utils';
import { ELEMENT_UNIQUENESS } from '~redux/CustomPizza/constants';
import { comboPizzaStepId } from '~utils/pizza';
import { CURRENT_SUBSIDIARY_TARGET, DISPATCH_METHOD_TARGET } from '~redux/SearchStore/constants';
import { CLIENT_PAGES } from '~constants/pages';
import {
  getPizzaSteps,
  getOtherSteps,
  basePizzaDataSelector,
  otherStepsFromOffer
} from '~screens/Dashboard/screens/Home/components/OrderBuilder/selectors';
import { arrayHasLength } from '~utils/array';

import {
  STEPS_TARGET,
  OFFER_TARGET,
  CHECK_OFFER_ID_TARGET,
  PROMO_BUILDER_OFFER_TARGET,
  BYOP_GROUPABLE_ID
} from './constants';
import { productOfferGroupsFromOffer, getPizzaIdsFromItem, getDefaultPizzaIds } from './utils';

const completedTypes = completeTypes(
  ['GET_OFFER', 'GET_OFFER_PIZZAS', 'CHECK_OFFER_ID'],
  ['SET_BASE_PIZZA_FOR_STEP', 'CLEAR_OFFER', 'SET_PROMO_BUILDER_OFFER', 'SET_BYOP_GROUPABLE_ID']
);

export const actions = createTypes(completedTypes, '@@OFFER');

export const actionCreators = {
  setBasePizzaIdsForSteps: stepPizzaIds => ({
    type: actions.SET_BASE_PIZZA_FOR_STEP,
    target: STEPS_TARGET,
    payload: stepPizzaIds
  }),
  setByopGroupableId: id => ({
    type: actions.SET_BYOP_GROUPABLE_ID,
    target: BYOP_GROUPABLE_ID,
    payload: id
  }),
  // eslint-disable-next-line no-empty-function
  getOffer: ({ offerId, storeId, dispatchMethod }, item, onSuccess = () => {}) => ({
    type: actions.GET_OFFER,
    target: OFFER_TARGET,
    service: OfferService.getOffer,
    payload: { offerId, storeId, dispatchMethod },
    injections: [
      withSuccess((dispatch, { data }) => {
        const offer = deserializer.serialize(data);
        const pizzaIds = item ? getPizzaIdsFromItem(offer, item) : getDefaultPizzaIds(offer);

        const handleSuccess = pizzaData => {
          onSuccess(offer, pizzaData);
          dispatch({ type: actions.GET_OFFER_SUCCESS, target: OFFER_TARGET, payload: offer });
        };
        dispatch(customPizzaActions.getOfferPizzas(pizzaIds, handleSuccess, storeId, dispatchMethod));
      }),
      withFailure(dispatch => {
        dispatch(push(`${CLIENT_PAGES.HOME.path}`));
      })
    ]
  }),
  checkOfferId: (offerId, storeId, dispatchMethod, callback) => ({
    type: actions.CHECK_OFFER_ID,
    target: CHECK_OFFER_ID_TARGET,
    payload: { offerId, storeId, dispatchMethod },
    service: OfferService.getOffer,
    injections: [withSuccess(() => callback?.())]
  }),
  clearOffer: () => ({
    type: actions.CLEAR_OFFER,
    target: OFFER_TARGET
  }),
  setSelectedPizza:
    ({ item, half, stepId }) =>
    (dispatch, getState) => {
      const { config } = getState().customPizza;
      const loadedDefaultIngredients = config.basePizzaData?.defaultIngredients?.[item?.menuableId];

      if (loadedDefaultIngredients) {
        const { ingredients, bases } = ingredientsFromPizzaData(
          { ingredients: loadedDefaultIngredients.whole },
          config,
          half
        );

        dispatch(
          customPizzaActions.setPizzaCreationSelections({ menuable: item, stepId, half, ingredients, bases })
        );
      } else {
        dispatch(customPizzaActions.setDefaultEditPizzaValues({ menuable: item, stepId, half }));
      }
    },
  modifyOffer: item => (dispatch, getState) => {
    const state = getState();
    const {
      customPizza: { config },
      searchStore,
      home: { menu }
    } = state;
    const storeId = searchStore[CURRENT_SUBSIDIARY_TARGET]?.id;
    const dispatchMethod = searchStore[DISPATCH_METHOD_TARGET];

    dispatch(
      actionCreators.getOffer(
        { offerId: item.offerId, storeId, dispatchMethod },
        item,
        (offer, pizzaData) => {
          const getStepId = productId =>
            item.groupProducts.find(offerProduct => offerProduct?.productMenuableId === productId)?.stepId;

          const basePizzaSteps = pizzaData.reduce(
            (result, pizza, index) => ({
              ...result,
              [getStepId(pizza.id) || comboPizzaStepId(index)]: pizza.id
            }),
            {}
          );

          const otherSteps = otherStepsFromOffer(offer, menu);

          dispatch(actionCreators.setBasePizzaIdsForSteps(basePizzaSteps));
          item.groupProducts.forEach((group, index) => {
            if (group.menuableType === productTypes.PIZZA_VARIANT) {
              const { ingredients, bases } = ingredientsFromPizzaData(
                pizzaData.find(pizza => pizza.id === group?.productMenuableId) || pizzaData[index],
                config,
                CUSTOM_HALF_OPTIONS.whole
              );
              dispatch(
                customPizzaActions.setPizzaCreationSelections({
                  menuable: group,
                  ingredients,
                  bases,
                  stepId: group?.stepId || comboPizzaStepId(index)
                })
              );
            } else {
              const productOfferGroups = productOfferGroupsFromOffer(offer);
              const selectedItem = productOfferGroups[group.offerGroupId].find(
                offerGroup => offerGroup.menuableId === group.menuableId
              );

              const itemStep = otherSteps.find(step => step.offerGroupId === selectedItem.offerGroupId);
              const variantSelected = itemStep?.options.find(
                option =>
                  option.productId === selectedItem.menuable.productId ||
                  option.productId === selectedItem.menuable.id
              );

              const elementSelected = {
                ...variantSelected,
                variants: variantSelected.variants.map(variant =>
                  variant.id === selectedItem.menuableId
                    ? {
                        ...variant,
                        isSelected: true
                      }
                    : variant
                )
              };

              dispatch(
                customPizzaActions.addElement({
                  element: elementSelected,
                  stepId: group?.stepId || comboPizzaStepId(index),
                  uniqueness: ELEMENT_UNIQUENESS.TYPE,
                  elementType: getNonPizzaGroupId(group.offerGroupId)
                })
              );
            }
          });
        }
      )
    );
  },
  modifyPromoBuilderOffer: menuable => ({
    type: actions.SET_PROMO_BUILDER_OFFER,
    target: PROMO_BUILDER_OFFER_TARGET,
    payload: menuable
  }),
  confirmPizzaSelected: stepId => (dispatch, getState) => {
    const selections = getState().customPizza?.selections;
    const pizzaSelected = selections?.[stepId];

    const pizzaConfirmed = { ...pizzaSelected, isConfirmed: true };

    dispatch(
      customPizzaActions.setSelections({
        ...selections,
        [stepId]: pizzaConfirmed
      })
    );
  },
  endComboCreation: (stepId, path, editingId, completedSteps) => (dispatch, getState) => {
    const state = getState();
    const { selections, helpers } = state.customPizza;
    const byop = helpers?.byop;
    const { offer, steps: offerStepPizzaIds } = state.offers;
    const { menu } = state.home;
    const pizzaSteps = getPizzaSteps(state, { stepId });
    const nonPizzaSteps = getOtherSteps(state);
    const basePizzaData = basePizzaDataSelector(state);
    const onlySelectedSteps = {};
    const { translations } = state.sizesAndCrusts.data;

    Object.keys(selections).forEach(step => {
      if (
        arrayHasLength(selections[step].allElements) ||
        // eslint-disable-next-line camelcase
        completedSteps.find(({ offer_group_id }) => selections[step][getNonPizzaGroupId(offer_group_id)])
      ) {
        onlySelectedSteps[step] = selections[step];
      }
    });

    const cartItem = createCartItemData({
      offer,
      offerStepPizzaIds,
      onlySelectedSteps,
      pizzaSteps,
      nonPizzaSteps,
      menu,
      byop,
      basePizzaData,
      completedSteps,
      translations
    });

    dispatch(customPizzaActions.addComboToCart(cartItem, path, editingId));
  }
};
