import i18next from 'i18next';

i18next.addResources('es_CL', 'SuggestiveSells', {
  title: 'Agrégale mas sabor a tu pedido',
  have: 'Tienes',
  points: '{{points}} Papa Puntos',
  usePoints: 'Ingresa a tu cuenta para usar tus Papa Puntos'
});

i18next.addResources('es_CR', 'SuggestiveSells', {
  have: 'Tenés',
  usePoints: 'Usá tus Papa Puntos'
});

i18next.addResources('es_ES', 'SuggestiveSells', {
  title: 'No te quedes con hambre'
});

i18next.addResources('pt_PT', 'SuggestiveSells', {
  title: 'Não fiques com fome',
  have: 'Tens',
  points: '{{points}} Papa Pontos',
  usePoints: 'Usa os teus Papa Pontos'
});
