import { createTypes, completeTypes, withPostSuccess } from 'redux-recompose';

import { deserializer } from '~services/SearchStore/serializers';
import HelpCenterService from '~services/HelpCenter/service';

import {
  SEARCH_RESULTS_TARGET,
  CATEGORIES_TARGET,
  ARTICLES_TARGET,
  SELECTED_QUESTION_TARGET,
  SECTIONS_TARGET
} from './constants';

export const actions = createTypes(
  completeTypes(['SEARCH', 'GET_CATEGORIES', 'GET_SECTIONS', 'GET_ARTICLES'], ['SELECT_QUESTION']),
  '@@HELP_CENTER'
);

export const actionCreators = {
  search: query => ({
    type: actions.SEARCH,
    target: SEARCH_RESULTS_TARGET,
    service: HelpCenterService.search,
    payload: query,
    successSelector: response => deserializer.serialize(response.data?.results)
  }),
  selectQuestion: question => ({
    type: actions.SELECT_QUESTION,
    target: SELECTED_QUESTION_TARGET,
    payload: question
  }),
  getCategories: () => ({
    type: actions.GET_CATEGORIES,
    target: CATEGORIES_TARGET,
    service: HelpCenterService.getCategories,
    successSelector: response => deserializer.serialize(response.data?.categories),
    injections: [
      withPostSuccess(dispatch => {
        dispatch(actionCreators.getSections());
      })
    ]
  }),
  getSections: () => ({
    type: actions.GET_SECTIONS,
    target: SECTIONS_TARGET,
    service: HelpCenterService.getSections,
    successSelector: response => deserializer.serialize(response.data?.sections)
  }),
  getArticles: categoryId => ({
    type: actions.GET_ARTICLES,
    target: ARTICLES_TARGET,
    service: HelpCenterService.getArticles,
    payload: categoryId,
    successSelector: response => deserializer.serialize(response.data?.articles)
  })
};
