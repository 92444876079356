import { CALL_CENTER_PAGES } from '~constants/pages';
import dynamicImportComponent from '~utils/dynamicImports';

export const FIELDS = {
  name: 'name',
  email: 'email',
  phone: 'phone',
  floor: 'floor',
  note: 'note',
  orderBuilder: 'orderBuilder',
  dispatchNote: 'dispatchNote',
  notes: 'notes'
};

const LoginImport = () => import('./screens/Login');
const NewOrderImport = () => import('./screens/NewOrder');
const MenuImport = () => import('./screens/Menu');
const CheckoutImport = () => import('./screens/Checkout');
const OrderBuilderImport = () => import('./screens/OrderBuilder');

export const Login = dynamicImportComponent(LoginImport, CALL_CENTER_PAGES.LOGIN.path);
export const NewOrder = dynamicImportComponent(NewOrderImport, CALL_CENTER_PAGES.NEW_ORDER.path);
export const Menu = dynamicImportComponent(MenuImport, CALL_CENTER_PAGES.MENU.path);
export const Checkout = dynamicImportComponent(CheckoutImport, CALL_CENTER_PAGES.CHECKOUT.path);
export const OrderBuilder = dynamicImportComponent(OrderBuilderImport, CALL_CENTER_PAGES.ORDER_BUILDER.path);
