import i18next from 'i18next';

i18next.addResources('es_CL', 'Profile', {
  pageTitle: "Tu perfil | Papa John's Chile",
  pageDescription: 'Visualiza y actualiza tu información.',
  points: '{{points}} Puntos acumulados',
  currentDate: 'Tu última compra: {{- date}}',
  exchangeBtn: 'Ir a canjear',
  addresses: 'Mis direcciones',
  paymentMethod: 'Mis métodos de pago',
  coupons: 'Mis cupones',
  add: 'Agregar',
  logout: 'Cerrar sesión',
  currentAddress: 'Esta es la dirección de:',
  home: 'Mi casa',
  work: 'Mi trabajo',
  couple: 'Mi pareja o amig@',
  other: 'Otro',
  save: 'Guardar',
  cancel: 'Cancelar',
  delete: 'Eliminar dirección',
  placeholderSearch: 'Dirección',
  createAddressModalTitle: 'Agregar nueva dirección',
  editAddressModalTitle: 'Editar dirección',
  floor: 'Piso / Oficina',
  noPoints: 'No tienes puntos disponibles',
  changeName: 'Edita tu nombre',
  changePhone: 'Edita tu teléfono',
  validCouponUntil: 'Cupón usado el {{- date}}',
  noAddressContent: 'No tienes direcciones guardadas',
  noCouponsContent: 'No tienes cupones usados',
  dispatchNote: 'Nota de despacho',
  noStreetNumber: 'Escribe una dirección con número de calle',
  updatePassword: 'Actualizar contraseña',
  update: 'Actualizar',
  currentPassword: 'Contraseña actual',
  newPassword: 'Contraseña nueva',
  creditCards: 'Mis tarjetas',
  noCreditCardContent: 'No tienes tarjetas guardadas',
  deleteCard: 'Eliminar',
  companies: 'Mis empresas',
  noCompanies: 'No tienes empresas guardadas',
  editCompanyModalTitle: 'Editar empresa',
  createCompanyModalTitle: 'Agregar nueva empresa',
  main: 'Principal',
  updatePhone: 'Actualizar teléfono',
  deleteCreditCardTitle: 'Eliminar tarjeta',
  deleteCreditCardMessage:
    '¿Estás seguro que quieres eliminar la tarjeta {{kind}} {{cardNumber}} de tu cuenta?',
  yesDelete: 'Si, eliminar esta tarjeta',
  accept: 'Aceptar',
  shareCodeDescription: 'Comparte este código con tus amigos y gana Papa Puntos.',
  shareCodeDisclaimer: '*Válido sólo para app',
  shareCodeTimesUsed: 'Tu código ha sido utilizado {{times}}',
  shareCodeTimesSingular: ' vez',
  shareCodeTimesPlural: ' veces',
  shareCodePhoto: 'Decorado para la tarjeta de recomendar a un amigo.',
  shareCodeText: 'Ingresa a www.papajohns.cl e ingresa el código {{code}} en tu checkout para un descuento.',
  url: 'https://www.papajohns.cl/',
  tooltip: 'Comparte por {{name}}',
  tooltipCopy: 'Copia en portapapeles',
  clipboardNotification: 'Se ha copiado en el portapapeles',
  infoTitle: 'Mis datos'
});

i18next.addResources('es_ES', 'Profile', {
  pageTitle: "Tu perfil | Papa John's España",
  shareCodeText: 'Ingresa a www.papajohns.es e ingresa el código {{code}} en tu checkout para un descuento.',
  url: 'https://www.papajohns.es/',
  add: 'Añadir'
});

i18next.addResources('pt_PT', 'Profile', {
  pageTitle: "Seu perfil | Papa John's Portugal",
  pageDescription: 'Visualize e atualize suas informações.',
  points: '{{points}} Pontos acumulados',
  currentDate: 'Sua última compra: {{- date}}',
  exchangeBtn: 'Ir para resgatar',
  addresses: 'Minhas direções',
  paymentMethod: 'Meus métodos de pagamento',
  coupons: 'Meus cupons',
  add: 'Adicionar',
  logout: 'Encerrar sessão',
  currentAddress: 'Este é o endereço do:',
  home: 'Minha casa',
  work: 'O meu trabalho',
  couple: 'Meu parceiro ou o amig@',
  other: 'Outro',
  save: 'Salvar',
  cancel: 'Cancelar',
  delete: 'Excluir endereço',
  placeholderSearch: 'Endereço',
  createAddressModalTitle: 'Adicionar novo endereço',
  editAddressModalTitle: 'Editar endereço',
  floor: 'Piso / Escritório',
  noPoints: 'Você não tem pontos disponíveis',
  changeName: 'Edite seu nome',
  changePhone: 'Edite seu telefone',
  validCouponUntil: 'Cupão usado em {{- date}}',
  noAddressContent: 'Você não tem endereços salvos',
  noCouponsContent: 'Você não usou cupons',
  dispatchNote: 'Nota de despacho',
  noStreetNumber: 'Digite um endereço com o número da rua',
  updatePassword: 'Atualizar senha',
  update: 'Atualizar',
  currentPassword: 'Senha atual',
  newPassword: 'Senha novo',
  noCompanies: 'Você não tem empresas salvas',
  editCompanyModalTitle: 'Editar empresa',
  createCompanyModalTitle: 'Adicionar nova empresa',
  updatePhone: 'Atualizar telefone',
  shareCodeDescription: 'Partilha este código com os teus amigos e ganha Papa Pontos.',
  shareCodeDisclaimer: '*Válido apenas no app',
  shareCodeTimesUsed: 'O teu código foi utilizado {{times}}',
  shareCodeTimesSingular: ' vez',
  shareCodeTimesPlural: ' vezes',
  shareCodePhoto: 'Decorado para o cartão recomendar a um amigo.',
  shareCodeText:
    'Vai a www.papajohns.pt e introduz o código {{code}} aquando do checkout para usufruires de um desconto.' /* TODO: May change text */,
  url: 'https://www.papajohns.pt/',
  creditCards: 'Meus cartões',
  deleteCard: 'Remover',
  deleteCreditCardMessage: '¿Tem certeza de que deseja excluir este cartão de crédito?',
  accept: 'Aceitar',
  noCreditCardContent: 'Você não tem cartões salvos',
  tooltip: 'Partilha por {{name}}',
  tooltipCopy: 'Copiar para área de transferência',
  clipboardNotification: 'Foi copiado para a área de transferência',
  infoTitle: 'Os meus dados'
});

i18next.addResources('es_PA', 'Profile', {
  pageTitle: "Tu perfil | Papa John's Panama",
  shareCodeText:
    'Ingresa a www.papajohns.com.pa e ingresa el código {{code}} en tu checkout para un descuento.',
  url: 'https://www.papajohns.com.pa/'
});

i18next.addResources('es_CR', 'Profile', {
  pageTitle: "Tu perfil | Papa John's Costa Rica",
  changeName: 'Cambia tu nombre',
  shareCodeText: 'Ingresa a www.papajohns.cr e ingresa el código {{code}} en tu checkout para un descuento.',
  url: 'https://www.papajohns.cr/'
});

i18next.addResources('es_GT', 'Profile', {
  pageTitle: "Tu perfil | Papa John's Guatemala",
  changeName: 'Cambia tu nombre',
  shareCodeText:
    'Ingresa a www.papajohns.com.gt e ingresa el código {{code}} en tu checkout para un descuento.',
  url: 'https://www.papajohns.com.gt/'
});
