import i18next from 'i18next';

i18next.addResources('es_CL', 'CrossSelling', {
  title: 'Agrégale mas sabor a tu pedido',
  noThanks: 'No gracias',
  continueToPayment: 'Continuar al pago',
  discountText: '{{discount}}% OFF'
});

i18next.addResources('es_ES', 'CrossSelling', {
  title: 'No te quedes con hambre'
});

i18next.addResources('pt_PT', 'CrossSelling', {
  title: 'Não fiques com fome',
  noThanks: 'Não, obrigado',
  continueToPayment: 'Continuar para finalizar a compra'
});
