import Immutable from 'seamless-immutable';
import { createReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { SMARTBANNER_COOKIE_TARGET } from './constants';

const initialState = {
  [SMARTBANNER_COOKIE_TARGET]: false
};

const reducerDescription = {
  [actions.SET_SMARTBANNER_COOKIE]: onReadValue()
};

const reducer = createReducer(new Immutable(initialState), reducerDescription);

export default reducer;
