import { itemHasRulesAdded } from '~utils/shoppingCart';

export const checkAvailablePoints = items =>
  items?.map(item => ({
    ...item,
    ...(item.pointsSpent > 0 && { withLoyalty: true })
  }));

export const buildBodyWithRulesInItems = body => {
  if (body?.shoppingCart?.items) {
    return {
      ...body,
      shoppingCart: {
        ...body.shoppingCart,
        items: body.shoppingCart.items.map(item => {
          if (itemHasRulesAdded(item)) {
            return {
              ...item,
              ruleId: item.ruleId || item.appliedPromos[0].id
            };
          }
          return item;
        })
      }
    };
  }
  return body;
};
