import React, { Component } from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { func } from 'prop-types';

import ErrorScreen from '../ErrorScreen';

import { CLIENT_PAGES } from '~constants/pages';

import styles from './styles.module.scss';

class ErrorBoundary extends Component {
  state = { showErrorScreen: false };

  static getDerivedStateFromError() {
    return { showErrorScreen: true };
  }

  handleBack = () => {
    this.setState({ showErrorScreen: false });
    this.props.goToMenu();
  };

  render() {
    const { children } = this.props;
    const { showErrorScreen } = this.state;

    return showErrorScreen ? (
      <ErrorScreen title={t('ErrorBoundary:title')}>
        <button type="button" className={`button primary ${styles.refreshButton}`} onClick={this.handleBack}>
          {t('ErrorBoundary:back')}
        </button>
      </ErrorScreen>
    ) : (
      children
    );
  }
}

ErrorBoundary.propTypes = {
  goToMenu: func.isRequired
};

const mapDispatchToProps = dispatch => ({
  goToMenu: () => dispatch(push(CLIENT_PAGES.HOME.path))
});

export default connect(null, mapDispatchToProps)(ErrorBoundary);
