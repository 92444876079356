import i18next from 'i18next';

i18next.addResources('es_CL', 'WarningOrderReceipt', {
  altImage: 'Icono de advertencia',
  title: 'Ya tienes un pedido activo',
  subtitle: '¿Estás seguro de confirmar este nuevo pedido?',
  cancelBtn: 'Cancelar',
  confirmBtn: 'Confirmar pedido',
  goToOrders: 'Ir a mis pedidos'
});
