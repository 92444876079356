import i18next from 'i18next';

i18next.addResources('es_CL', 'Snackbar', {
  cookieInformation:
    'En este sitio web utilizamos cookies propias o de terceros para mejorar nuestros servicios con fines analíticos para crear perfiles y mostrarte publicidad relacionados con tus preferencias en base al perfil elaborado, a partir de tus hábitos de navegación. Puedes aceptar todas las cookies dando al botón de “aceptar”  o configurarlas o rechazarlas dando al botón de Configuración de cookies”. Puedes obtener más información.',
  moreInformationLink: 'Más información',
  hereLink: 'AQUÍ',
  closeButton: 'Cerrar',
  acceptButton: 'Aceptar',
  declinedButton: 'Rechazar',
  settingsButton: 'Configuración'
});

i18next.addResources('es_ES', 'Snackbar', {
  cookieInformation:
    'En este sitio web utilizamos cookies propias o de terceros para mejorar nuestros servicios con fines analíticos para crear perfiles y mostrarte publicidad relacionados con tus preferencias en base al perfil elaborado, a partir de tus hábitos de navegación. Puedes aceptar todas las cookies dando al botón de “aceptar”  o configurarlas o rechazarlas dando al botón de Configuración de cookies”. Puedes obtener más información.',
  moreInformationLink: 'Más información',
  hereLink: 'AQUÍ',
  closeButton: 'Cerrar',
  acceptButton: 'Aceptar',
  declinedButton: 'Rechazar',
  settingsButton: 'Configuración'
});

i18next.addResources('pt_PT', 'Snackbar', {
  cookieInformation:
    'Neste site utilizamos cookies próprios ou de terceiros para melhorar os nossos serviços com fins analíticos para criar perfis e mostrar-lhe publicidade relacionada com as suas preferências com base no perfil elaborado, com base nos seus hábitos de navegação. Você pode aceitar todos os cookies clicando no botão "aceitar" ou configurá-los ou rejeitá-los clicando no botão "Configurações de cookies". Você pode obter mais informações.',
  moreInformationLink: 'Mais informação',
  hereLink: 'AQUI',
  closeButton: 'Fechar',
  acceptButton: 'Aceitar',
  declinedButton: 'Recusar',
  settingsButton: 'Configuração'
});
