import { helpCenterApi } from '~config/api';

const HelpCenterService = {
  search: query => helpCenterApi.get(`help_center/articles/search.json?query=${query}`),
  getCategories: () => helpCenterApi.get('help_center/es/categories.json'),
  getSections: () => helpCenterApi.get('help_center/es/sections.json'),
  getArticles: categoryId => helpCenterApi.get(`help_center/es/categories/${categoryId}/articles.json`)
};

export default HelpCenterService;
