import i18next from 'i18next';

i18next.addResources('es_CL', 'HalfSelection', {
  half1: 'Mitad 1',
  half2: 'Mitad 2'
});

i18next.addResources('es_ES', 'HalfSelection', {
  half1: 'Mitad 1',
  half2: 'Mitad 2'
});

i18next.addResources('pt_PT', 'HalfSelection', {
  half1: 'Metade 1',
  half2: 'Metade 2'
});

i18next.addResources('es_PA', 'HalfSelection', {
  half1: 'Mitad 1',
  half2: 'Mitad 2'
});
