import { useEffect, useRef } from 'react';

import { useClickedOutside } from './useClickedOutside';
import { useToggle } from './useToggle';

export const useDropDown = (defaultValue = false) => {
  const { state: isOpen, toggle } = useToggle(defaultValue);
  const dropDownRef = useRef();
  const clickedOutsideOptions = useClickedOutside(dropDownRef);

  useEffect(() => {
    if (clickedOutsideOptions && isOpen) {
      toggle();
    }
  }, [clickedOutsideOptions, isOpen]);

  return {
    isOpen,
    toggle,
    dropDownRef
  };
};
