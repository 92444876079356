import i18next from 'i18next';

i18next.addResources('es_CL', 'StoreLanding', {
  store: 'Pizzería',
  pizzaStore: 'Pizzería en',
  delivery: 'Delivery',
  pickup: 'Retiro en local',
  pickupOrder: 'Pedir retiro en Local',
  deliveryOrder: 'Pedir delivery',
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo',
  description:
    "Papa John's {{country}} en {{address}} ofrece un menú variado con opciones de pizzas, acompañamientos, bebidas, postres y salsas extras. Además, puedes elegir entre delivery o retiro en tienda. Prueba la calidad y frescura de cada pizza. ¡Haz tu pedido hoy mismo!"
});

i18next.addResources('pt_PT', 'StoreLanding', {
  store: 'Pizzaria',
  pizzaStore: 'Pizzaria em',
  orderPickup: 'Pedir por levantamento no local',
  orderDelivery: 'Pedir por delivery',
  monday: 'Segunda',
  tuesday: 'Terça',
  wednesday: 'Quarta',
  thursday: 'Quinta',
  friday: 'Sexta',
  saturday: 'Sábado',
  sunday: 'Domingo'
});
