import i18next from 'i18next';

i18next.addResources('es_CL', 'Promotions', {
  description: '¡Aprovecha estas promociones! pide delivery o retira en local',
  path: '/promociones',
  pageTitle: 'Promociones',
  title:
    '¡aprovecha estas promociones! pide <linkToHomeWithAddressModal>delivery</linkToHomeWithAddressModal> o <linkToHomeWithAddressModal>retira en local</linkToHomeWithAddressModal>',
  subtitle: '¿Quieres ver todos nuestros productos? <linkToHome>revisa nuestro menú</linkToHome>'
});
