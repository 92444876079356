/* eslint-disable */
export const WEGLOT_LIB = `var Weglot=(function () {
    'use strict';
    var e = {
      v1: [],
      v2: [
        'ABBR',
        'ACRONYM',
        'B',
        'BDO',
        'BIG',
        'CITE',
        'EM',
        'I',
        'KBD',
        'Q',
        'SMALL',
        'STRONG',
        'SUB',
        'SUP',
        'U'
      ],
      v3: ['A', 'BDI', 'BR', 'DEL', 'DFN', 'INS', 'S', 'SPAN']
    };
    e.v2.unshift('#text');
    var t = e,
      n = {
        excluded_blocks: [],
        media_enabled: !1,
        external_enabled: !1,
        extra_definitions: [],
        translation_engine: 2,
        noTranslateAttribute: 'data-wg-notranslate',
        mergeNodes: []
      },
      r = {
        ddtags: 'env:prod',
        clientToken: 'pub4efaec96ce2494088ba70a2049d58dc3',
        site: 'datadoghq.com'
      },
      o = {
        'dd-api-key': 'pub4efaec96ce2494088ba70a2049d58dc3',
        ddsource: 'browser'
      },
      a = 'prod';
  
    function i(e) {
      var t = e.service;
  
      function n(e, n, i) {
        var s = i.sendToConsole;
        void 0 === s && (s = !0);
        var c = i.consoleOverride,
          l = i.sendToDatadog;
        if (
          (void 0 === l && (l = !0),
          l &&
            'dev' !== a &&
            (function (e, t, n) {
              'string' == typeof t &&
                (t = {
                  message: t
                });
              var a = Object.assign(
                {},
                t,
                {
                  service: e,
                  status: n
                },
                window.location && {
                  view: {
                    url: window.location.href
                  }
                },
                t.message && {
                  message: t.message
                },
                t.stack && {
                  stack: t.stack
                },
                t.status && {
                  logStatus: t.status
                },
                r
              );
              window.Weglot &&
                window.Weglot.options &&
                (a.projectInfo = [
                  'host',
                  'api_key',
                  'url_type',
                  'technology_name',
                  'technology_id',
                  'is_connect',
                  'auto_switch'
                ].reduce(function (e, t) {
                  var n;
                  return Object.assign({}, e, (((n = {})[t] = window.Weglot.options[t]), n));
                }, {}));
              var i = Object.keys(o)
                .map(function (e) {
                  return e + '=' + o[e];
                })
                .join('&');
              fetch('https://http-intake.logs.datadoghq.com/api/v2/logs?' + i, {
                method: 'POST',
                body: JSON.stringify(a),
                headers: {
                  'Content-Type': 'application/json'
                }
              });
            })(t, e, n),
          s)
        ) {
          var u = c || e,
            f = ['notice', 'info'].includes(n) ? 'log' : n;
          console[f]('[Weglot]', u);
        }
      }
      var i = function (e) {
        return function (t, r) {
          return void 0 === r && (r = {}), n(t, e, r);
        };
      };
      return {
        log: i('info'),
        info: i('info'),
        notice: i('notice'),
        warn: i('warn'),
        error: i('error')
      };
    }
    var s = i({
      service: 'html-parser-engine'
    });
  
    function c(e, t, n) {
      var r = t && t[e];
      if (r && r.textContent === t.textContent) return r.result;
      var o = n(t);
      return t
        ? ((t[e] = {
            result: o,
            textContent: t.textContent
          }),
          o)
        : o;
    }
  
    function l(e) {
      return c('__validMergeNodes', e, function (e) {
        return (
          e &&
          d(e) &&
          u(e) &&
          !(function (e) {
            return c('__containsNoTranslateNodes', e, function (e) {
              return 1 === e.nodeType && (!!e.querySelector('[' + n.noTranslateAttribute + ']') || g(e));
            });
          })(e)
        );
      });
    }
  
    function u(e, t) {
      return (
        void 0 === t && (t = !0),
        c('__validTextNodes', e, function (e) {
          return !(
            !e.textContent ||
            (t && !e.textContent.trim()) ||
            -1 !== e.textContent.indexOf('BESbswy') ||
            (e.parentNode &&
              e.parentNode.nodeName &&
              -1 !== ['script', 'style', 'noscript'].indexOf(e.parentNode.nodeName.toLowerCase())) ||
            (function (e) {
              if (!(e = e.trim())) return !1;
              var t = e.charAt(0);
              if ('[' !== t && '{' !== t) return !1;
              var n = e[e.length - 1];
              if (']' !== n && '}' !== n) return !1;
              return (
                (e = e),
                /^[\],:{}\s]*$/.test(e)
              );
            })(e.textContent)
          );
        })
      );
    }
  
    function f(e) {
      try {
        if (n.mergedSelectorRemove && e.closest && e.closest(n.mergedSelectorRemove)) return !1;
      } catch (e) {}
      return (
        !(!n.mergeNodes || -1 === n.mergeNodes.indexOf(e.nodeName)) ||
        (e.dataset && e.dataset.wgMerge) ||
        (n.selectorMerging && e.matches && e.matches(n.selectorMerging))
      );
    }
  
    function d(e) {
      return c('__onlyInlineChildsNodes', e, function (e) {
        if (!e.childNodes) return !0;
        for (var t = 0, n = e.childNodes; t < n.length; t += 1) {
          var r = n[t];
          if (r.weglot || !f(r) || !d(r)) return !1;
        }
        return !0;
      });
    }
  
    function g(e) {
      if (!e.children) return !1;
      for (var t = 0, n = e.children; t < n.length; t += 1) {
        var r = n[t];
        if (r.wgNoTranslate || g(r)) return !0;
      }
      return !1;
    }
  
    function _(e) {
      return !!e && (!!e.wgNoTranslate || _(e.parentNode));
    }
  
    function p(e) {
      if (!e) return !1;
      var t = e.closest ? e : e.parentNode;
      return !!(t && t.closest && t.closest('[' + n.noTranslateAttribute + ']')) || _(e);
    }
    var h = function (e, t) {
        return function (n, r) {
          try {
            var o = r;
            return -1 !== o.indexOf(':') && (o = o.replace('', '')), n[e] ? n[e](o) : t;
          } catch (o) {
            try {
              return n[e] ? n[e](r) : t;
            } catch (e) {
              s.warn(e, {
                consoleOverride: 'Your CSS rules are incorrect: ' + r,
                sendToDatadog: !1
              });
            }
          }
          return t;
        };
      },
      m = h('querySelectorAll', []),
      v = h('matches', !1);
    var w = new WeakMap();
  
    function y(e) {
      if (!e) return [];
      var t = e.querySelectorAll ? e : e.parentNode;
      if (!t) return [];
      if (
        ((function (e) {
          var t = n.excluded_blocks;
          if (t && t.length) {
            var r = t.map(function (e) {
                return e.value;
              }),
              o = r.join(',');
            if (v(e, o)) {
              if (n.private_mode) {
                var a = r.find(function (t) {
                  return v(e, t);
                });
                e.wgNoTranslate = 'Excluded by selector: ' + a;
              } else e.wgNoTranslate = !0;
              return;
            }
            var i = m(e, o);
            if (i)
              for (
                var s = function () {
                    var e = l[c];
                    if (n.private_mode) {
                      var t = r.find(function (t) {
                        return v(e, t);
                      });
                      e.wgNoTranslate = 'Excluded by selector: ' + t;
                    } else e.wgNoTranslate = !0;
                  },
                  c = 0,
                  l = i;
                c < l.length;
                c += 1
              )
                s();
          }
        })(t),
        !n.whitelist || !n.whitelist.length)
      )
        return [].concat(
          (function (e) {
            var t = document.getElementsByTagName('title')[0];
            if (e !== document.documentElement || !document.title || !t || p(t)) return [];
            return [
              {
                element: t.firstChild,
                type: 9,
                words: t.textContent,
                properties: {}
              }
            ];
          })(t),
          b(t)
        );
      var r = n.whitelist
        .map(function (e) {
          return e.value;
        })
        .join(',');
      if (t.closest && t.closest(r)) return b(t);
      for (var o = [], a = 0, i = m(t, r); a < i.length; a += 1) {
        var s = i[a];
        [].push.apply(o, b(s));
      }
      return o;
    }
  
    function b(e) {
      return [].concat(
        (function (e) {
          var t = [];
          return (
            R.forEach(function (n) {
              for (
                var r,
                  o,
                  a,
                  i = n.attribute,
                  s = n.type,
                  c = n.selectors,
                  l = 0,
                  u =
                    ((r = e),
                    (o = []),
                    (a = c.join(',')),
                    r.matches && r.matches(a) && o.push(r),
                    r.childElementCount > 0 && o.push.apply(o, r.querySelectorAll(a)),
                    o);
                l < u.length;
                l += 1
              ) {
                var f = u[l];
                if (!p(f)) {
                  var d = i.get(f);
                  O(d) ||
                    t.push({
                      element: f,
                      words: d,
                      type: s,
                      attrSetter: i.set,
                      attrName: i.name
                    });
                }
              }
            }),
            t
          );
        })(e),
        (function (e) {
          var t,
            r = [],
            o = n.translation_engine >= 2,
            a = document.createTreeWalker(e, 4, k, !1);
          for (; (t = a.nextNode()); ) {
            var i = (o && (f(t.parentNode) || t.parentNode.childNodes.length > 1) ? E : S)(t, a);
            i && r.push(i);
          }
          return r;
        })(e)
      );
    }
  
    function k(e) {
      return !u(e) || p(e) ? NodeFilter.FILTER_REJECT : NodeFilter.FILTER_ACCEPT;
    }
  
    function E(e, t) {
      var r = (function (e) {
        if (e.wgResolved) return !1;
        var t = e;
        do {
          if (t.wgResolved) return t;
          t = t.parentElement || t.parentNode;
        } while (null !== t && 1 === t.nodeType);
        return !1;
      })(e);
      if (r && w.has(r)) {
        var o = w.get(r);
        return {
          element: o[0],
          words: o[1],
          type: 1,
          properties: o[2]
        };
      }
      var a = (function (e, t) {
        var r = [],
          o = e;
        for (; l(e.parentNode); ) (e = e.parentNode), o.textContent.trim() !== e.textContent.trim() && (o = e);
        o.textContent.trim() === e.textContent.trim() && (e = o);
        for (; t.nextNode(); )
          if (!e.contains || !e.contains(t.currentNode)) {
            t.previousNode();
            break;
          }
        var a = e.cloneNode(!0);
        if (n.translation_engine > 2) {
          C(e, function (e) {
            if (1 === e.nodeType) {
              var t = (function (e) {
                for (var t = [], n = 0, r = e.attributes; n < r.length; n += 1) {
                  var o = r[n];
                  t.push(o);
                }
                return t;
              })(e);
              r.push({
                attributes: t,
                child: e
              });
            }
          });
          var i = 1;
          C(a, function (e) {
            1 === e.nodeType &&
              (!(function (e) {
                if (!e.attributes) return e;
                for (; e.attributes.length > 0; ) e.removeAttribute(e.attributes[0].name);
              })(e),
              e.setAttribute('wg-' + i++, ''));
          });
        }
        if (e) {
          return (e.wgResolved = !0), [e, (a.innerHTML || a.textContent || '').replace(/<!--[^>]*-->/g, ''), r];
        }
      })(e, t);
      if (a) {
        var i = a[0],
          s = a[1],
          c = a[2];
        if (!O(s))
          return (
            w.set(i, a),
            {
              element: i,
              words: s,
              type: 1,
              properties: c
            }
          );
      }
    }
  
    function S(e) {
      var t = e.textContent;
      if (!O(t))
        return {
          element: e,
          words: t,
          type: 1,
          properties: {}
        };
    }
  
    function C(e, t) {
      if (e.childNodes)
        for (var n = 0, r = e.childNodes; n < r.length; n += 1) {
          var o = r[n];
          if (!o) return;
          t(o), C(o, t);
        }
    }
  
    function O(e) {
      return !e || !e.trim() || !isNaN(e) || '​' === e;
    }
  
    function x(e, t) {
      for (var n = 0, r = e; n < r.length; n += 1) {
        var o = r[n],
          a = o.weglot.content;
        if (a && o.isConnected) {
          for (var i = 0, s = a; i < s.length; i += 1) {
            var c = s[i],
              l = c.original,
              u = c.properties,
              f = c.attrSetter,
              d = c.translations[t] || l;
            u && ((o.weglot.setted = !0), N(o, d, u, e)), f && ((o.weglot.setted = !0), f(o, d, l));
          }
          o.wgResolved = !1;
        }
      }
    }
  
    function N(e, t, n, r) {
      if (1 === e.nodeType) {
        var o = (function (e, t, n) {
          var r = document.createElement('div');
          return (r.innerHTML = e), L(t, r, n);
        })(t, e, n);
        return (e.innerHTML = ''), void e.appendChild(o);
      }
      if (-1 !== t.indexOf('<') && -1 !== t.indexOf('>')) {
        if (!e.parentNode)
          return (
            s.warn('Unable to translate some words, please contact support@weglot.com.'),
            void s.warn(e, {
              sendToDatadog: !1
            })
          );
        if (1 === e.parentNode.childNodes.length)
          return (e.parentNode.weglot = e.weglot), void (r ? r.push(e.parentNode) : N(e.parentNode, t, n));
        var a =
          (e.closest && e.closest('[data-wg-translation-wrapper]')) ||
          e.parentNode.closest('[data-wg-translation-wrapper]');
        if (!a || a.innerHTML !== t) {
          var i = document.createElement('span');
          (i.dataset.wgTranslationWrapper = ''),
            (i.weglot = e.weglot),
            e.parentNode.replaceChild(i, e),
            r ? r.push(i) : N(e.parentNode, t, n);
        }
      } else e.textContent = t;
    }
  
    function L(e, t, n) {
      var r = document.createDocumentFragment();
      if (1 !== e.nodeType) return r.appendChild(t), r;
      for (var o = t.childNodes.length, a = 0; a < o; a++) {
        var i,
          c = t.firstChild;
        if ((i = T(c))) {
          var l = n[i - 1];
          if (!l) continue;
          var u = l.used ? l.child.cloneNode(!0) : l.child,
            f = L(u, c, n);
          if (f.contains(u)) return s.error('There is an HTML error in the translation of: ' + e.innerHTML), r;
          (u.innerHTML = ''),
            u.appendChild(f),
            r.appendChild(u),
            document.createDocumentFragment().appendChild(c),
            (l.used = !0);
        } else r.appendChild(c);
      }
      return r;
    }
  
    function T(e) {
      if (e && 1 === e.nodeType && e.attributes && e.attributes[0]) {
        var t = parseInt(e.attributes[0].name.split('wg-')[1]);
        return isNaN(t) ? void 0 : t;
      }
    }
  
    function j(e) {
      return {
        name: e,
        get: function (t) {
          return t.getAttribute(e);
        },
        set: function (t, n) {
          return t.setAttribute(e, n);
        }
      };
    }
  
    function A(e, t) {
      if (e.parentNode && 'PICTURE' === e.parentNode.tagName)
        for (var n = 0, r = e.parentNode.children; n < r.length; n += 1) {
          var o = r[n];
          'SOURCE' === o.tagName && o.getAttribute('srcset') && o.setAttribute('srcset', t);
        }
    }
  
    function P(e) {
      return (e && e.split && e.split('www.')[1]) || e;
    }
  
    function D(e) {
      var t = [
        {
          type: 1,
          selectors: ['[title]'],
          attribute: j('title')
        },
        {
          type: 2,
          selectors: ["input[type='submit']", "input[type='button']", 'button'],
          attribute: j('value')
        },
        {
          type: 3,
          selectors: ['input[placeholder]', 'textarea[placeholder]'],
          attribute: j('placeholder')
        },
        {
          type: 4,
          selectors: [
            "meta[name='description']",
            "meta[property='og:description']",
            "meta[property='og:site_name']",
            "meta[property='og:image:alt']",
            "meta[name='twitter:description']",
            "meta[itemprop='description']",
            "meta[itemprop='name']"
          ],
          attribute: j('content')
        },
        {
          type: 7,
          selectors: ['img'],
          attribute: j('alt')
        },
        {
          type: 8,
          selectors: ["[href$='.pdf']", "[href$='.docx']", "[href$='.doc']"],
          attribute: j('href')
        },
        {
          type: 9,
          selectors: ["meta[property='og:title']", "meta[name='twitter:title']"],
          attribute: j('content')
        }
      ];
      if (!e) return t;
      if (
        (e.media_enabled &&
          t.push(
            {
              type: 5,
              selectors: ['youtube.com', 'youtu.be', 'vimeo.com', 'dailymotion.com'].map(function (e) {
                return "iframe[src*='" + e + "']";
              }),
              attribute: j('src')
            },
            {
              type: 6,
              selectors: ['img', 'source'],
              attribute: {
                name: 'src',
                get: function (e) {
                  var t = e.getAttribute('src');
                  if (!t || !t.split) return '';
                  if (0 === t.indexOf('data:image')) return '';
                  var n = t.split('?');
                  return n[1] && (e.queryString = n[1]), n[0];
                },
                set: function (e, t, n) {
                  var r = e.getAttribute('src'),
                    o = e.getAttribute('srcset');
                  if (t === n) {
                    if ((e.removeAttribute('data-wgtranslated'), e.isChanged)) {
                      var a = '' + t + (e.queryString ? '?' + e.queryString : '');
                      e.setAttribute('src', a),
                        A(e, a),
                        e.hasAttribute('wgsrcset') &&
                          (e.setAttribute('srcset', e.getAttribute('wgsrcset') || e.dataset.srcset),
                          e.removeAttribute('wgsrcset'));
                    }
                  } else
                    r.split('?')[0] !== t &&
                      n !== t &&
                      (e.setAttribute('src', t),
                      A(e, t),
                      e.hasAttribute('srcset') && (e.setAttribute('wgsrcset', o), e.setAttribute('srcset', '')),
                      (e.dataset.wgtranslated = !0),
                      (e.isChanged = !0));
                }
              }
            },
            {
              type: 6,
              selectors: ["meta[property='og:image']", "meta[property='og:logo']"],
              attribute: j('content')
            },
            {
              type: 6,
              selectors: ['img'],
              attribute: j('srcset')
            }
          ),
        e.translate_aria &&
          t.push({
            type: 1,
            selectors: ['[aria-label]'],
            attribute: j('aria-label')
          }),
        e.external_enabled)
      ) {
        var n = P(
          (function () {
            var e = window.location,
              t = e.hostname,
              n = e.search;
            if ('ve-weglot.com' !== t || !n) return t;
            var r = decodeURIComponent(n).match('');
            return r ? r[1] : (s.warn('[Weglot] Unable to get current hostname'), t);
          })()
        );
        t.push(
          {
            type: 10,
            selectors: ['iframe'],
            attribute: j('src')
          },
          {
            type: 10,
            selectors: ['a[rel=external]'],
            attribute: j('href')
          },
          {
            type: 10,
            selectors: ['[href^="mailto"]'],
            attribute: j('href')
          },
          {
            type: 10,
            selectors: ['[href^="tel"]'],
            attribute: j('href')
          },
          {
            type: 10,
            selectors: ['http:', 'https:', '//'].map(function (e) {
              return '[href^="' + e + '"]:not(link)';
            }),
            attribute: {
              name: 'href',
              get: function (e) {
                if (!e.href || !e.href.split) return '';
                var t = e.href.split('/')[2];
                return t && P(t) !== n ? e.getAttribute('href') : '';
              },
              set: function (e, t) {
                return e.setAttribute('href', t);
              }
            }
          }
        );
      }
      if (e.extra_definitions && e.extra_definitions.length)
        for (
          var r = function () {
              var e = a[o],
                n = e.type,
                r = e.selector,
                i = e.attribute;
              i && r
                ? t.push({
                    type: n,
                    selectors: [r],
                    attribute: {
                      name: i,
                      get: function (e) {
                        return e.getAttribute(i);
                      },
                      set: function (e, t) {
                        return e.setAttribute(i, t);
                      }
                    }
                  })
                : s.warn(
                    'Each extra definition option needs at least {attribute,selector} https://bit.ly/2yDsLxy',
                    {
                      sendToDatadog: !1
                    }
                  );
            },
            o = 0,
            a = e.extra_definitions;
          o < a.length;
          o += 1
        )
          r();
      return t;
    }
    k.acceptNode = k;
    var R = [];
  
    function I(e, r) {
      if (!r || !r.translation_engine) throw 'translation_engine is required';
      var o;
      return (
        Object.assign(n, r),
        (n.document = e),
        (n.mergeNodes =
          ((o = r.translation_engine),
          t.v2.unshift('#text', '#comment'),
          Object.keys(t).reduce(function (e, n, r) {
            return o >= r + 1 && [].push.apply(e, t[n]), e;
          }, []))),
        Array.isArray(n.extra_merged_selectors) &&
          (n.selectorMerging = r.extra_merged_selectors
            .filter(function (e) {
              return e && 'string' == typeof e;
            })
            .join(',')),
        r.merged_selectors_remove &&
          (n.mergedSelectorRemove = r.merged_selectors_remove
            .map(function (e) {
              return e.value;
            })
            .join(',')),
        {
          getTextNodes: y,
          setTextNodes: x,
          definitions: (R = D(n))
        }
      );
    }
    var W = i({
        service: 'js-library'
      }),
      U = 'https://cdn.weglot.com/projects-settings/',
      M = 'preview.weglot.io',
      q = 'wglang',
      H = 'wg-style-trans',
      z = 'data-wg-notranslate',
      B = 've-weglot.com',
      F = 'wg-translations',
      V = 'wg-slugs',
      $ = 'Shopify',
      G = 'BigCommerce',
      J = 'Jimdo',
      Y = 'Squarespace',
      K = 'Wix',
      X = 'Webflow',
      Z = 'Square Online',
      Q = 'Bubble',
      ee = 'Salesforce',
      te = [
        'excluded_blocks',
        'excluded_blocks_remove',
        'dynamics',
        'excluded_paths',
        'dangerously_force_dynamic',
        'extra_definitions',
        'translate_event'
      ],
      ne = ['polyfillReady', 'languageChanged', 'initialized', 'start', 'switchersReady'],
      re = {
        button_style: {
          full_name: !0,
          with_name: !0,
          is_dropdown: !0,
          with_flags: !1,
          flag_type: ''
        },
        switchers: [],
        auto_switch: !1,
        auto_switch_fallback: '',
        excluded_blocks: [],
        excluded_blocks_remove: [],
        whitelist: [],
        translate_event: [
          {
            selector: '[data-wg-translate-event]',
            eventName: null
          }
        ],
        customer_tag: !1,
        order_tag: !0,
        dynamics: [],
        excluded_paths: [],
        wait_transition: !0,
        hide_switcher: !1,
        translate_search: !1,
        media_enabled: !1,
        search_forms: '',
        cache: !1,
        live: !0,
        loading_bar: !0,
        search_parameter: '',
        translation_engine: 2,
        override_hreflang: !0
      },
      oe = ['none', 'shiny', 'square', 'circle', 'rectangle_mat'],
      ae = {};
    !(function (e) {
      var t = (function () {
          try {
            return !!Symbol.iterator;
          } catch (e) {
            return !1;
          }
        })(),
        n = function (e) {
          var n = {
            next: function () {
              var t = e.shift();
              return {
                done: void 0 === t,
                value: t
              };
            }
          };
          return (
            t &&
              (n[Symbol.iterator] = function () {
                return n;
              }),
            n
          );
        },
        r = function (e) {
          try {
            return encodeURIComponent(e).replace(/%20/g, '+');
          } catch (t) {
            return e;
          }
        },
        o = function (e) {
          try {
            return decodeURIComponent(String(e).replace('', ' '));
          } catch (t) {
            return e;
          }
        };
      (function () {
        try {
          var t = e.URLSearchParams;
          return (
            'a=1' === new t('?a=1').toString() &&
            'function' == typeof t.prototype.set &&
            'function' == typeof t.prototype.entries
          );
        } catch (e) {
          return !1;
        }
      })() ||
        (function () {
          var o = function (e) {
              Object.defineProperty(this, '_entries', {
                writable: !0,
                value: {}
              });
              var t = typeof e;
              if ('undefined' === t);
              else if ('string' === t) '' !== e && this._fromString(e);
              else if (e instanceof o) {
                var n = this;
                e.forEach(function (e, t) {
                  n.append(t, e);
                });
              } else {
                if (null === e || 'object' !== t)
                  throw new TypeError("Unsupported input's type for URLSearchParams");
                if ('[object Array]' === Object.prototype.toString.call(e))
                  for (var r = 0; r < e.length; r++) {
                    var a = e[r];
                    if ('[object Array]' !== Object.prototype.toString.call(a) && 2 === a.length)
                      throw new TypeError(
                        'Expected [string, any] as entry at index ' + r + " of URLSearchParams's input"
                      );
                    this.append(a[0], a[1]);
                  }
                else for (var i in e) e.hasOwnProperty(i) && this.append(i, e[i]);
              }
            },
            a = o.prototype;
          (a.append = function (e, t) {
            e in this._entries ? this._entries[e].push(String(t)) : (this._entries[e] = [String(t)]);
          }),
            (a.delete = function (e) {
              delete this._entries[e];
            }),
            (a.get = function (e) {
              return e in this._entries ? this._entries[e][0] : null;
            }),
            (a.getAll = function (e) {
              return e in this._entries ? this._entries[e].slice(0) : [];
            }),
            (a.has = function (e) {
              return e in this._entries;
            }),
            (a.set = function (e, t) {
              this._entries[e] = [String(t)];
            }),
            (a.forEach = function (e, t) {
              var n;
              for (var r in this._entries)
                if (this._entries.hasOwnProperty(r)) {
                  n = this._entries[r];
                  for (var o = 0; o < n.length; o++) e.call(t, n[o], r, this);
                }
            }),
            (a.keys = function () {
              var e = [];
              return (
                this.forEach(function (t, n) {
                  e.push(n);
                }),
                n(e)
              );
            }),
            (a.values = function () {
              var e = [];
              return (
                this.forEach(function (t) {
                  e.push(t);
                }),
                n(e)
              );
            }),
            (a.entries = function () {
              var e = [];
              return (
                this.forEach(function (t, n) {
                  e.push([n, t]);
                }),
                n(e)
              );
            }),
            t && (a[Symbol.iterator] = a.entries),
            (a.toString = function () {
              var e = [];
              return (
                this.forEach(function (t, n) {
                  e.push(r(n) + '=' + r(t));
                }),
                e.join('&')
              );
            }),
            (e.URLSearchParams = o);
        })();
      var a = e.URLSearchParams.prototype;
      'function' != typeof a.sort &&
        (a.sort = function () {
          var e = this,
            t = [];
          this.forEach(function (n, r) {
            t.push([r, n]), e._entries || e.delete(r);
          }),
            t.sort(function (e, t) {
              return e[0] < t[0] ? -1 : e[0] > t[0] ? 1 : 0;
            }),
            e._entries && (e._entries = {});
          for (var n = 0; n < t.length; n++) this.append(t[n][0], t[n][1]);
        }),
        'function' != typeof a._fromString &&
          Object.defineProperty(a, '_fromString', {
            enumerable: !1,
            configurable: !1,
            writable: !1,
            value: function (e) {
              if (this._entries) this._entries = {};
              else {
                var t = [];
                this.forEach(function (e, n) {
                  t.push(n);
                });
                for (var n = 0; n < t.length; n++) this.delete(t[n]);
              }
              var r,
                a = (e = e.replace('', '')).split('&');
              for (n = 0; n < a.length; n++)
                (r = a[n].split('=')), this.append(o(r[0]), r.length > 1 ? o(r[1]) : '');
            }
          });
    })(ae),
      (function (e) {
        if (
          ((function () {
            try {
              var t = new e.URL('b', 'http://a');
              return (t.pathname = 'c d'), 'http://a/c%20d' === t.href && t.searchParams;
            } catch (e) {
              return !1;
            }
          })() ||
            (function () {
              var t = e.URL,
                n = function (t, n) {
                  'string' != typeof t && (t = String(t)), n && 'string' != typeof n && (n = String(n));
                  var r,
                    o = document;
                  if (n && (void 0 === e.location || n !== e.location.href)) {
                    (n = n.toLowerCase()),
                      ((r = (o = document.implementation.createHTMLDocument('')).createElement('base')).href =
                        n),
                      o.head.appendChild(r);
                    try {
                      if (0 !== r.href.indexOf(n)) throw new Error(r.href);
                    } catch (e) {
                      throw new Error('URL unable to set base ' + n + ' due to ' + e);
                    }
                  }
                  var a = o.createElement('a');
                  (a.href = t), r && (o.body.appendChild(a), (a.href = a.href));
                  var i = o.createElement('input');
                  if (
                    ((i.type = 'url'),
                    (i.value = t),
                    ':' === a.protocol || !/:/.test(a.href) || (!i.checkValidity() && !n))
                  )
                    throw new TypeError('Invalid URL');
                  Object.defineProperty(this, '_anchorElement', {
                    value: a
                  });
                  var s = new e.URLSearchParams(this.search),
                    c = !0,
                    l = !0,
                    u = this;
                  ['append', 'delete', 'set'].forEach(function (e) {
                    var t = s[e];
                    s[e] = function () {
                      t.apply(s, arguments), c && ((l = !1), (u.search = s.toString()), (l = !0));
                    };
                  }),
                    Object.defineProperty(this, 'searchParams', {
                      value: s,
                      enumerable: !0
                    });
                  var f = void 0;
                  Object.defineProperty(this, '_updateSearchParams', {
                    enumerable: !1,
                    configurable: !1,
                    writable: !1,
                    value: function () {
                      this.search !== f &&
                        ((f = this.search),
                        l && ((c = !1), this.searchParams._fromString(this.search), (c = !0)));
                    }
                  });
                },
                r = n.prototype;
              ['hash', 'host', 'hostname', 'port', 'protocol'].forEach(function (e) {
                !(function (e) {
                  Object.defineProperty(r, e, {
                    get: function () {
                      return this._anchorElement[e];
                    },
                    set: function (t) {
                      this._anchorElement[e] = t;
                    },
                    enumerable: !0
                  });
                })(e);
              }),
                Object.defineProperty(r, 'search', {
                  get: function () {
                    return this._anchorElement.search;
                  },
                  set: function (e) {
                    (this._anchorElement.search = e), this._updateSearchParams();
                  },
                  enumerable: !0
                }),
                Object.defineProperties(r, {
                  toString: {
                    get: function () {
                      var e = this;
                      return function () {
                        return e.href;
                      };
                    }
                  },
                  href: {
                    get: function () {
                      return this._anchorElement.href.replace('', '');
                    },
                    set: function (e) {
                      (this._anchorElement.href = e), this._updateSearchParams();
                    },
                    enumerable: !0
                  },
                  pathname: {
                    get: function () {
                      return this._anchorElement.pathname.replace('', '/');
                    },
                    set: function (e) {
                      this._anchorElement.pathname = e;
                    },
                    enumerable: !0
                  },
                  origin: {
                    get: function () {
                      var e = {
                          'http:': 80,
                          'https:': 443,
                          'ftp:': 21
                        }[this._anchorElement.protocol],
                        t = this._anchorElement.port != e && '' !== this._anchorElement.port;
                      return (
                        this._anchorElement.protocol +
                        '//' +
                        this._anchorElement.hostname +
                        (t ? ':' + this._anchorElement.port : '')
                      );
                    },
                    enumerable: !0
                  },
                  password: {
                    get: function () {
                      return '';
                    },
                    set: function (e) {},
                    enumerable: !0
                  },
                  username: {
                    get: function () {
                      return '';
                    },
                    set: function (e) {},
                    enumerable: !0
                  }
                }),
                (n.createObjectURL = function (e) {
                  return t.createObjectURL.apply(t, arguments);
                }),
                (n.revokeObjectURL = function (e) {
                  return t.revokeObjectURL.apply(t, arguments);
                }),
                (e.URL = n);
            })(),
          void 0 !== e.location && !('origin' in e.location))
        ) {
          var t = function () {
            return (
              e.location.protocol + '//' + e.location.hostname + (e.location.port ? ':' + e.location.port : '')
            );
          };
          try {
            Object.defineProperty(e.location, 'origin', {
              get: t,
              enumerable: !0
            });
          } catch (n) {
            setInterval(function () {
              e.location.origin = t();
            }, 100);
          }
        }
      })(ae);
    var ie = ae.URL,
      se = function (e, t) {
        return function (n, r) {
          if (!n || !n[e] || !r) return t;
          try {
            return n[e](r);
          } catch (e) {
            W.error(e, {
              consoleOverride: 'The CSS selectors that you provided are incorrect: ' + r,
              sendToDatadog: !1
            });
          }
          return t;
        };
      },
      ce = se('querySelectorAll', []),
      le = se('querySelector', null),
      ue = se('closest', null),
      fe = function (e) {
        return document.getElementById(e);
      };
  
    function de(e) {
      e && e.parentNode && e.parentNode.removeChild(e);
    }
  
    function ge(e) {
      e = '' + e;
      return ['&nbsp;', '&amp;', '&quot;', '&lt;', '&gt;'].some(function (t) {
        return -1 !== e.indexOf(t);
      })
        ? e
            .replace(/&nbsp;/g, ' ')
            .replace(/&amp;/g, '&')
            .replace(/&quot;/g, '"')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
        : e;
    }
  
    function _e(e) {
      var t = window.location.search
        .slice(1)
        .split('&')
        .map(function (e) {
          return e && e.split('=');
        })
        .find(function (t) {
          return t[0] === e;
        });
      return t && t[1];
    }
  
    function pe(e, t) {
      var n = document.createElement('style');
      de(fe(t)),
        (n.id = t),
        (n.type = 'text/css'),
        n.styleSheet ? (n.styleSheet.cssText = e) : n.appendChild(document.createTextNode(e)),
        document.head.appendChild(n);
    }
    var he = function () {
      return /google|facebook|bing|yahoo|baidu|yandex|lighthouse/i.test(navigator.userAgent);
    };
  
    function me(e) {
      try {
        document.createDocumentFragment().querySelector(e);
      } catch (e) {
        return !1;
      }
      return !0;
    }
    var ve = function (e, t, n) {
        var r = new ie(e, location.href);
        return r.searchParams.set(t, n), '' + r.pathname + r.search;
      },
      we = function (e, t) {
        var n;
        return (
          void 0 === t && (t = 1e3),
          function () {
            for (var r = this, o = [], a = arguments.length; a--; ) o[a] = arguments[a];
            clearTimeout(n),
              (n = setTimeout(function () {
                e.apply(r, o);
              }, t));
          }
        );
      },
      ye = function (e) {
        var t = new Date().getTime().toString();
        try {
          var n = e.contentWindow;
          return (n[t] = 'asd'), 'asd' === n[t];
        } catch (e) {
          return !1;
        }
      };
    var be = {},
      ke = function (e, t) {
        be[e] = t;
      },
      Ee = function (e, t) {
        if (be[e]) return be[e](t);
      };
    var Se,
      Ce = {};
    try {
      document.cookie, (Se = !0);
    } catch (Hn) {
      Se = !1;
    }
    (Ce.set = function (e, t, n, r, o) {
      Se &&
        ((e = e
          .replace(/[^+#$&^|]/g, encodeURIComponent)
          .replace('(', '%28')
          .replace(')', '%29')),
        (t = t.toString().replace(/[^+#$&/:<-[\]-}]/g, encodeURIComponent)),
        !n && gt.is_connect && gt.subdomain && (n = gt.host.split('www.').pop()),
        (n = n ? ';domain=' + n : ''),
        (o = o ? ';expires=' + o : ''),
        (r = r || ''),
        (document.cookie = e + '=' + t + n + ';path=/' + r + o + ';SameSite=None;Secure'));
    }),
      (Ce.get = function (e) {
        if (!Se) return null;
        for (var t = document.cookie.split(';'); t.length; ) {
          var n = t.pop(),
            r = n.indexOf('=');
          if (((r = r < 0 ? n.length : r), decodeURIComponent(n.slice(0, r).replace(/^\s+/, '')) === e))
            return decodeURIComponent(n.slice(r + 1));
        }
        return null;
      }),
      (Ce.erase = function (e, t, n) {
        Ce.set(e, '', t, n, 'Thu, 01 Jan 1970 00:00:00 GMT');
      });
    var Oe = [];
  
    function xe(e, t, n) {
      if (e) return n();
      Ne(t, n, !0);
    }
  
    function Ne(e, t, n) {
      return 'function' != typeof t
        ? (W.error('You should provide a callback function as second argument', {
            sendToDatadog: n
          }),
          !1)
        : !n && ne.indexOf(e) < 0
        ? (W.error('No Weglot event is named ' + e, {
            sendToDatadog: !1
          }),
          !1)
        : (Oe.push({
            name: e,
            callback: t,
            internal: n
          }),
          !0);
    }
  
    function Le(e) {
      for (var t = [], n = arguments.length - 1; n-- > 0; ) t[n] = arguments[n + 1];
      for (
        var r = Oe.filter(function (t) {
            return t.name === e;
          }),
          o = 0,
          a = r;
        o < a.length;
        o += 1
      ) {
        var i = a[o];
        try {
          i.callback.apply(i, t);
        } catch (e) {
          if (i.internal) throw e;
          W.error('Error triggering callback function: ' + e, {
            sendToDatadog: !1
          });
        }
      }
    }
  
    function Te() {
      if (window.location.hostname !== B || !document.baseURI) {
        var e = window.location;
        return {
          url: e.href,
          hostname: e.hostname,
          pathname: e.pathname,
          search: e.search
        };
      }
      var t = new ie(document.baseURI),
        n = t.hostname,
        r = t.pathname,
        o = t.search;
      return {
        url: document.baseURI,
        hostname: n,
        pathname: r,
        search: o
      };
    }
    var je = Te();
    xe(gt && Object.keys(gt).length > 0, 'onOptionsReady', function () {
      if (gt.dynamicPushState) {
        var e = history.pushState;
        history.pushState = function () {
          for (var t = [], n = arguments.length; n--; ) t[n] = arguments[n];
          e.apply(history, t);
          var r = Te();
          (je.hostname = r.hostname),
            (je.pathname = r.pathname),
            (je.url = r.url),
            Le('onCurrentLocationChanged');
        };
      }
    });
    var Ae,
      Pe = {};
  
    function De(e) {
      var t = 1;
      return e.replace(/\((.*?)\)/g, function () {
        return '$' + t++;
      });
    }
  
    function Re() {
      var e = je.pathname,
        t = gt.localeRules;
      void 0 === t && (t = []);
      var n = gt.languages,
        r = {
          position: 0,
          translatedFormat: 'CODE',
          originalFormat: '',
          addedByDefault: !0
        },
        o = gt.language_from;
      if (t.length) {
        var a = [];
        t.map(function (e) {
          var t = e.position,
            n = e.translatedFormat;
          n && 'CODE' !== n && a.push(t || 0);
        });
        var i = a
          .filter(function (e, t, n) {
            return n.indexOf(e) === t;
          })
          .map(function (e) {
            return Object.assign({}, r, {
              position: e
            });
          });
        t.unshift.apply(t, i);
      } else t.push(r);
      var s = null,
        c = null,
        l =
          t.find(function (t) {
            var r = t.position;
            void 0 === r && (r = 0);
            var a = t.translatedFormat;
            void 0 === a && (a = 'CODE');
            var i = t.originalFormat;
            void 0 === i && (i = '');
            var l = t.addedByDefault;
            if (!a.includes('CODE')) return !1;
            var u = e.split('/');
            if (u.length <= r) return !1;
            var f = u[r + 1],
              d = n.find(function (e) {
                var t = e.custom_code || e.language_to,
                  n = a.replace('CODE', t),
                  r = new RegExp('^' + n + '$', 'g');
                return !!r.test(f) && ((c = r), !0);
              });
            if (d) return (s = d.custom_code || d.language_to), !0;
            if (i) {
              var g = i.replace('CODE', o);
              return new RegExp('^' + g + '$', 'g').test(f);
            }
            return !l;
          }) || r;
      return (
        (Pe.convertLocale = function (t, n, r, a) {
          if ((void 0 === n && (n = e), void 0 === r && (r = s || o), void 0 === a && (a = null), r === t))
            return n;
          var i = l.position;
          void 0 === i && (i = 0);
          var u = l.originalFormat;
          void 0 === u && (u = '');
          var f = l.translatedFormat;
          void 0 === f && (f = 'CODE');
          var d = n.split('/');
          if (d.length <= i) return n;
          var g = d[i + 1];
          if (r === o) {
            var _ = f.replace(/CODE/g, t),
              p = !1;
            if (u) {
              var h = u.replace(/CODE/g, o),
                m = new RegExp('^' + h + '$', 'g'),
                v = De(_);
              (_ = g.replace(m, v)), a && !m.test(g) && ((p = !0), (_ = a.split('/')[i + 1]));
            }
            var w = u && !p ? 2 : 1;
            return d
              .slice(0, i + 1)
              .concat([_], d.slice(i + w))
              .join('/');
          }
          if (t === o && !u)
            return d
              .slice(0, i + 1)
              .concat(d.slice(i + 2))
              .join('/');
          var y = De((t === o ? u : f).replace(/CODE/g, t)),
            b = g.replace(c, y);
          return d
            .slice(0, i + 1)
            .concat([b], d.slice(i + 2))
            .join('/');
        }),
        (Pe.language = s || o),
        Pe
      );
    }
  
    function Ie() {
      var e = je.hostname,
        t = gt.languages.find(function (t) {
          return t.connect_host_destination && t.connect_host_destination.host === e;
        });
      return t ? t.custom_code || t.language_to : gt.language_from;
    }
  
    function We() {
      return Re().language;
    }
  
    function Ue() {
      if (Ae) return Ae;
      if (gt.is_connect) {
        var e = document.documentElement.dataset.wgTranslated;
        return e ? ((Ae = e), e) : (Ae = gt.subdirectory ? We() : Ie());
      }
      return (Ae = gt.language_from);
    }
  
    function Me(e, t) {
      var n = t;
      n || (n = Ue());
      for (var r = 0, o = e; r < o.length; r += 1) {
        var a = o[r];
        if (!a || !a.dataset || !a.dataset.wgOnlyDisplay) return;
        a.hidden = a.dataset.wgOnlyDisplay !== n;
      }
    }
    Ne(
      'onCurrentLocationChanged',
      function () {
        Pe = {};
      },
      !0
    );
    var qe = {
        getItem: function (e) {
          return Ce.get(e);
        },
        setItem: function (e, t, n) {
          void 0 === n && (n = {});
          var r = n.domain,
            o = n.path,
            a = n.expires;
          Ce.set(e, t, r, o, a);
        },
        removeItem: function (e) {
          return Ce.erase(e);
        }
      },
      He = {
        getItem: function () {},
        setItem: function () {},
        removeItem: function () {}
      };
  
    function ze(e) {
      void 0 === e && (e = {});
      var t = e.type || 'local';
      try {
        return 'cookie' === t ? qe : window.localStorage;
      } catch (e) {}
      return e.type
        ? He
        : ze({
            type: 'local' === t ? 'cookie' : 'local'
          });
    }
    var Be = {
      slugs: {},
      version: 0,
      network: void 0
    };
  
    function Fe() {
      return new Promise(function (e) {
        for (
          var t = gt.languages,
            n = {},
            r = function () {
              var r = a[o],
                i = r.custom_code,
                s = r.language_to;
              (function (e) {
                var t = gt.api_key,
                  n = gt.versions;
                if (!n || !n.slugTranslation) return Promise.resolve({});
                var r =
                  'https://cdn-api-weglot.com/translations/slugs?api_key=' +
                  t +
                  '&language_to=' +
                  e +
                  '&v=' +
                  n.slugTranslation;
                return fetch(r)
                  .then(function (e) {
                    return e.json();
                  })
                  .then(function (e) {
                    return Array.isArray(e) ? {} : e;
                  })
                  .catch(function (e) {
                    return W.error(e), {};
                  });
              })(s).then(function (r) {
                (n[i || s] = r), Object.keys(n).length === t.length && e(n);
              });
            },
            o = 0,
            a = t;
          o < a.length;
          o += 1
        )
          r();
      });
    }
  
    function Ve(e) {
      return e
        ? Object.keys(e).reduce(function (t, n) {
            return (
              (t[n] = (function (e) {
                return Object.keys(e).reduce(
                  function (t, n) {
                    return e[n] && ((t.original[n] = e[n]), (t.translated[e[n]] = n)), t;
                  },
                  {
                    original: {},
                    translated: {}
                  }
                );
              })(e[n])),
              t
            );
          }, {})
        : {};
    }
  
    function $e(e) {
      var t = gt.versions;
      if (t && t.slugTranslation) {
        var n = t.slugTranslation;
        Be.version < n &&
          (Be.network
            ? Be.network.resolved ||
              Be.network.then(function (t) {
                return e(Ve(t));
              })
            : (Be.network = Fe()
                .then(function (t) {
                  return (
                    (Be.network.resolved = !0),
                    (function (e) {
                      var t = gt.versions,
                        n = {
                          version: t ? t.slugTranslation : 1,
                          slugs: e
                        };
                      try {
                        var r = ze({
                          type: 'local'
                        });
                        r && r.setItem(V, JSON.stringify(n));
                      } catch (e) {
                        W.warn(e);
                      }
                      Be = Object.assign({}, Be, n);
                    })(t),
                    e(Ve(t)),
                    t
                  );
                })
                .catch(function () {
                  return e({}), {};
                }))),
          e(Ve(Be.slugs));
      } else e({});
    }
    !(function () {
      if (Object.keys(Be.slugs).length) return Be.slugs;
      try {
        var e = ze({
          type: 'local'
        });
        if (!e) return {};
        var t = e.getItem(V);
        t && (Object.assign(Be, JSON.parse(t)), Be.slugs);
      } catch (e) {
        return {};
      }
    })();
    var Ge = {};
  
    function Je(e, t) {
      return e
        .split('/')
        .map(function (e) {
          return t[decodeURIComponent(e)] || e;
        })
        .join('/');
    }
  
    function Ye(e, t) {
      gt.is_tld &&
        gt.auto_switch &&
        (e === gt.language_from
          ? t.searchParams.set('no_redirect', 'true')
          : t.searchParams.delete('no_redirect'));
    }
  
    function Ke(e, t) {
      var n = Ue(),
        r = new ie(je.url);
      r.searchParams.has('lang') && r.searchParams.delete('lang'), Ye(e, r);
      var o = (function (e) {
        if (gt.subdirectory) return !1;
        var t = gt.language_from,
          n = gt.host,
          r = gt.languages;
        if (e === t) return n;
        var o =
            r.find(function (t) {
              return t.custom_code === e || t.language_to === e;
            }) || {},
          a = o.connect_host_destination;
        return a && a.host;
      })(e);
      return (
        o && (r.hostname = o),
        (r.pathname = (function (e, t, n, r) {
          if (!Object.keys(e).length) return t;
          if (!Ge.originalPath)
            if (n !== gt.language_from && e[n]) {
              var o = e[n].translated;
              Ge.originalPath = Je(t, o);
            } else Ge.originalPath = t;
          return r === gt.language_from
            ? Ge.originalPath
            : e[r] && e[r].original
            ? Je(Ge.originalPath, e[r].original)
            : t;
        })(t, r.pathname, n, e)),
        gt.subdirectory &&
          e &&
          (r.pathname = (function (e, t) {
            return Re().convertLocale(e, t);
          })(e, r.pathname)),
        r.toString()
      );
    }
  
    function Xe(e, t) {
      if (!gt.is_connect || !e) return t('#');
      var n = gt.dynamicPushState,
        r = gt.injectedData;
      void 0 === r && (r = {});
      var o = r.allLanguageUrls;
      if ((void 0 === o && (o = {}), !n && o && o[e])) {
        var a = new ie(o[e]);
        return Ye(e, a), t(a.toString());
      }
      $e(function (n) {
        return t(Ke(e, n));
      });
    }
    Ne(
      'onCurrentLocationChanged',
      function () {
        Ge = {};
      },
      !0
    );
    var Ze = {};
  
    function Qe() {
      var e = gt.host;
      return void 0 === e && (e = window.location.hostname), 0 === e.indexOf('www.') ? e.slice(3) : '.' + e;
    }
  
    function et() {
      var e = document.cookie.match(/(^cart=[^;]+|[\W]cart=[^;]+)/g);
      if (e) {
        var t = e.map(function (e) {
          return e.split('=').pop();
        });
        (1 !== t.length && t[0] === t[1]) || Ce.set('cart', t[0], Qe());
      } else setTimeout(et, 100);
    }
  
    function tt(e, t) {
      return gt.is_connect && e !== gt.language_from
        ? Ze[t]
          ? Ze[t]
          : ($e(function (r) {
              var o = r && r[e] ? Je(t, r[e].original) : t;
              n = gt.subdirectory ? Re().convertLocale(e, o, gt.language_from) : o;
            }),
            (Ze[t] = n),
            n)
        : t;
      var n;
    }
    Ne(
      'onCurrentLocationChanged',
      function () {
        Ze = {};
      },
      !0
    ),
      ke('onWeglotSetup', function () {
        !1 !== gt.original_shopify_checkout &&
          gt.is_connect &&
          !gt.subdirectory &&
          gt.language_from === Ue() &&
          et();
      });
    var nt = {};
  
    function rt(e) {
      return e
        ? 'string' != typeof e
          ? e
          : e.split(',').map(function (e) {
              return {
                value: e
              };
            })
        : [];
    }
  
    function ot(e, t) {
      if ((void 0 === t && (t = ''), !e)) return re.button_style;
      var n = e.classF || '',
        r = n.match(/flag-(\d)/),
        o = {
          with_name: e.withname,
          full_name: !!e.fullname,
          is_dropdown: !!e.is_dropdown,
          with_flags: -1 !== n.indexOf('wg-flags'),
          flag_type: r && r[1] ? oe[r[1]] : '',
          invert_flags: !0
        };
      return t && (o.custom_css = t), o;
    }
  
    function at(e) {
      var t = e.styleOpt,
        n = e.containerCss,
        r = e.target,
        o = e.sibling;
      return {
        style: ot(t, n),
        location: {
          target: r,
          sibling: o
        }
      };
    }
    (nt[$] = function () {
      var e = function (e) {
          void 0 === e && (e = Ue());
          for (
            var t = function (t) {
                return tt(e, t);
              },
              r = n(e),
              o = 0,
              a = document.querySelectorAll(
                [
                  'form[method="post"][action^="' + t('/cart') + '"]',
                  'form[method="post"][action^="' + t('/checkout') + '"]'
                ].join(',')
              );
            o < a.length;
            o += 1
          ) {
            var i = a[o],
              s = i.getAttribute('action');
            (0 === s.indexOf(t('/cart')) && s.split('?')[0] !== t('/cart')) ||
              i.setAttribute('action', ve(s, 'locale', r));
          }
          for (
            var c = 0,
              l = document.querySelectorAll(
                'a[href^="' + t('/checkout') + '"],a[href^="' + t('/cart/checkout') + '"]'
              );
            c < l.length;
            c += 1
          ) {
            var u = l[c];
            u.setAttribute('href', ve(u.getAttribute('href'), 'locale', r));
          }
        },
        t = function (e) {
          void 0 === e && (e = Ue());
          var t =
            document.getElementById('create_customer') ||
            document.querySelector('form[action="' + tt(e, '/account') + '"]') ||
            ('string' == typeof gt.customer_tag && le(document, gt.customer_tag));
          if (t) {
            var n = document.getElementById('weglot-lang-form');
            n && n.parentNode.removeChild(n);
            var r = document.createElement('input');
            Object.assign(r, {
              type: 'hidden',
              id: 'weglot-lang-form',
              name: 'customer[tags]',
              value: '#wg' + e + '#wg'
            }),
              t.appendChild(r);
          }
        };
  
      function n(e) {
        return 'pt' === e
          ? 'pt-PT'
          : 'ro' === e
          ? 'ro-RO'
          : 'fl' === e
          ? 'fil'
          : 'zh' === e
          ? 'zh-CN'
          : 'tw' === e
          ? 'zh-TW'
          : e.substr(0, 2);
      }
      var r = function () {
          var e = document.getElementById('shopify-features');
          if (!e) return null;
          var t = e.textContent.match(/"shopId":(\d*)/);
          return t ? t[1] : null;
        },
        o = function (e) {
          if ((void 0 === e && (e = Ue()), !gt.visual_editor && window.top === window.self)) {
            var t = gt.cart_attributes
                .map(function (t) {
                  return 'attributes[' + t + ']=' + n(e);
                })
                .join('&'),
              r = fetch('/cart/update.js', {
                method: 'POST',
                body: t,
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                },
                credentials: 'same-origin'
              });
            !1 !== gt.original_shopify_checkout &&
              gt.is_connect &&
              !gt.subdirectory &&
              gt.language_from === Ue() &&
              r
                .then(function (e) {
                  return e.json();
                })
                .then(function (e) {
                  var t = e.token;
                  return Ce.set('cart', t, Qe());
                });
            for (
              var o = document.querySelectorAll('a[href*="/cart/"]'), a = 'attributes[lang]=' + e, i = 0, s = o;
              i < s.length;
              i += 1
            ) {
              var c = s[i],
                l = c.getAttribute('href');
              if (l) {
                var u = l.match(new RegExp('/cart/\\d+:\\d+(\\?)?'));
                u &&
                  ((l = l.replace(/&?attributes\[lang\]=([a-zA-Z-]+)/g, '')),
                  c.setAttribute('href', l + (u[1] ? '&' : '?') + a));
              }
            }
          }
        };
      function a(a) {
        if (gt.language_from !== a) {
          var i, s;
          window.Shopify && (window.Shopify.locale = a),
            !he() && gt.order_tag && o(a),
            e(a),
            (i = a),
            (s = r()) && Ce.set('checkout_locale', n(i), null, s);
          var c = document.querySelectorAll('[data-wg-only-display]');
          c.length && Me(c, a), gt.customer_tag && t(a);
        }
      }
      Ne(
        'initialized',
        function () {
          window.langify &&
            W.log('%c Please, uninstall langify to properly use Weglot', {
              sendToDatadog: !1
            }),
            he() || !gt.order_tag || (gt.is_connect && gt.language_from !== Ue()) || o(),
            e();
          var r,
            a = document.querySelectorAll('[data-wg-only-display]');
          a.length && Me(a),
            gt.customer_tag && t(),
            document.getElementsByClassName('shopify-payment-button').length &&
              ((r = window.fetch),
              (window.fetch = function () {
                if ('/wallets/checkouts.json' === arguments[0])
                  try {
                    var e = JSON.parse(arguments[1].body),
                      t = n(Ue());
                    (e.checkout.attributes = {}),
                      gt.cart_attributes.forEach(function (n) {
                        return (e.checkout.attributes[n] = t);
                      }),
                      (arguments[1].body = JSON.stringify(e));
                  } catch (e) {}
                return r.apply(window, arguments);
              }));
        },
        !0
      ),
        ke('onConnectPageLoad', function (e) {
          return a(e);
        }),
        ke('onPageLanguageSet', function (e) {
          return a(e);
        }),
        ke('onDynamicDetected', function () {
          e(Ue());
        }),
        ke('startWhen', function () {
          return (
            fe('admin-bar-iframe') ||
            fe('preview-bar-iframe') ||
            gt.private_mode ||
            (function () {
              for (var e = 0, t = document.scripts; e < t.length; e += 1)
                if (-1 !== t[e].src.indexOf('preview_bar_injector')) return !0;
              return !1;
            })()
          );
        });
      var i = ['.shopify-payment-button button']
        .concat([
          '#isp_search_result_page_container',
          '.snize-ac-results',
          '#snize_results',
          '.snize-recommendation',
          '.snize-modal',
          '.snize-search-results-header',
          'div>span.cc-message',
          '.hc-widget',
          '.jdgm-rev-widg__header',
          '.jdgm-rev__body',
          '.jdgm-rev-title',
          '.yotpo-main-widget',
          '#swell-popup',
          '.swell-tab',
          '.yotpo-widget-override-css',
          '#saso-notifications',
          '.saso-cross-sell-popup',
          '.saso-cart-item-discount-notes',
          '.saso-cart-item-upsell-notes',
          '.saso-volume-discount-tiers',
          '.opw-leading-normal',
          '.opw-my-2.opw-leading-normal.opw-text-lg.opw-text-left',
          '.opinew-navbar.opw-flex.opw-items-center.opw-justify-between.opw-flex-wrap.opw-py-4.opw-px-6',
          '.main-content-container.opw--mx-1',
          '.opw-text-center.opw-text-sm.opw-border-solid.opw-border-0.opw-mt-3',
          '.summary-card-container.opw-mx-1',
          '.opw-reviews-container.opw-mt-3.opw--mx-1',
          '.opinew-reviews-title.opw-flex.opw-items-center.opw-flex-no-shrink.opw-mr-6',
          '.opw-flex.opw-flex-row-reverse',
          '#opinew-app-container',
          '.gem_dynamic-content',
          '.pp_tracking_content',
          '.pp_all_form_div',
          '.pp_tracking_result_title',
          '.progress-bar-style',
          '.pp_tracking_left',
          '.pp_num_status_show',
          '.pp_tracking_status_tips',
          '.pp_page_map_div',
          '.pp_tracking_result_parent',
          '.pp_tracking_right',
          '.pp_recommend_product_parent',
          '.currency-converter-cart-note',
          '.cbb-shipping-rates-calculator',
          '.cbb-frequently-bought-container',
          '.cbb-frequently-bought-discount-applied-message',
          '.cbb-also-bought-container',
          '#zonos',
          '.buddha-menu-item',
          '.R-GlobalModal',
          '.ruk-rating-snippet-count',
          '.R-ContentList-container',
          '.R-ReviewsList-container',
          '.R-SliderIndicator-group',
          '.R-TextBody',
          '.widgetId-reviewsio-carousel-widget',
          '.REVIEWSIO-FloatingMinimised',
          '.REVIEWSIO-FloatingMinimised__Container',
          '.reviewsio-carousel-widget',
          '.reviews-io-floating-widget',
          '.reviews_container',
          '.site-nav.style--sidebar .site-nav-container .subtitle',
          '.search-more',
          '.variant-quantity',
          '.lion-claimed-rewards-list',
          '.lion-header',
          '.lion-header__join-buttons',
          '.lion-header__join-today',
          '.lion-history-table',
          '.lion-integrated-page-section__heading-text',
          '.lion-loyalty-panel',
          '.lion-loyalty-splash',
          '.lion-loyalty-widget',
          '.lion-modal__content',
          '.lion-modal__header',
          '.lion-referral-widget',
          '.lion-rewards-list',
          '.lion-rules-list',
          '.lion-tier-overview',
          '.ccpops-popup__content__bottom-text',
          '.ccpops-popup__content__top-text',
          '.ccpops-trigger__text',
          '.ks-table-row',
          '.klaviyo-form'
        ])
        .concat(
          gt.is_connect
            ? []
            : [
                'form.cart.ajaxcart',
                'form.cart-drawer',
                '#cross-sell',
                '.wheelio_holder',
                '.mini-cart',
                '#shopify-product-reviews',
                '#esc-oos-form',
                '.product__add-to-cart-button',
                'select.product-variants>option:not([value])',
                '.ui-autocomplete',
                '.shopify-payment-button__button',
                '#shopify-section-static-recently-viewed-products',
                '#recently-viewed-products',
                '#shopify-section-product-recommendations',
                '.action_button.add_to_cart'
              ]
        );
      return {
        cart_attributes: ['lang', 'Invoice Language'],
        excluded_blocks: [
          "input[type='radio']",
          'span.money',
          '.price',
          '.product__prices',
          '#admin-bar-iframe',
          '.notranslate',
          '.skiptranslate',
          '#isp_refine_nevigation',
          '#isp_header_subtitle',
          '.isp_sorting_and_result_view_wrapper',
          '#isp_results_did_you_mean > span',
          '.isp_facet_show_hide_values',
          '#isp_main_search_box',
          '.snize-filter-variant-count',
          '.snize-search-results-header a',
          '.snize-search-results-header b',
          '.hc-author__text',
          '.hc-avatar__initials',
          '.hc-rating-chart__count',
          '.hc-rating-chart__percentage-value',
          '.yotpo-review-date',
          '.yotpo-user-name',
          '.yotpo-user-letter',
          '.yotpo .avg-score',
          '.yotpo .sr-only',
          '.yotpo-mandatory-mark'
        ].map(function (e) {
          return {
            value: e
          };
        }),
        search_forms: "form[action='/pages/search-results'],form[action='/search']",
        search_parameter: 'q',
        dynamics: i.map(function (e) {
          return {
            value: e
          };
        }),
        extra_definitions: [
          {
            type: 1,
            selector: '.snize-color-swatch',
            attribute: 'data-sntooltip'
          },
          {
            type: 1,
            selector: 'button[data-pf-type=ProductATC]',
            attribute: 'data-soldout'
          },
          {
            type: 1,
            selector: 'button[data-pf-type=ProductATC]',
            attribute: 'data-adding'
          },
          {
            type: 1,
            selector: 'button[data-pf-type=ProductATC]',
            attribute: 'data-added'
          }
        ]
      };
    }),
      (nt[G] = function () {
        return (
          ke('onPageLanguageSet', function (e) {
            !(function (e) {
              for (
                var t = 0, n = document.querySelectorAll('[href*="/checkout.php"],[href*="/cart.php"]');
                t < n.length;
                t += 1
              ) {
                var r = n[t];
                r.setAttribute('href', ve(r.getAttribute('href'), 'lang', e));
              }
            })(e);
          }),
          {
            dynamics: [
              '.quick-shop-details',
              '#QuickViewProductDetails',
              '.QuickViewModal',
              '.modalContainer',
              '.ng-checkout-container',
              '.previewCartAction',
              '#checkout-app'
            ].map(function (e) {
              return {
                value: e
              };
            }),
            search_forms: "form[action='/search.php']",
            search_parameter: 'search_query'
          }
        );
      }),
      (nt[Q] = function () {
        return gt.is_connect
          ? {}
          : {
              dynamics: [
                {
                  value: '.content'
                }
              ]
            };
      }),
      (nt[J] = function () {
        return {
          excluded_blocks: ['[data-display="cms-only"]', '.j-admin-links', '.cc-m-status-empty'].map(function (
            e
          ) {
            return {
              value: e
            };
          })
        };
      }),
      (nt[Y] = function () {
        var e = [],
          t = ['body.sqs-is-page-editing'];
        document.getElementById('sqs-cart-root') &&
          (e.push('#sqs-cart-container'),
          t.push(
            '#sqs-cart-container [class*=subtotalPrice]',
            '#sqs-cart-container .cart-container .item-price'
          )),
          document.getElementById('sqs-standard-checkout') &&
            (e.push('#checkout'),
            t.push(
              '#checkout span.money',
              '#checkout [data-test*=incomplete] [class^=PaymentCard-container]',
              '#checkout [data-test*=incomplete] [class^=CustomerAddress-container]',
              '#checkout [class^=CustomerInfoSection-email]',
              '#checkout [class^=GoogleResultsList]'
            ));
        var n = window.location.host.endsWith('squarespace.com');
        if (
          (Ne('initialized', function () {
            try {
              var e = window.ExtensionScriptsSDK;
              if (!e) return;
              e['1.0'].page.events.dispatchScriptLoadEvent('Weglot');
            } catch (e) {}
          }),
          !gt.is_connect)
        )
          try {
            for (
              var r = 0, o = Array.from(document.querySelectorAll('.animation-segment-wrapper'));
              r < o.length;
              r += 1
            ) {
              o[r].parentNode.dataset.dynamicStrings = '';
            }
            for (
              var a = 0, i = Array.from(document.querySelectorAll('.animation-segment-parent-hidden'));
              a < i.length;
              a += 1
            ) {
              i[a].dataset.dynamicStrings = '';
            }
            t.push('.animation-segment-wrapper'), t.push('.animation-segment-parent-hidden > *');
          } catch (e) {}
        return {
          force_translation: e.join(','),
          dynamics: [
            '#sqs-cart-container',
            '#checkout',
            '.sqs-widgets-confirmation',
            '.video-player',
            '.jdgm-widget',
            '.calendar-block',
            '.opentable-v2-block',
            '.blog-item-comments'
          ]
            .map(function (e) {
              return {
                value: e
              };
            })
            .concat(
              gt.is_connect
                ? [
                    {
                      value: '.sqs-add-to-cart-button.cart-adding'
                    },
                    {
                      value: '.sqs-add-to-cart-button.cart-added'
                    }
                  ]
                : [
                    {
                      value: '[data-dynamic-strings]'
                    },
                    {
                      value: '.sqs-add-to-cart-button'
                    },
                    {
                      value: '.variant-select-wrapper'
                    }
                  ]
            ),
          excluded_blocks: t
            .map(function (e) {
              return {
                value: e
              };
            })
            .concat(
              gt.is_connect
                ? [
                    {
                      value: '.comment-body'
                    }
                  ]
                : []
            ),
          forceDisableConnect: n,
          merged_selectors_remove: [
            {
              value: '.plyr__menu__container'
            },
            {
              value: '.product-price .original-price'
            },
            {
              value: '.comment-btn-wrapper'
            }
          ],
          extra_definitions: [
            {
              type: 1,
              selector: '.variant-select-wrapper',
              attribute: 'data-text'
            }
          ]
        };
      }),
      (nt[K] = function () {
        var e = {
          dynamics: document.documentElement.getAttribute('data-wg-translated')
            ? []
            : [
                {
                  value: '#SITE_CONTAINER'
                }
              ],
          dynamicPushState: !0
        };
        if (
          (window.wixBiSession &&
            'bolt' !== window.wixBiSession.renderType &&
            !gt.visual_editor &&
            (document.addEventListener('DOMContentLoaded', function () {
              new MutationObserver(function (e) {
                for (var t = 0; t < e.length; t++) {
                  'SUCCESS' === e[t].target.getAttribute('data-santa-render-status') &&
                    (this.disconnect(), Le('start'));
                }
              }).observe(document.getElementById('SITE_CONTAINER'), {
                attributes: !0,
                attributeFilter: ['data-santa-render-status']
              });
            }),
            (e.delayStart = !0),
            (e.wait_transition = !1)),
          window.wixBiSession && 'bolt' === window.wixBiSession.renderType)
        ) {
          var t = 0,
            n = setInterval(function () {
              ((document.body && window.sssr) || 40 === t) && (Le('start'), clearInterval(n)), t++;
            }, 100);
          (e.delayStart = !0), (e.wait_transition = !1);
        }
        return e;
      }),
      (nt[X] = function () {
        return (
          Ne('switchersReady', function () {
            var e = document.querySelector('.weglot-container');
            e && e.classList.add('weglot-container--left');
          }),
          {
            forceDisableConnect: window.Webflow && window.Webflow.env && !!window.Webflow.env('editor'),
            excluded_blocks: ['.wg-element-wrapper'].map(function (e) {
              return {
                value: e
              };
            })
          }
        );
      }),
      (nt[Z] = function () {
        return {
          dynamics: [
            '.w-container',
            '.w-wrapper',
            '.product-header',
            '.product-messages',
            '.error',
            'button'
          ].map(function (e) {
            return {
              value: e
            };
          })
        };
      }),
      (nt[ee] = function () {
        return {
          ignoreDynamicFragments: !0,
          dynamicPushState: !0,
          merged_selectors_remove: [
            {
              value: '.themeProfileMenu'
            }
          ]
        };
      });
    var it = [
      {
        from: 'originalLanguage',
        to: 'language_from'
      },
      {
        from: 'autoSwitch',
        to: 'auto_switch'
      },
      {
        from: 'autoSwitchFallback',
        to: 'auto_switch_fallback'
      },
      {
        from: 'waitTransition',
        to: 'wait_transition'
      },
      {
        from: 'subDomain',
        to: 'subdomain'
      },
      {
        from: 'translateSearch',
        to: 'translate_search'
      },
      {
        from: 'searchsForms',
        to: 'search_forms'
      },
      {
        from: 'searchParameter',
        to: 'search_parameter'
      },
      {
        from: 'hideSwitcher',
        to: 'hide_switcher'
      },
      {
        from: 'dangerouslyForceDynamic',
        to: 'dangerously_force_dynamic'
      },
      {
        from: 'loadingBar',
        to: 'loading_bar'
      },
      {
        from: 'customerTag',
        to: 'customer_tag'
      },
      {
        from: 'orderTag',
        to: 'order_tag'
      },
      {
        from: 'translateImages',
        to: 'media_enabled'
      },
      {
        from: 'extraDefinitions',
        to: 'extra_definitions'
      },
      {
        from: 'excludePaths',
        to: 'excluded_paths',
        func: function (e) {
          return e
            ? 'string' != typeof e
              ? e
              : e
                  .split(',')
                  .filter(function (e) {
                    return !!e;
                  })
                  .map(function (e) {
                    return {
                      value: e,
                      type: 'CONTAIN'
                    };
                  })
            : [];
        }
      },
      {
        from: 'exceptions',
        to: 'excluded_blocks',
        func: rt
      },
      {
        from: 'whiteList',
        to: 'whitelist',
        func: rt
      },
      {
        from: 'styleOpt',
        to: 'button_style',
        func: ot
      },
      {
        from: 'destinationLanguages',
        to: 'languages',
        func: function (e) {
          return 'string' != typeof e
            ? e
            : e.split(',').map(function (e) {
                return {
                  language_to: e,
                  provider: null,
                  automatic_translation_enabled: !0
                };
              });
        }
      }
    ];
  
    function st(e) {
      var t = Object.assign({}, e);
      return (
        t.switchers &&
          ('string' == typeof t.switchers && (t.switchers = JSON.parse(t.switchers)),
          t.switchers.length && t.switchers[0].styleOpt && (t.switchers = t.switchers.map(at)),
          (t.scriptParamSwitcher = !0)),
        Array.isArray(t.dynamic) && (t.dynamic = t.dynamic.join(',')),
        Array.isArray(t.translate_iframes) && (t.translate_iframes = t.translate_iframes.join(',')),
        t.translate_images && (t.media_enabled = !0),
        it.forEach(function (e) {
          var n = e.from,
            r = e.to,
            o = e.func;
          void 0 !== t[n] && ((t[r] = o ? o(t[n]) : t[n]), delete t[n]);
        }),
        t
      );
    }
  
    function ct(e, t) {
      var n = {};
      for (var r in e) Object.prototype.hasOwnProperty.call(e, r) && -1 === t.indexOf(r) && (n[r] = e[r]);
      return n;
    }
    var lt = {};
  
    function ut(e) {
      if (!e || !e.api_key) throw Error('You have to provide at least a key');
      var t = e.api_key.split('wg_').pop(),
        n = st(e);
      return (function (e) {
        if (window.location.hostname === B)
          return fetch('https://api.weglot.com/projects/settings?api_key=wg_' + e).then(function (e) {
            return e.json();
          });
        var t = fe('weglot-data');
        if (t)
          try {
            var n = JSON.parse(t.innerHTML),
              r = n.settings,
              o = ct(n, ['settings']);
            if (r) return (r.injectedData = o), Promise.resolve(r);
          } catch (e) {}
        var a = (function (e) {
          if (!e.includes(M)) return null;
          var t = e.split('.')[0];
          if (t.includes('-')) {
            return t.split('-').reverse()[0];
          }
          return t;
        })(window.location.hostname);
        if (a)
          return fetch('' + U + a + '.json')
            .then(function (e) {
              return e.json();
            })
            .then(function (e) {
              return (
                'SUBDOMAIN' === e.url_type &&
                  (e.languages = e.languages.map(function (e) {
                    return Object.assign({}, e, {
                      connect_host_destination: Object.assign({}, e.connect_host_destination, {
                        is_dns_set: !0,
                        created_on_aws: 1,
                        host: (e.custom_code || e.language_to) + '-' + a + '.' + M
                      })
                    });
                  })),
                (e.is_dns_set = !0),
                (e.previewHash = a),
                e
              );
            });
        return fetch('' + U + e + '.json').then(function (e) {
          return e.json();
        });
      })(t)
        .then(function (e) {
          var t = e.custom_settings,
            r = ct(e, ['custom_settings']);
          n.button_style = Object.assign(t ? t.button_style : {}, n.button_style);
          var o = r.language_from,
            a = r.languages;
          o && (n.language_from = o),
            a && (n.languages = a),
            t && t.localeRules && (n.localeRules = t.localeRules);
          var i = dt(Object.assign({}, r, t, n));
          return Le('onOptionsReady'), i;
        })
        .catch(function (e) {
          W.error(e, {
            consoleOverride: (e && e.wgErrMsg) || 'Cannot fetch Weglot options. Is your key correct?',
            sendToDatadog: !1
          });
        });
    }
  
    function ft() {
      var e = fe('weglot-data');
      if (e)
        try {
          var t = JSON.parse(e.innerHTML);
          delete t.settings, t && (lt.injectedData = t);
        } catch (e) {}
    }
  
    function dt(e) {
      if (e.deleted_at)
        throw {
          wgErrMsg: 'Cannot load Weglot because the project has been deleted'
        };
      e.injectedData ||
        ('loading' === document.readyState
          ? document.addEventListener('DOMContentLoaded', function () {
              ft();
            })
          : ft()),
        'SUBDIRECTORY' === e.url_type && e.is_dns_set && (e.subdirectory = !0),
        e.languages.length || (e.languages = []),
        (lt.is_connect =
          e.subdirectory ||
          e.languages.some(function (e) {
            return (
              e.connect_host_destination &&
              e.connect_host_destination.is_dns_set &&
              e.connect_host_destination.created_on_aws
            );
          })),
        (e.subdomain = !e.subdirectory && (e.subdomain || lt.is_connect)),
        e.dynamic &&
          (e.dynamics ||
            (e.dynamics = e.dynamic.split(',').map(function (e) {
              return {
                value: e.trim()
              };
            })),
          delete e.dynamic),
        window.location.hostname === B && (lt.visual_editor = !0),
        ((function () {
          -1 !== location.search.indexOf('weglot-private=0') && ze().removeItem('wg-private-mode');
          var e =
            document.getElementById('admin-bar-iframe') ||
            document.getElementById('preview-bar-iframe') ||
            -1 !== location.search.indexOf('weglot-private=1') ||
            '1' ===
              ze({
                type: 'cookie'
              }).getItem('wg-private-mode');
          return (
            e &&
              ze({
                type: 'cookie'
              }).setItem('wg-private-mode', '1'),
            e
          );
        })() ||
          lt.switcher_editor ||
          lt.visual_editor) &&
          (lt.private_mode = !0);
      var t,
        n,
        r,
        o = e.technology_name || lt.technology_name,
        a = (t = o) && nt[t] ? nt[t]() : {},
        i = Object.assign({}, re, a);
      if (
        (Object.assign(lt, i, e),
        te.forEach(function (e) {
          lt[e] !== i[e] &&
            (lt[e] = (function (e, t) {
              if (!t) return e;
              if (Array.isArray(e)) return [].concat(e, t);
              if ('object' == typeof e) return Object.assign({}, e, t);
              return (e = (function (e) {
                return e
                  .split(',')
                  .filter(function (e) {
                    return e;
                  })
                  .join(',');
              })(e)) &&
                e.length > 0 &&
                e !== t
                ? (e += ',' + t)
                : (e = t);
            })(lt[e], i[e]));
        }),
        (n = 'https://cdn.weglot.com/weglot.min.css?v=4'),
        ((r = document.createElement('link')).rel = 'stylesheet'),
        (r.type = 'text/css'),
        (r.href = n),
        document.head.appendChild(r),
        lt.button_style && lt.button_style.custom_css && pe(lt.button_style.custom_css, 'weglot-custom-style'),
        lt.switchers && 0 !== lt.switchers.length
          ? (lt.switchers = lt.switchers.map(function (e) {
              var t = e.button_style,
                n = ct(e, ['button_style']);
              return Object.assign(
                {},
                {
                  style: n.style || t
                },
                n
              );
            }))
          : (lt.switchers = [
              {
                style: lt.button_style,
                location: {},
                default: !0
              }
            ]),
        lt.cache && lt.visual_editor && (lt.cache = !1),
        lt.api_key.length < 36 && (lt.translation_engine = 1),
        lt.excluded_blocks_remove &&
          (lt.excluded_blocks = lt.excluded_blocks.filter(function (e) {
            return !lt.excluded_blocks_remove.includes(e.value);
          })),
        lt.dangerously_force_dynamic &&
          (lt.dynamics = lt.dynamics.concat(
            lt.dangerously_force_dynamic.split(',').map(function (e) {
              return {
                value: e.trim()
              };
            })
          )),
        (lt.excluded_blocks = lt.excluded_blocks.filter(function (e) {
          return me(e.value);
        })),
        (lt.dynamics = lt.dynamics.filter(function (e) {
          return me(e.value);
        })),
        lt.dynamics_remove &&
          (lt.dynamics = lt.dynamics.filter(function (e) {
            return !lt.dynamics_remove.includes(e.value);
          })),
        (lt.is_tld = !1),
        a.forceDisableConnect && (lt.is_connect = !1),
        lt.is_connect)
      ) {
        var s = lt.host.split('www.').pop();
        lt.is_tld = lt.languages.some(function (e) {
          if (e.connect_host_destination && e.connect_host_destination.host)
            return -1 === e.connect_host_destination.host.indexOf(s);
        });
      }
      return lt;
    }
    var gt = lt;
    var _t = {
        'Node.prototype.contains': document.contains,
        'Element.prototype.cloneNode': 'document' in self && 'cloneNode' in document.documentElement,
        'location.origin': 'location' in self && 'origin' in self.location,
        MutationObserver: 'MutationObserver' in self,
        Promise: 'Promise' in self && 'resolve' in Promise,
        'Element.prototype.matches': 'document' in self && 'matches' in document.documentElement,
        'Element.prototype.closest': 'document' in self && 'closest' in document.documentElement,
        'Element.prototype.classList':
          'document' in self &&
          'classList' in document.documentElement &&
          (function () {
            var e = document.createElement('div');
            if (
              !(
                e.classList &&
                e.classList.add &&
                e.classList.remove &&
                e.classList.contains &&
                e.classList.toggle
              )
            )
              return !1;
            var t = !0;
            e.classList.add('add1', 'add2'),
              (t = t && e.className.indexOf('add1') >= 0 && e.className.indexOf('add2') >= 0),
              (e.className = 'remove1 remove2 remove3'),
              e.classList.remove('remove1', 'remove3'),
              (t =
                t &&
                -1 === e.className.indexOf('remove1') &&
                e.className.indexOf('remove2') >= 0 &&
                -1 === e.className.indexOf('remove3'));
            try {
              e.remove();
            } catch (t) {
              e = null;
            }
            return t;
          })(),
        'String.prototype.includes': 'includes' in String.prototype,
        fetch: 'fetch' in self,
        'Array.prototype.find': 'find' in Array.prototype,
        'Array.prototype.findIndex': 'findIndex' in Array.prototype,
        'Object.assign': 'assign' in Object,
        'Array.prototype.includes': 'includes' in Array.prototype,
        URL: (function (e) {
          try {
            var t = new e.URL('http://weglot.com');
            if ('href' in t && 'searchParams' in t) {
              var n = new URL('http://weglot.com');
              if (
                ((n.search = 'a=1&b=2'),
                'http://weglot.com/?a=1&b=2' === n.href && ((n.search = ''), 'http://weglot.com/' === n.href))
              ) {
                var r = new e.URLSearchParams('a=1'),
                  o = new e.URLSearchParams(r);
                if ('a=1' === String(o)) return !0;
              }
            }
            return !1;
          } catch (e) {
            return !1;
          }
        })(self)
      },
      pt = !1;
  
    function ht() {
      (pt = !0), Le('polyfillReady');
    }
  
    function mt() {
      return pt;
    }
  
    function vt(e) {
      if (e && e.toLowerCase) {
        var t = e.toLowerCase(),
          n = gt.languages.find(function (e) {
            var n = e.language_to,
              r = e.custom_code;
            return n === t || (r ? r.toLowerCase() === t : void 0);
          });
        return n ? n.language_to : e;
      }
    }
    !(function (e) {
      window.Prototype && (delete Object.prototype.toJSON, delete Array.prototype.toJSON);
      var t = Object.keys(_t).filter(function (e) {
        return !_t[e];
      });
      if (t.length) {
        !(function (e, t, n) {
          var r = !1;
  
          function o() {
            r ||
              ((r = !0),
              setTimeout(function () {
                return t(n);
              }, 20));
          }
          var a = document.getElementsByTagName('head')[0] || document.documentElement,
            i = document.createElement('script');
          (i.type = 'text/javascript'),
            (i.src = e),
            i.addEventListener
              ? (i.addEventListener('load', o, !1), i.addEventListener('error', o, !1))
              : i.readyState && (i.onreadystatechange = o),
            a.insertBefore(i, a.firstChild);
        })(
          'https://cdn.polyfill.io/v2/polyfill.min.js?callback=Weglot.polyReady&features=' + t.join(','),
          function () {}
        );
      } else e();
    })(ht);
    var wt,
      yt = {};
  
    function bt(e) {
      return {
        START_WITH: function (t) {
          return 0 === e.indexOf(t);
        },
        END_WITH: function (t) {
          return -1 !== e.indexOf(t, e.length - t.length);
        },
        CONTAIN: function (t) {
          return -1 !== e.indexOf(t);
        },
        IS_EXACTLY: function (t) {
          return e === t;
        },
        MATCH_REGEX: function (t) {
          try {
            return new RegExp(t, 'i').test(e);
          } catch (e) {
            return (
              W.warn(e, {
                consoleOverride: t + ' is an invalid regex',
                sendToDatadog: !1
              }),
              !1
            );
          }
        }
      };
    }
  
    function kt(e) {
      var t = gt.excluded_paths,
        n = je.pathname;
      if (((n = n.toLowerCase()), 'shopify.weglot.com' === window.location.host || !t || !t.length)) return !1;
      if (
        'string' == typeof t &&
        t.split(',').some(function (e) {
          return new RegExp(e, 'i').test(n);
        })
      )
        return {
          allExcluded: !0,
          language_button_displayed: !0
        };
      var r = e || vt(Ue());
      return (
        (void 0 !== yt[r] && yt.currentLang === r) ||
          ((yt.currentLang = r),
          gt.injectedData && gt.injectedData.originalPath && (n = gt.injectedData.originalPath.toLowerCase()),
          t.some(function (e) {
            var t = e.type,
              o = e.value,
              a = e.excluded_languages,
              i = e.language_button_displayed,
              s = e.regex;
            o = o.toLowerCase();
            var c = {
              language_button_displayed: i,
              allExcluded: !(!a || !(0 === a.length || a.length >= gt.languages.length))
            };
            if (a && a.length && !a.includes(r)) return !1;
            var l = bt(n);
            if (s ? l.MATCH_REGEX(s) : l[t](o)) return (yt[r] = c), !0;
            var u = n;
            try {
              u = decodeURIComponent(n);
            } catch (e) {
              return;
            }
            if (u !== n) {
              var f = bt(u);
              return (s ? f.MATCH_REGEX(s) : f[t](o)) ? ((yt[r] = c), !0) : void 0;
            }
          })),
        yt[r]
      );
    }
  
    function Et() {
      if (wt) return wt;
      if (!gt.api_key)
        return (
          W.warn('Weglot must be initialized to use it.', {
            sendToDatadog: !1
          }),
          []
        );
      var e = (gt.languages || [])
          .filter(function (e) {
            var t = kt(e.language_to),
              n = !t || t.language_button_displayed;
            return (
              (!1 !== e.enabled || gt.private_mode) &&
              n &&
              (gt.subdirectory ||
                !gt.is_connect ||
                (e.connect_host_destination && e.connect_host_destination.created_on_aws))
            );
          })
          .map(function (e) {
            return e.custom_code || e.language_to;
          }),
        t = [gt.language_from].concat(e);
      return (
        (wt = t.filter(function (e, n) {
          return t.indexOf(e) == n;
        })),
        e.length ||
          W.log('No public language available.', {
            sendToDatadog: !1
          }),
        wt
      );
    }
    Ne(
      'onCurrentLocationChanged',
      function () {
        yt = {};
      },
      !0
    ),
      Ne(
        'onCurrentLocationChanged',
        function () {
          wt = null;
        },
        !0
      );
    var St = [
      {
        codes: ['no'],
        pattern: /^(nn|nb)(-[a-z]+)?$/i
      },
      {
        codes: ['zh'],
        pattern: /^zh(-hans(-\w{2})?)?(-(cn|sg))?$/i
      },
      {
        codes: ['tw', 'zh-TW'],
        pattern: /^zh-(hant)?-?(tw|hk|mo)?$/i
      },
      {
        codes: ['br'],
        pattern: /^pt-br$/i
      },
      {
        codes: ['fl'],
        pattern: /^fil$/i
      }
    ];
  
    function Ct(e) {
      void 0 === e && (e = Et());
      for (var t = {}, n = {}, r = 0, o = e; r < o.length; r += 1) {
        var a = o[r],
          i = a.toLowerCase(),
          s = i.substring(0, 2);
        t[s] || (t[s] = []), t[s].push(i), (n[i] = a);
      }
      for (var c = 0, l = navigator.languages || [navigator.language]; c < l.length; c += 1) {
        var u = l[c],
          f = u.toLowerCase(),
          d = f.substring(0, 2);
        if (n[f]) return n[f];
        for (var g = 0, _ = St; g < _.length; g += 1) {
          var p = _[g],
            h = p.codes,
            m = p.pattern,
            v = h.find(function (t) {
              return e.includes(t);
            });
          if (v && m.test(u)) return v;
        }
        if (t[d]) {
          if ('zh' === d) continue;
          var w = t[d].indexOf(d);
          return w >= 0 ? n[t[d][w]] : n[t[d].shift()];
        }
      }
    }
    var Ot = function () {
      var e = window.location.search.indexOf('no_redirect=true') > -1;
      if (
        !(
          !gt.auto_switch ||
          gt.subdirectory ||
          e ||
          ze({
            type: 'cookie'
          }).getItem('WG_CHOOSE_ORIGINAL') ||
          he() ||
          gt.visual_editor
        )
      ) {
        var t = Ct();
        return t && !kt(t)
          ? t
          : gt.auto_switch_fallback && !kt(gt.auto_switch_fallback)
          ? gt.auto_switch_fallback
          : void 0;
      }
      e && xt(Ue());
    };
  
    function xt(e) {
      if (e === gt.language_from) {
        var t = new Date();
        t.setTime(t.getTime() + 2592e6),
          ze({
            type: 'cookie'
          }).setItem('WG_CHOOSE_ORIGINAL', '1', {
            expires: t.toUTCString()
          });
      } else
        ze({
          type: 'cookie'
        }).removeItem('WG_CHOOSE_ORIGINAL');
    }
    var Nt = function (e) {
      var t = gt.api_key;
      return fetch('https://cdn-api.weglot.com/pageviews?api_key=' + t, {
        method: 'POST',
        body: JSON.stringify({
          url: e || je.url,
          language: Ue(),
          browser_language: navigator.language
        })
      });
    };
  
    function Lt() {
      var e = ze().getItem(q);
      if (e && Et().includes(e)) return e;
    }
    var Tt = function (e) {
      return e && ze().setItem(q, e);
    };
  
    function jt() {
      de(fe(H));
    }
    var At = [
        {
          condition: [
            {
              type: 'TECHNOLOGY_ID',
              payload: 2
            }
          ],
          value: [
            {
              original: '^/checkouts/(?:[\\w]{32})(/.*)?$',
              formatted: '/checkouts$1'
            },
            {
              original: '^/account/(orders|activate)/(?:[\\w]{32})$',
              formatted: '/account/$1/'
            },
            {
              original: '^/orders/(?:[\\w]{32})$',
              formatted: '/orders/'
            },
            {
              original: '^/wallets/checkouts/(?:.*)$',
              formatted: '/wallets/checkouts/'
            },
            {
              original: '^/(.+)\\.(json|xml)$',
              formatted: '/$1'
            }
          ]
        }
      ],
      Pt = !1,
      Dt = {},
      Rt = {},
      It = ze({
        type: 'local'
      });
    if (It.getItem(F))
      try {
        (Dt = JSON.parse(It.getItem(F))),
          Object.keys(Dt).forEach(function (e) {
            Object.keys(Dt[e]).forEach(function (t) {
              if (2 === t.length) {
                Rt[t] || (Rt[t] = {});
                var n = Dt[e][t];
                Rt[t][n] = e;
              }
            });
          }),
          (Pt = !0);
      } catch (Hn) {
        Pt = !0;
      }
  
    function Wt(e) {
      return Dt[e];
    }
  
    function Ut(e, t, n, r) {
      var o,
        a = Wt(e);
      a
        ? ((a[r] = n), (a.createdTime = new Date().getTime()), (a.t = t))
        : (Dt[e] = (((o = {})[r] = n), (o.createdTime = new Date().getTime()), (o.t = t), o)),
        Rt[r] || (Rt[r] = {}),
        (Rt[r][n] = e),
        gt.cache && we(Mt)();
    }
    var Mt = function () {
      return Dt && It.setItem(F, JSON.stringify(Dt));
    };
    var qt = [];
  
    function Ht(e, t) {
      void 0 === e && (e = document.documentElement);
      var n = Ue();
      return y(e)
        .filter(function (e) {
          return (t || zt)(e);
        })
        .map(
          (function (e) {
            return function (t) {
              var n = t.element,
                r = t.words,
                o = t.type,
                a = t.properties,
                i = t.attrSetter;
              n.weglot ||
                (n.weglot = {
                  content: []
                });
              var s,
                c,
                l = n.weglot,
                u = {},
                f = ((s = r), !!Rt[(c = e)] && Rt[c][s]);
              if ((f && ((u[e] = r), (r = f)), a)) {
                var d = l.content.find(function (e) {
                  return e.html;
                });
                d
                  ? Object.assign(d, {
                      original: r,
                      properties: a,
                      translations: u
                    })
                  : l.content.push({
                      html: !0,
                      original: r,
                      type: o,
                      properties: a,
                      translations: u
                    });
              }
              if (i) {
                var g = l.content.find(function (e) {
                    return e.attrSetter === i;
                  }),
                  _ = {
                    attrSetter: i,
                    original: r,
                    type: o,
                    translations: u
                  };
                g ? Object.assign(g, _) : l.content.push(_);
              }
              return n;
            };
          })(n)
        );
    }
  
    function zt(e) {
      var t = e.element,
        n = e.words;
      return (
        !t.weglot ||
        !t.weglot.content ||
        !t.weglot.content.some(function (e) {
          var t,
            r = e.original,
            o = e.translations;
          return (
            r === n ||
            ((t = o),
            Object.keys(t).map(function (e) {
              return t[e];
            })).includes(ge(n))
          );
        })
      );
    }
  
    function Bt(e) {
      for (var t = [], n = 0, r = e; n < r.length; n += 1) {
        var o = r[n];
        -1 === qt.indexOf(o) && t.push(o);
      }
      return [].push.apply(qt, t), t;
    }
  
    function Ft(e, t) {
      void 0 === e && (e = qt), void 0 === t && (t = {});
      for (var n = [], r = {}, o = 0, a = e; o < a.length; o += 1)
        for (var i = 0, s = a[o].weglot.content; i < s.length; i += 1) {
          var c = s[i],
            l = c.original,
            u = c.type;
          r[l] ||
            ((r[l] = !0),
            n.push({
              t: u,
              w: l,
              l: t.label
            }));
        }
      return n;
    }
  
    function Vt(e, t, n) {
      if ((void 0 === t && (t = Ue()), void 0 === n && (n = qt), e && e.to_words && e.to_words.length))
        for (var r = e.from_words, o = e.to_words, a = 0, i = n; a < i.length; a += 1)
          for (var s = 0, c = i[a].weglot.content || {}; s < c.length; s += 1) {
            var l = c[s],
              u = l.original,
              f = l.translations,
              d = r.indexOf(ge(u));
            -1 !== d &&
              (f[t] || (f[t] = (g = o[d]) && g.replace && g.replace(new RegExp('wg-(\\d+)=""(\\s*)\\/(\\s*)>', 'g'), 'wg-$1="">')));
          }
      var g;
      try {
        x(n, t);
      } catch (e) {
        W.error(e);
      }
    }
  
    function $t(e, t) {
      var n;
      void 0 === t &&
        (t = {
          cdn: !1,
          search: !1
        });
      var r = e.l_to,
        o = e.words;
      e.l_to = vt(r);
      var a,
        i = o;
      if (!gt.visual_editor) {
        if (
          ((n = (function (e, t) {
            var n = [],
              r = [],
              o = [];
            return (
              e.forEach(function (e) {
                var a = Wt(e.w);
                a && a[t] ? (n.push(a[t]), r.push(ge(e.w))) : o.push(e);
              }),
              {
                cachedWords: {
                  to_words: n,
                  from_words: r
                },
                newWords: o
              }
            );
          })(o, r)),
          (i = n.newWords),
          (a = n.cachedWords).to_words.length && !t.search)
        ) {
          if (!i.length) return Promise.resolve(a);
          Vt(a, r, t.nodes);
        }
        Pt &&
          [].push.apply(
            i,
            (function () {
              Pt = !1;
              var e = new Date().getTime();
              return Object.keys(Dt)
                .filter(function (t) {
                  return Dt[t].createdTime + 216e5 < e;
                })
                .map(function (e) {
                  return {
                    t: Dt[e].t,
                    w: e
                  };
                });
            })()
          );
      }
      return i.length
        ? ((e.words = i),
          (e.request_url = (function () {
            var e = (function () {
              if (gt.visual_editor) return new ie(je.url);
              var e = gt.technology_name,
                t = gt.injectedData;
              if (e === K) return new ie(window.location.href);
              if (t && t.originalCanonicalUrl)
                try {
                  return new ie(t.originalCanonicalUrl);
                } catch (e) {}
              var n = document.querySelector("link[rel='canonical'][href]");
              if (n)
                try {
                  return new ie(n.href);
                } catch (e) {}
              return new ie(window.location.href);
            })();
            e.pathname =
              ((t = e.pathname),
              t
                .split('/')
                .filter(function (e) {
                  return !e || isNaN(Number(e));
                })
                .join('/'));
            var t;
            for (
              var n = 0,
                r = At.filter(function (e) {
                  return e.condition.some(function (e) {
                    var t = e.type,
                      n = e.payload;
                    return 'TECHNOLOGY_ID' === t && n === gt.technology_id;
                  });
                });
              n < r.length;
              n += 1
            ) {
              var o = r[n].value;
              try {
                for (var a = 0, i = o; a < i.length; a += 1) {
                  var s = i[a],
                    c = s.original,
                    l = s.formatted,
                    u = e.pathname.replace(new RegExp(c), l);
                  if (u !== e.pathname) return (e.pathname = u), e.toString();
                }
              } catch (e) {
                W.warn(e, {
                  consoleOverride: 'Invalid URL regex, ' + e.stack
                });
              }
            }
            return e.toString();
          })()),
          (function (e) {
            var t = (gt.versions && gt.versions.translation) || 1,
              n = ['api_key=' + gt.api_key, 'v=' + t],
              r =
                'https://' +
                (gt.bypass_cdn_api ? 'api.weglot.com' : 'cdn-api-weglot.com') +
                '/translate?' +
                n.join('&');
            return fetch(r, {
              method: 'POST',
              body: Gt(JSON.stringify(e))
            })
              .then(Jt)
              .then(function (e) {
                return e.json();
              })
              .then(function (e) {
                if (!e || !e.to_words) throw (W.warn(e), Error('An error occurred, please try again later'));
                return e;
              });
          })(e).then(function (e) {
            return (
              i.forEach(function (t, n) {
                var o = e.to_words[n];
                Ut(t.w, t.t, o, r);
              }),
              e
            );
          }))
        : t.search && a
        ? Promise.resolve(a)
        : Promise.resolve({
            to_words: [],
            from_words: []
          });
    }
  
    function Gt(e) {
      return e.replace(/[\u007F-\uFFFF]/g, function (e) {
        return '\\\\u' + ('0000' + e.charCodeAt(0).toString(16)).substr(-4);
      });
    }
    
    function Jt(e) {
      if (400 === e.status) throw Error('You reached Weglot limitation. Please upgrade your plan.');
      if (401 === e.status) throw Error('Your Weglot API key seems wrong.');
      if (e.status >= 402) throw Error(e.statusText);
      return e;
    }
  
    function Yt(e, t, n) {
      void 0 === n && (n = {});
      n = Object.assign(
        {},
        {
          title: !0,
          cdn: !1,
          search: !1
        },
        n
      );
      var r = {
        l_from: gt.language_from,
        l_to: t,
        words: e
      };
      return n.title && (r.title = document.title), $t(r, n);
    }
  
    function Kt(e, t) {
      if ('string' != typeof e || 'function' != typeof t) return !1;
      var n = Ue();
      return n === gt.language_from
        ? (t(e), !1)
        : ($t(
            {
              l_from: n,
              l_to: gt.language_from,
              words: [
                {
                  t: 2,
                  w: e
                }
              ]
            },
            {
              cdn: !0,
              search: !0
            }
          )
            .then(function (e) {
              return e.to_words[0].toLowerCase().trim();
            })
            .then(t),
          !0);
    }
  
    function Xt(e) {
      return "a[href='#Weglot-" + e + "'],a[href*='change-language.weglot.com/" + e + "']";
    }
    var Zt,
      Qt,
      en,
      tn,
      nn,
      rn,
      on,
      an = {},
      sn = [],
      cn = /acit|ex(?:s|g|n|p|$)|rph|grid|ows|mnc|ntw|ine[ch]|zoo|^ord|itera/i;
  
    function ln(e, t) {
      for (var n in t) e[n] = t[n];
      return e;
    }
  
    function un(e) {
      var t = e.parentNode;
      t && t.removeChild(e);
    }
  
    function fn(e, t, n) {
      var r,
        o,
        a,
        i = {};
      for (a in t) 'key' == a ? (r = t[a]) : 'ref' == a ? (o = t[a]) : (i[a] = t[a]);
      if (
        (arguments.length > 2 && (i.children = arguments.length > 3 ? Zt.call(arguments, 2) : n),
        'function' == typeof e && null != e.defaultProps)
      )
        for (a in e.defaultProps) void 0 === i[a] && (i[a] = e.defaultProps[a]);
      return dn(e, i, r, o, null);
    }
  
    function dn(e, t, n, r, o) {
      var a = {
        type: e,
        props: t,
        key: n,
        ref: r,
        __k: null,
        __: null,
        __b: 0,
        __e: null,
        __d: void 0,
        __c: null,
        __h: null,
        constructor: void 0,
        __v: null == o ? ++en : o
      };
      return null == o && null != Qt.vnode && Qt.vnode(a), a;
    }
  
    function gn(e) {
      return e.children;
    }
  
    function _n(e, t) {
      (this.props = e), (this.context = t);
    }
  
    function pn(e, t) {
      if (null == t) return e.__ ? pn(e.__, e.__.__k.indexOf(e) + 1) : null;
      for (var n; t < e.__k.length; t++) if (null != (n = e.__k[t]) && null != n.__e) return n.__e;
      return 'function' == typeof e.type ? pn(e) : null;
    }
  
    function hn(e) {
      var t, n;
      if (null != (e = e.__) && null != e.__c) {
        for (e.__e = e.__c.base = null, t = 0; t < e.__k.length; t++)
          if (null != (n = e.__k[t]) && null != n.__e) {
            e.__e = e.__c.base = n.__e;
            break;
          }
        return hn(e);
      }
    }
  
    function mn(e) {
      ((!e.__d && (e.__d = !0) && tn.push(e) && !vn.__r++) || nn !== Qt.debounceRendering) &&
        ((nn = Qt.debounceRendering) || rn)(vn);
    }
  
    function vn() {
      var e, t, n, r, o, a, i, s;
      for (tn.sort(on); (e = tn.shift()); )
        e.__d &&
          ((t = tn.length),
          (r = void 0),
          (o = void 0),
          (i = (a = (n = e).__v).__e),
          (s = n.__P) &&
            ((r = []),
            ((o = ln({}, a)).__v = a.__v + 1),
            Nn(
              s,
              a,
              o,
              n.__n,
              void 0 !== s.ownerSVGElement,
              null != a.__h ? [i] : null,
              r,
              null == i ? pn(a) : i,
              a.__h
            ),
            Ln(r, a),
            a.__e != i && hn(a)),
          tn.length > t && tn.sort(on));
      vn.__r = 0;
    }
  
    function wn(e, t, n, r, o, a, i, s, c, l) {
      var u,
        f,
        d,
        g,
        _,
        p,
        h,
        m = (r && r.__k) || sn,
        v = m.length;
      for (n.__k = [], u = 0; u < t.length; u++)
        if (
          null !=
          (g = n.__k[u] =
            null == (g = t[u]) || 'boolean' == typeof g || 'function' == typeof g
              ? null
              : 'string' == typeof g || 'number' == typeof g || 'bigint' == typeof g
              ? dn(null, g, null, null, g)
              : Array.isArray(g)
              ? dn(
                  gn,
                  {
                    children: g
                  },
                  null,
                  null,
                  null
                )
              : g.__b > 0
              ? dn(g.type, g.props, g.key, g.ref ? g.ref : null, g.__v)
              : g)
        ) {
          if (
            ((g.__ = n), (g.__b = n.__b + 1), null === (d = m[u]) || (d && g.key == d.key && g.type === d.type))
          )
            m[u] = void 0;
          else
            for (f = 0; f < v; f++) {
              if ((d = m[f]) && g.key == d.key && g.type === d.type) {
                m[f] = void 0;
                break;
              }
              d = null;
            }
          Nn(e, g, (d = d || an), o, a, i, s, c, l),
            (_ = g.__e),
            (f = g.ref) &&
              d.ref != f &&
              (h || (h = []), d.ref && h.push(d.ref, null, g), h.push(f, g.__c || _, g)),
            null != _
              ? (null == p && (p = _),
                'function' == typeof g.type && g.__k === d.__k
                  ? (g.__d = c = yn(g, c, e))
                  : (c = kn(e, g, d, m, _, c)),
                'function' == typeof n.type && (n.__d = c))
              : c && d.__e == c && c.parentNode != e && (c = pn(d));
        }
      for (n.__e = p, u = v; u--; )
        null != m[u] &&
          ('function' == typeof n.type && null != m[u].__e && m[u].__e == n.__d && (n.__d = En(r).nextSibling),
          An(m[u], m[u]));
      if (h) for (u = 0; u < h.length; u++) jn(h[u], h[++u], h[++u]);
    }
  
    function yn(e, t, n) {
      for (var r, o = e.__k, a = 0; o && a < o.length; a++)
        (r = o[a]) && ((r.__ = e), (t = 'function' == typeof r.type ? yn(r, t, n) : kn(n, r, r, o, r.__e, t)));
      return t;
    }
  
    function bn(e, t) {
      return (
        (t = t || []),
        null == e ||
          'boolean' == typeof e ||
          (Array.isArray(e)
            ? e.some(function (e) {
                bn(e, t);
              })
            : t.push(e)),
        t
      );
    }
  
    function kn(e, t, n, r, o, a) {
      var i, s, c;
      if (void 0 !== t.__d) (i = t.__d), (t.__d = void 0);
      else if (null == n || o != a || null == o.parentNode)
        e: if (null == a || a.parentNode !== e) e.appendChild(o), (i = null);
        else {
          for (s = a, c = 0; (s = s.nextSibling) && c < r.length; c += 1) if (s == o) break e;
          e.insertBefore(o, a), (i = a);
        }
      return void 0 !== i ? i : o.nextSibling;
    }
  
    function En(e) {
      var t, n, r;
      if (null == e.type || 'string' == typeof e.type) return e.__e;
      if (e.__k) for (t = e.__k.length - 1; t >= 0; t--) if ((n = e.__k[t]) && (r = En(n))) return r;
      return null;
    }
  
    function Sn(e, t, n) {
      '-' === t[0]
        ? e.setProperty(t, null == n ? '' : n)
        : (e[t] = null == n ? '' : 'number' != typeof n || cn.test(t) ? n : n + 'px');
    }
  
    function Cn(e, t, n, r, o) {
      var a;
      e: if ('style' === t)
        if ('string' == typeof n) e.style.cssText = n;
        else {
          if (('string' == typeof r && (e.style.cssText = r = ''), r))
            for (t in r) (n && t in n) || Sn(e.style, t, '');
          if (n) for (t in n) (r && n[t] === r[t]) || Sn(e.style, t, n[t]);
        }
      else if ('o' === t[0] && 'n' === t[1])
        (a = t !== (t = t.replace(/Capture$/, ''))),
          (t = t.toLowerCase() in e ? t.toLowerCase().slice(2) : t.slice(2)),
          e.l || (e.l = {}),
          (e.l[t + a] = n),
          n ? r || e.addEventListener(t, a ? xn : On, a) : e.removeEventListener(t, a ? xn : On, a);
      else if ('dangerouslySetInnerHTML' !== t) {
        if (o) t = t.replace(/xlink(H|:h)/, 'h').replace(/sName$/, 's');
        else if (
          'width' !== t &&
          'height' !== t &&
          'href' !== t &&
          'list' !== t &&
          'form' !== t &&
          'tabIndex' !== t &&
          'download' !== t &&
          t in e
        )
          try {
            e[t] = null == n ? '' : n;
            break e;
          } catch (e) {}
        'function' == typeof n ||
          (null == n || (!1 === n && '-' !== t[4]) ? e.removeAttribute(t) : e.setAttribute(t, n));
      }
    }
  
    function On(e) {
      return this.l[e.type + !1](Qt.event ? Qt.event(e) : e);
    }
  
    function xn(e) {
      return this.l[e.type + !0](Qt.event ? Qt.event(e) : e);
    }
  
    function Nn(e, t, n, r, o, a, i, s, c) {
      var l,
        u,
        f,
        d,
        g,
        _,
        p,
        h,
        m,
        v,
        w,
        y,
        b,
        k,
        E,
        S = t.type;
      if (void 0 !== t.constructor) return null;
      null != n.__h && ((c = n.__h), (s = t.__e = n.__e), (t.__h = null), (a = [s])), (l = Qt.__b) && l(t);
      try {
        e: if ('function' == typeof S) {
          if (
            ((h = t.props),
            (m = (l = S.contextType) && r[l.__c]),
            (v = l ? (m ? m.props.value : l.__) : r),
            n.__c
              ? (p = (u = t.__c = n.__c).__ = u.__E)
              : ('prototype' in S && S.prototype.render
                  ? (t.__c = u = new S(h, v))
                  : ((t.__c = u = new _n(h, v)), (u.constructor = S), (u.render = Pn)),
                m && m.sub(u),
                (u.props = h),
                u.state || (u.state = {}),
                (u.context = v),
                (u.__n = r),
                (f = u.__d = !0),
                (u.__h = []),
                (u._sb = [])),
            null == u.__s && (u.__s = u.state),
            null != S.getDerivedStateFromProps &&
              (u.__s == u.state && (u.__s = ln({}, u.__s)), ln(u.__s, S.getDerivedStateFromProps(h, u.__s))),
            (d = u.props),
            (g = u.state),
            (u.__v = t),
            f)
          )
            null == S.getDerivedStateFromProps && null != u.componentWillMount && u.componentWillMount(),
              null != u.componentDidMount && u.__h.push(u.componentDidMount);
          else {
            if (
              (null == S.getDerivedStateFromProps &&
                h !== d &&
                null != u.componentWillReceiveProps &&
                u.componentWillReceiveProps(h, v),
              (!u.__e && null != u.shouldComponentUpdate && !1 === u.shouldComponentUpdate(h, u.__s, v)) ||
                t.__v === n.__v)
            ) {
              for (
                t.__v !== n.__v && ((u.props = h), (u.state = u.__s), (u.__d = !1)),
                  u.__e = !1,
                  t.__e = n.__e,
                  t.__k = n.__k,
                  t.__k.forEach(function (e) {
                    e && (e.__ = t);
                  }),
                  w = 0;
                w < u._sb.length;
                w++
              )
                u.__h.push(u._sb[w]);
              (u._sb = []), u.__h.length && i.push(u);
              break e;
            }
            null != u.componentWillUpdate && u.componentWillUpdate(h, u.__s, v),
              null != u.componentDidUpdate &&
                u.__h.push(function () {
                  u.componentDidUpdate(d, g, _);
                });
          }
          if (
            ((u.context = v),
            (u.props = h),
            (u.__P = e),
            (y = Qt.__r),
            (b = 0),
            'prototype' in S && S.prototype.render)
          ) {
            for (
              u.state = u.__s, u.__d = !1, y && y(t), l = u.render(u.props, u.state, u.context), k = 0;
              k < u._sb.length;
              k++
            )
              u.__h.push(u._sb[k]);
            u._sb = [];
          } else
            do {
              (u.__d = !1), y && y(t), (l = u.render(u.props, u.state, u.context)), (u.state = u.__s);
            } while (u.__d && ++b < 25);
          (u.state = u.__s),
            null != u.getChildContext && (r = ln(ln({}, r), u.getChildContext())),
            f || null == u.getSnapshotBeforeUpdate || (_ = u.getSnapshotBeforeUpdate(d, g)),
            (E = null != l && l.type === gn && null == l.key ? l.props.children : l),
            wn(e, Array.isArray(E) ? E : [E], t, n, r, o, a, i, s, c),
            (u.base = t.__e),
            (t.__h = null),
            u.__h.length && i.push(u),
            p && (u.__E = u.__ = null),
            (u.__e = !1);
        } else
          null == a && t.__v === n.__v
            ? ((t.__k = n.__k), (t.__e = n.__e))
            : (t.__e = Tn(n.__e, t, n, r, o, a, i, c));
        (l = Qt.diffed) && l(t);
      } catch (e) {
        (t.__v = null),
          (c || null != a) && ((t.__e = s), (t.__h = !!c), (a[a.indexOf(s)] = null)),
          Qt.__e(e, t, n);
      }
    }
  
    function Ln(e, t) {
      Qt.__c && Qt.__c(t, e),
        e.some(function (t) {
          try {
            (e = t.__h),
              (t.__h = []),
              e.some(function (e) {
                e.call(t);
              });
          } catch (e) {
            Qt.__e(e, t.__v);
          }
        });
    }
  
    function Tn(e, t, n, r, o, a, i, s) {
      var c,
        l,
        u,
        f = n.props,
        d = t.props,
        g = t.type,
        _ = 0;
      if (('svg' === g && (o = !0), null != a))
        for (; _ < a.length; _++)
          if ((c = a[_]) && 'setAttribute' in c == !!g && (g ? c.localName === g : 3 === c.nodeType)) {
            (e = c), (a[_] = null);
            break;
          }
      if (null == e) {
        if (null === g) return document.createTextNode(d);
        (e = o
          ? document.createElementNS('http://www.w3.org/2000/svg', g)
          : document.createElement(g, d.is && d)),
          (a = null),
          (s = !1);
      }
      if (null === g) f === d || (s && e.data === d) || (e.data = d);
      else {
        if (
          ((a = a && Zt.call(e.childNodes)),
          (l = (f = n.props || an).dangerouslySetInnerHTML),
          (u = d.dangerouslySetInnerHTML),
          !s)
        ) {
          if (null != a)
            for (f = {}, _ = 0; _ < e.attributes.length; _++) f[e.attributes[_].name] = e.attributes[_].value;
          (u || l) &&
            ((u && ((l && u.__html == l.__html) || u.__html === e.innerHTML)) ||
              (e.innerHTML = (u && u.__html) || ''));
        }
        if (
          ((function (e, t, n, r, o) {
            var a;
            for (a in n) 'children' === a || 'key' === a || a in t || Cn(e, a, null, n[a], r);
            for (a in t)
              (o && 'function' != typeof t[a]) ||
                'children' === a ||
                'key' === a ||
                'value' === a ||
                'checked' === a ||
                n[a] === t[a] ||
                Cn(e, a, t[a], n[a], r);
          })(e, d, f, o, s),
          u)
        )
          t.__k = [];
        else if (
          ((_ = t.props.children),
          wn(
            e,
            Array.isArray(_) ? _ : [_],
            t,
            n,
            r,
            o && 'foreignObject' !== g,
            a,
            i,
            a ? a[0] : n.__k && pn(n, 0),
            s
          ),
          null != a)
        )
          for (_ = a.length; _--; ) null != a[_] && un(a[_]);
        s ||
          ('value' in d &&
            void 0 !== (_ = d.value) &&
            (_ !== e.value || ('progress' === g && !_) || ('option' === g && _ !== f.value)) &&
            Cn(e, 'value', _, f.value, !1),
          'checked' in d &&
            void 0 !== (_ = d.checked) &&
            _ !== e.checked &&
            Cn(e, 'checked', _, f.checked, !1));
      }
      return e;
    }
  
    function jn(e, t, n) {
      try {
        'function' == typeof e ? e(t) : (e.current = t);
      } catch (e) {
        Qt.__e(e, n);
      }
    }
  
    function An(e, t, n) {
      var r, o;
      if (
        (Qt.unmount && Qt.unmount(e),
        (r = e.ref) && ((r.current && r.current !== e.__e) || jn(r, null, t)),
        null != (r = e.__c))
      ) {
        if (r.componentWillUnmount)
          try {
            r.componentWillUnmount();
          } catch (e) {
            Qt.__e(e, t);
          }
        (r.base = r.__P = null), (e.__c = void 0);
      }
      if ((r = e.__k)) for (o = 0; o < r.length; o++) r[o] && An(r[o], t, n || 'function' != typeof e.type);
      n || null == e.__e || un(e.__e), (e.__ = e.__e = e.__d = void 0);
    }
  
    function Pn(e, t, n) {
      return this.constructor(e, n);
    }
    (Zt = sn.slice),
      (Qt = {
        __e: function (e, t, n, r) {
          for (var o, a, i; (t = t.__); )
            if ((o = t.__c) && !o.__)
              try {
                if (
                  ((a = o.constructor) &&
                    null != a.getDerivedStateFromError &&
                    (o.setState(a.getDerivedStateFromError(e)), (i = o.__d)),
                  null != o.componentDidCatch && (o.componentDidCatch(e, r || {}), (i = o.__d)),
                  i)
                )
                  return (o.__E = o);
              } catch (t) {
                e = t;
              }
          throw e;
        }
      }),
      (en = 0),
      (_n.prototype.setState = function (e, t) {
        var n;
        (n = null != this.__s && this.__s !== this.state ? this.__s : (this.__s = ln({}, this.state))),
          'function' == typeof e && (e = e(ln({}, n), this.props)),
          e && ln(n, e),
          null != e && this.__v && (t && this._sb.push(t), mn(this));
      }),
      (_n.prototype.forceUpdate = function (e) {
        this.__v && ((this.__e = !0), e && this.__h.push(e), mn(this));
      }),
      (_n.prototype.render = gn),
      (tn = []),
      (rn = 'function' == typeof Promise ? Promise.prototype.then.bind(Promise.resolve()) : setTimeout),
      (on = function (e, t) {
        return e.__v.__b - t.__v.__b;
      }),
      (vn.__r = 0);
    var Dn,
      Rn,
      In,
      Wn,
      Un = 0,
      Mn = [],
      qn = [],
      Hn = Qt.__b,
      zn = Qt.__r,
      Bn = Qt.diffed,
      Fn = Qt.__c,
      Vn = Qt.unmount;
  
    function $n(e, t) {
      Qt.__h && Qt.__h(Rn, e, Un || t), (Un = 0);
      var n =
        Rn.__H ||
        (Rn.__H = {
          __: [],
          __h: []
        });
      return (
        e >= n.__.length &&
          n.__.push({
            __V: qn
          }),
        n.__[e]
      );
    }
  
    function Gn(e) {
      return (
        (Un = 1),
        (function (e, t, n) {
          var r = $n(Dn++, 2);
          if (
            ((r.t = e),
            !r.__c &&
              ((r.__ = [
                n ? n(t) : nr(void 0, t),
                function (e) {
                  var t = r.__N ? r.__N[0] : r.__[0],
                    n = r.t(t, e);
                  t !== n && ((r.__N = [n, r.__[1]]), r.__c.setState({}));
                }
              ]),
              (r.__c = Rn),
              !Rn.u))
          ) {
            var o = function (e, t, n) {
              if (!r.__c.__H) return !0;
              var o = r.__c.__H.__.filter(function (e) {
                return e.__c;
              });
              if (
                o.every(function (e) {
                  return !e.__N;
                })
              )
                return !a || a.call(this, e, t, n);
              var i = !1;
              return (
                o.forEach(function (e) {
                  if (e.__N) {
                    var t = e.__[0];
                    (e.__ = e.__N), (e.__N = void 0), t !== e.__[0] && (i = !0);
                  }
                }),
                !(!i && r.__c.props === e) && (!a || a.call(this, e, t, n))
              );
            };
            Rn.u = !0;
            var a = Rn.shouldComponentUpdate,
              i = Rn.componentWillUpdate;
            (Rn.componentWillUpdate = function (e, t, n) {
              if (this.__e) {
                var r = a;
                (a = void 0), o(e, t, n), (a = r);
              }
              i && i.call(this, e, t, n);
            }),
              (Rn.shouldComponentUpdate = o);
          }
          return r.__N || r.__;
        })(nr, e)
      );
    }
  
    function Jn(e, t) {
      var n = $n(Dn++, 3);
      !Qt.__s && tr(n.__H, t) && ((n.__ = e), (n.i = t), Rn.__H.__h.push(n));
    }
  
    function Yn(e) {
      return (
        (Un = 5),
        (function (e, t) {
          var n = $n(Dn++, 7);
          return tr(n.__H, t) ? ((n.__V = e()), (n.i = t), (n.__h = e), n.__V) : n.__;
        })(function () {
          return {
            current: e
          };
        }, [])
      );
    }
  
    function Kn() {
      for (var e; (e = Mn.shift()); )
        if (e.__P && e.__H)
          try {
            e.__H.__h.forEach(Qn), e.__H.__h.forEach(er), (e.__H.__h = []);
          } catch (t) {
            (e.__H.__h = []), Qt.__e(t, e.__v);
          }
    }
    (Qt.__b = function (e) {
      (Rn = null), Hn && Hn(e);
    }),
      (Qt.__r = function (e) {
        zn && zn(e), (Dn = 0);
        var t = (Rn = e.__c).__H;
        t &&
          (In === Rn
            ? ((t.__h = []),
              (Rn.__h = []),
              t.__.forEach(function (e) {
                e.__N && (e.__ = e.__N), (e.__V = qn), (e.__N = e.i = void 0);
              }))
            : (t.__h.forEach(Qn), t.__h.forEach(er), (t.__h = []))),
          (In = Rn);
      }),
      (Qt.diffed = function (e) {
        Bn && Bn(e);
        var t = e.__c;
        t &&
          t.__H &&
          (t.__H.__h.length &&
            ((1 !== Mn.push(t) && Wn === Qt.requestAnimationFrame) ||
              ((Wn = Qt.requestAnimationFrame) || Zn)(Kn)),
          t.__H.__.forEach(function (e) {
            e.i && (e.__H = e.i), e.__V !== qn && (e.__ = e.__V), (e.i = void 0), (e.__V = qn);
          })),
          (In = Rn = null);
      }),
      (Qt.__c = function (e, t) {
        t.some(function (e) {
          try {
            e.__h.forEach(Qn),
              (e.__h = e.__h.filter(function (e) {
                return !e.__ || er(e);
              }));
          } catch (n) {
            t.some(function (e) {
              e.__h && (e.__h = []);
            }),
              (t = []),
              Qt.__e(n, e.__v);
          }
        }),
          Fn && Fn(e, t);
      }),
      (Qt.unmount = function (e) {
        Vn && Vn(e);
        var t,
          n = e.__c;
        n &&
          n.__H &&
          (n.__H.__.forEach(function (e) {
            try {
              Qn(e);
            } catch (e) {
              t = e;
            }
          }),
          (n.__H = void 0),
          t && Qt.__e(t, n.__v));
      });
    var Xn = 'function' == typeof requestAnimationFrame;
  
    function Zn(e) {
      var t,
        n = function () {
          clearTimeout(r), Xn && cancelAnimationFrame(t), setTimeout(e);
        },
        r = setTimeout(n, 100);
      Xn && (t = requestAnimationFrame(n));
    }
  
    function Qn(e) {
      var t = Rn,
        n = e.__c;
      'function' == typeof n && ((e.__c = void 0), n()), (Rn = t);
    }
  
    function er(e) {
      var t = Rn;
      (e.__c = e.__()), (Rn = t);
    }
  
    function tr(e, t) {
      return (
        !e ||
        e.length !== t.length ||
        t.some(function (t, n) {
          return t !== e[n];
        })
      );
    }
  
    function nr(e, t) {
      return 'function' == typeof t ? t(e) : t;
    }
  
    function rr(e, t) {
      for (var n in e) if ('__source' !== n && !(n in t)) return !0;
      for (var r in t) if ('__source' !== r && e[r] !== t[r]) return !0;
      return !1;
    }
  
    function or(e) {
      this.props = e;
    }
    ((or.prototype = new _n()).isPureReactComponent = !0),
      (or.prototype.shouldComponentUpdate = function (e, t) {
        return rr(this.props, e) || rr(this.state, t);
      });
    var ar = Qt.__b;
    Qt.__b = function (e) {
      e.type && e.type.__f && e.ref && ((e.props.ref = e.ref), (e.ref = null)), ar && ar(e);
    };
    var ir = Qt.__e;
    Qt.__e = function (e, t, n, r) {
      if (e.then)
        for (var o, a = t; (a = a.__); )
          if ((o = a.__c) && o.__c) return null == t.__e && ((t.__e = n.__e), (t.__k = n.__k)), o.__c(e, t);
      ir(e, t, n, r);
    };
    var sr = Qt.unmount;
  
    function cr(e, t, n) {
      return (
        e &&
          (e.__c &&
            e.__c.__H &&
            (e.__c.__H.__.forEach(function (e) {
              'function' == typeof e.__c && e.__c();
            }),
            (e.__c.__H = null)),
          null !=
            (e = (function (e, t) {
              for (var n in t) e[n] = t[n];
              return e;
            })({}, e)).__c && (e.__c.__P === n && (e.__c.__P = t), (e.__c = null)),
          (e.__k =
            e.__k &&
            e.__k.map(function (e) {
              return cr(e, t, n);
            }))),
        e
      );
    }
  
    function lr(e, t, n) {
      return (
        e &&
          ((e.__v = null),
          (e.__k =
            e.__k &&
            e.__k.map(function (e) {
              return lr(e, t, n);
            })),
          e.__c &&
            e.__c.__P === t &&
            (e.__e && n.insertBefore(e.__e, e.__d), (e.__c.__e = !0), (e.__c.__P = n))),
        e
      );
    }
  
    function ur() {
      (this.__u = 0), (this.t = null), (this.__b = null);
    }
  
    function fr(e) {
      var t = e.__.__c;
      return t && t.__a && t.__a(e);
    }
  
    function dr() {
      (this.u = null), (this.o = null);
    }
    (Qt.unmount = function (e) {
      var t = e.__c;
      t && t.__R && t.__R(), t && !0 === e.__h && (e.type = null), sr && sr(e);
    }),
      ((ur.prototype = new _n()).__c = function (e, t) {
        var n = t.__c,
          r = this;
        null == r.t && (r.t = []), r.t.push(n);
        var o = fr(r.__v),
          a = !1,
          i = function () {
            a || ((a = !0), (n.__R = null), o ? o(s) : s());
          };
        n.__R = i;
        var s = function () {
            if (!--r.__u) {
              if (r.state.__a) {
                var e = r.state.__a;
                r.__v.__k[0] = lr(e, e.__c.__P, e.__c.__O);
              }
              var t;
              for (
                r.setState({
                  __a: (r.__b = null)
                });
                (t = r.t.pop());
  
              )
                t.forceUpdate();
            }
          },
          c = !0 === t.__h;
        r.__u++ ||
          c ||
          r.setState({
            __a: (r.__b = r.__v.__k[0])
          }),
          e.then(i, i);
      }),
      (ur.prototype.componentWillUnmount = function () {
        this.t = [];
      }),
      (ur.prototype.render = function (e, t) {
        if (this.__b) {
          if (this.__v.__k) {
            var n = document.createElement('div'),
              r = this.__v.__k[0].__c;
            this.__v.__k[0] = cr(this.__b, n, (r.__O = r.__P));
          }
          this.__b = null;
        }
        var o = t.__a && fn(gn, null, e.fallback);
        return o && (o.__h = null), [fn(gn, null, t.__a ? null : e.children), o];
      });
    var gr = function (e, t, n) {
      if (
        (++n[1] === n[0] && e.o.delete(t), e.props.revealOrder && ('t' !== e.props.revealOrder[0] || !e.o.size))
      )
        for (n = e.u; n; ) {
          for (; n.length > 3; ) n.pop()();
          if (n[1] < n[0]) break;
          e.u = n = n[2];
        }
    };
    ((dr.prototype = new _n()).__a = function (e) {
      var t = this,
        n = fr(t.__v),
        r = t.o.get(e);
      return (
        r[0]++,
        function (o) {
          var a = function () {
            t.props.revealOrder ? (r.push(o), gr(t, e, r)) : o();
          };
          n ? n(a) : a();
        }
      );
    }),
      (dr.prototype.render = function (e) {
        (this.u = null), (this.o = new Map());
        var t = bn(e.children);
        e.revealOrder && 'b' === e.revealOrder[0] && t.reverse();
        for (var n = t.length; n--; ) this.o.set(t[n], (this.u = [1, 0, this.u]));
        return e.children;
      }),
      (dr.prototype.componentDidUpdate = dr.prototype.componentDidMount =
        function () {
          var e = this;
          this.o.forEach(function (t, n) {
            gr(e, n, t);
          });
        });
    var _r = ('undefined' != typeof Symbol && Symbol.for && Symbol.for('react.element')) || 60103,
      pr =
        /^(?:accent|alignment|arabic|baseline|cap|clip(?!PathU)|color|dominant|fill|flood|font|glyph(?!R)|horiz|image|letter|lighting|marker(?!H|W|U)|overline|paint|pointer|shape|stop|strikethrough|stroke|text(?!L)|transform|underline|unicode|units|v|vector|vert|word|writing|x(?!C))[A-Z]/,
      hr = /^on(Ani|Tra|Tou|BeforeInp|Compo)/,
      mr = /[A-Z0-9]/g,
      vr = 'undefined' != typeof document,
      wr = function (e) {
        return (
          'undefined' != typeof Symbol && 'symbol' == typeof Symbol() ? /fil|che|rad/ : /fil|che|ra/
        ).test(e);
      };
    (_n.prototype.isReactComponent = {}),
      ['componentWillMount', 'componentWillReceiveProps', 'componentWillUpdate'].forEach(function (e) {
        Object.defineProperty(_n.prototype, e, {
          configurable: !0,
          get: function () {
            return this['UNSAFE_' + e];
          },
          set: function (t) {
            Object.defineProperty(this, e, {
              configurable: !0,
              writable: !0,
              value: t
            });
          }
        });
      });
    var yr = Qt.event;
  
    function br() {}
  
    function kr() {
      return this.cancelBubble;
    }
  
    function Er() {
      return this.defaultPrevented;
    }
    Qt.event = function (e) {
      return (
        yr && (e = yr(e)),
        (e.persist = br),
        (e.isPropagationStopped = kr),
        (e.isDefaultPrevented = Er),
        (e.nativeEvent = e)
      );
    };
    var Sr = {
        enumerable: !1,
        configurable: !0,
        get: function () {
          return this.class;
        }
      },
      Cr = Qt.vnode;
    Qt.vnode = function (e) {
      'string' == typeof e.type &&
        (function (e) {
          var t = e.props,
            n = e.type,
            r = {};
          for (var o in t) {
            var a = t[o];
            if (
              !(
                ('value' === o && 'defaultValue' in t && null == a) ||
                (vr && 'children' === o && 'noscript' === n) ||
                'class' === o ||
                'className' === o
              )
            ) {
              var i = o.toLowerCase();
              'defaultValue' === o && 'value' in t && null == t.value
                ? (o = 'value')
                : 'download' === o && !0 === a
                ? (a = '')
                : 'ondoubleclick' === i
                ? (o = 'ondblclick')
                : 'onchange' !== i || ('input' !== n && 'textarea' !== n) || wr(t.type)
                ? 'onfocus' === i
                  ? (o = 'onfocusin')
                  : 'onblur' === i
                  ? (o = 'onfocusout')
                  : hr.test(o)
                  ? (o = i)
                  : -1 === n.indexOf('-') && pr.test(o)
                  ? (o = o.replace(mr, '-$&').toLowerCase())
                  : null === a && (a = void 0)
                : (i = o = 'oninput'),
                'oninput' === i && r[(o = i)] && (o = 'oninputCapture'),
                (r[o] = a);
            }
          }
          'select' == n &&
            r.multiple &&
            Array.isArray(r.value) &&
            (r.value = bn(t.children).forEach(function (e) {
              e.props.selected = -1 != r.value.indexOf(e.props.value);
            })),
            'select' == n &&
              null != r.defaultValue &&
              (r.value = bn(t.children).forEach(function (e) {
                e.props.selected = r.multiple
                  ? -1 != r.defaultValue.indexOf(e.props.value)
                  : r.defaultValue == e.props.value;
              })),
            t.class && !t.className
              ? ((r.class = t.class), Object.defineProperty(r, 'className', Sr))
              : ((t.className && !t.class) || (t.class && t.className)) &&
                (r.class = r.className = t.className),
            (e.props = r);
        })(e),
        (e.$$typeof = _r),
        Cr && Cr(e);
    };
    var Or = Qt.__r;
    Qt.__r = function (e) {
      Or && Or(e), e.__c;
    };
    var xr = Qt.diffed;
    Qt.diffed = function (e) {
      xr && xr(e);
      var t = e.props,
        n = e.__e;
      null != n &&
        'textarea' === e.type &&
        'value' in t &&
        t.value !== n.value &&
        (n.value = null == t.value ? '' : t.value);
    };
    var Nr = [
      {
        name: 'default',
        editorDropdown: !0,
        editableProps: [
          'flag_type',
          'with_flags',
          'is_dropdown',
          'with_name',
          'full_name',
          'invert_flags',
          'open_hover',
          'close_outside_click'
        ],
        defaultValues: {
          style: {
            with_name: !0,
            with_flags: !0,
            full_name: !0,
            is_dropdown: !0,
            invert_flags: !0,
            flag_type: 'rectangle_mat'
          },
          opts: {
            open_hover: !1,
            close_outside_click: !1
          }
        }
      },
      {
        name: 'toggle',
        editableProps: ['color'],
        defaultValues: {
          style: {
            full_name: !1,
            color: '#3D46FB'
          }
        }
      },
      {
        name: 'bubble',
        editorDropdown: !0,
        editableProps: ['flag_type', 'with_flags', 'full_name', 'color', 'open_hover', 'close_outside_click'],
        defaultValues: {
          style: {
            with_name: !0,
            with_flags: !0,
            full_name: !0,
            flag_type: 'rectangle_mat',
            color: '#555555'
          },
          opts: {
            open_hover: !1,
            close_outside_click: !0
          }
        }
      },
      {
        name: 'vertical_expand',
        editorDropdown: !0,
        editableProps: [
          'with_flags',
          'full_name',
          'color',
          'open_hover',
          'close_outside_click',
          'bg_transparent'
        ],
        defaultValues: {
          style: {
            with_name: !0,
            with_flags: !0,
            full_name: !1,
            flag_type: 'square',
            color: '#3D46FB',
            bg_transparent: !1
          },
          opts: {
            open_hover: !0,
            close_outside_click: !1
          }
        }
      },
      {
        name: 'horizontal_expand',
        editorDropdown: !1,
        editableProps: ['color', 'open_hover', 'close_outside_click'],
        defaultValues: {
          style: {
            with_name: !0,
            with_flags: !1,
            full_name: !1,
            flag_type: 'square',
            color: '#3D46FB'
          },
          opts: {
            open_hover: !0,
            close_outside_click: !1
          }
        }
      },
      {
        name: 'underline_edge',
        editableProps: ['full_name', 'color'],
        maxLanguages: 10,
        minLanguages: null,
        defaultValues: {
          style: {
            full_name: !1,
            color: '#FA8072'
          }
        }
      },
      {
        name: 'skewed',
        editorDropdown: !0,
        editableProps: [
          'with_flags',
          'full_name',
          'color',
          'open_hover',
          'close_outside_click',
          'bg_transparent'
        ],
        defaultValues: {
          style: {
            with_name: !0,
            with_flags: !0,
            full_name: !1,
            flag_type: 'square',
            color: '#3D46FB'
          },
          opts: {
            open_hover: !0,
            close_outside_click: !1
          }
        }
      },
      {
        name: 'underline_full',
        maxLanguages: 10,
        minLanguages: null,
        editableProps: ['with_flags', 'flag_type', 'color'],
        defaultValues: {
          style: {
            full_name: !0,
            color: '#3D46FB',
            with_flags: !0,
            flag_type: 'rectangle_mat'
          }
        }
      }
    ].map(function (e) {
      var t = ['is_responsive', 'display_device'];
      'default' !== e.name && t.push('size_scale');
      return Object.assign({}, e, {
        defaultValues: Object.assign({}, e.defaultValues, {
          opts: Object.assign({}, e.defaultValues.opts, {
            is_responsive: !1,
            display_device: 'mobile'
          }),
          style: Object.assign(
            {},
            e.defaultValues.style,
            'default' !== e.name && {
              size_scale: 1
            }
          )
        }),
        editableProps: e.editableProps.concat(t)
      });
    });
  
    function Lr(e) {
      var t = (function (e) {
          return (
            void 0 === e && (e = 'default'),
            Nr.find(function (t) {
              return t.name === (e || 'default');
            })
          );
        })(e),
        n = t.defaultValues;
      void 0 === n && (n = {});
      var r = n,
        o = r.style;
      void 0 === o && (o = {});
      var a = r.opts;
      return (
        void 0 === a && (a = {}),
        {
          style: o,
          opts: a
        }
      );
    }
    var Tr = {
      af: {
        name: 'Afrikaans',
        flag: 'za'
      },
      am: {
        name: 'አማርኛ',
        flag: 'et'
      },
      ar: {
        name: 'العربية',
        flag: 'sa'
      },
      az: {
        name: 'Azərbaycan dili',
        flag: 'az'
      },
      ba: {
        name: 'башҡорт теле',
        flag: 'ru'
      },
      be: {
        name: 'Беларуская',
        flag: 'by'
      },
      bg: {
        name: 'Български',
        flag: 'bg'
      },
      bn: {
        name: 'বাংলা',
        flag: 'bd'
      },
      br: {
        name: 'Português Brasileiro',
        flag: 'br'
      },
      bs: {
        name: 'Bosanski',
        flag: 'ba'
      },
      ca: {
        name: 'Català',
        flag: 'es-ca'
      },
      co: {
        name: 'Corsu',
        flag: 'fr-co'
      },
      cs: {
        name: 'Čeština',
        flag: 'cz'
      },
      cy: {
        name: 'Cymraeg',
        flag: 'gb-wls'
      },
      da: {
        name: 'Dansk',
        flag: 'dk'
      },
      de: {
        name: 'Deutsch',
        flag: 'de'
      },
      el: {
        name: 'Ελληνικά',
        flag: 'gr'
      },
      en: {
        name: 'English',
        flag: 'gb'
      },
      eo: {
        name: 'Esperanto',
        flag: 'eo'
      },
      es: {
        name: 'Español',
        flag: 'es'
      },
      et: {
        name: 'Eesti',
        flag: 'ee'
      },
      eu: {
        name: 'Euskara',
        flag: 'eus'
      },
      fa: {
        name: 'فارسی',
        flag: 'ir'
      },
      fi: {
        name: 'Suomi',
        flag: 'fi'
      },
      fj: {
        name: 'Vosa Vakaviti',
        flag: 'fj'
      },
      fl: {
        name: 'Filipino',
        flag: 'ph'
      },
      fr: {
        name: 'Français',
        flag: 'fr'
      },
      fy: {
        name: 'frysk',
        flag: 'nl'
      },
      ga: {
        name: 'Gaeilge',
        flag: 'ie'
      },
      gd: {
        name: 'Gàidhlig',
        flag: 'gb-sct'
      },
      gl: {
        name: 'Galego',
        flag: 'es-ga'
      },
      gu: {
        name: 'ગુજરાતી',
        flag: 'in'
      },
      ha: {
        name: 'هَوُسَ',
        flag: 'ne'
      },
      he: {
        name: 'עברית',
        flag: 'il'
      },
      hi: {
        name: 'हिंदी',
        flag: 'in'
      },
      hr: {
        name: 'Hrvatski',
        flag: 'hr'
      },
      ht: {
        name: 'Kreyòl ayisyen',
        flag: 'ht'
      },
      hu: {
        name: 'Magyar',
        flag: 'hu'
      },
      hw: {
        name: 'Ōlelo Hawaii',
        flag: 'hw'
      },
      hy: {
        name: 'հայերեն',
        flag: 'am'
      },
      id: {
        name: 'Bahasa Indonesia',
        flag: 'id'
      },
      ig: {
        name: 'Igbo',
        flag: 'ne'
      },
      is: {
        name: 'Íslenska',
        flag: 'is'
      },
      it: {
        name: 'Italiano',
        flag: 'it'
      },
      ja: {
        name: '日本語',
        flag: 'jp'
      },
      jv: {
        name: 'Wong Jawa',
        flag: 'id'
      },
      ka: {
        name: 'ქართული',
        flag: 'ge'
      },
      kk: {
        name: 'Қазақша',
        flag: 'kz'
      },
      km: {
        name: 'ភាសាខ្មែរ',
        flag: 'kh'
      },
      kn: {
        name: 'ಕನ್ನಡ',
        flag: 'in'
      },
      ko: {
        name: '한국어',
        flag: 'kr'
      },
      ku: {
        name: 'كوردی',
        flag: 'iq'
      },
      ky: {
        name: 'кыргызча',
        flag: 'kg'
      },
      la: {
        name: 'Latine',
        flag: 'it'
      },
      lb: {
        name: 'Lëtzebuergesch',
        flag: 'lu'
      },
      lo: {
        name: 'ພາສາລາວ',
        flag: 'la'
      },
      lt: {
        name: 'Lietuvių',
        flag: 'lt'
      },
      lv: {
        name: 'Latviešu',
        flag: 'lv'
      },
      lg: {
        name: 'Oluganda',
        flag: 'ug'
      },
      mg: {
        name: 'Malagasy',
        flag: 'mg'
      },
      mi: {
        name: 'te reo Māori',
        flag: 'nz'
      },
      mk: {
        name: 'Македонски',
        flag: 'mk'
      },
      ml: {
        name: 'മലയാളം',
        flag: 'in'
      },
      mn: {
        name: 'Монгол',
        flag: 'mn'
      },
      mr: {
        name: 'मराठी',
        flag: 'in'
      },
      ms: {
        name: 'Bahasa Melayu',
        flag: 'my'
      },
      mt: {
        name: 'Malti',
        flag: 'mt'
      },
      my: {
        name: 'မျန္မာစာ',
        flag: 'mm'
      },
      ne: {
        name: 'नेपाली',
        flag: 'np'
      },
      nl: {
        name: 'Nederlands',
        flag: 'nl'
      },
      no: {
        name: 'Norsk',
        flag: 'no'
      },
      ny: {
        name: 'chiCheŵa',
        flag: 'mw'
      },
      pa: {
        name: 'ਪੰਜਾਬੀ',
        flag: 'in'
      },
      pl: {
        name: 'Polski',
        flag: 'pl'
      },
      ps: {
        name: 'پښت',
        flag: 'af'
      },
      pt: {
        name: 'Português',
        flag: 'pt'
      },
      ro: {
        name: 'Română',
        flag: 'ro'
      },
      ru: {
        name: 'Русский',
        flag: 'ru'
      },
      sd: {
        name: '"سنڌي، سندھی, सिन्धी"',
        flag: 'pk'
      },
      si: {
        name: 'සිංහල',
        flag: 'lk'
      },
      sk: {
        name: 'Slovenčina',
        flag: 'sk'
      },
      sl: {
        name: 'Slovenščina',
        flag: 'si'
      },
      sm: {
        name: '"gagana faa Samoa"',
        flag: 'ws'
      },
      sn: {
        name: 'chiShona',
        flag: 'zw'
      },
      so: {
        name: 'Soomaaliga',
        flag: 'so'
      },
      sq: {
        name: 'Shqip',
        flag: 'al'
      },
      sr: {
        name: 'Српски',
        flag: 'rs'
      },
      st: {
        name: 'seSotho',
        flag: 'ng'
      },
      su: {
        name: 'Sundanese',
        flag: 'sd'
      },
      sv: {
        name: 'Svenska',
        flag: 'se'
      },
      sw: {
        name: 'Kiswahili',
        flag: 'ke'
      },
      ta: {
        name: 'தமிழ்',
        flag: 'in'
      },
      te: {
        name: 'తెలుగు',
        flag: 'in'
      },
      tg: {
        name: 'Тоҷикӣ',
        flag: 'tj'
      },
      th: {
        name: 'ภาษาไทย',
        flag: 'th'
      },
      tl: {
        name: 'Tagalog',
        flag: 'ph'
      },
      to: {
        name: 'faka-Tonga',
        flag: 'to'
      },
      tr: {
        name: 'Türkçe',
        flag: 'tr'
      },
      tt: {
        name: 'Tatar',
        flag: 'tr'
      },
      tw: {
        name: '中文 (繁體)',
        flag: 'tw'
      },
      ty: {
        name: '"te reo Tahiti, te reo Māohi"',
        flag: 'pf'
      },
      uk: {
        name: 'Українська',
        flag: 'ua'
      },
      ur: {
        name: 'اردو',
        flag: 'pk'
      },
      uz: {
        name: '"Ozbek"',
        flag: 'uz'
      },
      vi: {
        name: 'Tiếng Việt',
        flag: 'vn'
      },
      xh: {
        name: 'isiXhosa',
        flag: 'za'
      },
      yi: {
        name: 'ייִדיש',
        flag: 'il'
      },
      yo: {
        name: 'Yorùbá',
        flag: 'ng'
      },
      zh: {
        name: '中文 (简体)',
        flag: 'cn'
      },
      zu: {
        name: 'isiZulu',
        flag: 'za'
      },
      hm: {
        name: 'Hmoob',
        flag: 'hmn'
      },
      cb: {
        name: 'Sugbuanon',
        flag: 'ph'
      },
      or: {
        name: 'ଓଡ଼ିଆ',
        flag: 'in'
      },
      tk: {
        name: 'Türkmen',
        flag: 'tr'
      },
      ug: {
        name: 'ئۇيغۇر',
        flag: 'uig'
      },
      fc: {
        name: 'Français (Canada)',
        flag: 'ca'
      },
      as: {
        name: 'অসমীয়া',
        flag: 'in'
      },
      sa: {
        name: 'Srpski',
        flag: 'rs'
      }
    };
    
    function jr(e) {
      if (!e || !e.toLowerCase) return 'Unknown';
      var t = e.toLowerCase(),
        n = gt.languages.find(function (e) {
          var n = e.language_to,
            r = e.custom_code;
          return n === t || (r ? r.toLowerCase() === t : void 0);
        });
      return n && n.custom_local_name
        ? n.custom_local_name
        : n && n.custom_name
        ? n.custom_name
        : t === gt.language_from && gt.language_from_custom_name
        ? gt.language_from_custom_name
        : Tr[t].name;
    }
  
    function Ar(e, t) {
      return t[e] ? t[e].flag : '';
    }
  
    function Pr(e) {
      return (function (e, t, n) {
        if (!e || !e.toLowerCase) return '';
        if (t.language_from === e) return t.language_from_custom_flag || Ar(e, n);
        var r = e.toLowerCase(),
          o = t.languages.find(function (e) {
            var t = e.language_to,
              n = e.custom_code;
            return t === r || (n && n.toLowerCase() === r);
          });
        return o ? o.custom_flag || Ar(o.language_to, n) : '';
      })(e, gt, Tr);
    }
  
    function Dr(e, t) {
      return 'WordPress' === gt.technology_name && gt.injectedData && !gt.is_connect
        ? t(gt.injectedData.switcher_links[e])
        : Xe(e, t);
    }
    var Rr = 13,
      Ir = 27,
      Wr = 38,
      Ur = 40;
    var Mr = ['none', 'shiny', 'square', 'circle', 'rectangle_mat'];
  
    function qr(e) {
      return e
        ? e.getBoundingClientRect()
        : {
            bottom: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            width: 0
          };
    }
  
    function Hr() {
      var e = Gn(
          'WordPress' === gt.technology_name && gt.injectedData && !gt.is_connect
            ? gt.injectedData.current_language
            : gt.switcher_editor
            ? gt.language_from
            : window.Weglot.getCurrentLang()
        ),
        t = e[0],
        n = e[1];
      return (
        Jn(function () {
          gt.is_connect ||
            'WordPress' === gt.technology_name ||
            gt.switcher_editor ||
            window.Weglot.on('languageChanged', function (e) {
              n(e);
            });
        }, []),
        [t, n]
      );
    }
  
    function zr(e) {
      var t = window.innerWidth > 0 ? window.innerWidth : screen.width;
      return 'mobile' === e ? t <= 450 : t > 450;
    }
  
    function Br(e) {
      var t = e.close_outside_click;
      void 0 === t && (t = !1);
      var n = e.open_hover;
      void 0 === n && (n = !1);
      var r = Hr(),
        o = r[0],
        a = r[1],
        i = Gn(!1),
        s = i[0],
        c = i[1],
        l = (function (e) {
          var t = Yn(null);
          return (
            Jn(
              function () {
                if (e)
                  return (
                    document.addEventListener('mousedown', n),
                    function () {
                      document.removeEventListener('mousedown', n);
                    }
                  );
  
                function n(n) {
                  t.current && !t.current.contains(n.target) && e();
                }
              },
              [t]
            ),
            t
          );
        })(t && !n && k),
        u = Yn(null),
        f = Et().filter(function (e) {
          return e !== o;
        }),
        d = Gn(null),
        g = d[0],
        _ = d[1],
        p = Gn(!1),
        h = p[0],
        m = p[1],
        v = Gn(!1),
        w = v[0],
        y = v[1];
  
      function b() {
        var e = qr(l.current),
          t = e.bottom;
        void 0 === t && (t = 0);
        var n = e.left;
        void 0 === n && (n = 0), y(t > window.innerHeight / 2), m(n > window.innerWidth / 2), c(!0);
      }
  
      function k() {
        c(!1), _(null);
      }
  
      function E() {
        return s ? k() : b();
      }
  
      function S(e) {
        if (gt.switcher_editor) return c(!1);
        var t;
        a(e),
          (t = e),
          'WordPress' === gt.technology_name && gt.injectedData && !gt.is_connect
            ? Dr(t, function (e) {
                've-weglot.com' === window.location.hostname
                  ? window.dispatchEvent(
                      new CustomEvent('veLanguageChangeUrl', {
                        detail: {
                          targetUrl: e
                        }
                      })
                    )
                  : window.location.replace(e);
              })
            : window.Weglot.switchTo(t),
          c(!1);
      }
      return (
        Jn(
          function () {
            g &&
              u.current.scrollIntoView({
                block: 'center'
              });
          },
          [g]
        ),
        {
          open: s,
          opensUpward: w,
          opensLeftward: h,
          language: o,
          otherLanguages: f,
          focusedLanguage: g,
          switcherContainerRef: l,
          focusedLanguageRef: u,
          handleMouseEnter: function () {
            n && b();
          },
          handleMouseLeave: function () {
            n && k();
          },
          handleKeyDown: function (e) {
            return e.keyCode === Rr
              ? (e.preventDefault(), g && S(g), E())
              : e.keyCode === Ur || e.keyCode === Wr
              ? (e.preventDefault(),
                void (function (e) {
                  var t = e === Ur ? 'down' : 'up',
                    n = f.slice(-1).pop(),
                    r = f[0],
                    o = qr(l.current).bottom;
                  void 0 === o && (o = 0);
                  var a = o > window.innerHeight / 2;
                  if (!g || !s) {
                    return (
                      _('down' === t ? r : n), void (!s && (('up' === t && a) || ('down' === t && !a)) && b())
                    );
                  }
                  if ((!a && 'up' === t && g === r) || (a && 'down' === t && g === n)) return void E();
                  var i = 'up' === t ? -1 : 1,
                    c = f.indexOf(g) + i;
                  if (c === f.length || -1 === c) return;
                  _(f[c]);
                })(e.keyCode))
              : void (e.keyCode === Ir && s && (e.preventDefault(), E()));
          },
          switchLanguage: S,
          toggleOpen: E
        }
      );
    }
    var Fr,
      Vr,
      $r,
      Gr = {
        rectangle_mat: {
          width: 30,
          height: 20
        },
        shiny: {
          width: 30,
          height: 20
        },
        circle: {
          width: 24,
          height: 24
        },
        square: {
          width: 24,
          height: 24
        }
      },
      Jr = function (e) {
        var t = e.language,
          n = e.flagType;
        void 0 === n && (n = 'circle');
        var r = Pr(t),
          o = Gr[n] || {},
          a = o.width,
          i = o.height;
        if (r)
          return fn('img', {
            src: r.indexOf('http') > -1 ? r : 'https://cdn.weglot.com/flags/' + n + '/' + r + '.svg',
            width: a,
            height: i,
            className: 'wg-flag',
            role: 'none',
            alt: jr(t) + ' flag'
          });
      },
      Yr = function (e) {
        var t = e.styleOpts,
          n = e.language,
          r = e.onClick,
          o = e.legacyFlags,
          a = e.url,
          i = e.isFocused;
        void 0 === i && (i = !1);
        var s = e.isSelected;
        void 0 === s && (s = !1);
        var c = e.focusRef;
        void 0 === c && (c = null);
        var l = t.with_name;
        void 0 === l && (l = !0);
        var u = t.full_name;
        void 0 === u && (u = !0);
        var f = t.with_flags,
          d = t.flag_type,
          g = u ? jr(n) : n.toUpperCase(),
          _ = s ? 'div' : 'li',
          p = Mr.indexOf(d || 'rectangle_mat'),
          h = f ? ' wg-flags' + (o ? ' flag-' + p + ' legacy' : '') : '',
          m = i && !s ? ' focus' : '',
          v = s ? ' wgcurrent' : '';
        return fn(
          _,
          Object.assign(
            {},
            {
              'data-l': n,
              onClick: function (e) {
                return (function (e, t) {
                  e.preventDefault(), r(t);
                })(e, n);
              },
              className: 'wg-li ' + n + v + h + m
            },
            !s && {
              role: 'none',
              id: 'wg-' + n
            }
          ),
          fn(
            'a',
            Object.assign(
              {},
              s
                ? {
                    tabIndex: -1,
                    target: '_self'
                  }
                : {
                    role: 'option'
                  },
              {
                href: a
              },
              !l && {
                'aria-label': g
              },
              i &&
                !s && {
                  ref: c
                },
              {
                id: 'weglot-language-' + n
              }
            ),
            f &&
              !o &&
              fn(Jr, {
                language: n,
                flagType: d
              }),
            l && g
          )
        );
      },
      Kr = (function (e, t) {
        return function (n) {
          var r = n || {},
            o = r.style;
          void 0 === o && (o = {});
          var a = r.opts;
          void 0 === a && (a = {});
          var i = Lr(t),
            s = i.style,
            c = i.opts,
            l = document.createElement('div');
          return (
            (function (e, t, n) {
              var r, o, a;
              Qt.__ && Qt.__(e, t),
                (o = (r = 'function' == typeof n) ? null : (n && n.__k) || t.__k),
                (a = []),
                Nn(
                  t,
                  (e = ((!r && n) || t).__k = fn(gn, null, [e])),
                  o || an,
                  an,
                  void 0 !== t.ownerSVGElement,
                  !r && n ? [n] : o ? null : t.firstChild ? Zt.call(t.childNodes) : null,
                  a,
                  !r && n ? n : o ? o.__e : t.firstChild,
                  r
                ),
                Ln(a, e);
            })(
              fn(e, {
                style: Object.assign({}, s, o),
                opts: Object.assign({}, c, a)
              }),
              l
            ),
            l.classList.add('weglot-container'),
            l
          );
        };
      })(function (e) {
        var t = e.style,
          n = e.opts,
          r = Br(n),
          o = r.open,
          a = r.opensUpward,
          i = r.opensLeftward,
          s = r.language,
          c = r.focusedLanguage,
          l = r.switcherContainerRef,
          u = r.focusedLanguageRef,
          f = r.handleMouseEnter,
          d = r.handleMouseLeave,
          g = r.handleKeyDown,
          _ = r.switchLanguage,
          p = r.toggleOpen,
          h = (function () {
            var e = Et(),
              t = Gn(
                e.reduce(function (e, t) {
                  var n;
                  return Object.assign({}, e, (((n = {})[t] = ''), n));
                }, {})
              ),
              n = t[0],
              r = t[1];
            return (
              Jn(function () {
                Promise.all(
                  e.map(function (e) {
                    return new Promise(function (t) {
                      return Dr(e, function (n) {
                        return t({
                          l: e,
                          url: n
                        });
                      });
                    });
                  })
                ).then(function (e) {
                  return r(
                    e.reduce(function (e, t) {
                      var n,
                        r = t.l,
                        o = t.url;
                      return Object.assign({}, e, (((n = {})[r] = o), n));
                    }, {})
                  );
                });
              }, []),
              n
            );
          })(),
          m = (function (e) {
            var t = e.is_responsive,
              n = e.display_device,
              r = Gn(!t || zr(n)),
              o = r[0],
              a = r[1],
              i = function () {
                return a(zr(n));
              };
            return (
              Jn(
                function () {
                  if (t)
                    return (
                      window.addEventListener('resize', i),
                      function () {
                        window.removeEventListener('resize', i);
                      }
                    );
                },
                [t, n]
              ),
              o
            );
          })(n),
          v = gt.switcher_editor,
          w = t.is_dropdown || t.invert_flags,
          y = Et().filter(function (e) {
            return !w || e !== s;
          }),
          b =
            /background-position/i.test(gt.button_style.custom_css) &&
            !gt.languages.some(function (e) {
              return e.custom_flag;
            }),
          k = t.is_dropdown ? 'wg-drop' : 'wg-list';
        return m
          ? fn(
              'aside',
              {
                ref: l,
                'data-wg-notranslate': !0,
                onKeyDown: g,
                onMouseEnter: f,
                onMouseLeave: d,
                'aria-expanded': o,
                tabIndex: 0,
                className:
                  'weglot_switcher ' +
                  k +
                  ' wg-mouse-click country-selector ' +
                  (o ? 'open' : 'closed') +
                  (a && o ? ' weg-openup' : '') +
                  (i && o ? ' weg-openleft' : '') +
                  (v ? ' wg-editor' : ''),
                'aria-label': 'Language selected: ' + jr(s)
              },
              w &&
                fn(Yr, {
                  styleOpts: t,
                  language: s,
                  isSelected: !0,
                  onClick: p,
                  legacyFlags: b,
                  url: '#'
                }),
              fn(
                'ul',
                {
                  role: 'none',
                  style: !o &&
                    t.is_dropdown && {
                      display: 'none'
                    }
                },
                y.map(function (e) {
                  return fn(Yr, {
                    language: e,
                    url: e === s ? '#' : h[e],
                    onClick: _,
                    isSelected: e === s,
                    key: 'wg-' + e,
                    isFocused: e === c,
                    focusRef: u,
                    styleOpts: t,
                    legacyFlags: b
                  });
                })
              )
            )
          : fn(gn, null);
      }, 'default'),
      Xr = 0,
      Zr = [];
  
    function Qr(e, t) {
      if (
        (void 0 === t && (t = document.documentElement),
        e && document.querySelectorAll('.weglot-container').length < 1)
      ) {
        t = document.documentElement;
        var n = e.style || gt.button_style,
          r = e.location;
        void 0 === r && (r = {});
        var o = (function (e, t) {
            void 0 === e && (e = {});
            var n = e.target,
              r = e.sibling;
            if (!n)
              return {
                defaultPosition: !0
              };
            var o = ce(t, n);
            if (!o.length)
              return {
                error: me(n)
                  ? 'The provided target is not on this page.'
                  : 'The provided target is not a valid CSS selector.'
              };
            var a = ce(t, r);
            if (!r || !a.length)
              return {
                targetNode: o[0],
                siblingNode: null
              };
            var i = Array.from(o),
              s = Array.from(a),
              c = null,
              l = s.find(function (e) {
                return (
                  (c = i.find(function (t) {
                    return e.parentNode === t;
                  })),
                  !!c
                );
              });
            return l && c
              ? {
                  targetNode: c,
                  siblingNode: l
                }
              : {
                  error: 'The provided sibling selector does not belong to target element.'
                };
          })(r, t),
          a = o.error,
          i = o.targetNode,
          s = o.siblingNode,
          c = o.defaultPosition;
        if (!a) {
          var l = Kr(
            Object.assign(
              {},
              e,
              !gt.switcher_editor && {
                style: n
              }
            )
          );
          if (((l.weglotSwitcher = e), Zr.push(l), c))
            return l.classList.add('wg-default'), document.body.appendChild(l), (e.ready = !0), l;
          l.setAttribute('data-switcher-id', String(++Xr)),
            (l.id = 'weglot-switcher-' + Xr),
            l.setAttribute('data-switcher-style-opt', JSON.stringify(n)),
            i.insertBefore(l, s),
            (e.ready = !0);
          for (var u = 0, f = t.querySelectorAll('.weglot-container:empty'); u < f.length; u += 1) {
            de(f[u]);
          }
          return l;
        }
        W.warn(a, {
          sendToDatadog: !1
        });
      }
    }
  
    function eo(e) {
      var t = e.name,
        n = e.hash,
        r = e.interactive;
      if ((void 0 === r && (r = !1), le(document.documentElement, 'script#weglot-switcher-' + t))) return !1;
      var o = !gt.switcher_editor && n ? t + '.' + n : t,
        a = document.getElementsByTagName('head')[0] || document.documentElement,
        i = document.createElement('script');
      return (
        (i.type = 'text/javascript'),
        (i.src = 'https://cdn.weglot.com/switchers/' + o + '.min.js'),
        (i.id = 'weglot-switcher-' + t),
        r && i.setAttribute('interactive', 'true'),
        a.insertBefore(i, a.firstChild),
        !0
      );
    }
  
    function to() {
      Fr || Le('switchersReady', Ue()), (Fr = !0), clearTimeout($r), Vr && Vr.parentNode.removeChild(Vr);
    }
  
    function no(e) {
      if ((void 0 === e && (e = document), !(Et().length < 2 || gt.hide_switcher))) {
        var t = e.isConnected ? e : document;
        (function (e) {
          if (
            (void 0 === e && (e = document.body),
            0 !== ce(e, 'a[href^="#Weglot-"],a[href*="change-language.weglot.com/"]').length)
          ) {
            for (
              var t = Et(),
                n = Ue(),
                r = !1,
                o = [],
                a = function () {
                  var t = s[i],
                    a = ce(e, Xt(t));
                  if (0 !== a.length) {
                    r = !0;
                    for (
                      var c = function () {
                          var e = u[l];
                          e.setAttribute(z, ''),
                            e.classList.add('weglot-link', 'weglot-link-' + t),
                            t === n && e.classList.add('weglot-link--active'),
                            Xe(t, function (t) {
                              return e.setAttribute('href', t);
                            }),
                            e.addEventListener('click', function (e) {
                              e.preventDefault(), e.stopPropagation(), jo(t);
                            });
                        },
                        l = 0,
                        u = a;
                      l < u.length;
                      l += 1
                    )
                      c();
                    o.push({
                      language: t,
                      links: a
                    });
                  }
                },
                i = 0,
                s = t;
              i < s.length;
              i += 1
            )
              a();
            return (
              Ne(
                'languageChanged',
                function (e) {
                  for (var t = 0, n = o; t < n.length; t += 1) {
                    var r = n[t],
                      a = r.language,
                      i = r.links;
                    if (a === e)
                      for (var s = 0, c = i; s < c.length; s += 1) c[s].classList.add('weglot-link--active');
                    else
                      for (var l = 0, u = i; l < u.length; l += 1) u[l].classList.remove('weglot-link--active');
                  }
                },
                !0
              ),
              r
            );
          }
        })(t) && to();
        var n = t.querySelectorAll('#weglot_here:not(.weglot-container),.weglot_here:not(.weglot-container)');
        if (n.length) {
          for (var r = 0, o = n; r < o.length; r += 1) {
            var a = o[r],
              i = Kr({
                style: gt.button_style
              });
            i.classList.add('weglot_here'), a.parentNode.insertBefore(i, a), de(a);
          }
          to();
        }
        for (var s = 0, c = gt.switchers; s < c.length; s += 1) {
          var l = c[s];
          if (!l.default) {
            var u = l.template;
            if (u) {
              if (u.name) {
                if (!eo(u)) {
                  var f = window.Weglot.switchers && window.Weglot.switchers[u.name];
                  f && f.addSwitchers(t);
                }
                to();
              }
            } else Qr(l, t) && to();
          }
        }
        if (!Fr && !Vr) {
          var d = gt.switchers.find(function (e) {
            return e.default;
          }) || {
            style: gt.button_style
          };
          $r = setTimeout(function () {
            (Vr = Qr(d)), Le('switchersReady', Ue());
          });
        }
      }
    }
    Ne(
      'onCurrentLocationChanged',
      function () {
        Zr.forEach(function (e) {
          return e.parentNode && e.parentNode.removeChild(e);
        }),
          Zr.splice(0),
          window.Weglot.switchers &&
            Object.values(window.Weglot.switchers).forEach(function (e) {
              e.removeSwitchers();
            }),
          (Fr = null),
          (Vr = null),
          (Xr = 0),
          (gt.button_style.ready = !1),
          gt.switchers.map(function (e) {
            return (e.ready = !1);
          }),
          no();
      },
      !0
    );
    var ro = 0;
  
    function oo() {
      var e = ['name', 'value'];
      gt.translate_event.forEach(function (t) {
        for (
          var n = ce(document.body, t.selector),
            r = function () {
              var n = a[o];
              if (n.alreadyListeningEventInput)
                return (
                  !n.alreadyListeningEventInput.isConnected &&
                    ro < 10 &&
                    (ro++, n.parentNode.insertBefore(n.alreadyListeningEventInput, n.nextSibling)),
                  {}
                );
              var r = n.cloneNode(!0);
              if (!r) return {};
              (r.name = ''),
                (n.alreadyListeningEventInput = r),
                n.parentNode.insertBefore(r, n.nextSibling),
                (n.style.display = 'none'),
                new MutationObserver(function (t) {
                  for (var o = 0, a = t; o < a.length; o += 1) {
                    var i = a[o],
                      s = n.getAttribute(i.attributeName);
                    e.includes(i.attributeName) && r.setAttribute(i.attributeName, s);
                  }
                }).observe(n, {
                  attributes: !0
                });
              var i = we(function (e) {
                13 === e.keyCode && e.target.form
                  ? e.target.form.dispatchEvent(new Event('submit'))
                  : Kt(e.target.value, function (e) {
                      Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set.call(
                        n,
                        e
                      );
                      var r = t.eventName || n.getAttribute('data-wg-translate-event'),
                        o = document.createEvent('HTMLEvents');
                      o.initEvent('focus', !0, !1),
                        n.dispatchEvent(o),
                        o.initEvent(r, !0, !1),
                        n.dispatchEvent(o);
                    });
              }, 400);
              r.addEventListener('keydown', i);
            },
            o = 0,
            a = n;
          o < a.length;
          o += 1
        ) {
          var i = r();
          if (i) return i.v;
        }
      });
    }
    try {
      var ao = Element.prototype.attachShadow;
      Element.prototype.attachShadow = function (e) {
        var t = ao.call(this, e);
        return io([this]), t;
      };
    } catch (Yn) {}
  
    function io(e) {
      if (gt.translate_shadow_roots) {
        e ||
          (e = ce(
            document,
            gt.dynamics
              .map(function (e) {
                return e.value;
              })
              .join(',')
          ));
        for (var t = 0, n = e; t < n.length; t += 1) {
          var r = n[t];
          if (r.shadowRoot && !r.shadowRoot.wgTranslated) {
            (r.shadowRoot.wgTranslated = !0), mo(r.shadowRoot);
            var o = Ht(r.shadowRoot);
            o.length && (Bt(o), yo(o));
          }
        }
      }
    }
    var so,
      co = null,
      lo = [],
      uo = [z, 'class', 'id'],
      fo = [],
      go = [];
  
    function _o(e, t) {
      so && clearTimeout(so);
      for (var n = 0, r = t; n < r.length; n += 1) {
        var o = r[n];
        1 === o.nodeType && lo.push(o);
      }
      lo.length &&
        (so = setTimeout(function () {
          no(e),
            oo(),
            gt.subdomain &&
              (function (e) {
                var t = window.location.hostname;
                if (t === gt.host) return;
                for (var n = 0, r = e; n < r.length; n += 1)
                  for (var o = r[n], a = 0, i = ce(o, '[href]'); a < i.length; a += 1) {
                    var s = i[a];
                    if (!p(s)) {
                      var c = s.getAttribute('href');
                      c && c.includes('//' + gt.host) && s.setAttribute('href', c.replace(gt.host, t));
                    }
                  }
              })(lo),
            io(lo),
            Ee('onDynamicDetected'),
            (lo = []);
        }, 100));
    }
  
    function po(e, t) {
      var n = gt.dynamics,
        r = vo;
      t !== document
        ? (r = function () {
            return !0;
          })
        : (n && 0 !== n.length) ||
          (r = function () {
            return !1;
          });
      try {
        if (kt()) return;
        if (
          ((e = (function (e, t) {
            var n = [],
              r = e.filter(function (e) {
                var r = e.addedNodes,
                  o = e.type,
                  a = e.target;
                'attributes' === o &&
                  (function (e) {
                    'IMG' === e.nodeName &&
                      e.srcset &&
                      e.dataset.wgtranslated &&
                      (e.setAttribute('wgsrcset', e.srcset), (e.srcset = ''));
                  })(a);
                var i = (function (e) {
                  do {
                    if (e.weglot && e.weglot.setted) return e;
                    e = e.parentElement || e.parentNode;
                  } while (e);
                })(a);
                return i
                  ? (n.push(i), !1)
                  : r.length
                  ? (setTimeout(function () {
                      return _o(a, r);
                    }),
                    !co || !a || !ue(a, co))
                  : !uo.includes(e.attributeName) && t(a) && ('characterData' === o || 'attributes' === o);
              });
            if (n.length)
              for (var o = 0, a = n; o < a.length; o += 1) {
                a[o].weglot.setted = !1;
              }
            return r;
          })(e, r)),
          !n || 0 === n.length)
        )
          return;
        if (e.length)
          try {
            !(function (e, t, n) {
              void 0 === n && (n = !0);
              for (
                var r = [],
                  o = function (e) {
                    var n = e.outerHTML || e.textContent;
                    if (e.wgParsed !== n) {
                      e.wgParsed = n;
                      for (
                        var o = Ht(e, function (e) {
                            var n = e.element;
                            return (
                              !(function (e) {
                                return e.weglot && e.weglot.dynamic > 20;
                              })(n) && t(n)
                            );
                          }),
                          a = 0,
                          i = o;
                        a < i.length;
                        a += 1
                      ) {
                        var s = i[a];
                        (gt.ignoreDynamicFragments && !document.body.contains(s)) ||
                          (s.weglot.dynamic || (s.weglot.dynamic = 0), s.weglot.dynamic++, r.push(s));
                      }
                    }
                  },
                  a = [],
                  i = 0,
                  s = e;
                i < s.length;
                i += 1
              ) {
                var c = s[i],
                  l = c.type,
                  u = c.target,
                  f = c.addedNodes;
                switch (l) {
                  case 'attributes':
                  case 'characterData':
                    if (a.includes(u)) break;
                    a.push(u), o(u);
                    break;
                  case 'childList':
                    var d = f.length > 1 ? u : f[0];
                    if (a.includes(d)) break;
                    if ((o(d), a.push(d), !n)) break;
                    for (var g = 0, _ = f; g < _.length; g += 1) {
                      var h = _[g],
                        m = [];
                      'IFRAME' === h.tagName
                        ? (m = [h])
                        : h.querySelectorAll && (m = h.querySelectorAll('iframe'));
                      for (var v = 0; v < m.length; v++) {
                        var w = m[v];
                        t(w) && ye(w) && !p(w) && (o(w.contentWindow.document), mo(w.contentWindow.document));
                      }
                    }
                }
              }
              r.length && (Bt(r), yo(r));
            })(e, r);
          } catch (e) {
            W.warn(e);
          }
      } catch (e) {
        W.warn(e, {
          consoleOverride: 'Error in MutationObserver'
        });
      }
    }
    var ho = !1;
  
    function mo(e) {
      var t = e !== document ? e : e.body || e,
        n = new MutationObserver(function (t) {
          var n;
          if (ho) po(t, e);
          else {
            var r = fo.find(function (t) {
              return t.documentElement === e;
            });
            r
              ? (n = r.mutations).push.apply(n, t)
              : fo.push({
                  documentElement: e,
                  mutations: [].concat(t)
                });
          }
        });
      n.observe(t, {
        childList: !0,
        subtree: !0,
        characterData: !0,
        attributes: !0
      }),
        go.push(n);
    }
  
    function vo(e) {
      return (
        !(!gt.dynamics || 0 === gt.dynamics.length) &&
        ((e && e.closest) || (e = e.parentNode),
        e &&
          e.closest &&
          ue(
            e,
            gt.dynamics
              .map(function (e) {
                return e.value;
              })
              .join(', ')
          ))
      );
    }
    var wo = {
      times: [],
      timeout: null,
      nodes: []
    };
  
    function yo(e) {
      void 0 === e && (e = []), clearTimeout(wo.timeout);
      var t = Ue();
      if (t !== gt.language_from) {
        if (
          ((wo.times = wo.times.filter(function (e) {
            return e > Date.now() - 1e3;
          })),
          wo.times.length && (wo.timeout || wo.times.length >= 10))
        )
          return (
            (wo.nodes = wo.nodes.concat(e)),
            void (wo.timeout = setTimeout(function () {
              return yo();
            }, 1e3))
          );
        (wo.timeout = null), wo.times.push(Date.now());
        var n = wo.nodes.concat(e);
        return (
          (wo.nodes = []),
          Yt(Ft(n), t, {
            title: !1,
            cdn: !0,
            nodes: n
          }).then(function (e) {
            return Vt(e, t, n);
          })
        );
      }
    }
  
    function bo() {
      var e = fe('wg_progress').firstElementChild,
        t = e.getAttribute('aria-valuenow'),
        n = parseInt(t) + (4 * Math.random() + 2);
      n <= 100 && (e.setAttribute('aria-valuenow', n.toString()), (e.style.width = n + '%'));
    }
  
    function ko(e) {
      clearInterval(e), de(fe('wg_progress'));
    }
    var Eo,
      So,
      Co,
      Oo = [];
  
    function xo(e) {
      if (e.data && 'null' !== e.origin) {
        var t = e.data,
          n = t.message,
          r = t.payload;
        if (n) {
          if ('Weglot.iframe' === n) {
            var o = {
              message: 'Weglot.setLanguage',
              payload: Ue()
            };
            return e.source.postMessage(o, e.origin), void Oo.push(e.source);
          }
          'Weglot.setLanguage' !== n || jo(r);
        }
      }
    }
  
    function No(e) {
      void 0 === e && (e = Ue());
      for (
        var t = {
            message: 'Weglot.setLanguage',
            payload: e
          },
          n = 0,
          r = Oo;
        n < r.length;
        n += 1
      ) {
        var o = r[n];
        try {
          o.postMessage(t, '*');
        } catch (e) {
          console.warn(e);
        }
      }
    }
  
    function Lo() {
      try {
        if (window.frameElement || window.self !== window.top) return 'with-window-top';
      } catch (e) {
        return 'no-window-top';
      }
    }
  
    function To(e, t, n) {
      if (n || !e || window.top !== window || !Po(e)) {
        var r = [];
        try {
          Bt((r = Ht()));
        } catch (e) {
          W.warn(e);
        }
        var o = kt();
        if (
          (e && t && !o && Tt(e),
          !gt.is_connect || gt.dynamicPushState || (!o && e !== gt.language_from)
            ? (function (e) {
                void 0 === e && (e = !0);
                var t = gt.excluded_blocks,
                  n = gt.is_connect;
                if ((ho = e))
                  if (
                    ((co =
                      t &&
                      t.length &&
                      t
                        .map(function (e) {
                          return e.value;
                        })
                        .join(',')),
                    n && fo.length > 0)
                  )
                    for (
                      var r = function () {
                          var e = a[o],
                            t = e.mutations,
                            n = e.documentElement,
                            r = function () {
                              var e = t.splice(0, 100);
                              e.length > 0 && (po(e, n), setTimeout(r, 0));
                            };
                          r();
                        },
                        o = 0,
                        a = fo;
                      o < a.length;
                      o += 1
                    )
                      r();
                  else fo = [];
              })()
            : (function () {
                if (0 !== go.length) {
                  for (var e = 0, t = go; e < t.length; e += 1) t[e].disconnect();
                  fo = [];
                }
              })(),
          n || o)
        )
          Ao(e);
        else if ((gt.is_connect && !o && Ee('onConnectPageLoad', e), gt.force_translation)) {
          for (var a = [], i = 0, s = r; i < s.length; i += 1) {
            var c = s[i];
            ((c.closest && c.closest(gt.force_translation)) ||
              (!c.closest &&
                c.parentNode &&
                c.parentNode.closest &&
                c.parentNode.closest(gt.force_translation))) &&
              a.push(c);
          }
          yo(a);
        }
        (o && !o.language_button_displayed && o.allExcluded) || no(),
          o ||
            (gt.remove_unused_link_hooks &&
              (function () {
                var e = Et(),
                  t = gt.languages
                    .map(function (e) {
                      return e.custom_code || e.language_to;
                    })
                    .filter(function (t) {
                      return !e.includes(t);
                    });
                1 === e.length && t.push(gt.language_from);
                for (
                  var n = t
                      .map(function (e) {
                        return Xt(e);
                      })
                      .join(','),
                    r = 0,
                    o = ce(document, n);
                  r < o.length;
                  r += 1
                )
                  de(o[r]);
              })(),
            io(),
            oo(),
            (function () {
              window.addEventListener('message', xo, !1);
              var e = gt.translate_iframes;
              if (e)
                for (var t = 0, n = ce(document.body, e); t < n.length; t += 1) {
                  var r = n[t];
                  r.contentWindow && Oo.push(r.contentWindow);
                }
              ke('onPageLanguageSet', No),
                'with-window-top' === Lo() &&
                  window.top.postMessage(
                    {
                      message: 'Weglot.iframe'
                    },
                    '*'
                  );
            })(),
            ['alert'].forEach(function (e) {
              var t = window[e];
              window[e] = function () {
                var e = arguments;
                if ('string' == typeof arguments[0]) {
                  var n = Ue();
                  return gt.language_from === n
                    ? t.apply(window, arguments)
                    : Yt(
                        [
                          {
                            t: 2,
                            w: arguments[0]
                          }
                        ],
                        n,
                        {
                          title: !1,
                          cdn: !0
                        }
                      ).then(function (n) {
                        return (e[0] = n.to_words[0]), t.apply(window, e);
                      });
                }
              };
            })),
          Le('initialized', e);
      }
    }
  
    function jo(e) {
      var t = Ue();
      e !== t &&
        (gt.visual_editor
          ? Xe(e, function (n) {
              if ('#' === n) return Ao(e, t);
              window.dispatchEvent(
                new CustomEvent('veLanguageChangeUrl', {
                  detail: {
                    targetUrl: n
                  }
                })
              );
            })
          : Ao(e, t));
    }
  
    function Ao(e, t) {
      if (!Et().includes(e))
        return (
          jt(),
          void W.warn(e + " isn't a language you have added", {
            sendToDatadog: !1
          })
        );
      gt.auto_switch && xt(e);
      var n = kt();
      if ((gt.is_connect || n || Tt(e), !Po(e))) {
        if (gt.loading_bar)
          var r = (function () {
            try {
              var e = document.createElement('div');
              return (
                (e.className = 'wg-progress'),
                (e.id = 'wg_progress'),
                (e.innerHTML =
                  '<div class="wg-progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width:0"></div>'),
                document.body.appendChild(e),
                setInterval(bo, 100)
              );
            } catch (e) {}
          })();
        if (
          ((function (e) {
            var t = _e('lang');
            if (t && t !== e) {
              var n = window.location.search.replace('lang=' + t, 'lang=' + e);
              try {
                window.history.replaceState(null, '', window.location.pathname + n);
              } catch (e) {}
            }
            Ae = e;
          })(e),
          kt())
        )
          return jt(), void ko(r);
        if (e === gt.language_from)
          return (
            Ee('onPageLanguageSet', e),
            jt(),
            Vt(null, e),
            gt.loading_bar && ko(r),
            document.documentElement.setAttribute('lang', e),
            void Le('languageChanged', e, t || '')
          );
        Yt(Ft(), e)
          .then(function (n) {
            jt(),
              Vt(n, e),
              document.documentElement.setAttribute('lang', e),
              Le('languageChanged', e, t || ''),
              gt.loading_bar && ko(r);
          })
          .catch(function (e) {
            throw (gt.loading_bar && ko(r), jt(), ze().removeItem(q), e);
          }),
          Ee('onPageLanguageSet', e);
      }
    }
  
    function Po(e) {
      return (
        !(!gt.is_connect || Ue() === e) &&
        (!gt.host ||
        (gt.previewHash && window.location.hostname.includes(M)) ||
        (function () {
          if (gt.subdirectory) return [gt.host, B];
          return gt.languages
            .map(function (e) {
              return e.connect_host_destination && e.connect_host_destination.host;
            })
            .concat([gt.host, B]);
        })().includes(window.location.hostname)
          ? (Xe(e, function (e) {
              return window.location.replace(e);
            }),
            !0)
          : (Lo() ||
              W.warn(
                '"' +
                  window.location.hostname +
                  '" is not configured with Weglot. Please contact support@weglot.com',
                {
                  sendToDatadog: !1
                }
              ),
            !1))
      );
    }
    Ne(
      'initialized',
      function () {
        gt.translate_search &&
          !gt.switcher_editor &&
          (function () {
            for (
              var e = gt.search_forms, t = gt.search_parameter, n = 0, r = ce(document, e);
              n < r.length;
              n += 1
            ) {
              var o = r[n];
              o.addEventListener('submit', function (e) {
                e.preventDefault();
                var n = e.target.elements[t].value;
                Kt(n, function (e) {
                  Ce.set('wg-search-form', n), (o.elements[t].value = e), o.submit();
                });
              });
              var a = void 0;
              -1 !== window.location.search.indexOf(t + '=') &&
                o.elements &&
                o.elements[t] &&
                (a = Ce.get('wg-search-form')) &&
                (o.elements[t].value = a);
            }
            Ce.erase('wg-search-form');
          })();
      },
      !0
    );
    var Do = !1;
  
    function Ro() {
      window.addEventListener('message', Uo, !1);
      var e = document.createElement('meta');
      (e.name = 'google'), (e.content = 'notranslate'), document.head && document.head.appendChild(e);
      document.documentElement &&
        -1 === ['cms.e.jimdo.com', 'proxy.weglot.com'].indexOf(window.location.host) &&
        document.documentElement.setAttribute('translate', 'no');
      var t = document.head.querySelector('link[href*=weglot_shopify]');
      t && document.head.removeChild(t);
    }
  
    function Io() {
      if (gt.api_key) {
        Ne(
          'initialized',
          function () {
            gt.page_views_enabled &&
              (gt.is_connect
                ? Xe(gt.language_from, function (e) {
                    return Nt(e);
                  })
                : Nt());
          },
          !0
        );
        try {
          I(document, gt);
        } catch (e) {
          W.error(e);
        }
        if ((Ee('onWeglotSetup'), !Ho.initialized || window.Turbolinks)) {
          (So = (function () {
            var e = Et();
            if (gt.is_connect) {
              var t = document.documentElement.dataset.wgTranslated || (gt.subdirectory ? We() : Ie());
              if (t !== gt.language_from) return t;
              if (!gt.subdirectory && gt.technology_name === $) {
                var n = Ce.get('wg_checkout_language');
                if (n && e.includes(n)) return Ce.erase('wg_checkout_language'), n;
              }
              var r = Ot();
              return t === gt.language_from && r && e.includes(r) ? r : gt.language_from;
            }
            var o = _e('lang');
            if (o && e.includes(o)) return (Do = !0), o;
            var a = Lt();
            if (a && e.includes(a)) return a;
            var i = Ot();
            if (i && e.includes(i)) return (Do = !0), i;
            return gt.language_from;
          })()),
            Ue();
          var e = kt();
          if (
            ((Co =
              So &&
              So !== gt.language_from &&
              document.documentElement.dataset.wgTranslated !== So &&
              !e &&
              !document.documentElement.dataset.wgExcludedUrl &&
              !gt.switcher_editor) && gt.wait_transition
              ? pe(
                  '@keyframes wg{from{color:transparent;}to{color:transparent;}}body *{color:transparent!important;animation:1s linear infinite wg!important;}',
                  H
                )
              : jt(),
            gt.delayStart)
          )
            return Ne(
              'start',
              function () {
                return Wo();
              },
              !0
            );
          'loading' === document.readyState
            ? document.addEventListener('DOMContentLoaded', function () {
                return Wo();
              })
            : Wo();
        }
      }
    }
  
    function Wo() {
      if (
        !document.querySelector('#has-script-tags') ||
        (document.querySelector('#has-script-tags') &&
          (document.head.innerHTML.indexOf('weglot_script_tag') > 0 ||
            document.documentElement.innerHTML.indexOf('weglot_script_tag') > 0))
      )
        try {
          To(So, Do, Co);
        } catch (e) {
          jt(),
            W.error(e, {
              consoleOverride: 'There has been an error initializing, ' + e.stack
            });
        }
      else jt();
      (Eo = !1), (Ho.initialized = !0);
    }
  
    function Uo(e) {
      if (e.data)
        try {
          var t = JSON.parse(e.data);
          switch (t.message) {
            case 'Weglot.detect':
              e.source.postMessage(
                JSON.stringify({
                  message: 'Weglot.ready',
                  data: {
                    initialized: Ho.initialized,
                    options: gt
                  }
                }),
                e.origin
              );
              break;
            case 'Weglot.switchTo':
              jo(t.language);
          }
        } catch (e) {}
    }
    var Mo = [
      "/cdn(?:-staging)?\\.weglot\\.(?:com|us)\\/weglot\\.min\\.js\\?(?<params>[^#]+)/",
      "/cdn(?:-staging)?\\.weglot\\.(?:com|us)\\/weglot-switcher-editor\\.js\\?(?<params>[^#]+)/",
      "/cdn(?:-staging)?\\/\\.\\/weglot\\.(?:com|us)\\/weglot_squarespace-[0-9]+\\.min\\.js\\?(?<params>[^#]+)/"
  ];
  
  
    function qo(e) {
      return e && e.src
        ? Mo.find(function (t) {
            return t.test(e.src);
          })
        : null;
    }
    var Ho = window.Weglot || {
      initialized: !1,
      options: gt,
      dynamic: '',
      switchTo: jo,
      setup: function (e) {
        Ro(),
          Eo ||
            ((Eo = !0),
            xe(mt(), 'polyfillReady', function () {
              ut(e)
                .then(function () {
                  return Io();
                })
                .catch(function () {
                  W.warn(
                    'Your setup is deprecated, please save settings in your dashboard to hide this message.',
                    {
                      sendToDatadog: !1
                    }
                  );
                  var t = e.api_key;
                  (e.translation_engine = t && t.length >= 36 ? 2 : 1),
                    (function (e) {
                      try {
                        var t = ['api_key', 'originalLanguage', 'destinationLanguages'];
                        if (
                          !e ||
                          t.some(function (t) {
                            return !e[t];
                          })
                        )
                          throw {
                            wgErrMsg: 'You have to provide at least: ' + t.join(', ')
                          };
                        dt(st(e));
                      } catch (e) {
                        throw new Error((e && e.wgErrMsg) || 'Error while reading Weglot options');
                      }
                    })(e),
                    Io();
                });
            }));
      },
      initialize: function (e) {
        Ro(),
          Eo ||
            ((Eo = !0),
            xe(mt(), 'polyfillReady', function () {
              ut(e).then(function () {
                return Io();
              });
            }));
      },
      on: function (e, t) {
        return Ne(e, t, !1);
      },
      off: function (e, t) {
        var n,
          r = !1,
          o = function (t) {
            return Oe[t].name === e && !Oe[t].internal;
          };
        n =
          'function' == typeof t
            ? function (e) {
                return o(e) && Oe[e].callback === t;
              }
            : function (e) {
                return o(e);
              };
        for (var a = Oe.length - 1; a >= 0; a--) n(a) && (Oe.splice(a, 1), (r = !0));
        return r;
      },
      getStoredLang: Lt,
      getLanguageName: jr,
      getCurrentLang: Ue,
      polyReady: ht,
      getCache: function () {
        return Dt;
      },
      addNodes: function (e) {
        var t = Ht(e);
        return Bt(t), yo(t);
      },
      search: Kt,
      translate: function (e, t) {
        void 0 === e && (e = {});
        var n = e.words,
          r = e.languageTo;
        if ((void 0 === r && (r = Ue()), !Array.isArray(n) || 'object' != typeof n[0])) {
          var o = 'Weglot.translate: 1st arg must be an array of objects';
          return (
            W.error(o, {
              sendToDatadog: !1
            }),
            t && t(null, o),
            Promise.reject()
          );
        }
        return r === gt.language_from
          ? (t &&
              t(
                n.map(function (e) {
                  return e.w;
                })
              ),
            Promise.resolve(
              n.map(function (e) {
                return e.w;
              })
            ))
          : new Promise(function (e, o) {
              Yt(n, r, {
                title: !1,
                cdn: !0
              })
                .then(function (n) {
                  if (!n || !n.to_words) throw n;
                  t && t(n.to_words), e(n.to_words);
                })
                .catch(function (e) {
                  o(e), t && t(null, e);
                });
            });
      },
      getBestAvailableLanguage: Ct,
      getAvailableLanguages: Et
    };
    return (
      xe(mt(), 'polyfillReady', function () {
        mo(document);
        var e = (function (e) {
          var t = qo(e);
          if (!t) return null;
          var n = t
            .exec(e.src)
            .groups.params.split('&')
            .map(function (e) {
              var t = e.split('='),
                n = t[0],
                r = t[1];
              try {
                return [n, decodeURIComponent(r)];
              } catch (e) {
                return [n, r];
              }
            })
            .reduce(
              function (e, t) {
                var n,
                  r = t[0],
                  o = t[1];
                return Object.assign({}, e, (((n = {})[r] = 'true' === o || ('false' !== o && o)), n));
              },
              {
                api_key: ''
              }
            );
          return n.api_key ? n : null;
        })(
          document.currentScript ||
            [].concat(document.scripts).find(function (e) {
              return qo(e);
            })
        );
        e && Ho.initialize(e);
      }),
      Ho
    );
  })()`;
