/* eslint-disable camelcase */
export const mapUpdateMeUser = ({ name, phone, birthdate, exclusiveInformation, acceptPoll, idNumber }) => ({
  name,
  phone,
  birthdate,
  subscription_attributes: { accepted: exclusiveInformation },
  accept_poll: acceptPoll,
  id_number: idNumber || null
});

export function isUserComplete(user) {
  return user.email && user.name && user.phone && user.accept_privacy_policies !== null;
}

export function mapUpdateUserPasswordless(user) {
  return {
    user: {
      name: user.name,
      phone: user.phone,
      accept_poll: !user.acceptPoll,
      privacy_policies: {
        accept: user.acceptPrivacyPolicies
      },
      subscription_attributes: {
        accepted: user.subscription
      }
    }
  };
}
