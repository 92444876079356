import i18next from 'i18next';

import { buildPizzaSections } from '~constants/products';

i18next.addResources('es_CL', 'PizzaCreation', {
  makePizza: 'Arma tu pizza',
  pizzaByHalves: 'Pizza por mitades',
  selectYourPizzaBase: 'Selecciona la masa de tu pizza',
  base: 'Masa',
  bases: 'Tipos de masa',
  ingredients: 'Ingredientes',
  half: 'Mitad {{ half }}',
  pickMoreIngredients: 'Añade más ingredientes',
  selectIngredients: 'Selecciona los ingredientes',
  selectSizeError: 'Debes elegir una opción',
  selectCrustError: 'Debes elegir una opción',
  [buildPizzaSections.CRUST]: 'Tipo de masa',
  [buildPizzaSections.SIZE]: 'Tamaño',
  [buildPizzaSections.SIZE_CRUST]: 'Tamaño y tipo de masa',
  [buildPizzaSections.BASE_CHEESE]: 'Queso',
  [buildPizzaSections.BASE_SAUCE]: 'Salsa base',
  [buildPizzaSections.EXTRA_SAUCE]: 'Cantidad de salsa',
  [buildPizzaSections.MEAT]: 'Carnes',
  [buildPizzaSections.VEGETABLE]: 'Vegetales',
  [buildPizzaSections.EXTRA_CHEESE]: 'Quesos especiales',
  [buildPizzaSections.SHOT]: 'Shots',
  [buildPizzaSections.PREMIUM]: 'Premium',
  [buildPizzaSections.NOT_INGREDIENT]: 'Personaliza tu pizza',
  required: 'Requerido',
  optional: 'Opcional'
});

i18next.addResources('es_CR', 'PizzaCreation', {
  makePizza: 'Crea tu pizza',
  [buildPizzaSections.PREMIUM]: 'Premium'
});

i18next.addResources('es_ES', 'PizzaCreation', {
  makePizza: 'Crea tu pizza',
  [buildPizzaSections.EXTRA_CHEESE]: 'Selección de quesos',
  [buildPizzaSections.PREMIUM]: 'Ingredientes premium'
});

i18next.addResources('es_PA', 'PizzaCreation', {
  makePizza: 'Crea tu pizza',
  [buildPizzaSections.PREMIUM]: 'Premium'
});

i18next.addResources('pt_PT', 'PizzaCreation', {
  makePizza: 'Crie sua pizza',
  pizzaByHalves: 'Pizza por metades',
  selectYourPizzaBase: 'Selecione a massa da sua pizza',
  base: 'Missa',
  bases: 'Tipos de massa',
  ingredients: 'Ingredientes',
  half: 'Metade {{ half }}',
  pickMoreIngredients: 'Adicione mais ingredientes',
  selectIngredients: 'Selecione os ingredientes',
  selectSizeError: '* Selecione o tamanho da pizza',
  selectCrustError: '* Selecione o tipo de massa',
  [buildPizzaSections.CRUST]: 'Tipo de massa',
  [buildPizzaSections.SIZE]: 'Tamanho',
  [buildPizzaSections.SIZE_CRUST]: 'Tamanho e tipo de massa',
  [buildPizzaSections.BASE_CHEESE]: 'Queijo',
  [buildPizzaSections.BASE_SAUCE]: 'Molho Base',
  [buildPizzaSections.EXTRA_SAUCE]: 'Quantidade de molho',
  [buildPizzaSections.MEAT]: 'Carnes',
  [buildPizzaSections.VEGETABLE]: 'Legumes',
  [buildPizzaSections.EXTRA_CHEESE]: 'Seleção De Queijo',
  [buildPizzaSections.PREMIUM]: 'Premium',
  [buildPizzaSections.NOT_INGREDIENT]: 'Molho de alho e pepperoncini',
  required: 'Obrigatório',
  optional: 'Opcional'
});
