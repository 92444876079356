import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer } from 'redux-recompose';

import { SIZES_AND_CRUSTS_TARGETS } from './constants';
import { actions } from './actions';

const initialState = completeState({
  [SIZES_AND_CRUSTS_TARGETS.SIZES_AND_CRUSTS]: {}
});

const reducerDescription = {
  primaryActions: [actions.GET_SIZES_AND_CRUSTS]
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
