import i18next from 'i18next';

i18next.addResources('es_CL', 'CustomizableOptionList', {
  half1: 'Izquierda',
  half2: 'Derecha',
  whole: 'Normal',
  wholePizza: 'Toda la pizza',
  double: 'Doble'
});

i18next.addResources('es_ES', 'CustomizableOptionList', {
  half1: 'Izquierda',
  half2: 'Derecha',
  whole: 'Normal',
  wholePizza: 'Toda la pizza',
  double: 'Doble'
});

i18next.addResources('pt_PT', 'CustomizableOptionList', {
  half1: 'Deixou',
  half2: 'Direita',
  whole: 'Normal',
  double: 'Duplo'
});
