import api, { getApiWithExternalUserId } from '~config/api';

const PapaPointsService = {
  getLoyalties: () => api.get('/v1/loyalties'),
  getLoyaltiesRedeem: () => api.get('/v1/order_items/redeems'),
  getLoyaltyLevels: async externalUserId => {
    const apiWithExternalUserId = externalUserId?.externalUserId
      ? getApiWithExternalUserId(externalUserId)
      : api;
    const response = await apiWithExternalUserId.get('/v1/loyalty_levels');
    return response;
  }
};

export default PapaPointsService;
