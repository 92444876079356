import i18next from 'i18next';

i18next.addResources('es_CL', 'HelpCenter', {
  pagePath: '/centro-de-ayuda',
  pageTitle: "Preguntas Frecuentes | Papa John's Chile",
  pageDescription:
    "Dudas, consultas, sugerencias y toda la información necesaria sobre las pizzerías Papa John's de Chile.",
  welcome: "Preguntas Frecuentes - FAQ's",
  question: '¿En qué podemos ayudarte?',
  placeholderSearch: 'Buscar...',
  helpTopics: 'Temas de ayuda',
  back: 'Volver'
});

i18next.addResources('es_ES', 'HelpCenter', {
  pagePath: '/centro-de-ayuda',
  pageTitle: 'Centro de Ayuda | Papa Johns España',
  pageDescription:
    "Dudas, consultas, sugerencias y toda la información necesaria sobre las pizzerías Papa John's en España.",
  welcome: "Preguntas Frecuentes - FAQ's",
  question: '¿En qué podemos ayudarte?',
  placeholderSearch: 'Buscar...',
  helpTopics: 'Temas de ayuda',
  back: 'Volver'
});

i18next.addResources('pt_PT', 'HelpCenter', {
  pagePath: '/ajuda',
  pageTitle: "Centro de ajuda | Papa John's Portugal",
  pageDescription: "Encontra toda a informação relacionada a Papa John's Portugal",
  welcome: 'Bemvindo ao centro de ajuda',
  question: 'Em que te podemos ajudar ?',
  placeholderSearch: 'Pesquisar...',
  helpTopics: 'Tópicos de ajuda',
  back: 'Voltar'
});

i18next.addResources('es_PA', 'HelpCenter', {
  pageTitle: "Centro de ayuda | Papa John's Panama",
  pageDescription: "Encuentra toda la información relacionada a Papa John's Panama."
});

i18next.addResources('es_CR', 'HelpCenter', {
  pageTitle: "Centro de Ayuda | Papa John's Costa Rica",
  pageDescription:
    "Dudas, consultas, sugerencias y toda la información necesaria sobre las pizzerías Papa John's de Costa Rica."
});

i18next.addResources('es_GT', 'HelpCenter', {
  pageTitle: "Centro de ayuda | Papa John's Guatemala",
  pageDescription: "Encuentra toda la información relacionada a Papa John's Guatemala."
});
