import { arrayHasLength } from '~utils/array';

const getActualPriceWithPromo = (actualPriceWithOutPromo, suggestivePromo) => {
  if (suggestivePromo.discount > 0 && !suggestivePromo.price) {
    return actualPriceWithOutPromo - (actualPriceWithOutPromo * suggestivePromo.discount) / 100;
  }
  return (
    suggestivePromo.price.cents / 100 +
    suggestivePromo.matchedItems.reduce((acc, item) => acc + item.delta, 0)
  );
};

const getPriceDifference = (suggestivePromo, actualCartItems) => {
  const onlyMatchItemsInCart = suggestivePromo.matchedItems.map(item => item.menuableId);
  const actualPriceWithOutPromo = onlyMatchItemsInCart
    .map(menuId => actualCartItems.find(cartItem => cartItem.menuableId === menuId))
    .reduce((acc, item) => acc + item.price, 0);
  const actualPriceWithPromo = getActualPriceWithPromo(actualPriceWithOutPromo, suggestivePromo);
  return actualPriceWithOutPromo - actualPriceWithPromo;
};

const existsCheckedActualPromo = actualPromos =>
  actualPromos.some(promo => arrayHasLength(promo.backupItems));
const actualPromoIsChecked = actualPromo => arrayHasLength(actualPromo.backupItems);

export const refreshActualPromos = (actualPromos, suggestedPromos, cartItems) => {
  if (existsCheckedActualPromo(actualPromos)) {
    return actualPromos.filter(
      promo => actualPromoIsChecked(promo) && cartItems.find(cartItem => cartItem.offerId === promo.id)
    );
  }
  return suggestedPromos.map(promo => {
    const newActualPromo = {
      id: promo.id,
      name: promo.name,
      description: promo.description,
      price: promo.price,
      offerType: promo.offerType,
      matchedItems: promo.matchedItems,
      checked: false,
      backupItems: [],
      discount: promo.discount || null,
      savedPrice: getPriceDifference(promo, cartItems)
    };

    const actualPromo = actualPromos.find(actualPromoItem => actualPromoItem.id === promo.id);
    return actualPromo && actualPromoIsChecked(actualPromo) ? actualPromo : newActualPromo;
  });
};
