import { PRODUCT_CATEGORIES } from '~screens/Dashboard/screens/Home/components/Menu/constants';
import { CATEGORIES } from '~screens/Dashboard/screens/OtherPages/Ingredients/constants';
import { buildPizzaSections } from '~constants/products';

const PREFIX = 'ga-';
const BUTTON_PREFIX = `${PREFIX}btn-`;
const MODAL_PREFIX = `${PREFIX}modal-`;
const TITLE_PREFIX = `${PREFIX}title-`;
const CONTAINER_PREFIX = `${PREFIX}container-`;
const LINK_PREFIX = `${PREFIX}link-`;
const NAVBAR_PREFIX = `${PREFIX}navbar-`;
const COMPLETE_BUTTON_PREFIX = 'button-';

export const BRAND = 'PJ';
export const CALLCENTER_KEYWORDS = { agent: 'agentepj', agentCallCenter: 'agentecallcenter' };

export const ANALYTICS_IDS = {
  auth: {
    btnLogin: `${BUTTON_PREFIX}login`,
    btnRegister: `${BUTTON_PREFIX}register`,
    btnForgot: `${BUTTON_PREFIX}forgot`,
    btnCodeValidation: `${BUTTON_PREFIX}code-validation`,
    btnFacebook: `${BUTTON_PREFIX}login-facebook`,
    btnGoogle: `${BUTTON_PREFIX}login-google`
  },
  profile: {
    btnUpdatePassword: `${BUTTON_PREFIX}update-password`,
    btnAddAddress: `${BUTTON_PREFIX}add-address`,
    btnEditAddress: `${BUTTON_PREFIX}edit-address`
  },
  sectionTitles: {
    menu: {
      [PRODUCT_CATEGORIES.PIZZA]: `${TITLE_PREFIX}${PRODUCT_CATEGORIES.PIZZA}`,
      [PRODUCT_CATEGORIES.DESSERT]: `${TITLE_PREFIX}${PRODUCT_CATEGORIES.DESSERT}`,
      [PRODUCT_CATEGORIES.SIDE]: `${TITLE_PREFIX}${PRODUCT_CATEGORIES.SIDE}`,
      [PRODUCT_CATEGORIES.DRINK]: `${TITLE_PREFIX}${PRODUCT_CATEGORIES.DRINK}`,
      [PRODUCT_CATEGORIES.EXTRA]: `${TITLE_PREFIX}${PRODUCT_CATEGORIES.EXTRA}`,
      [PRODUCT_CATEGORIES.PROMO]: `${TITLE_PREFIX}${PRODUCT_CATEGORIES.PROMO}`,
      [PRODUCT_CATEGORIES.PIZZA_PREMIUM]: `${TITLE_PREFIX}${PRODUCT_CATEGORIES.PIZZA_PREMIUM}`,
      [PRODUCT_CATEGORIES.PIZZA_SPECIALTY]: `${TITLE_PREFIX}${PRODUCT_CATEGORIES.PIZZA_SPECIALTY}`
    },
    cart: {
      yourOrder: `${TITLE_PREFIX}your-order`
    },
    checkout: {
      delivery: `${TITLE_PREFIX}delivery`,
      pickUpAt: `${TITLE_PREFIX}pick-up-at`,
      personalData: `${TITLE_PREFIX}personal-data`,
      completePersonalData: `${TITLE_PREFIX}complete-personal-data`,
      suggestiveSells: `${TITLE_PREFIX}suggestive-sells`,
      crossSelling: `${TITLE_PREFIX}cross-selling`,
      paymentMethods: `${TITLE_PREFIX}payment-methods`,
      orderDetail: `${TITLE_PREFIX}order-detail`,
      summary: `${TITLE_PREFIX}summary`,
      electronicReceipt: `${TITLE_PREFIX}electronic-receipt`,
      bntAddPaymentCard: `${TITLE_PREFIX}add-payment-card`,
      btnSetTip: `${TITLE_PREFIX}set-tip`
    },
    stores: {
      searchStore: `${TITLE_PREFIX}search-store`
    },
    currentOrder: {
      detail: `${TITLE_PREFIX}order-detail`,
      storeDetail: `${TITLE_PREFIX}order-store-detail`
    },
    profile: {
      addresses: `${TITLE_PREFIX}profile-addresses`,
      coupons: `${TITLE_PREFIX}profile-coupons`,
      creditCards: `${TITLE_PREFIX}profile-creditCards`,
      companies: `${TITLE_PREFIX}profile-companies`,
      name: `${TITLE_PREFIX}profile-name`
    },
    contact: {
      title: `${TITLE_PREFIX}contact-title`
    },
    ingredients: {
      [CATEGORIES.MEAT]: `${TITLE_PREFIX}ingredients-${CATEGORIES.MEAT}`,
      [CATEGORIES.VEGETABLE]: `${TITLE_PREFIX}ingredients-${CATEGORIES.VEGETABLE}`,
      [CATEGORIES.EXTRA_CHEESE]: `${TITLE_PREFIX}ingredients-${CATEGORIES.EXTRA_CHEESE}`,
      [CATEGORIES.BASE]: `${TITLE_PREFIX}ingredients-${CATEGORIES.BASE}`,
      [CATEGORIES.PREMIUM]: `${TITLE_PREFIX}ingredients-${CATEGORIES.PREMIUM}`
    },
    helpCenter: {
      helpTopics: `${TITLE_PREFIX}help-topics`
    }
  },
  checkout: {
    cardPayment: `${CONTAINER_PREFIX}card-payment`,
    containerDispatchDetails: `${CONTAINER_PREFIX}dispatch-details`,
    containerPersonalData: `${CONTAINER_PREFIX}personal-data`,
    containerCompletePersonalData: `${CONTAINER_PREFIX}complete-personal-data`,
    containerSuggestiveSells: `${CONTAINER_PREFIX}suggestive-sells`,
    containerPaymentMethods: `${CONTAINER_PREFIX}payment-methods`,
    containerOrderDetail: `${CONTAINER_PREFIX}order-detail`,
    containerSummary: `${CONTAINER_PREFIX}summary`,
    containerModalActiveOrder: `${CONTAINER_PREFIX}active-orders`,
    btnBack: `${BUTTON_PREFIX}checkout-go-back`,
    btnModify: `${BUTTON_PREFIX}checkout-modify-dispatch`,
    btnLegal: `${BUTTON_PREFIX}checkout-legal`,
    btnConfirmOrder: `${BUTTON_PREFIX}checkout-confirm-order`,
    btnDecreaseTip: `${BUTTON_PREFIX}checkout-decrease-tip`,
    btnIncreaseTip: `${BUTTON_PREFIX}checkout-increase-tip`,
    electronicReceipt: `${CONTAINER_PREFIX}electronic-receipt`,
    btnSaveElectronicReceipt: `${BUTTON_PREFIX}checkout-save-electronic-receipt`,
    bntAddPaymentCard: `${BUTTON_PREFIX}checkout-add-card`,
    bntReceiptOrder: `${BUTTON_PREFIX}checkout-receipt-order`,
    btnCancelActiveOrders: `${BUTTON_PREFIX}checkout-receipt-order`,
    btnContinueActiveOrders: `${BUTTON_PREFIX}checkout-receipt-order`,
    btnGoToMyOrders: `${BUTTON_PREFIX}checkout-receipt-order`,
    btnGoToCheckoutMenu: `${COMPLETE_BUTTON_PREFIX}go-to-checkout-menu`,
    btnGoToQuickOrder: `${COMPLETE_BUTTON_PREFIX}go-to-quick-order`,
    btnGoToCheckout: `${COMPLETE_BUTTON_PREFIX}go-to-checkout`
  },
  links: {
    footer: {
      aboutUs: `${LINK_PREFIX}footer-about-us`,
      workWithUs: `${LINK_PREFIX}footer-work-with-us`,
      ingredients: `${LINK_PREFIX}footer-ingredients`,
      companyInternational: `${LINK_PREFIX}footer-company-international`,
      helpCenter: `${LINK_PREFIX}footer-help-center`,
      contact: `${LINK_PREFIX}footer-contact`,
      ticket: `${LINK_PREFIX}footer-ticket`,
      conditions: `${LINK_PREFIX}footer-conditions`,
      privacyPolicy: `${LINK_PREFIX}footer-privacy-policy`,
      ethicalChannel: `${LINK_PREFIX}footer-ethical-channel`,
      stores: `${LINK_PREFIX}footer-stores`,
      joinTheProgram: `${LINK_PREFIX}footer-join-the-program`,
      cookiesPolicy: `${LINK_PREFIX}footer-cookies-policy`,
      complaintsBook: `${LINK_PREFIX}footer-complaints-book`,
      officialStatement: `${LINK_PREFIX}footer-official-statement`,
      franchises: `${LINK_PREFIX}footer-franchises`,
      freeChickens: `${LINK_PREFIX}footer-free-chickens`,
      betterWorld: `${LINK_PREFIX}footer-better-world`,
      pizzas: `${LINK_PREFIX}footer-pizzas`,
      blog: `${LINK_PREFIX}footer-blog`,
      contactFormfranchises: `${LINK_PREFIX}franchises-footer-contact-form`,
      ethicalCode: `${LINK_PREFIX}-ethical-code`,
      legalDisclaimer: `${LINK_PREFIX}-legal-disclaimer`,
      complaintsChannel: `${LINK_PREFIX}-complaints-channel`,
      sacNumber: `${LINK_PREFIX}-sac-number`
    },
    carousel: {
      papaPoints: `${LINK_PREFIX}carousel-papa-points`
    },
    orders: {
      phone: `${LINK_PREFIX}order-store-phone`
    }
  },
  menu: {
    navSectionsContainer: `${CONTAINER_PREFIX}menu-nav-sections-container`
  },
  order: {
    containerStatus: `${CONTAINER_PREFIX}order-status`,
    containerOrderDetail: `${CONTAINER_PREFIX}order-products-detail`,
    containerStoreDetail: `${CONTAINER_PREFIX}order-store-detail`
  },
  smartBanner: 'ga-smartbanner',
  orderBuilder: {
    container: `${CONTAINER_PREFIX}order-builder`
  }
};

export const ANALYTICS_CLASSES = {
  auth: {
    btnLogout: `${BUTTON_PREFIX}action-logout`,
    btnLogin: `${BUTTON_PREFIX}action-login`,
    btnRegister: `${BUTTON_PREFIX}action-register`,
    btnForgot: `${BUTTON_PREFIX}action-forgot`
  },
  modals: {
    authorization: `${MODAL_PREFIX}authorization`,
    register: `${MODAL_PREFIX}register`,
    pizzaByHalves: `${MODAL_PREFIX}pizza-by-halves`,
    makePizza: `${MODAL_PREFIX}make-pizza`,
    combo: `${MODAL_PREFIX}combo`,
    changeAddress: `${MODAL_PREFIX}change-address`,
    pizzasTutorial: `${MODAL_PREFIX}pizzas-tutorial`,
    storeIsClosed: `${MODAL_PREFIX}store-is-closed`,
    missingItems: `${MODAL_PREFIX}missing-items`,
    orderConfirmation: `${MODAL_PREFIX}order-confirmation`,
    updatePassword: `${MODAL_PREFIX}update-password`,
    saveAddress: `${MODAL_PREFIX}save-address`,
    saveCompany: `${MODAL_PREFIX}save-company`,
    contactStoreAddress: `${MODAL_PREFIX}contact-store-address`,
    nextButton: `${BUTTON_PREFIX}modal-next-button`,
    buildPizza: {
      [buildPizzaSections.CRUST]: `${CONTAINER_PREFIX}build-pizza-crust`,
      [buildPizzaSections.SIZE]: `${CONTAINER_PREFIX}build-pizza-size`,
      [buildPizzaSections.BASE_CHEESE]: `${CONTAINER_PREFIX}build-pizza-base-cheese`,
      [buildPizzaSections.BASE_SAUCE]: `${CONTAINER_PREFIX}build-pizza-base-sauce`,
      [buildPizzaSections.EXTRA_SAUCE]: `${CONTAINER_PREFIX}build-pizza-extra-sauce`,
      [buildPizzaSections.MEAT]: `${CONTAINER_PREFIX}build-pizza-meat`,
      [buildPizzaSections.VEGETABLE]: `${CONTAINER_PREFIX}build-pizza-vegetable`,
      [buildPizzaSections.EXTRA_CHEESE]: `${CONTAINER_PREFIX}build-pizza-extra-cheese`,
      [buildPizzaSections.PREMIUM]: `${CONTAINER_PREFIX}build-pizza-premium`,
      specialitySelector: `${CONTAINER_PREFIX}build-pizza-speciality-selector`,
      complement: `${CONTAINER_PREFIX}select-complement`,
      complementSauce: `${CONTAINER_PREFIX}select-complement-sauce`
    },
    selectDispatchTime: `${MODAL_PREFIX}select-dispatch-time`,
    optimizedCart: `${MODAL_PREFIX}optimized-cart`,
    offerCompletion: `${MODAL_PREFIX}offer-completion`,
    orderRule: `${MODAL_PREFIX}order-rule`,
    orderReceipt: `${MODAL_PREFIX}order-receipt`,
    promotion: `${MODAL_PREFIX}promotion`,
    upSelling: `${MODAL_PREFIX}up-selling`
  },
  sectionTitles: {
    pastOrders: { title: `${TITLE_PREFIX}past-order` }
  },
  links: {
    stores: {
      phone: `${LINK_PREFIX}store-phone`,
      indications: `${LINK_PREFIX}store-indications`
    },
    general: {
      back: `${BUTTON_PREFIX}go-back`,
      next: `${BUTTON_PREFIX}go-next`,
      finish: `${BUTTON_PREFIX}finish`
    }
  },
  cart: {
    btnModifyProduct: `${BUTTON_PREFIX}cart-modify-product`,
    btnContinuePayment: `${BUTTON_PREFIX}cart-continue-payment`,
    btnContinuePaymentUpSelling: `${BUTTON_PREFIX}cart-continue-payment-up-selling-modal`,
    btnDecreaseProductQuantity: `${BUTTON_PREFIX}cart-decrease-product-quantity`,
    btnIncreaseProductQuantity: `${BUTTON_PREFIX}cart-increase-product-quantity`,
    btnCart: `${BUTTON_PREFIX}cart`
  },
  menu: {
    btnAddProduct: `${BUTTON_PREFIX}add-product`,
    btnAddProductPB: `${BUTTON_PREFIX}add-product-PB`,
    btnOpenPB: `${BUTTON_PREFIX}open-PB`,
    containerProduct: `${CONTAINER_PREFIX}product`,
    productQuantityBadge: `${PREFIX}menu-product-quantity-badge`,
    productNameTitle: `${PREFIX}menu-product-name-title`,
    productPrice: `${PREFIX}menu-product-price`,
    productVariant: `${PREFIX}menu-product-variant`,
    productDescription: `${PREFIX}menu-product-description`,
    btnSuggestiveSellPapaPoints: `${BUTTON_PREFIX}suggestive-papa-points`,
    btnSuggestiveSellMoney: `${BUTTON_PREFIX}suggestive-money`,
    productDiscount: `${PREFIX}menu-product-discount-percent`,
    productPriceDiscount: `${PREFIX}menu-product-discount-original-price`
  },
  checkout: {
    paymentMethod: `${BUTTON_PREFIX}checkout-payment-method`
  },
  navBar: {
    container: `${CONTAINER_PREFIX}navbar`,
    logo: `${NAVBAR_PREFIX}brand-logo`,
    menu: `${NAVBAR_PREFIX}menu`,
    papaPoints: `${NAVBAR_PREFIX}papa-points`,
    orders: `${NAVBAR_PREFIX}orders`,
    stores: `${NAVBAR_PREFIX}stores`,
    profile: `${NAVBAR_PREFIX}profile`,
    intercom: `${NAVBAR_PREFIX}intercom`,
    login: `${NAVBAR_PREFIX}login`,
    logout: `${NAVBAR_PREFIX}logout`,
    giftCard: `${NAVBAR_PREFIX}giftCard`
  }
};
