import i18next from 'i18next';

i18next.addResources('es_CL', 'MissingItems', {
  title: '¡Advertencia!',
  message:
    'Si cambias la opción de delivery/retiro en local, los siguientes items serán retirados de tu carrito',
  confirmMessage: '¿Seguro quieres hacer la modificación?',
  confirmButton: 'Confirmar Modificación',
  backToCatalog: 'Volverás al catálogo para continuar agregando productos.'
});

i18next.addResources('es_ES', 'MissingItems', {
  title: '¡Advertencia!',
  message:
    'Al cambiar la opción para recoger en tienda los siguientes productos serán eliminados de tu carrito.',
  confirmMessage: '¿Seguro que quieres hacer el cambio?',
  confirmButton: 'Confirmar'
});

i18next.addResources('pt_PT', 'MissingItems', {
  title: 'Aviso!',
  message: 'Ao alterar a opção para recolha na loja, os seguintes produtos serão removidos do teu carrinho.',
  confirmMessage: 'Tens a certeza de que queres fazer a alteração?',
  confirmButton: 'Confirme a modificação',
  backToCatalog: 'Voltarás ao catálogo para continuar a adicionar produtos.'
});

i18next.addResources('es_CR', 'MissingItems', {
  message:
    'Si cambias la opción de entrega/retiro en local, los siguientes items serán retirados de tu carrito',
  confirmMessage: '¿Seguro querés hacer la modificación?'
});

i18next.addResources('es_PA', 'MissingItems', {
  message:
    'Si cambias la opción de delivery/retiro en restaurante, los siguientes items serán retirados de tu carrito'
});
