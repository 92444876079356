import { createTypes } from 'redux-recompose';

import { SMARTBANNER_COOKIE_TARGET } from './constants';

export const actions = createTypes(['SET_SMARTBANNER_COOKIE'], '@@SMARTBANNER_COOKIE');

export const actionCreators = {
  setSmartbannerCookie: value => ({
    type: actions.SET_SMARTBANNER_COOKIE,
    target: SMARTBANNER_COOKIE_TARGET,
    payload: value
  })
};
