import { completeTypes, createTypes } from 'redux-recompose';

import { UPSELLING_TARGETS } from './constants';

const completedTypes = completeTypes([], ['SET_UPSELLING_VARIANTS']);

export const actions = createTypes(completedTypes, '@@UPSELLING');

export const actionCreators = {
  setUpsellingVariants: upsellingVariants => ({
    type: actions.SET_UPSELLING_VARIANTS,
    target: UPSELLING_TARGETS.VARIANTS,
    payload: upsellingVariants
  }),
  updateVariants: update => (dispatch, getState) => {
    const state = getState();
    const upsellingVariants = state.upselling[UPSELLING_TARGETS.VARIANTS];
    dispatch(actionCreators.setUpsellingVariants(update(upsellingVariants)));
  },
  clearVariants: () => dispatch => dispatch(actionCreators.setUpsellingVariants({}))
};

export default actionCreators;
