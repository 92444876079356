import i18next from 'i18next';

i18next.addResources('es_CL', 'PopUpRetargeting', {
  title: 'No te vayas!!!',
  subtitle: 'Retoma tu compra con este cupón de descuento',
  couponOff: '10% OFF',
  couponCodeButton: 'Copiar cupón',
  couponCodeCopied: 'Cupón copiado'
});

i18next.addResources('pt_PT', 'PopUpRetargeting', {
  title: 'Não se vão embora!',
  subtitle: 'Retome a sua compra com este cupão de desconto',
  couponOff: '10% OFF',
  couponCodeButton: 'Copiar cupão',
  couponCodeCopied: 'Cupão copiado'
});
