/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { bool } from 'prop-types';

import { arrayHasLength } from '~utils/array';
import { useDropDown } from '~hooks/useDropDown';
import { createPromoSellingEvent } from '~gtmEvents/promoSelling';

import { ActualPromos } from './Components/ActualPromos/ActualPromos';
import CollapsibleWrapperPromo from './Components/CollapsibleWrapperPromo/CollapsibleWrapperPromo';
import styles from './styles.module.scss';
import { usePromoHandling } from './hooks';
import { ApplyPromo } from './Components/ApplyPromo';

function PromoSelling({ isCheckout }) {
  const actualPromos = useSelector(state => state.promoSelling.actualPromos);
  const handleCheckPromo = usePromoHandling(actualPromos, isCheckout);
  const { isOpen, toggle } = useDropDown();

  useEffect(() => {
    if (isOpen) {
      createPromoSellingEvent(actualPromos[0], isCheckout);
    }
  }, [isOpen]);

  const onlyApplyPromo = actualPromosList => {
    if (arrayHasLength(actualPromosList)) {
      if (actualPromosList[0].checked) {
        return <ApplyPromo actualPromo={actualPromosList[0]} handleCheckPromo={handleCheckPromo} />;
      }
      return (
        <CollapsibleWrapperPromo
          title={t('PromoSelling:title')}
          className="m-bottom-4"
          isCheckout={isCheckout}
          isOpen={isOpen}
          toggle={toggle}
          titleContainerClassName={styles.titleContainer}
          contentContainerClassName={styles.contentContainer}>
          <ActualPromos actualPromos={actualPromosList} handleCheckPromo={handleCheckPromo} />
        </CollapsibleWrapperPromo>
      );
    }
    return null;
  };

  return onlyApplyPromo(actualPromos);
}

PromoSelling.propTypes = {
  isCheckout: bool
};

export default PromoSelling;
