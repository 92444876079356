import { shape, string, number, oneOfType } from 'prop-types';

export const userPropTypes = shape({
  id: number,
  name: string,
  phone: string,
  email: string,
  externalId: string,
  quickOrderId: oneOfType([string, number]),
  invitationCode: string,
  usedCode: number,
  sailplayInformation: shape({
    id: number,
    confirmedPoints: number,
    totalPoints: number,
    spentPoints: number,
    unconfirmedPoints: number,
    createdAt: string,
    updatedAt: string
  })
});

export const profileFields = shape({
  name: string,
  phone: string,
  password: string,
  newPassword: string
});
