import { COUNTRIES, CURRENT_COUNTRY_ID } from './environment';

export const DEFAULT_INGREDIENT_QUANTITY = 1;
export const DEFAULT_INGREDIENT_ACTION_TYPE = 'add';

export const MAX_INGREDIENT_SELECTION = 10;

export const OFFER_TYPES = {
  Combo: 'Combo',
  Offer: 'Offer'
};

export const INGREDIENT_ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove'
};

const INGREDIENT_SETTINGS_BY_COUNTRY = {
  [COUNTRIES.cl]: {
    offer: {
      min: 1,
      max: 10
    },
    buildPizza: {
      min: 1,
      max: 10
    },
    pizza: {
      min: 1,
      max: 10
    }
  },
  [COUNTRIES.es]: {
    offer: {
      min: 1,
      max: 10
    },
    buildPizza: {
      min: 1,
      max: 10
    },
    pizza: {
      min: 1,
      max: 10
    }
  },
  [COUNTRIES.pt]: {
    offer: {
      min: 1,
      max: 10
    },
    buildPizza: {
      min: 1,
      max: 10
    },
    pizza: {
      min: 1,
      max: 10
    }
  },
  [COUNTRIES.pa]: {
    offer: {
      min: 1,
      max: 10
    },
    buildPizza: {
      min: 1,
      max: 10
    },
    pizza: {
      min: 1,
      max: 10
    }
  },
  [COUNTRIES.cr]: {
    offer: {
      min: 1,
      max: 10
    },
    buildPizza: {
      min: 1,
      max: 10
    },
    pizza: {
      min: 1,
      max: 10
    }
  }
};

export const INGREDIENTS_SETTINGS = INGREDIENT_SETTINGS_BY_COUNTRY[CURRENT_COUNTRY_ID];

// Trick for spain and portugal to send always base sauce if the pizza has been modified
// If true, sets baseSauce as null; if false, sets the baseSauce from the pizza menuable element
const SEND_BASE_SAUCE_BY_COUNTRY = {
  [COUNTRIES.cl]: false,
  [COUNTRIES.es]: true,
  [COUNTRIES.pt]: true,
  [COUNTRIES.pa]: false,
  [COUNTRIES.cr]: false,
  [COUNTRIES.gt]: false
};

export const SEND_BASE_SAUCE = SEND_BASE_SAUCE_BY_COUNTRY[CURRENT_COUNTRY_ID];
