/* eslint-disable max-lines */
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_INGREDIENT_QUANTITY } from '~constants/buildPizza';
import { productTypes, pizzaCategories } from '~constants/products';
import { CUSTOM_HALF_OPTIONS as HALVES } from '~components/CustomizableOption/constants';
import { isBase, BASE_PRESELECTED_SAUCE } from '~utils/pizza';
import { sendGTMAddtoCartEvent } from '~utils/analytics';
import { isEurope } from '~utils/regions';

import {
  INGREDIENT_ACTIONS,
  INGREDIENT_API_TEMPLATE,
  HALF_TO_SIDE,
  BASE_PIZZA_DATA_TARGET,
  QUANTITY_MAX
} from './constants';

const dontFilterBaseSauce = baseIngredient => {
  const preSelectedSauce = BASE_PRESELECTED_SAUCE.includes(baseIngredient.name);
  const isSetToBeBaseIngredient = baseIngredient.base;
  return isEurope() && preSelectedSauce && isSetToBeBaseIngredient;
};

export const getInverseHalf = half => (half === HALVES.half1 ? HALVES.half2 : HALVES.half1);

// If an ingredient is in both halves, it removes it from both halves and instead adds it as "whole"
export const flattenIngredientsWithHalves = ingredients =>
  ingredients.reduce((result, ingredient) => {
    // Merging ingredients with same id and half to 1 ingredient adding quantities
    const flatIngredients = ingredients.reduce((flatHalf, currentIngredient) => {
      const existingIngredient = flatHalf.find(
        flatIngredient =>
          currentIngredient.id === flatIngredient.id && currentIngredient.half === flatIngredient
      );

      if (existingIngredient) {
        flatHalf.push({
          ...currentIngredient,
          quantity: currentIngredient.quantity + existingIngredient.quantity
        });
      } else {
        flatHalf.push({
          ...currentIngredient,
          quantity: currentIngredient.quantity
        });
      }

      return flatHalf;
    }, []);

    // Checking if ingredient is in both halves
    const ingredientInBothHalves = flatIngredients.find(
      i =>
        i.id === ingredient.id &&
        ((i.half === HALVES.half1 && ingredient.half === HALVES.half2) ||
          (i.half === HALVES.half2 && ingredient.half === HALVES.half1)) &&
        i.quantity === ingredient.quantity
    );

    // Ignoring ingredient if it's in both halves but it was already added
    if (ingredientInBothHalves && result.find(i => i.id === ingredientInBothHalves.id)) {
      return result;
    }

    const mergedIngredient = ingredientInBothHalves ? { ...ingredient, half: HALVES.whole } : ingredient;
    const alreadyAddedIndex = result.findIndex(
      i => i.id === mergedIngredient.id && mergedIngredient.half === i.half
    );

    if (alreadyAddedIndex === -1) {
      result.push(mergedIngredient);
    } else {
      result[alreadyAddedIndex] = {
        ...result[alreadyAddedIndex],
        quantity: result[alreadyAddedIndex].quantity + 1
      };
    }

    return result;
  }, []);

export const removeHalfIngredients = (ingredients, half) =>
  ingredients?.reduce((result, ingredient) => {
    if (ingredient.half === half) {
      return result;
    }
    const newHalf = ingredient.half === HALVES.whole ? getInverseHalf(half) : ingredient.half;

    result.push({ ...ingredient, half: newHalf });

    return result;
  }, []) || [];

export const getSizesAndCrusts = (basePizza, sizesAndCrusts) => {
  const crusts = [];
  const sizes = [];

  basePizza?.variants?.forEach(({ menuable: { size, crust } }) => {
    const sizeIsInArray = sizes.find(_size => _size.text === sizesAndCrusts.sizes?.[size]);
    const crustIsInArray = crusts.find(_crust => _crust.text === sizesAndCrusts.crusts?.[crust]);

    if (!sizeIsInArray) {
      sizes.push({ id: size, text: sizesAndCrusts.sizes?.[size] });
    }
    if (!crustIsInArray) {
      crusts.push({ id: crust, text: sizesAndCrusts.crusts?.[crust] });
    }
  });

  return { crust: crusts, size: sizes };
};

export const getRestrictionsFromPizza = basePizza => {
  if (!basePizza?.variants?.length) {
    return null;
  }

  return basePizza.variants.reduce((restrictions, variant) => {
    const variantBase = variant.menuable || variant;

    return {
      ...restrictions,
      [variantBase.size]: [...(restrictions[variantBase.size] || []), variantBase.crust]
    };
  }, {});
};

export const getBasePizzaById = (menu, id, helpers) =>
  menu.find(({ menuableId, menuableType }) => menuableId === id && menuableType === productTypes.PIZZA) ||
  helpers[pizzaCategories.MAKE_YOUR_PIZZA];

export const getBasePizzaByCategory = (menu, category) =>
  menu.find(
    ({ menuable, menuableType }) => menuable.category === category && menuableType === productTypes.PIZZA
  );

export const getBasePizzaVariant = ({ crust, size }, variants) =>
  variants.find(variant => variant.menuable.crust === crust && variant.menuable.size === size);

export const getIngredientsByCategory = (
  pizzaIngredients,
  pizzaHalves,
  allIngredients,
  initialValues = {}
) => {
  const joinedIngredients = [
    ...(pizzaIngredients || []).map(i => i && { quantity: 1, side: HALVES.ALL, ...i }),
    ...(pizzaHalves?.left?.ingredients || []).map(i => i && { quantity: 1, side: HALVES.LEFT, ...i }),
    ...(pizzaHalves?.right?.ingredients || []).map(i => i && { quantity: 1, side: HALVES.RIGHT, ...i })
  ];

  return (joinedIngredients || []).reduce(
    (acc, item) => {
      const ingredient = item.category ? item : allIngredients?.find(i => i.id === item.id);

      acc[ingredient.category] = (acc[ingredient.category] || []).concat(item);

      return acc;
    },
    { ...initialValues }
  );
};

// eslint-disable-next-line max-params
const buildHalf = (half, halves, ingredient, ingredientHalf, variants) => {
  const isIngredientInSide = ingredientHalf === half || ingredientHalf === HALVES.WHOLE;
  const side = HALF_TO_SIDE[half];
  const ingredients = (isIngredientInSide || halves?.[side]?.ingredients) && {
    ingredients: isIngredientInSide
      ? [...(halves?.[side]?.ingredients || []), ingredient]
      : halves?.[side]?.ingredients
  };

  return {
    ...halves?.[side],
    name: variants[half].name,
    menuableId: variants[half].menuableId,
    menuableType: productTypes.PIZZA_VARIANT,
    ...ingredients
  };
};

export const processDefaultIngredients = (defaultIngredients, ingredients) => {
  // selectedIngredients are the ingredients selected that aren't default ingredients
  const selectedIngredients =
    ingredients.reduce((finalIngredients, selectedIngredient) => {
      const defaultIngredientIndex = defaultIngredients?.findIndex(
        i =>
          i.id === selectedIngredient.id &&
          (i.half === HALVES.whole ||
            selectedIngredient.half === i.half ||
            selectedIngredient.half === HALVES.whole)
      );
      const defaultIngredient = defaultIngredients[defaultIngredientIndex];

      if (
        defaultIngredient &&
        (defaultIngredient.half === HALVES.whole || defaultIngredient.half === selectedIngredient.half)
      ) {
        if (selectedIngredient.quantity === 1) {
          return finalIngredients;
        }

        finalIngredients.push({ ...selectedIngredient, quantity: 1 });

        return finalIngredients;
      }
      if (defaultIngredient && selectedIngredient.half === HALVES.whole) {
        finalIngredients.push({ ...selectedIngredient, half: getInverseHalf(defaultIngredient.half) });

        return finalIngredients;
      }

      finalIngredients.push(selectedIngredient);

      return finalIngredients;
    }, []) || [];

  // Removing deselected defaultIngredients
  defaultIngredients.forEach(ingredient => {
    const selectedDefaultIngredient = ingredients.find(
      selectedIngredient =>
        selectedIngredient.id === ingredient.id &&
        // If defaultIngredient is in whole pizza, this ingredient is selected
        (ingredient.half === HALVES.whole ||
          // If defaultIngredient isn't in whole pizza, check that it matches the half of the selectedIngredient
          // Or that the selected ingredient is in the whole pizza
          selectedIngredient.half === ingredient.half ||
          selectedIngredient.half === HALVES.whole)
    );

    if (!selectedDefaultIngredient) {
      // If the default ingredient can't be found in the selected ingredients in that half, remove it
      if (!ingredient.base) {
        selectedIngredients.push({
          ...ingredient,
          action: INGREDIENT_ACTIONS.REMOVE,
          quantity: undefined
        });
      }
    } else if (
      selectedDefaultIngredient.half === HALVES.whole &&
      ingredient.half !== HALVES.whole &&
      selectedDefaultIngredient.quantity > 1
    ) {
      // If the default ingredient could be found in selected ingredients and it's in the whole pizza
      // the selected ingredient was selected for only one half and the quantity is more than 1
      // I remove one quantity in the half that has the default ingredient
      selectedIngredients.push({
        ...ingredient,
        // Remove one quantity only in the half that has the ingredient as default, as the ingredient is x2
        quantity: selectedDefaultIngredient.quantity - 1,
        half: ingredient.half,
        action: INGREDIENT_ACTIONS.ADD
      });
    } else if (selectedDefaultIngredient.half === HALVES.whole) {
      // If the ingredient was selected for the whole pizza and its default in the whole pizza
      // or its quantity is 1, I don't have to change anything.
    } else if (ingredient.half === HALVES.whole) {
      // If the ingredient could be found, it's in a half and the base pizza is the same for both halves
      // search for the inverse half and remove if from there
      const inverseHalf = getInverseHalf(selectedDefaultIngredient.half);
      const otherHalfSelectedIngredient = ingredients.find(
        selectedIngredient =>
          selectedIngredient.id === ingredient.id && selectedIngredient.half === inverseHalf
      );

      if (otherHalfSelectedIngredient) {
        return;
      }
      selectedIngredients.push({
        ...ingredient,
        action: INGREDIENT_ACTIONS.REMOVE,
        quantity: undefined,
        half: inverseHalf
      });
    }
  });

  return selectedIngredients;
};

export const getPizzaCompositionForCart = (ingredients, pizzaVariantByHalf) => {
  const initialComposition =
    pizzaVariantByHalf[HALVES.half1].menuableId === pizzaVariantByHalf[HALVES.half2].menuableId
      ? {}
      : {
          halves: {
            left: {
              name: pizzaVariantByHalf.half1.name,
              menuableId: pizzaVariantByHalf.half1.menuableId,
              menuableType: productTypes.PIZZA_VARIANT
            },
            right: {
              name: pizzaVariantByHalf.half2.name,
              menuableId: pizzaVariantByHalf.half2.menuableId,
              menuableType: productTypes.PIZZA_VARIANT
            }
          }
        };

  return (ingredients || []).reduce(
    (
      allIngredients,
      { id, name, quantity, half = HALVES.whole, action = INGREDIENT_ACTIONS.AD, freeIngredient = null }
    ) => {
      const withFreeIngredient = freeIngredient ? { freeIngredient } : {};
      const ingredient = { id, name, quantity, action, ...withFreeIngredient };

      if (half === HALVES.whole) {
        allIngredients.ingredients = (allIngredients?.ingredients || []).concat(ingredient);
      } else {
        allIngredients.halves = {
          left: buildHalf(HALVES.half1, allIngredients.halves, ingredient, half, pizzaVariantByHalf),
          right: buildHalf(HALVES.half2, allIngredients.halves, ingredient, half, pizzaVariantByHalf)
        };
      }

      return allIngredients;
    },
    { ...initialComposition }
  );
};

// Repeats removed ingredients in both halves only if the two halves have the same base pizza
export const verifyRemoveIngredient = pizza => {
  if (pizza.halves) {
    const removedIngredients = pizza.ingredients?.filter(
      ingredient => ingredient.action === INGREDIENT_ACTIONS.REMOVE
    );

    if (removedIngredients?.length) {
      const filteredIngredients = pizza.ingredients?.filter(
        ingredient => ingredient.action !== INGREDIENT_ACTIONS.REMOVE
      );
      const newPizza = {
        ...(filteredIngredients.length ? { ingredients: filteredIngredients } : {}),
        halves: {
          left: {
            ...pizza.halves.left,
            ingredients: (pizza.halves.left.ingredients || []).concat(removedIngredients)
          },
          right: {
            ...pizza.halves.right,
            ingredients: (pizza.halves.right.ingredients || []).concat(removedIngredients)
          }
        }
      };

      return newPizza;
    }
  }

  return pizza;
};

export const completeIngredientData = ingredient => ({
  ...INGREDIENT_API_TEMPLATE,
  ...ingredient
});

export const flattenIngredientsNames = ingredients =>
  ingredients
    .map(ingredient =>
      ingredient.quantity > 1 ? `${ingredient.name} x${ingredient.quantity}` : ingredient.name
    )
    .join(', ');

export const getSelectedVariantId = (basePizza, selectedSize, selectedCrust) =>
  basePizza?.variants?.find(
    ({ menuable: { crust, size } }) => size === selectedSize && crust === selectedCrust
  )?.menuableId;

export const buildPizzaForCart = (
  { bases, ingredients, allElements, defaultIngredients, leftHalfId, rightHalfId, ...pizzaData },
  menu,
  helpers,
  menuableId
) => {
  // Si la salsa de tomate esta como default

  const allIngredients = [
    ...(ingredients || []),
    ...(bases?.filter(b => !b.base || dontFilterBaseSauce(b)) || [])
  ].map(ingredient => completeIngredientData(ingredient));
  const { size, crust } = pizzaData;
  const basePizzaByHalf = {
    [HALVES.half1]: getBasePizzaById(menu, leftHalfId || pizzaData.id, helpers),
    [HALVES.half2]: getBasePizzaById(menu, rightHalfId || pizzaData.id, helpers)
  };
  const pizzaVariantByHalf = {
    [HALVES.half1]: {
      menuableId: getSelectedVariantId(basePizzaByHalf[HALVES.half1], size, crust),
      name: basePizzaByHalf[HALVES.half1]?.menuable?.name
    },
    [HALVES.half2]: {
      menuableId: getSelectedVariantId(basePizzaByHalf[HALVES.half2], size, crust),
      name: basePizzaByHalf[HALVES.half2]?.menuable?.name
    }
  };

  const processedIngredients = processDefaultIngredients(defaultIngredients, allIngredients);
  const pizzaComposition = getPizzaCompositionForCart(processedIngredients, pizzaVariantByHalf);
  // TODO: check if this is needed.
  const verifiedPizzaComposition = verifyRemoveIngredient(pizzaComposition);
  const basePizza = verifiedPizzaComposition?.halves
    ? getBasePizzaByCategory(menu, pizzaCategories.BY_HALVES) || helpers[pizzaCategories.BY_HALVES]
    : getBasePizzaById(menu, pizzaData.id, helpers);

  return {
    size: pizzaData.size,
    crust: pizzaData.crust,
    allElements,
    menuableType: productTypes.PIZZA_VARIANT,
    menuableId: menuableId || getSelectedVariantId(basePizza, size, crust),
    quantity: DEFAULT_INGREDIENT_QUANTITY,
    ...{
      ...verifiedPizzaComposition
    }
  };
};

export const getPizzaCartItem = (state, stepId, pizzaId = null, variantId) => {
  const {
    home: { menu },
    customPizza: { selections, helpers, config }
  } = state;

  const { ingredients, bases, crusts, sizes, allElements } = selections?.[stepId] || {};
  const basePizzaData = config[BASE_PIZZA_DATA_TARGET];
  const defaultIngredients = Object.keys(basePizzaData?.defaultIngredients || []).reduce(
    (accumulator, pizzaIdKey) => {
      if (
        (basePizzaData.rightHalfId &&
          basePizzaData.leftHalfId &&
          basePizzaData.rightHalfId.toString() !== pizzaIdKey &&
          basePizzaData.leftHalfId.toString() !== pizzaIdKey) ||
        (pizzaId && pizzaId.toString() !== pizzaIdKey)
      ) {
        return accumulator;
      }
      const ingredientsToReduce =
        Object.keys(basePizzaData?.defaultIngredients[pizzaIdKey]) ||
        Object.keys(basePizzaData?.defaultIngredients);

      const pizzaIdIngredients = ingredientsToReduce?.reduce((result, halfKey) => {
        const halfIngredients =
          basePizzaData?.defaultIngredients?.[pizzaId || pizzaIdKey]?.[halfKey]?.map(i => ({
            ...i,
            half: halfKey
          })) || [];

        result.push(...halfIngredients);

        return flattenIngredientsWithHalves(result);
      }, []);

      accumulator.push(...pizzaIdIngredients);

      return accumulator;
    },
    []
  );

  return buildPizzaForCart(
    {
      bases,
      ingredients,
      crust: crusts?.[0]?.id,
      size: sizes?.[0]?.id,
      allElements,
      id:
        variantId ||
        pizzaId ||
        (basePizzaData?.leftHalfId === basePizzaData?.rightHalfId && basePizzaData?.rightHalfId) ||
        config[BASE_PIZZA_DATA_TARGET]?.id,
      leftHalfId: basePizzaData?.leftHalfId,
      rightHalfId: basePizzaData?.rightHalfId,
      defaultIngredients: flattenIngredientsWithHalves(defaultIngredients)
    },
    menu,
    helpers,
    variantId
  );
};

export const getOfferCartItem = (state, offerItem) => {
  const {
    offers: { steps }
  } = state;

  const groupProducts = offerItem.groupProducts.map((group, stepIndex) => {
    const stepId = group?.stepId || `step${stepIndex + 1}`;
    return group.menuableType === productTypes.PIZZA_VARIANT
      ? {
          ...getPizzaCartItem(state, stepId, steps[stepId]),
          offerGroupId: group.offerGroupId,
          stepId: group.stepId
        }
      : group;
  });

  return {
    ...offerItem,
    groupProducts,
    uuid: uuidv4()
  };
};

export const inverseHalfElement = element => {
  if (element.half === HALVES.whole) {
    return element;
  }
  const half = element.half === HALVES.half1 ? HALVES.half2 : HALVES.half1;

  return { ...element, half };
};

export const getSelectedMenuableIngredients = (menuable, allIngredients, currentIngredients) => {
  if (!menuable.ingredients && !menuable.halves) {
    return { menuableIngredients: [], menuableBases: [] };
  }

  const mapIngredient = (ingredient, half) => {
    const configIngredient = allIngredients.find(i => i.id === ingredient.id);

    return {
      ...configIngredient,
      ...ingredient,
      half
    };
  };

  const wholeIngredients =
    menuable.ingredients
      ?.filter(
        ingredient =>
          !currentIngredients?.find(current => current.id === ingredient.id && current.half === HALVES.whole)
      )
      .map(ingredient => mapIngredient(ingredient, HALVES.whole)) || [];

  const half1Ingredients =
    menuable.halves?.left?.ingredients?.map(ingredient => mapIngredient(ingredient, HALVES.half1)) || [];

  const half2Ingredients =
    menuable.halves?.right?.ingredients?.map(ingredient => mapIngredient(ingredient, HALVES.half2)) || [];

  const ingredients = [...wholeIngredients, ...half1Ingredients, ...half2Ingredients];

  return {
    menuableIngredients: ingredients.filter(i => !isBase(i)),
    menuableBases: ingredients.filter(i => isBase(i))
  };
};

// Return an object to reset the previously selected half
// This is used when modifying PizzaByHalves and changing a half's base pizza
export const resetSelectedPizzaHalfIdData = (defaultIngredients, half) => {
  if (half === HALVES.whole) {
    return {};
  }
  const previousSelectedPizzaIdKey =
    defaultIngredients &&
    Object.keys(defaultIngredients).find(pizzaIdKey =>
      Object.keys(defaultIngredients[pizzaIdKey]).find(
        halfKey => halfKey === half && defaultIngredients[pizzaIdKey]?.[halfKey]?.length > 0
      )
    );

  return previousSelectedPizzaIdKey
    ? {
        [previousSelectedPizzaIdKey]: {
          ...defaultIngredients[previousSelectedPizzaIdKey],
          [half]: []
        }
      }
    : null;
};

export const completeWithDefaultBases = (bases, config, half) => {
  const ingredients = Object.values(config.ingredients);
  const preSelectedSauce = ingredients.find(baseSauce => BASE_PRESELECTED_SAUCE.includes(baseSauce.name));
  const newBases = [...bases];
  if (preSelectedSauce && isEurope) {
    newBases.push({ ...preSelectedSauce, half });
  }

  const defaultBases = ingredients.filter(i => i.base);

  defaultBases.forEach(base => {
    const baseIndex = newBases.findIndex(b => b.category === base.category && b.half === half);

    if (baseIndex === -1) {
      newBases.push({ ...base, half });
    }
  });

  return newBases;
};

export const ingredientsFromPizzaData = (data, config, half) => {
  const ingredients = data?.ingredients
    .filter(i => !isBase(i))
    .map(i => ({
      ...completeIngredientData(i),
      half
    }));

  const currentBases = data?.ingredients
    .filter(i => isBase(i))
    .map(i => ({
      ...completeIngredientData(i),
      half
    }));
  const bases = completeWithDefaultBases(currentBases, config, half);

  return { ingredients, bases };
};

// eslint-disable-next-line id-length
export const addDefaultIngredientsToCartItem = (ingredients, pizzaDefaultIngredients, half) => {
  const newIngredients = [...(ingredients || [])].map(i => ({ ...i, half }));

  const untouchedDefaultIngredients = [];

  (pizzaDefaultIngredients || []).forEach(defaultIngredient => {
    const existingIngredientIndex = newIngredients.findIndex(i => defaultIngredient.id === i.id);

    if (existingIngredientIndex === -1) {
      untouchedDefaultIngredients.push({ ...defaultIngredient, half });
    } else if (newIngredients[existingIngredientIndex].action !== INGREDIENT_ACTIONS.remove) {
      newIngredients[existingIngredientIndex] = {
        ...newIngredients[existingIngredientIndex],
        quantity: newIngredients[existingIngredientIndex].quantity + defaultIngredient.quantity
      };
    }
  });

  return [...newIngredients, ...untouchedDefaultIngredients];
};

// Merges two base arrays, prioritizing the mainBases over secondaryBases
// Main bases will prevail over secondary bases if they both have a base in that category and that half
const mergeBaseArrays = (mainBases, secondaryBases) => {
  const initialBases = [...mainBases];
  const finalBases = [...initialBases];

  secondaryBases.forEach(secondaryBase => {
    const existingBaseIndex = initialBases.findIndex(i => i.category === secondaryBase.category);
    const existingBase = initialBases[existingBaseIndex];

    // If a base with same category and half (or whole) doesn't exist, just add it
    // If they have inverse halves, just add it too
    if (
      existingBaseIndex === -1 ||
      (secondaryBase.half !== existingBase.half && existingBase.half !== HALVES.whole)
    ) {
      finalBases.push({ ...secondaryBase });
    }
  });

  return finalBases;
};

export const mergePizzaBases = (bases1 = [], bases2 = [], bases3 = []) => {
  const initialMerge = mergeBaseArrays(bases1, bases2);

  return mergeBaseArrays(initialMerge, bases3).filter(i => i.action !== INGREDIENT_ACTIONS.REMOVE);
};

// ignoreHalf2 is to avoid processing the default bases until both halves are loaded
// only then I can use the default bases if there's no other base selected
export const mergePizzaByHalvesBases = (menuableBases = [], selections = [], bases = [], ignoreHalf2) => {
  let filteredSelections = selections;

  if (ignoreHalf2) {
    filteredSelections = selections.reduce((result, base) => {
      if (base.half === HALVES.half1) {
        result.push(base);
      } else if (base.half === HALVES.whole) {
        result.push({ ...base, half: HALVES.half1 });
      }

      return result;
    }, []);
  }

  const initialMerge = mergeBaseArrays(menuableBases, filteredSelections);

  return mergeBaseArrays(initialMerge, bases).filter(i => i.action !== INGREDIENT_ACTIONS.REMOVE);
};

export const formatModifiedIngredients = ({ currentIngredients, originalIngredients }) =>
  currentIngredients?.reduce(
    (arr, value) => {
      let currentArray = arr;

      if (value.action === INGREDIENT_ACTIONS.ADD) {
        if (arr.find(({ id }) => id === value.id)) {
          currentArray = arr.map(target =>
            target.id === value.id
              ? {
                  ...target,
                  quantity: QUANTITY_MAX
                }
              : target
          );

          return currentArray;
        }

        currentArray.push(value);
      } else if (value.action === INGREDIENT_ACTIONS.REMOVE) {
        currentArray = arr.filter(target => target.id !== value.id);
      }

      return currentArray;
    },
    [...originalIngredients]
  );

export const sendOfferToGTM = (offer, currency) => {
  const { quantity, total, name, offerId, groupProducts, offerType } = offer;

  const offerTotal = quantity > 1 ? total / quantity : total;

  sendGTMAddtoCartEvent({
    name,
    id: offerId,
    price: offerTotal,
    category: offerType || '-',
    variant: name || '-',
    quantity: groupProducts.length,
    currency
  });

  return offerTotal;
};
