import { completeState, createReducer, onReadValue } from 'redux-recompose';
import Immutable from 'seamless-immutable';

import { GIFT_CARD_TARGETS } from './contants';
import { actions } from './actions';

const initialStateDescription = {
  [GIFT_CARD_TARGETS.FORM_DATA]: {},
  [GIFT_CARD_TARGETS.GIFT_CARD_SECTION_OPENED]: false
};

const initialState = completeState(initialStateDescription, [
  GIFT_CARD_TARGETS.FORM_DATA,
  GIFT_CARD_TARGETS.GIFT_CARD_SECTION_OPENED
]);

const reducerDescription = {
  [actions.SET_FORM_DATA]: onReadValue(),
  [actions.SET_GIFT_CARD_SECTION_OPEN]: onReadValue()
};

const reducer = createReducer(new Immutable(initialState), reducerDescription);

export default reducer;
