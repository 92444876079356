import { COUNTRIES, CURRENT_COUNTRY_ID } from '~constants/environment';

import PromoSelling from './index';

export const parsePromos = listPromos =>
  listPromos.map(promo => {
    const { id, name, description, price, minPrice } = promo;
    return {
      id,
      name,
      description,
      price,
      minPrice,
      checked: false,
      matchedActualItems: []
    };
  });

export const enabledCountries = [COUNTRIES.es, COUNTRIES.cr];

export const enablePromoSellingByCountry = () => enabledCountries.includes(CURRENT_COUNTRY_ID);

export const enablePromoSelling = isCheckout =>
  enablePromoSellingByCountry() ? <PromoSelling isCheckout={isCheckout} /> : null;
