import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true
      })
    ],
    tracesSampleRate: 0.4,
    tracePropagationTargets: ['localhost', /^https:\/api\.papajohns\.(cl|es|cr|com\.gt|com\.pa|pt)/],
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0
  });
  Sentry.setTag('country', process.env.REACT_APP_ISO_COUNTRY);
}
