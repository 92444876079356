import i18next from 'i18next';

i18next.addResources('es_CL', 'DispatchTypeDropdown', {
  dispatchTextDefault: 'Retiro en local o delivery',
  dispatchMethodInStore: 'Retiro en Local:',
  dispatchMethodInStoreMobile: 'Retiro:',
  dispatchMethodDelivery: 'Delivery:',
  storeIsClosed: 'La tienda está cerrada',
  noSelectedAddress: 'Aún no seleccionado',
  fakeStoreDelivery: 'Introduce tu dirección',
  dispatchMethodHotspot: 'Retiro en espacio público',
  dispatchMethodPapaCar: 'Delivery a tu auto',
  dispatchMethodHotspotMobile: 'Espacio público',
  dispatchMethodPapaCarMobile: 'PapaCar',
  addressDistance: 'Parece que estás lejos de la dirección ingresada',
  nearbyStoreFound: 'Tienes una tienda cercana. ¡Cambia a retiro y ahorra!'
});

i18next.addResources('es_ES', 'DispatchTypeDropdown', {
  dispatchTextDefault: 'Recoger en local o domicilio',
  dispatchMethodInStore: 'Recoger en:',
  dispatchMethodInStoreMobile: 'Recoger en:',
  dispatchMethodDelivery: 'Domicilio:',
  storeIsClosed: 'La tienda está cerrada',
  noSelectedAddress: 'Aún no seleccionado',
  fakeStoreDelivery: 'Introduce tu dirección',
  nearbyStoreFound: 'Hay una tienda cerca. Pásate a recoger tu pedido y ahorra.'
});

i18next.addResources('pt_PT', 'DispatchTypeDropdown', {
  dispatchTextDefault: 'Levantar no local / Delivery',
  dispatchMethodInStore: 'Levantar em:',
  dispatchMethodInStoreMobile: 'Levantar em:',
  dispatchMethodDelivery: 'Endereço residencial:',
  storeIsClosed: 'A loja está fechada',
  noSelectedAddress: 'Ainda não selecionado',
  fakeStoreDelivery: 'Digite seu endereço',
  dispatchMethodHotspot: 'Retirada em espaço público',
  dispatchMethodHotspotMobile: 'Espaço publico',
  addressDistance: 'Parece que você está longe do endereço inserido',
  nearbyStoreFound: 'Tens uma loja nas proximidades. Recolhe a tua encomenda na loja e poupa.'
});

i18next.addResources('es_CR', 'DispatchTypeDropdown', {
  dispatchTextDefault: 'Retiro en local o entrega',
  dispatchMethodDelivery: 'Entrega:'
});

i18next.addResources('es_PA', 'DispatchTypeDropdown', {
  dispatchTextDefault: 'Retiro en restaurante o delivery',
  dispatchMethodInStore: 'Retiro en restaurante:',
  dispatchMethodDelivery: 'Entrega:'
});

i18next.addResources('es_GT', 'DispatchTypeDropdown', {
  dispatchMethodDelivery: 'Entrega:'
});
