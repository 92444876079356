import i18next from 'i18next';

i18next.addResources('es_CL', 'Error', {
  defaultImgAlt: 'Imagen de error',
  defaultErrorTitle: 'Se ha producido un error',
  defaultErrorMessage: 'Por favor vuelve a intentarlo más tarde'
});

i18next.addResources('es_ES', 'Error', {
  defaultImgAlt: 'Imagen de error',
  defaultErrorTitle: 'Se ha producido un error',
  defaultErrorMessage: 'Por favor vuelve a intentarlo más tarde'
});

i18next.addResources('pt_PT', 'Error', {
  defaultImgAlt: 'Imagem de erro',
  defaultErrorTitle: 'Ocorreu um erro',
  defaultErrorMessage: 'Por favor, tente novamente mais tarde.'
});
