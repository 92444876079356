import i18next from 'i18next';

i18next.addResources('es_CL', 'Monitor', {
  personalize: 'Personalizar',
  ingredients: 'Ingredientes',
  buyNow: 'Comprar ahora',
  addToCart: 'Añadir al carrito',
  basePrice: 'Precio base',
  total: 'Total'
});

i18next.addResources('es_ES', 'Monitor', {
  personalize: 'Personalizar',
  ingredients: 'Ingredientes',
  buyNow: 'Comprar ahora',
  addToCart: 'Añadir al carrito',
  basePrice: 'Precio base',
  total: 'Total'
});

i18next.addResources('pt_PT', 'Monitor', {
  personalize: 'Personalizar',
  ingredients: 'Ingredientes',
  buyNow: 'Comprar agora',
  addToCart: 'Adicionar ao Pedido',
  basePrice: 'Preço base',
  total: 'Total'
});
