/* eslint-disable */
import i18next from 'i18next';

i18next.addResources('es_ES', 'FranchisesContactForm', {
  pagePath: '/formulario-contacto-franquicias',
  pageTitle: "Formulario contacto Franquicias | Papa John's España",
  pageDescription: 'Franquicias',
  titleForm: 'Quiero ser franquiciado',
  inputFirstName: 'Nombre',
  inputLastName: 'Apellido',
  inputPhone: 'Teléfono de contacto',
  inputEmail: 'Dirección de correo electrónico',
  inputAge: 'Edad',
  inputOrganizedExperience: 'Experiencia en restauración organizada',
  inputFranchiseeOtherBrands: '¿Es franquiciado de otras marcas?',
  inputOtherFranchises: 'En caso afirmativo ¿Cuáles?',
  inputGeographicalAreasInterest: 'Zonas geográficas de interés',
  inputCapitalAvailableInvestment: 'Capital disponible para inversión',
  inputPersonallyOperate: '¿Operará personalmente el restaurante?',
  btnSubmit: 'Enviar',
  postSuccess: 'Se ha enviado su petición de manera satisfactoria',
  postFail: 'No se ha podido enviar tu petición de manera satisfactoria. Intenta mas tarde.',
  yes: 'Si',
  no: 'No',
  motivePlaceholder: 'Seleccione una opción',
  ageRangeValidation: 'Edad debe ser mayor a {{min}} y menor de {{max}}'
});

i18next.addResources('pt_PT', 'FranchisesContactForm', {
  pagePath: '/formulario-contacto-franquia',
  pageTitle: "Formulário de contacto para Franquias | Papa John's Portugal",
  pageDescription: 'Franquias',
  titleForm: 'Eu quero ser um franqueado',
  inputFirstName: 'Nome',
  inputLastName: 'Sobrenomes',
  inputPhone: 'Telefone de contato',
  inputEmail: 'Endereço de email',
  inputAge: 'Idade',
  inputOrganizedExperience: 'Experiência de catering organizado',
  inputFranchiseeOtherBrands: 'Você é franqueado de outras marcas?',
  inputOtherFranchises: 'Se sim, quais são?',
  inputGeographicalAreasInterest: 'Áreas geográficas de interesse',
  inputCapitalAvailableInvestment: 'Capital disponível para investimento',
  inputPersonallyOperate: 'Você vai operar pessoalmente o restaurante?',
  btnSubmit: 'Enviar',
  postSuccess: 'O seu pedido foi enviado de forma satisfatória',
  postFail: 'O seu pedido não pôde ser enviado com sucesso. Por favor, tente novamente mais tarde.',
  yes: 'Sim',
  no: 'Não',
  motivePlaceholder: 'Seleccione uma opção',
  ageRangeValidation: 'A idade deve ser maior que {{min}} e menor que {{max}}'
});
