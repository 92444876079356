import i18next from 'i18next';

i18next.addResources('es_CL', 'CallCenterChangeInfo', {
  updateButton: 'Actualizar',
  cancelButton: 'Restaurar formulario',
  changeButton: 'Modificar datos',
  nameLabel: 'Nombre',
  phoneLabel: 'Teléfono celular',
  emailLabel: 'Correo electrónico',
  updateUserError: 'Error al actualizar el usuario'
});
