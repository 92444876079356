import i18next from 'i18next';

i18next.addResources('es_CL', 'StoreList', {
  outsideCoverageArea: 'Fuera del área de cobertura',
  outsideCoverageAreaDetail: 'No encontramos locales cercanos a la dirección ingresada.'
});

// TODO: pending review for Spain
i18next.addResources('es_ES', 'StoreList', {
  outsideCoverageArea: 'Fuera del área de cobertura',
  outsideCoverageAreaDetail: 'No encontramos locales cercanos a la dirección ingresada.'
});

i18next.addResources('pt_PT', 'StoreList', {
  outsideCoverageArea: 'Fora da área de cobertura',
  outsideCoverageAreaDetail: 'Não encontramos lojas perto do endereço digitado.'
});
