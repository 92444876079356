import i18next from 'i18next';

i18next.addResources('es_CL', 'OptionsProduct', {
  chooseSizeAndCrust: 'Elige tamaño y masa',
  size: 'Tamaño',
  crust: 'Tipo de masa',
  add: 'Añadir',
  chooseOption: 'Elige tu opción',
  personalize: 'Agrega o quita ingredientes',
  personalizeYourPizza: 'Personaliza tu pizza',
  save: 'Guardar',
  byopIngredients: 'Debes elegir entre 1 y {{max}} ingredientes',
  mustChooseOption: 'Debes elegir una opción',
  extraOptions: 'Las opciones con * se consideran un ingrediente más',
  minIngredientsError: 'Elige al menos 1 ingrediente para continuar',
  maxIngredientsError: 'El límite de ingredientes por pizza es de {{max}}',
  discountNote: '(Se aplica al agregar el producto al carrito)',
  remove: 'Quitar Producto'
});

i18next.addResources('es_ES', 'OptionsProduct', {
  mustChooseOption: 'Elige una opción',
  extraOptions: 'Las opciones marcadas con un * se consideran como un ingrediente adicional'
});

i18next.addResources('pt_PT', 'OptionsProduct', {
  chooseSizeAndCrust: 'Escolha o tamanho e a massa',
  size: 'Tamanho',
  crust: 'Tipo de massa',
  add: 'Adicionar',
  chooseOption: 'Escolha sua opção',
  personalize: 'Adicione ou remova ingredientes',
  personalizeYourPizza: 'Personalize sua pizza',
  save: 'Salvar',
  byopIngredients: 'Deve escolher entre 1 e {{max}} ingredientes',
  mustChooseOption: 'Deve escolher uma opção',
  extraOptions: 'As opções com * são consideradas mais um ingrediente',
  minIngredientsError: 'Escolha pelo menos 1 ingrediente para continuar',
  maxIngredientsError: 'O número máximo de ingredientes por pizza é {{max}}',
  discountNote: '(Aplica-se ao adicionar o produto ao carrinho)',
  remove: 'Remover Produto'
});
