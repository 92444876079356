import Immutable from 'seamless-immutable';
import { createReducer, onReadValue } from 'redux-recompose';

import { actions, TARGETS } from './actions';

const defaultState = {
  [TARGETS.CURRENT_ITEM]: null,
  [TARGETS.SELECTED_VARIANT]: null
};

const reducerDescription = {
  [actions.SET_CURRENT_ITEM]: onReadValue(),
  [actions.SET_SELECTED_VARIANT]: onReadValue()
};

const reducer = createReducer(new Immutable(defaultState), reducerDescription);

export default reducer;
