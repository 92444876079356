import i18next from 'i18next';

i18next.addResources('es_CL', 'LoginStep', {
  title: '¡Compra, acumula puntos y disfruta de beneficios exclusivos!',
  description: 'Inicia sesión o regístrate de manera rápida usando tus redes sociales',
  separator: 'También puedes usar tu email',
  continueWithEmail: 'Continuar con email',
  continueAsGuest: 'Continuar como invitado'
});

i18next.addResources('pt_PT', 'LoginStep', {
  title: 'Compra, acumula pontos e desfruta de vantagens exclusivas!',
  description: 'Inicia sessão ou regista-te de forma rápida usando as tuas redes sociais.',
  separator: 'Também podes utilizar o teu e-mail',
  continueWithEmail: 'Continuar com o e-mail',
  continueAsGuest: 'Continuar como convidado'
});
