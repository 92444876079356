/* eslint-disable max-lines */
import freshDough from './assets/fresh_dough.jpg';
import thinCrust from './assets/thin_crust.jpg';
import cheese from './assets/cheese.png';
import pizzaSauce from './assets/pizza_sauce.jpg';
import bordeQueso from './assets/borde_queso.jpg';

export const CATEGORIES = {
  MEAT: 'meat',
  VEGETABLE: 'vegetable',
  EXTRA_CHEESE: 'extra_cheese',
  SHOT: 'shots',
  BASE: 'base',
  PREMIUM: 'premium',
  REGULAR: 'normal'
};

export const CATEGORIES_LIST = [
  CATEGORIES.BASE,
  CATEGORIES.MEAT,
  CATEGORIES.VEGETABLE,
  CATEGORIES.EXTRA_CHEESE,
  CATEGORIES.SHOT,
  CATEGORIES.PREMIUM
];

export const BASE_INGREDIENTS = {
  cl: [
    {
      name: 'Masa fresca',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: freshDough
        }
      ],
      description:
        'Nuestra masa original es elaborada con harina de trigo con alto contenido proteico, es enviada a los locales fresca, nunca congelada. Nuestra consistencia en la preparación crea un sabor único que podrás disfrutar con tus ingredientes favoritos.'
    },
    {
      name: 'Masa delgada',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: thinCrust
        }
      ],
      description:
        "Harina sin blanquear elaborada con nuestra fórmula patentada. La masa delgada de Papa John's es tan delgada y crujiente que sentirás el 'crunch' en cada mordida. Además no contiene conservantes."
    },
    {
      name: 'Queso base',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: cheese
        }
      ],
      description:
        'Si eres amante al queso tienes que probar el nuestro. Es 100% Queso real. Además es bajo en grasa y elaborada con leche descremada de alta calidad.'
    },
    {
      name: 'Salsa de tomate',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: pizzaSauce
        }
      ],
      description:
        "Nuestra exclusiva salsa de tomate de Papa John's se elabora con tomates frescos recién cosechados en el fértil valle de California, dónde se producen los tomates más ricos del mundo y son madurados naturalmente. Nuestra receta patentada no utiliza colorantes ni saborizantes artificiales."
    },
    {
      name: 'Borde queso',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: bordeQueso
        }
      ],
      description:
        '¿Fanático del Queso? Ahora encontrarás nuestra exquisita Mozzarella 100% Real en el nuevo Borde de Queso.'
    }
  ],
  cr: [
    {
      name: 'Masa fresca',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: freshDough
        }
      ],
      description:
        'Nuestra masa original es elaborada con harina de trigo con alto contenido proteico, es enviada a los locales fresca, nunca congelada. Nuestra consistencia en la preparación crea un sabor único que podrás disfrutar con tus ingredientes favoritos.'
    },
    {
      name: 'Masa thin crust',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: thinCrust
        }
      ],
      description:
        "Harina sin blanquear elaborada con nuestra fórmula patentada. Nuestra masa delgada thin crust es tan delgada y crujiente que sentirás el 'crunch' en cada mordida. Además, no contiene conservantes."
    },
    {
      name: 'Queso base',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: cheese
        }
      ],
      description:
        'Si eres amante al queso mozzarella tienes que probar el nuestro. Es 100% Queso real. Además, es bajo en grasa y elaborada con leche descremada de alta calidad.'
    },
    {
      name: 'Salsa de tomate',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: pizzaSauce
        }
      ],
      description:
        "Nuestra exclusiva salsa de tomate de Papa John's se elabora con tomates frescos recién cosechados en el fértil valle de California, dónde se producen los tomates más ricos del mundo y son madurados naturalmente. Nuestra receta patentada no utiliza colorantes ni saborizantes artificiales."
    }
  ],
  es: [
    {
      name: 'Masa fresca',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: freshDough
        }
      ],
      description:
        'Nuestra masa original se elabora con harina de trigo de alto contenido proteico y se envía a pizzerías fresca, nunca congelada. Nuestra consistencia en la preparación crea un sabor único.'
    },
    {
      name: 'Masa fina',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: thinCrust
        }
      ],
      description:
        "Harina sin blanquear elaborada con nuestra fórmula patentada. La masa delgada de Papa John's es tan delgada y crujiente que sentirás el 'crunch' en cada mordisco. Además no contiene conservantes."
    },
    {
      name: 'Queso mozzarella',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: cheese
        }
      ],
      description:
        'Si eres amante del queso tienes que probar nuestra mozzarella, baja en grasa y elaborada con leche desnatada de la mejor calidad.'
    },
    {
      name: 'Salsa de tomate natural',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: pizzaSauce
        }
      ],
      description:
        "Nuestra exclusiva salsa de tomate de Papa John's se elabora con tomates frescos recién cosechados en el fértil valle de California, dónde se producen los tomates más ricos del mundo y son madurados naturalmente. Nuestra receta patentada no utiliza colorantes ni aromas artificiales."
    }
  ],
  pt: [
    {
      name: 'Massa fresca',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: freshDough
        }
      ],
      description:
        'Nossa massa original é feita com farinha de trigo de alta proteína e enviada para pizzarias frescas, nunca congeladas. Nossa consistência na preparação cria um sabor único.'
    },
    {
      name: 'Massa fina',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: thinCrust
        }
      ],
      description:
        'Farinha crua feita com a nossa fórmula patenteada. A massa fina do Papa John é tão fina e crocante que você vai sentir a crise em cada mordida. Também não contém conservantes.'
    },
    {
      name: 'Queijo mussarela',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: cheese
        }
      ],
      description:
        'Se você é um amante de queijo você tem que experimentar a mussarela, baixo teor de gordura e feita com o melhor leite desnatado de qualidade.'
    },
    {
      name: 'Molho de tomate natural',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: pizzaSauce
        }
      ],
      description:
        'Nosso exclusivo molho de tomate Papa John é feito com tomates frescos recém-colhidos no fértil vale da Califórnia, onde os tomates mais ricos do mundo são produzidos e amadurecidos naturalmente. Nossa receita patenteada não usa cores ou sabores artificiais.'
    }
  ],
  // TODO: ADD PANAMA BASE INGREDIENTS DESCRIPTIONS
  pa: [
    {
      name: 'Masa fresca',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: freshDough
        }
      ],
      description:
        'Nuestra masa original es elaborada con harina de trigo con alto contenido proteico, es enviada a los locales fresca, nunca congelada. Nuestra consistencia en la preparación crea un sabor único que podrás disfrutar con tus ingredientes favoritos.'
    },
    {
      name: 'Masa delgada',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: thinCrust
        }
      ],
      description:
        "Harina sin blanquear elaborada con nuestra fórmula patentada. Nuestra masa delgada thin crust es tan delgada y crujiente que sentirás el 'crunch' en cada mordida. Además, no contiene conservantes."
    },
    {
      name: 'Queso base',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: cheese
        }
      ],
      description:
        'Si eres amante al queso mozzarella tienes que probar el nuestro. Es 100% Queso real. Además, es bajo en grasa y elaborada con leche descremada de alta calidad.'
    },
    {
      name: 'Salsa de tomate',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: pizzaSauce
        }
      ],
      description:
        "Nuestra exclusiva salsa de tomate de Papa John's se elabora con tomates frescos recién cosechados en el fértil valle de California, dónde se producen los tomates más ricos del mundo y son madurados naturalmente. Nuestra receta patentada no utiliza colorantes ni saborizantes artificiales."
    },
    {
      name: 'Borde queso',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: bordeQueso
        }
      ],
      description:
        '¿Fanático del Queso? Ahora encontrarás nuestra exquisita Mozzarella 100% Real en el nuevo Borde de Queso.'
    }
  ],
  gt: [
    {
      name: 'Masa fresca',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: freshDough
        }
      ],
      description:
        'Nuestra masa original es elaborada con harina de trigo con alto contenido proteico, es enviada a los locales fresca, nunca congelada. Nuestra consistencia en la preparación crea un sabor único que podrás disfrutar con tus ingredientes favoritos.'
    },
    {
      name: 'Masa delgada',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: thinCrust
        }
      ],
      description:
        "Harina sin blanquear elaborada con nuestra fórmula patentada. La masa delgada de Papa John's es tan delgada y crujiente que sentirás el 'crunch' en cada mordida. Además no contiene conservantes."
    },
    {
      name: 'Queso base',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: cheese
        }
      ],
      description:
        'Si eres amante al queso tienes que probar el nuestro. Es 100% Queso real. Además es bajo en grasa y elaborada con leche descremada de alta calidad.'
    },
    {
      name: 'Salsa de tomate',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: pizzaSauce
        }
      ],
      description:
        "Nuestra exclusiva salsa de tomate de Papa John's se elabora con tomates frescos recién cosechados en el fértil valle de California, dónde se producen los tomates más ricos del mundo y son madurados naturalmente. Nuestra receta patentada no utiliza colorantes ni saborizantes artificiales."
    },
    {
      name: 'Borde queso',
      category: 'base',
      pictures: [
        {
          category: 'photo',
          url: bordeQueso
        }
      ],
      description:
        '¿Fanático del Queso? Ahora encontrarás nuestra exquisita Mozzarella 100% Real en el nuevo Borde de Queso.'
    }
  ]
};
