import { push } from 'connected-react-router';
import { completeTypes, createTypes } from 'redux-recompose';

import { CLIENT_PAGES } from '~constants/pages';
import { sendGiftCardSectionEvent } from '~gtmEvents/giftCards';

import { GIFT_CARD_TARGETS } from './contants';

export const actions = createTypes(
  completeTypes([], ['SET_FORM_DATA', 'SET_GIFT_CARD_SECTION_OPEN']),
  '@@GIFT_CARDS'
);

const actionsCreator = {
  setFormData: data => ({
    type: actions.SET_FORM_DATA,
    target: GIFT_CARD_TARGETS.FORM_DATA,
    payload: data
  }),
  editGiftCard: data => (dispatch, getState) => {
    const state = getState();
    const { pathname } = state.router.location;
    dispatch(actionsCreator.setFormData(data));
    dispatch(push(CLIENT_PAGES.GIFT_CARD.path, { from: pathname }));
  },
  setGiftCardSectionOpen: () => ({
    type: actions.SET_GIFT_CARD_SECTION_OPEN,
    target: GIFT_CARD_TARGETS.GIFT_CARD_SECTION_OPENED,
    payload: true
  }),
  onOpenGiftCardSection: () => (dispatch, getState) => {
    const state = getState();
    const giftCardSectionOpened = state.giftCards[GIFT_CARD_TARGETS.GIFT_CARD_SECTION_OPENED];
    if (!giftCardSectionOpened) {
      sendGiftCardSectionEvent();
    }
    dispatch(actionsCreator.setGiftCardSectionOpen());
    dispatch(push(CLIENT_PAGES.GIFT_CARD.path));
  }
};

export default actionsCreator;
