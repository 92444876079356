/* eslint-disable camelcase */
import { EVENT, sendGTMEvent } from '~utils/analytics';

export const createPromoSellingEvent = (actualPromo, isCheckout) => {
  const promoSellingEvent = {
    step: actualPromo.checked ? 'add_promoselling' : 'view_promoselling',
    product_name: actualPromo.name,
    discount: parseFloat(actualPromo.savedPrice.toFixed(2)),
    page_product: isCheckout ? 'checkout' : 'cart'
  };
  sendGTMEvent(EVENT.promoselling, promoSellingEvent);
};
