import i18next from 'i18next';

i18next.addResources('es_CL', 'DispatchDetails', {
  backToMenu: 'Volver al menú',
  fallbackTitle: 'Retiro en local / Delivery',
  delivery: 'Delivery',
  pickUpAt: 'Retiro en {{store}}',
  estimatedTime: '{{minutes}} min aprox',
  storeFrom: 'Desde tienda {{name}}',
  dispatchTimeText: 'Tiempo de entrega',
  soonDispatchTimeText: 'Lo antes posible',
  selectTimeDispatchText: 'Hora programada',
  futureOrderOK: 'Elige el tiempo de entrega',
  selectOnlinePayment: 'Disponible solo pago online',
  noFutureOrder: 'Programar hora no disponible',
  pickUpAtHotspot: 'Retiro en espacio público',
  pickUpAtPapaCar: 'Delivery a tu auto',
  pickUpAtStore: 'Retiro en local',
  dispatchDetails: 'Detalles de entrega',
  storeClosed: 'Tienda cerrada',
  storeOpen: 'Tienda abierta'
});

i18next.addResources('es_ES', 'DispatchDetails', {
  fallbackTitle: 'Recoger en local / Delivery',
  pickUpAt: 'Recoger en {{store}}',
  delivery: 'Entrega en el domicilio'
});

i18next.addResources('pt_PT', 'DispatchDetails', {
  backToMenu: 'Voltar ao menú',
  fallbackTitle: 'Para levantamento no local / Entrega',
  delivery: 'Entrega para o seu endereço',
  pickUpAt: 'Colecione em {{store}}',
  estimatedTime: '{{minutes}} min aprox',
  storeFrom: 'Desde loja {{name}}',
  dispatchTimeText: 'Tempo de entrega',
  soonDispatchTimeText: 'O mais depressa possível',
  futureOrderOK: 'Escolhe a hora para a entrega',
  noFutureOrder: 'Programar hora não disponível',
  selectOnlinePayment: 'Disponível apenas pagamento online',
  selectTimeDispatchText: 'Hora programada ',
  dispatchDetails: 'Detalhes da Entrega',
  storeClosed: 'Loja fechada',
  storeOpen: 'Loja aberta'
});

i18next.addResources('es_CR', 'DispatchDetails', {
  fallbackTitle: 'Retiro en local / Entrega',
  delivery: 'Entrega a tu dirección'
});

i18next.addResources('es_PA', 'DispatchDetails', {
  fallbackTitle: 'Retiro en restaurante / Delivery'
});
