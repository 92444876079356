import { createTypes, completeTypes } from 'redux-recompose';

import IngredientsService from '~services/Ingredients/service';
import { deserializer } from '~services/baseSerializers';

import { INGREDIENTS_SCREEN_TARGET } from './constants';

export const actions = createTypes(completeTypes(['GET_INGREDIENTS_SCREEN']), '@@INGREDIENTS_SCREEN');

export const actionCreators = {
  getIngredients: () => ({
    type: actions.GET_INGREDIENTS_SCREEN,
    target: INGREDIENTS_SCREEN_TARGET,
    service: IngredientsService.getIngredients,
    successSelector: response => deserializer.serialize(response.data)
  })
};
