import i18next from 'i18next';

i18next.addResources('es_CL', 'Notification', {
  error: 'Error',
  success: 'Exitoso',
  info: 'Información',
  closeNotification: 'Cerrar notificación'
});

i18next.addResources('es_ES', 'Notification', {
  error: 'Error',
  success: 'Exitoso',
  info: 'Información',
  closeNotification: 'Cerrar notificación'
});

i18next.addResources('pt_PT', 'Notification', {
  error: 'Erro',
  success: 'Sucesso',
  info: 'Informação',
  closeNotification: 'Fechar notificação'
});
