/* eslint-disable max-nested-callbacks */
/* eslint-disable max-lines */
import { onSuccess, onReadValue } from 'redux-recompose';

import { restrictions, buildPizzaSections } from '~constants/products';
import { CUSTOM_HALF_OPTIONS } from '~components/CustomizableOption/constants';

import {
  HELPERS_TARGET,
  ELEMENT_UNIQUENESS,
  CUSTOM_PIZZA_CONFIG_TARGET,
  PIZZA_SPECIALTY_DATA
} from './constants';
import { getSizesAndCrusts, inverseHalfElement, getRestrictionsFromPizza, getInverseHalf } from './utils';

export const onSetHelpers = onReadValue(action =>
  action.payload.reduce(
    (acc, helper) => ({
      ...acc,
      [helper.menuable.category]: {
        ...helper,
        restrictions: getRestrictionsFromPizza(helper)
      }
    }),
    {}
  )
);

export const onGetIngredientsSuccess = onSuccess((action, state) => {
  const { size, crust } = getSizesAndCrusts(state[HELPERS_TARGET].byop, action.payload.translations);

  return {
    ...state.config,
    sizes: size,
    crusts: crust,
    ingredients: action.payload.data,
    restrictions
  };
});

export const onSpreadValue = onReadValue((action, state) => ({
  ...state[action.target],
  ...action.payload
}));

const addCategoryUniqueElement = (selections, element) => [
  ...selections.filter(
    selection => selection.category !== element.category || selection.half !== element.half
  ),
  element
];

// If element in category exists as "whole" and I want to change one half
// I have to remove the "whole" element, add it again with the inverse half
// and finally add the new element in the other half
// eslint-disable-next-line id-length
const replaceCategoryUniqueWholeForHalf = (selections, existingSelectionIndex, element) => {
  const selectionsWithoutWholeElement = [...selections];
  const existingSelection = selections[existingSelectionIndex];

  selectionsWithoutWholeElement.splice(existingSelectionIndex, 1);

  return [
    ...selectionsWithoutWholeElement,
    element,
    inverseHalfElement({ ...existingSelection, half: element.half })
  ];
};

export const addElementCategoryUnique = (typeSelections, newElement) => {
  const existingSelectionIndex = typeSelections.findIndex(
    selection =>
      selection.category === newElement.category &&
      (selection.half === newElement.half || selection.half === CUSTOM_HALF_OPTIONS.whole)
  );

  if (existingSelectionIndex === -1) {
    // If element doesn't exist, add it
    return addCategoryUniqueElement(typeSelections, newElement);
  }
  const existingSelection = typeSelections[existingSelectionIndex];

  // If element exists in the half, replace it
  if (existingSelection.half === newElement.half) {
    return addCategoryUniqueElement(typeSelections, newElement);
    // If elem in category exists as "whole", remove it, add it in the inverse half and add the new one
  } else if (existingSelection.half === CUSTOM_HALF_OPTIONS.whole) {
    return replaceCategoryUniqueWholeForHalf(typeSelections, existingSelectionIndex, newElement);
  }

  return [];
};

export const onSetPizzaElement = (state, action) => {
  const { stepId, elementType, element, uniqueness } = action.payload;

  const stepSelections = state[action.target]?.[stepId] || {};
  const typeSelections = stepSelections[elementType] || [];
  const allElements = state[action.target]?.[stepId]?.allElements || [];

  let newElements = [];
  let newAllElements = [...allElements];
  // If uniqueness is CATEGORY, elements are unique within their category, so adding an element
  // in a category erases all the other elements in that category.
  // If uniqueness is TYPE, works in the same way but with the `type` property.
  // If there's no uniqueness, more than one element can be selected at the same time.
  if (uniqueness === ELEMENT_UNIQUENESS.CATEGORY) {
    newElements = addElementCategoryUnique(typeSelections, element);
    const elementIndex = newAllElements.findIndex(el => el.category === element.category);
    if (elementIndex === -1) {
      newAllElements = [...newAllElements, element];
    } else {
      newAllElements = newAllElements.filter(el => el.category !== element.category);
      newAllElements = [...newAllElements, element];
    }
  } else if (uniqueness === ELEMENT_UNIQUENESS.TYPE) {
    newElements = [element];
  } else {
    newElements = [...typeSelections, element];
    newAllElements = [...newAllElements, element];
  }

  return state.merge({
    [action.target]: {
      ...state[action.target],
      [stepId]: {
        ...stepSelections,
        [elementType]: newElements,
        allElements: newAllElements
      }
    }
  });
};

export const onDeletePizzaElement = (state, action) => {
  const { stepId, elementType, element } = action.payload;
  const stepSelections = state[action.target]?.[stepId];
  const typeSelections = stepSelections?.[elementType];
  const half = element.half || CUSTOM_HALF_OPTIONS.whole;
  const allElements = state[action.target]?.[stepId]?.allElements || [];
  const newAllElements = allElements.filter(el => el.id !== element.id && el.name !== element.name);

  if (!typeSelections) {
    return state;
  }

  const elementIndex = typeSelections.findIndex(
    e =>
      e.id === element.id &&
      (!element.half || e.half === element.half || e.half === CUSTOM_HALF_OPTIONS.whole)
  );
  const currentSelectedElement = typeSelections[elementIndex];
  let newSelections = [...typeSelections];

  if (currentSelectedElement?.half === CUSTOM_HALF_OPTIONS.whole && half !== CUSTOM_HALF_OPTIONS.whole) {
    newSelections[elementIndex] = {
      ...currentSelectedElement,
      half: getInverseHalf(currentSelectedElement.half)
    };
  } else {
    newSelections = [...typeSelections.slice(0, elementIndex), ...typeSelections.slice(elementIndex + 1)];
  }

  return state.merge({
    [action.target]: {
      ...state[action.target],
      [stepId]: {
        ...state[action.target][stepId],
        [elementType]: newSelections,
        allElements: newAllElements
      }
    }
  });
};

export const onSetPizzaIdConfig = (state, action) =>
  state.merge({
    [CUSTOM_PIZZA_CONFIG_TARGET]: {
      ...state[CUSTOM_PIZZA_CONFIG_TARGET],
      [action.target]: action.payload
    }
  });

const editElement = (getElement, replaceElement) => (state, action) => {
  const { stepId, elementType, element, half = CUSTOM_HALF_OPTIONS.whole } = action.payload;
  const stepSelections = state[action.target]?.[stepId];
  const typeSelections = stepSelections?.[elementType] || [];
  const allElements = state[action.target]?.[stepId]?.allElements || [];

  if (!typeSelections) {
    return state;
  }

  const elementIndex = typeSelections.findIndex(e => getElement(element, e));
  const newSelections = [...typeSelections];
  const newAllElements = [...allElements];

  if (elementIndex === -1) {
    newSelections.push(element);
    for (let times = 0; times < element.quantity; times++) {
      newAllElements.push(element);
    }
  } else if (
    !replaceElement &&
    element.half === CUSTOM_HALF_OPTIONS.whole &&
    half !== CUSTOM_HALF_OPTIONS.whole
  ) {
    // If element is in shole pizza but I want to edit only one half, split it
    newSelections[elementIndex] = { ...newSelections[elementIndex], half: getInverseHalf(half) };
    newSelections.push({ ...element, half });
  } else {
    newSelections[elementIndex] = element;
    const repeatedTimes = allElements.filter(el => el.id === element.id && el.name === element.name);
    if (repeatedTimes.length <= element.quantity) {
      newAllElements.push(element);
    } else {
      let lastRepeatedItemIndex = '';
      allElements.forEach((el, index) => {
        if (el.id === element.id && el.name === element.name) {
          lastRepeatedItemIndex = index;
        }
      });
      newAllElements.splice(lastRepeatedItemIndex, 1);
    }
  }

  return state.merge({
    [action.target]: {
      ...state[action.target],
      [stepId]: {
        ...state[action.target][stepId],
        [elementType]: newSelections,
        allElements: newAllElements
      }
    }
  });
};

export const onEditElementHalf = editElement((newElement, e) => e.id === newElement.id, true);

export const onEditElementQuantity = editElement(
  (newElement, e) => e.id === newElement.id && e.half === newElement.half,
  false
);

export const onGetOfferPizzas = (state, action) =>
  state.merge({
    config: {
      ...state.config,
      basePizzaData: {
        ...state.config.basePizzaData,
        defaultIngredients: {
          ...state.config.basePizzaData?.defaultIngredients,
          ...Object.keys(action.payload).reduce(
            (result, key) => ({
              ...result,
              [key]: { [CUSTOM_HALF_OPTIONS.whole]: action.payload[key].ingredients }
            }),
            {}
          )
        }
      }
    }
  });

const getNotIngredientSelected = (payloadBases, category) =>
  payloadBases?.filter(
    b =>
      b.category === category &&
      (b.half === CUSTOM_HALF_OPTIONS.half1 || b.half === CUSTOM_HALF_OPTIONS.whole)
  ) || [];

export const onSetSelections = onReadValue((action, state) =>
  Object.keys(action.payload).reduce(
    (result, stepId) => {
      let bases = [];

      [
        buildPizzaSections.BASE_CHEESE,
        buildPizzaSections.EXTRA_SAUCE,
        buildPizzaSections.BASE_SAUCE,
        buildPizzaSections.NOT_INGREDIENT
        // eslint-disable-next-line consistent-return
      ].forEach(category => {
        const payloadBases = action.payload[stepId].bases;
        // eslint-disable-next-line max-nested-callbacks
        const defaultBase = Object.values(state.config.ingredients).find(
          i => i.base && i.category === category
        );

        if (buildPizzaSections.NOT_INGREDIENT === category) {
          bases = [...bases, ...getNotIngredientSelected(payloadBases, category)];
          return (result[stepId] = { ...result[stepId], bases });
        }

        const baseHalf1Value =
          payloadBases?.find(
            b =>
              b.category === category &&
              (b.half === CUSTOM_HALF_OPTIONS.half1 || b.half === CUSTOM_HALF_OPTIONS.whole)
          ) || defaultBase;
        const baseHalf2Value =
          payloadBases?.find(
            b =>
              b.category === category &&
              (b.half === CUSTOM_HALF_OPTIONS.half2 || b.half === CUSTOM_HALF_OPTIONS.whole)
          ) || defaultBase;
        if (!baseHalf1Value && !baseHalf2Value) {
          return null;
        }

        if (baseHalf1Value?.id === baseHalf2Value?.id) {
          bases = [...bases, { ...baseHalf1Value, half: CUSTOM_HALF_OPTIONS.whole }];
        } else {
          const baseHalf1Values = baseHalf1Value
            ? [{ ...baseHalf1Value, half: CUSTOM_HALF_OPTIONS.half1 }]
            : [];
          const baseHalf2Values = baseHalf2Value
            ? [{ ...baseHalf2Value, half: CUSTOM_HALF_OPTIONS.half2 }]
            : [];

          bases = [...bases, ...baseHalf1Values, ...baseHalf2Values];
        }
      });
      result[stepId] = { ...result[stepId], bases };

      return result;
    },
    { ...action.payload }
  )
);

export const onSetPizzaSpecialtyData = onSuccess((action, state) => ({
  ...state[PIZZA_SPECIALTY_DATA],
  ...action?.payload
}));
