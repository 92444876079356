/* eslint-disable max-params */
import { t } from 'i18next';

import { PRODUCT_CATEGORIES } from '~screens/Dashboard/screens/Home/components/Menu/constants';
import { flattenIngredientsNames } from '~redux/CustomPizza/utils';
import { productTypes, pizzaCategories } from '~constants/products';
import { getIcon } from '~utils/images';
import { comboPizzaStepId, isPizzaCategory } from '~utils/pizza';
import { COUNTRIES, CURRENT_COUNTRY_ID } from '~constants/environment';
import { arrayHasLength } from '~utils/array';

import { DEFAULT_ACTION_TYPE, MAX_INGREDIENTS } from './constants';

export const getNonPizzaGroupId = groupId => `sideOptionsGroup${groupId}`;

export const getSauceGroupId = groupId => `sauceGroup${groupId}`;

export const getSelectedVariant = (variants = []) => {
  const selectedVariant = variants.filter(variant => variant.isSelected);

  if (selectedVariant.length > 0) {
    return selectedVariant;
  }
  return [variants[0]];
};

export const groupIsExistingPizza = group =>
  isPizzaCategory(group.productOfferGroups[0].menuable.category) ||
  group.productOfferGroups[0].menuable.category === PRODUCT_CATEGORIES.BYOP;

export const groupIsPizza = group => groupIsExistingPizza(group);

export const getPizzaGroups = offer => offer.groups.filter(group => groupIsPizza(group));

const getSelectedPizzas = (
  pizzaSteps,
  offerStepPizzaIds,
  menu,
  byop,
  basePizzaData,
  selectionValues,
  completedSteps,
  texts,
  translations
) =>
  pizzaSteps
    .filter(({ offerGroupId }) =>
      completedSteps.find(completedStep => completedStep.offer_group_id === offerGroupId)
    )
    .map((pizza, index) => {
      const [stepId, stepSelections] = selectionValues[index];
      const crust = stepSelections.crusts[0].id;
      const size = stepSelections.sizes[0].id;
      const allIngredients = [...stepSelections.bases?.filter(b => !b.base), ...stepSelections.ingredients];
      const ingredients = allIngredients.map(ingredient => ({
        id: ingredient.id,
        quantity: ingredient.quantity || 1,
        name: ingredient.name,
        action: DEFAULT_ACTION_TYPE
      }));
      const pizzaId = offerStepPizzaIds[comboPizzaStepId(index)];
      const defaultIngredients = basePizzaData.defaultIngredients[pizzaId]?.whole;
      const menuItem =
        menu.find(item => item.menuableId === pizzaId && item.menuableType === productTypes.PIZZA) || byop;

      const pizzaName = menuItem.menuable.name || t('App:pizza');
      let pizzaDescription = '';

      if (
        allIngredients?.length === defaultIngredients?.length &&
        defaultIngredients?.every(ingredient => allIngredients.some(i => i.id === ingredient.id))
      ) {
        pizzaDescription = menuItem.menuable.description;
      } else {
        pizzaDescription = flattenIngredientsNames(ingredients);
      }

      texts.push(
        `${pizzaName}: ${translations.sizes?.[size]}, ${translations.crusts?.[crust]} - ${pizzaDescription}`
      );

      return {
        crust,
        size,
        menuableType: pizza.menuableType,
        menuableId: pizza.menuableId,
        offerGroupId: pizza.offerGroupId,
        stepId
      };
    });

export const createCartItemData = ({
  offer,
  offerStepPizzaIds,
  onlySelectedSteps,
  pizzaSteps,
  nonPizzaSteps,
  menu,
  byop,
  basePizzaData,
  completedSteps,
  translations
}) => {
  const selectionValues = Object.entries(onlySelectedSteps);
  const texts = [];

  const groupPizzaProducts = getSelectedPizzas(
    pizzaSteps,
    offerStepPizzaIds,
    menu,
    byop,
    basePizzaData,
    selectionValues,
    completedSteps,
    texts,
    translations
  );
  const groupNonPizzaProducts = nonPizzaSteps
    .filter(({ offerGroupId }) =>
      completedSteps.find(completedStep => completedStep.offer_group_id === offerGroupId)
    )
    .map(nonPizza => {
      const [stepId, selectionGroup] = selectionValues.find(([, group]) =>
        Object.keys(group).includes(getNonPizzaGroupId(nonPizza.id))
      );

      const selectedItems = selectionGroup?.[getNonPizzaGroupId(nonPizza.id)] || [];

      texts.push(`${nonPizza.title}: ${selectedItems[0].text}`);

      const canAddSide =
        selectedItems[0]?.sideCategory === PRODUCT_CATEGORIES.SAUCE &&
        selectionGroup[getSauceGroupId(nonPizza.id)]?.length > 0;

      const selectedVariant = getSelectedVariant(selectedItems[0].variants);

      return {
        menuableType: selectedItems[0]?.menuableType,
        menuableId: selectedVariant[0].id,
        offerGroupId: nonPizza.offerGroupId,
        ...(canAddSide && {
          side: {
            menuableType: productTypes.COMPLEMENT,
            menuableId: selectionGroup[getSauceGroupId(nonPizza.id)][0]?.id,
            name: selectionGroup[getSauceGroupId(nonPizza.id)][0]?.name
          }
        }),
        stepId
      };
    });
  const offerCartItem = {
    offerId: offer.id,
    quantity: 1,
    offerType: offer.offerType,
    description: texts.join('\n'),
    groupProducts: [...groupPizzaProducts, ...groupNonPizzaProducts]
  };

  return offerCartItem;
};

export const mapGroupToOption = (group, menu = []) => {
  const { menuable, menuableType } = group;
  const menuItem = menu.find(item => (menuable?.productId || menuable.id) === item.menuableId);
  return {
    id: menuable.id,
    icon: getIcon(menuable),
    text: menuable.variantName ? `${menuable.name} ${menuable.variantName}` : menuable.name,
    category: menuable.category,
    menuableType,
    sideCategory: menuable.sideCategory,
    isAlcohol: !!menuItem?.menuable?.isAlcohol || false,
    delta: group.delta,
    productId: menuable?.productId || menuable?.id
  };
};

export const getDefaultMinIngredients = () =>
  CURRENT_COUNTRY_ID === COUNTRIES.cl ? MAX_INGREDIENTS[pizzaCategories.MAKE_YOUR_PIZZA] - 1 : 1;

export const createOfferItem = (offer, customPizza, stepsInfo) => {
  const groupProducts = offer?.groups
    .map((group, i) => {
      const stepId = group?.stepId || `step${i + 1}`;
      const stepSelections = customPizza?.selections?.[stepId];
      if (groupIsPizza(group) && stepSelections) {
        const { allElements } = stepSelections;
        const crust = stepSelections.crusts?.[0]?.id;
        const size = stepSelections.sizes?.[0]?.id;

        return {
          crust,
          size,
          allElements,
          menuableType: group.productOfferGroups[0].menuableType,
          menuableId: group.productOfferGroups[0].menuableId,
          offerGroupId: group.productOfferGroups[0].offerGroupId,
          stepId
        };
      }
      const selectedItems = stepSelections?.[getNonPizzaGroupId(group.id)] || [];

      const canAddSide =
        selectedItems[0]?.sideCategory === PRODUCT_CATEGORIES.SAUCE &&
        stepSelections[getSauceGroupId(group.id)]?.length > 0;

      const selectedVariant = arrayHasLength(selectedItems) && getSelectedVariant(selectedItems[0].variants);

      return {
        menuableType: selectedItems[0]?.menuableType,
        menuableId: selectedVariant[0]?.id,
        stepId: group?.stepId,
        offerGroupId: group.id,
        ...(canAddSide && {
          side: {
            menuableType: productTypes.COMPLEMENT,
            menuableId: stepSelections[getSauceGroupId(group.id)][0]?.id,
            name: stepSelections[getSauceGroupId(group.id)][0]?.name
          }
        })
      };
    })
    .filter((product, i) => product?.menuableId && stepsInfo?.steps[i]?.isClicked);

  return {
    offerId: offer?.id,
    quantity: 1,
    offerType: offer?.offerType,
    groupProducts
  };
};

export const getCartItem = (editingId, shoppingCart) =>
  shoppingCart.items.find(cartItem => cartItem.uuid === editingId);
