import { PRODUCT_CATEGORIES } from '~screens/Dashboard/screens/Home/components/Menu/constants';

export const PIZZA_GROUP_NAME = 'Pizzas';

export const DEFAULT_ACTION_TYPE = 'add';

export const MAX_INGREDIENTS = 10;

export const SELECTION_SAUCE_GROUP = 'sauceGroup';

export const STEP_CATEGORY = {
  pizza: PRODUCT_CATEGORIES.PIZZA,
  specialty: PRODUCT_CATEGORIES.PIZZA,
  byop: PRODUCT_CATEGORIES.PIZZA,
  dessert: PRODUCT_CATEGORIES.DESSERT,
  drink: PRODUCT_CATEGORIES.DRINK,
  side: PRODUCT_CATEGORIES.SIDE
};
