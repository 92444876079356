import i18next from 'i18next';

i18next.addResources('es_CL', 'Loyalty', {
  bronze: 'Bronce',
  silver: 'Plata',
  gold: 'Oro',
  change: 'Canjear',
  pts: 'PTS',
  faq: 'Preguntas frecuentes',
  changeProducts: 'Canjea productos',
  pizzaAndMore: 'Pizzas, acompañamientos y más...',
  welcome: 'Hola {{name}}',
  maxLevel: 'Haz alcanzado el nivel máximo',
  joinedDate: 'Te uniste al programa el {{date}}',
  lastPurchase: 'Tu última compra fue el',
  got: 'Tienes',
  onLevel: 'Estás en el nivel {{levelName}}. Tienes {{points}}/{{maxPoints}} de experiencia',
  pointsToNextLevel: 'Suma {{points}} puntos para alcanzar el nivel {{nextLevel}}',
  bronzeTitle: '¡Este es el comienzo de una gran relación!',
  silverTitle: '¡Avanzaste a un nuevo nivel de sabor!',
  goldTitle: '¡Bienvenido al último nivel!',
  bronzeDescription:
    'El primer mordisco a nuestras pizzas nunca se olvida. Bienvenid@ a esta gran aventura llena de sorpresas y beneficios.',
  silverDescription:
    'Si al momento de pensar en “qué comer”, se te vienen nuestras pizzas a la cabeza, es porque te estás transformando en un verdadero #PapaLover, aún te falta un poco, pero vas por buen camino.',
  goldDescription:
    'Seguro que aún no te aprendes la tabla periódica, ¡ah! Pero nuestros ingredientes, no se te escapa ni uno. <br /> Eres lo máximo y eso significa que tienes los mejores beneficios.'
});
