import { t } from 'i18next';

import { CURRENT_COUNTRY_CODE } from '~constants/validation';
import { COUNTRIES } from '~constants/environment';

export const FIELDS = {
  commercialName: 'commercialName',
  identificationType: 'identificationType',
  identificationNumber: 'identificationNumber',
  commercialPhone: 'commercialPhone',
  faxNumber: 'faxNumber',
  tradeName: 'tradeName',
  commercialEmail: 'commercialEmail',
  active: 'active',
  commercialTextAddress: 'commercialTextAddress',
  commercialCity: 'commercialCity',
  commercialPostalCode: 'commercialPostalCode'
};

export const INITIAL_VALUES = {
  [FIELDS.faxNumber]: CURRENT_COUNTRY_CODE,
  [FIELDS.commercialPhone]: CURRENT_COUNTRY_CODE
};

export const MAX_NUMBER_COMPANIES = 10;

export const ID_TYPES = {
  [COUNTRIES.cl]: [
    {
      name: t('ElectronicReceiptForm:nif'),
      value: t('ElectronicReceiptForm:nif')
    }
  ],
  [COUNTRIES.cr]: [
    {
      name: t('ElectronicReceiptForm:physicalId'),
      value: t('ElectronicReceiptForm:physicalId')
    },
    {
      name: t('ElectronicReceiptForm:legalId'),
      value: t('ElectronicReceiptForm:legalId')
    },
    {
      name: t('ElectronicReceiptForm:dimex'),
      value: t('ElectronicReceiptForm:dimex')
    },
    {
      name: t('ElectronicReceiptForm:nite'),
      value: t('ElectronicReceiptForm:nite')
    }
  ],
  [COUNTRIES.pa]: [
    {
      name: t('ElectronicReceiptForm:nif'),
      value: t('ElectronicReceiptForm:nif')
    }
  ],
  [COUNTRIES.pt]: [
    {
      name: t('ElectronicReceiptForm:nif'),
      value: t('ElectronicReceiptForm:nif')
    }
  ],
  [COUNTRIES.es]: [
    {
      name: t('ElectronicReceiptForm:nif'),
      value: t('ElectronicReceiptForm:nif')
    }
  ],
  [COUNTRIES.gt]: [
    {
      name: t('ElectronicReceiptForm:idNIT'),
      value: t('ElectronicReceiptForm:idNIT')
    }
  ]
};
