import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'GiftCardItem', {
  title: 'Gift Card',
  to: 'Para:'
});

i18next.addResources(LANGUAGE_KEYS.SPAIN, 'GiftCardItem', {
  title: 'Tarjeta Regalo'
});
