import { CamelcaseSerializer, Serializer, setSnakecaseKey } from 'cerealizr';

import FIELDS from '~constants/Fields/login';
import { FIELDS as ELECTRONIC_FORM_FIELDS } from '~screens/Dashboard/components/ElectronicReceipt/constants';

export const deserializer = new CamelcaseSerializer();

export const bodySerializer = new Serializer({
  descriptor: {
    grantType: 'grant_type',
    clientId: 'client_id',
    redirectUri: 'redirect_uri',
    code: 'code'
  },
  mapAllValues: false
});

export const serializer = new Serializer({
  descriptor: {
    [FIELDS.EMAIL]: 'Username',
    [FIELDS.PASSWORD]: 'Password',
    [FIELDS.NAME]: 'Name',
    [FIELDS.PHONE]: 'Phone',
    [FIELDS.SUSCRIBE_TO_NEWSLETTER]: 'Suscribe',
    [FIELDS.POLL_AGREEMENT]: 'Poll Agreement',
    [FIELDS.BIRTHDATE]: 'Birthdate'
  },
  mapAllValues: false
});

export const companySerializer = new Serializer({
  descriptor: {
    commercialName: 'name',
    identificationType: setSnakecaseKey(),
    identificationNumber: setSnakecaseKey(),
    commercialPhone: 'phone',
    faxNumber: setSnakecaseKey(),
    tradeName: setSnakecaseKey(),
    commercialEmail: 'email',
    active: setSnakecaseKey(),
    commercialTextAddress: 'text_address',
    commercialCity: 'city',
    commercialPostalCode: 'postcode'
  },
  mapAllValues: false
});

export const companyDeserializer = new CamelcaseSerializer({
  descriptor: {
    name: ELECTRONIC_FORM_FIELDS.commercialName,
    phone: ELECTRONIC_FORM_FIELDS.commercialPhone,
    email: ELECTRONIC_FORM_FIELDS.commercialEmail,
    // eslint-disable-next-line camelcase
    text_address: ELECTRONIC_FORM_FIELDS.commercialTextAddress,
    city: ELECTRONIC_FORM_FIELDS.commercialCity,
    postcode: ELECTRONIC_FORM_FIELDS.commercialPostalCode
  }
});

export const currentUserDeserealizer = new CamelcaseSerializer({
  descriptor: {
    company: value => (value ? companyDeserializer.serialize(value) : undefined)
  }
});
