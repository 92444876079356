import i18next from 'i18next';

i18next.addResources('es_CL', 'ChangeAddress', {
  modifyAddress: '¿Cómo quieres pedir hoy?',
  myAddresses: 'Mis direcciones',
  lastAddresses: 'Usadas anteriormente',
  instructions: 'Para comenzar indícanos la dirección y número donde recibirás tu pedido.',
  completeAddressWarning:
    'Completa tu dirección con el número de la calle para habilitar la entrega a domicilio.',
  moveThePin: 'Mueve el pin a tu ubicación para asegurar la entrega correcta de tu pedido.',
  addressSubtitle: 'El Pedido será enviado a:',
  changeAddress: '¿No es la dirección correcta?',
  enterYourAddressAsFollow: 'Ingresa tu dirección de la siguiente manera',
  addressFormat: '[Nombre de calle o avenida] + [Número], [Zona o Colonia], [Ciudad]',
  addressConfirmMessage: 'Confirmar Esta Ubicación'
});

// TODO: Check these texts
i18next.addResources('es_ES', 'ChangeAddress', {
  myAddresses: 'Mis direcciones',
  lastAddresses: 'Usadas anteriormente'
});

i18next.addResources('pt_PT', 'ChangeAddress', {
  modifyAddress: 'Como queres pedir hoje?',
  myAddresses: 'Meus endereços',
  lastAddresses: 'Anteriormente usado',
  instructions: 'Para começar, nos informe o endereço e o número onde você receberá o seu pedido.',
  completeAddressWarning: 'Preenche a tua morada com o número da rua para ativar a entrega ao domicílio.'
});

i18next.addResources('es_CR', 'ChangeAddress', {
  modifyAddress: '¿Cómo querés pedir hoy?'
});

i18next.addResources('es_PA', 'ChangeAddress', {
  modifyAddress: '¿Cómo querés pedir hoy?'
});

i18next.addResources('es_GT', 'ChangeAddress', {
  modifyAddress: '¿Cómo querés pedir hoy?'
});
