import i18next from 'i18next';

i18next.addResources('es_CL', 'FreeChickens', {
  pagePath: '/comunicado-gallinas-libres',
  pageTitle: "Gallinas Libres | Papa John's Chile",
  pageDescription:
    "Papa John's trabaja solo con proveedores que garanticen el cuidado de sus productos, por eso se adhiere al tratado de Gallinas libres.",
  text1:
    "Papa John's se enorgullece en anunciar su meta de conseguir que todos los productos locales utilizados en su menú, provengan exclusivamente de proveedores certificados como libre de jaulas para el año 2025 en América Latina.",
  text2:
    "Papa John's siempre ha creído en MEJORES INGREDIENTES, MEJOR PIZZA y servir pizzas de alta calidad elaboradas con ingredientes auténticos.",
  text3:
    'Actualmente, todos los productos importados de los Estados Unidos utilizan huevos que se obtienen de proveedores certificados 100% libre de jaulas. Esto incluye muchos de nuestros productos más populares disponibles en los restaurantes de Papa John’s Chile, como nuestras galletas, brownies, aderezos de canela para ensaladas, aderezos ranch, queso azul, mostaza y miel.'
});
