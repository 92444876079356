import i18next from 'i18next';

i18next.addResources('es_CL', 'CookieSettings', {
  title: 'Política de Cookies',
  description:
    'Este sitio web utiliza cookies tanto propias como de terceros para obtener o guardar información en su navegador, con las finalidades que se detallan a continuación en el panel de configuración. Esta información puede ser acerca de usted, sus preferencias o su dispositivo, y se usa principalmente para que el correcto funcionamiento de este sitio web y para brindarle la experiencia optima de nuestros servicios. Por lo general, la información no lo identifica directamente, pero puede proporcionarle una experiencia web más personalizada. Ya que respetamos su derecho a la privacidad, usted puede escoger no permitirnos usar ciertas cookies. Haga clic en los encabezados de cada categoría para saber más y cambiar nuestras configuraciones predeterminadas. Sin embargo, el bloqueo de algunos tipos de cookies puede afectar su experiencia en el sitio y los servicios que podemos ofrecer.',
  description2: 'También puede encontrar información sobre configuración, origen y finalidades ',
  buttonSave: 'Guardar',
  technicalsCookie: 'Cookies Técnicas',
  analyticsCookie: 'Cookies Analíticas',
  marketingCookie: 'Cookies Marketing'
});

i18next.addResources('pt_PT', 'CookieSettings', {
  title: 'Política de cookies',
  description:
    'Este site usa cookies próprios e de terceiros para obter ou salvar informações em seu navegador, para os fins detalhados a seguir no painel de configuração. Essas informações podem ser sobre você, suas preferências ou seu dispositivo e são usadas principalmente para o funcionamento adequado deste site e para fornecer a você a melhor experiência de nossos serviços. As informações geralmente não identificam você diretamente, mas podem fornecer uma experiência mais personalizada na web. Como respeitamos seu direito à privacidade, você pode optar por não nos permitir o uso de determinados cookies. Clique nos títulos de cada categoria para saber mais e alterar nossas configurações padrão. No entanto, o bloqueio de alguns tipos de cookies pode afetar sua experiência no site e os serviços que podemos oferecer.',
  description2: 'Você também pode encontrar informações sobre configuração, origem e finalidades ',
  buttonSave: 'Salve',
  technicalsCookie: 'Cookies Técnicas',
  analyticsCookie: 'Cookies Analíticas',
  marketingCookie: 'Cookies Marketing'
});
