import { t } from 'i18next';

import { buildPizzaSections, pizzaCategories } from '~constants/products';
import { CUSTOM_HALF_OPTIONS as PIZZA_HALVES } from '~components/CustomizableOption/constants';
import { INGREDIENT_ACTIONS } from '~constants/buildPizza';
import { snakeToCamel } from '~utils/string';

export const BASE_PRESELECTED_SAUCE = ['Salsa Tomate', 'Molho Tomate'];

export const baseCategories = [
  buildPizzaSections.BASE_SAUCE,
  buildPizzaSections.BASE_CHEESE,
  buildPizzaSections.EXTRA_SAUCE,
  buildPizzaSections.NOT_INGREDIENT
];

const extraIngredientCategories = [
  buildPizzaSections.EXTRA_CHEESE,
  buildPizzaSections.MEAT,
  buildPizzaSections.VEGETABLE,
  buildPizzaSections.PREMIUM,
  buildPizzaSections.SHOT
];

export const isBase = ingredient => baseCategories.includes(ingredient.category);

export const isExtraIngredient = ingredient => extraIngredientCategories.includes(ingredient.category);

const flattenIngredientNames = (array, startValue = '') =>
  array
    ? array
        .map(ingredient => `${ingredient.name}${ingredient.quantity > 1 ? ' x2' : ''}`)
        .join(', ')
        .replace(/^/, startValue)
    : null;

const mergeIngredients = (itemIngredients = [], basePizzaIngredients = []) =>
  itemIngredients.reduce(
    (result, ingredient1) => {
      const ingredient2Index = result.findIndex(i2 => i2.id === ingredient1.id);
      const ingredient2 = basePizzaIngredients[ingredient2Index];

      // !ingredient2 && ingredient1.action === INGREDIENT_ACTIONS.REMOVE --> shouldn't happen, won't add it
      // ingredient2 && ingredient1.action === INGREDIENT_ACTIONS.REMOVE --> remove ingredient
      if (!ingredient2 && ingredient1.action === INGREDIENT_ACTIONS.ADD) {
        // New ingredient, not present in base pizza
        result.push(ingredient1);
      } else if (ingredient2 && ingredient1.action === INGREDIENT_ACTIONS.ADD) {
        // Ingredient x2
        result.splice(ingredient2Index, 1, {
          ...ingredient1,
          quantity: ingredient1.quantity + 1
        });
      } else if (ingredient2 && ingredient1.action === INGREDIENT_ACTIONS.REMOVE) {
        // Remove ingredient
        result.splice(ingredient2Index, 1);
      }

      return result;
    },
    [...basePizzaIngredients]
  );

/* eslint-disable complexity */
export const buildPizzaDescription = (pizza, basePizzaData, hideDescription) => {
  const defaultIngredients = basePizzaData?.defaultIngredients;
  const wholeDefaultIngredients = defaultIngredients?.[pizza.productMenuableId]?.whole;

  if (!pizza.ingredients && !pizza.halves) {
    return hideDescription ? '' : pizza.description;
  }

  if (!pizza.halves) {
    const ingredients = mergeIngredients(pizza.ingredients, wholeDefaultIngredients);

    return flattenIngredientNames(ingredients);
  }

  const description = [];
  const { left, right } = pizza.halves;
  const sameBasePizza = left.menuableId === right.menuableId;
  const basePizzaIngredients = sameBasePizza && wholeDefaultIngredients;
  const leftDefaultIngredients =
    basePizzaIngredients || defaultIngredients?.[basePizzaData?.leftHalfId]?.half1;
  const rightDefaultIngredients =
    basePizzaIngredients || defaultIngredients?.[basePizzaData?.rightHalfId]?.half2;

  const leftIngredients = mergeIngredients(left.ingredients, leftDefaultIngredients);
  const rightIngredients = mergeIngredients(right.ingredients, rightDefaultIngredients);

  if (leftIngredients && leftIngredients.length > 0) {
    description.push(flattenIngredientNames(leftIngredients, `${t('CustomizableOptionList:half1')}:  `));
  }
  if (rightIngredients && rightIngredients.length > 0) {
    description.push(flattenIngredientNames(rightIngredients, `${t('CustomizableOptionList:half2')}:  `));
  }

  return description.filter(Boolean).join('\n');
};
/* eslint-enable complexity */
export const buildPizzaVariantName = (pizzaData, pizzaVariantsTranslator) =>
  `${t(`Home:${pizzaData?.size}`)}, ${
    pizzaVariantsTranslator
      ? pizzaVariantsTranslator[snakeToCamel(pizzaData?.crust)]
      : t(`Home:${pizzaData?.crust}`)
  }`;

export const getIngredientsWithPrice = (selections, half = PIZZA_HALVES.whole) => [
  ...(selections?.ingredients || []),
  ...(selections?.bases?.filter(b => b.priceModifier && (half === b.half || b.half === PIZZA_HALVES.whole)) ||
    [])
];

export const allBaseCategoriesAreSelected = (selections, half = PIZZA_HALVES.whole) => {
  const categoryHasSelection = selections?.bases?.reduce((result, base) => {
    result[base.category] = result[base.category] || base.half === half || base.half === PIZZA_HALVES.whole;

    return result;
  }, {});

  return baseCategories
    .filter(category => category !== buildPizzaSections.NOT_INGREDIENT)
    .every(category => categoryHasSelection?.[category]);
};

export const comboPizzaStepId = index => `step${index + 1}`;

export const getStepNumber = stepId => parseInt(stepId.split('step')[1]) - 1;

export const isPizzaCategory = category =>
  category === pizzaCategories.PIZZA ||
  category === pizzaCategories.PIZZA_PREMIUM ||
  category === pizzaCategories.PIZZA_SPECIALTY;
