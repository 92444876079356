import i18next from 'i18next';

i18next.addResources('es_CL', 'Login', {
  enter: 'Ingresar',
  forgotPassword: 'Recuperar contraseña',
  emailLabel: 'Email',
  passwordLabel: 'Contraseña',
  submitError: 'Error al iniciar sesión: Email y/o contraseña incorrecta',
  passwordResetRequiredException:
    'Tu seguridad es lo más importante y por eso implementamos continuas mejoras para seguir garantizándola.\n\n Para que sigas disfrutando de las mejores pizzas y de los mejores ingredientes, <Link>{{verifyHere}}</Link>.',
  otherAccount: 'O inicia sesión',
  facebook: 'Continuar con Facebook',
  google: 'Continuar con Google',
  withoutAccount: '¿Aún no tienes cuenta?',
  register: 'Regístrate',
  verifyHere: 'verifica aquí tu cuenta'
});

i18next.addResources('es_CR', 'Login', {
  otherAccount: 'O iniciá sesión',
  withoutAccount: '¿Aún no tenés cuenta?'
});

i18next.addResources('es_ES', 'Login', {
  enter: 'Entrar'
});

i18next.addResources('pt_PT', 'Login', {
  enter: 'ENTRAR',
  forgotPassword: 'Recuperar Palavra-chave',
  emailLabel: 'Email',
  passwordLabel: 'Palavra-chave',
  submitError: 'Erro ao iniciar a sessão: Email ou Senha incorrecta',
  passwordResetRequiredException:
    'A tua segurança é o mais importante, por isso implementamos continuamente melhorias para a garantir a todo o momento. Para que continues a desfrutar das melhores pizzas e dos melhores ingredientes, <Link>{{verifyHere}}</Link>.',
  otherAccount: 'Ou inicía sessão',
  facebook: 'Continuar com o Facebook',
  google: 'Continuar com o Google',
  withoutAccount: 'Ainda não tens conta?',
  register: 'Regista-te!',
  verifyHere: 'verifica a tua conta aqui'
});
