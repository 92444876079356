import Immutable from 'seamless-immutable';
import { createReducer, completeState, onReadValue, completeReducer } from 'redux-recompose';

import LocalStorageService from '~services/LocalStorageService';

import { PROMO_SELLING_TARGET } from './constants';
import { actions } from './actions';

const initialStateDescription = {
  [PROMO_SELLING_TARGET.PROMOS]: LocalStorageService.getPromoSelling() || [],
  [PROMO_SELLING_TARGET.ACTUAL_PROMOS]: LocalStorageService.getActualPromoSelling() || []
};

const initialState = completeState(initialStateDescription, [PROMO_SELLING_TARGET.ACTUAL_PROMOS]);

const reducerDescription = completeReducer({
  primaryActions: [actions.GET_SUGGESTIVE_PROMOS],
  override: {
    [actions.SET_ACTUAL_PROMOS]: onReadValue(action => {
      LocalStorageService.setActualPromoSelling(action.payload);
      return action.payload;
    }),
    [actions.CLEAR_SUGGESTIVE_PROMOS]: onReadValue()
  }
});

const reducer = createReducer(new Immutable(initialState), reducerDescription);

export default reducer;
