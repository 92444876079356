import { arrayHasLength } from './array';

// function adaptaed by https://davidwalsh.name/javascript-debounce-function
export function debounce(func, wait, immediate) {
  let timeout = undefined;

  return () => {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const callNow = immediate && !timeout;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

export const isElementInViewport = (el, scrollDifference, container) => {
  const rect = el.getBoundingClientRect();
  const containerRect = container !== document && container?.getBoundingClientRect();

  return (
    rect.top <=
      (containerRect?.bottom + scrollDifference ||
        window.innerHeight + scrollDifference ||
        document.documentElement.clientHeight + scrollDifference) &&
    rect.left <= (containerRect?.right || window.innerWidth || document.documentElement.clientWidth)
  );
};

export const retry = async (request, retries) => {
  const response = await request();

  if (!response.ok && retries > 0 && !response.abortRetries) {
    return retry(request, retries - 1);
  }

  return response;
};

/* eslint-disable no-magic-numbers */
// Checked using Luhn algorithm from -
// https://gist.github.com/DiegoSalazar/4075533
export const validateCreditCard = value => {
  if (/[^0-9-\s]+/.test(value)) {
    return false;
  }

  let nCheck = 0;
  let bEven = false;
  let replacedValue = value;

  replacedValue = value.replace(/\D/g, '');

  for (let n = replacedValue.length - 1; n >= 0; n--) {
    const cDigit = replacedValue.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) {
      nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
};

// Code from -
// https://stackoverflow.com/questions/27054951/how-do-i-validate-a-credit-card-expiry-date-with-javascript/27055165
export const validatePastExpiryDate = date => {
  if (!date) {
    return false;
  }

  const today = new Date();
  const monthToday = today.getMonth() + 1;
  const yearToday = today.getFullYear().toString().substr(-2);

  const [expMonth, expYear] = date.split('/');

  if (Number(expYear) < Number(yearToday)) {
    return false;
  } else if (Number(expMonth) < monthToday && Number(expYear) <= Number(yearToday)) {
    return false;
  }

  return true;
};

export const validateExpirationMonth = date => {
  if (!date) {
    return false;
  }

  const expMonth = date.split('/')[0];

  if (Number(expMonth) > 12) {
    return false;
  }

  return true;
};

export const hyphensToCamelCase = str => {
  const arr = str.split(/[_-]/);
  let newStr = '';
  for (let i = 1; i < arr.length; i++) {
    newStr += arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr[0] + newStr;
};

// Validation NIF Portugal
const nifRegex = new RegExp('^[0-9]+$');

export const validateNIF = (nif = '') => {
  if (nif.length === 9 && nifRegex.test(nif)) {
    const added =
      nif[7] * 2 + nif[6] * 3 + nif[5] * 4 + nif[4] * 5 + nif[3] * 6 + nif[2] * 7 + nif[1] * 8 + nif[0] * 9;
    const mod = added % 11;
    let control = 0;
    if (mod !== 0 && mod !== 1) {
      control = 11 - mod;
    }

    return parseInt(nif[8]) === control;
  }
  return false;
};

// Validation NIT Guatemala
// Reference for this code: https://jsfiddle.net/Newdarklady2004/w4Lfcna8/36/
const nitRegex = new RegExp('^[0-9]+(-?[0-9kK])?$');

export const validateNIT = (nit = '') => {
  if (nitRegex.test(nit)) {
    const cleanNit = nit.replace(/-/, '');

    const lastChar = cleanNit.length - 1;
    const number = cleanNit.substring(0, lastChar);
    const expectedValidation = cleanNit.substring(lastChar, lastChar + 1).toLowerCase();
    let factor = number.length + 1;
    let total = 0;

    for (let i = 0; i < number.length; i++) {
      const character = number.substring(i, i + 1);
      const digit = parseInt(character, 10);

      total += digit * factor;
      factor = factor - 1;
    }

    const module = (11 - (total % 11)) % 11;
    const resultValidation = module === 10 ? 'k' : module.toString();

    return expectedValidation === resultValidation;
  }

  return false;
};

export const needleFinder = (needle, haystack) =>
  arrayHasLength(needle) ? needle.find(value => haystack.includes(value)) : null;

export const copyToClipboard = text => navigator.clipboard.writeText(text);

export const isFunction = value => value && typeof value === 'function';
