import DOMPurify from 'dompurify';
import { push } from 'connected-react-router';

import { CLIENT_PAGES } from '~constants/pages';
import { deserializer } from '~services/baseSerializers';

import { ORDER_STATUSES } from './constants';
import { actionCreators } from './actions';

export const filterResponseFailed = response => {
  if (response.data) {
    const newResponse = deserializer.serialize(response.data);

    return {
      ...newResponse,
      page: newResponse.page ? newResponse.page.filter(el => el.status !== ORDER_STATUSES.FAILED) : []
    };
  }

  return {};
};

export const redirectPost = (url, token) => {
  const form = document.createElement('form');
  const input = document.createElement('input');

  // create mock form
  document.body.appendChild(form);
  form.method = 'post';
  form.action = url;
  input.type = 'hidden';
  input.name = 'token_ws';
  input.value = token;
  form.appendChild(input);
  form.submit();
  document.body.removeChild(form);
};

export const redirectToExternal = url => {
  window.location.href = url;
};

export const parsePowertranzForm = html => {
  const formString = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['form', 'input'],
    ALLOWED_ATTR: ['action', 'method', 'value', 'name'],
    KEEP_CONTENT: false
  });

  const div = document.createElement('div');

  div.innerHTML = formString;

  const formHtml = div.children[0];

  document.body.appendChild(div);
  formHtml.submit();
  document.body.removeChild(div);
};

export const sendFailedPurchase = (dispatch, externalError) => {
  dispatch(actionCreators.setExternalError(externalError));
  dispatch(push(CLIENT_PAGES.PURCHASE_FAIL.path));
};
