export const LANGUAGE_KEYS = {
  CHILE: 'es_CL',
  COSTA_RICA: 'es_CR',
  GUATEMALA: 'es_GT',
  PANAMA: 'es_PA',
  PORTUGAL: 'pt_PT',
  SPAIN: 'es_ES'
};

export const COUNTRIES = {
  [LANGUAGE_KEYS.CHILE]: 'Chile',
  [LANGUAGE_KEYS.COSTA_RICA]: 'Costa Rica',
  [LANGUAGE_KEYS.GUATEMALA]: 'Guatemala',
  [LANGUAGE_KEYS.PANAMA]: 'Panama',
  [LANGUAGE_KEYS.PORTUGAL]: 'Portugal',
  [LANGUAGE_KEYS.SPAIN]: 'España'
};

export const LANGUAGES_COMMON_TRANSLATIONS = [
  LANGUAGE_KEYS.CHILE,
  LANGUAGE_KEYS.COSTA_RICA,
  LANGUAGE_KEYS.GUATEMALA,
  LANGUAGE_KEYS.PANAMA
];
