/* eslint-disable import/no-extraneous-dependencies */
import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  palette: {
    primary: {
      main: '#65a17a'
    },
    error: {
      main: '#fc3a3a'
    }
  }
});

export default Theme;
