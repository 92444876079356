import i18next from 'i18next';

i18next.addResources('es_CL', 'PhoneForm', {
  title: '¡Ya casi terminamos!',
  subtitle: 'Sólo debes ingresar tu teléfono para finalizar',
  continue: 'Continuar'
});

i18next.addResources('es_ES', 'PhoneForm', {
  title: '¡Ya casi terminamos!',
  subtitle: 'Sólo debes ingresar tu teléfono para finalizar',
  continue: 'Continuar'
});

i18next.addResources('pt_PT', 'PhoneForm', {
  title: 'Estamos quase terminando!',
  subtitle: 'Você só precisa digitar seu telefone para terminar',
  continue: 'Continue'
});
