import i18next from 'i18next';

i18next.addResources('es_CL', 'LoginOldUser', {
  title: 'Hola, {{name}}!',
  text: "¡Los tiempos cambian y Papa John's te acompaña! ¡Reestablece tu contraseña e ingresa a la nueva experiencia de pedir pizza!",
  OkButton: 'Vincular cuenta'
});

i18next.addResources('es_ES', 'LoginOldUser', {
  title: 'Hola, {{name}}!',
  text: "¡Los tiempos cambian y Papa John's te acompaña! ¡Reestablece tu contraseña e ingresa a la nueva experiencia de pedir pizza!",
  OkButton: 'Vincular cuenta'
});

i18next.addResources('pt_PT', 'LoginOldUser', {
  title: 'Hola, {{name}}!',
  text: 'Os tempos mudam e Papa John o acompanha! Redefina sua senha e insira a nova experiência de pedido de pizza',
  OkButton: 'Vincular conta'
});
