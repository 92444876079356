import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';
import { generateI18nResources } from '~utils/i18n';

import { BUTTONS, INPUTS, SECTIONS } from './constants';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'GiftCards', {
  pageTitle: "Gift Cards | Papa John's {{country}}",
  title: '¡Regala una Gift Card Papa John’s!',
  information:
    'Esta Gift Card es digital. El receptor recibirá un correo con los detalles e instrucciones de uso. La Gift Card tiene una vigencia de {{months}} meses desde el momento de la compra.',
  name: 'Gift Card Papa John’s {{price}}',
  disclaimer: '* La tarjeta no se puede usar en productos con promociones.'
});

i18next.addResources(LANGUAGE_KEYS.SPAIN, 'GiftCards', {
  pageTitle: "Tarjeta Regalo | Papa John's {{country}}"
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'GiftCards', {
  title: 'Oferece um Gift Card Papa John’s!',
  information:
    'Este cartão é digital. O destinatário receberá um email com os detalhes e instruções de utilização. O cartão tem uma validade de {{months}} meses desde o momento da compra.',
  disclaimer: '* La tarjeta não pode ser usada em produtos com promoções.'
});

i18next.addResourceBundle(LANGUAGE_KEYS.CHILE, 'GiftCardForm', {
  sections: {
    [SECTIONS.SELECT_PRICE]: 'Selecciona el monto',
    [SECTIONS.GIFT_CARD_DETAILS]: 'Detalles de la Gift Card'
  },
  inputs: {
    [INPUTS.ADDRESEE_NAME]: 'Nombre del destinatario',
    [INPUTS.ADDRESEE_EMAIL]: 'Email del destinatario',
    [INPUTS.CUSTOMER_NAME]: 'De (tu nombre)',
    [INPUTS.MESSAGE]: {
      label: 'Mensaje (opcional):',
      placeholder: '¡Hola! Aquí tienes un regalo para que disfrutes en Papa John’s :)'
    }
  },
  buttons: {
    [BUTTONS.ADD_TO_CART]: 'Añadir al carrito',
    [BUTTONS.BUY_NOW]: 'Comprar ahora {{price}}',
    [BUTTONS.SAVE_AND_BACK]: 'Guardar y volver'
  }
});

generateI18nResources(
  [LANGUAGE_KEYS.GUATEMALA, LANGUAGE_KEYS.PANAMA, LANGUAGE_KEYS.COSTA_RICA],
  languageKey =>
    i18next.addResourceBundle(languageKey, 'GiftCardForm', {
      inputs: {
        [INPUTS.MESSAGE]: {
          placeholder: '¡Hola! Aquí tienes una tarjeta de regalo para que disfrutes en Papa John’s :)'
        }
      }
    })
);

i18next.addResourceBundle(LANGUAGE_KEYS.PORTUGAL, 'GiftCardForm', {
  sections: {
    [SECTIONS.SELECT_PRICE]: 'Seleciona o valor',
    [SECTIONS.GIFT_CARD_DETAILS]: 'Detalhes do Gift Card'
  },
  inputs: {
    [INPUTS.ADDRESEE_NAME]: 'Nome do destinatário',
    [INPUTS.ADDRESEE_EMAIL]: 'Email do destinatário',
    [INPUTS.CUSTOMER_NAME]: 'De (o teu nome)',
    [INPUTS.MESSAGE]: {
      label: 'Mensagem (opcional):',
      placeholder: 'Olá! Aqui tens um presente para aproveitares na Papa John’s :)'
    }
  },
  buttons: {
    [BUTTONS.ADD_TO_CART]: 'Adicionar ao Carrinho',
    [BUTTONS.BUY_NOW]: 'Comprar agora {{price}}',
    [BUTTONS.SAVE_AND_BACK]: 'Salvar e retornar'
  }
});
