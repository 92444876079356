import { completeTypes, createTypes } from 'redux-recompose';

import { statementsService } from '~services/Statements/service';

import { STATEMENTS_TARGET } from './constants';

const completedTypes = completeTypes(['GET_STATEMENT']);

export const actions = createTypes(completedTypes, '@@STATEMENT');

export const actionCreators = {
  getStatementFile: file => ({
    type: actions.GET_STATEMENT,
    target: STATEMENTS_TARGET,
    service: statementsService.getStatement,
    payload: file
  })
};
