import i18next from 'i18next';

i18next.addResources('es_CL', 'AboutUsOurValues', {
  title: 'Nuestros valores',
  subText:
    'Nuestros valores son el pilar de nuestra cultura, guían nuestro actuar y nos permiten tomar las mejores decisiones.',
  subTitle1: 'Servicio',
  text11: 'Poner a disposición todas nuestras capacidades para servir a nuestros clientes.',
  subTitle2: 'Excelencia',
  text21: 'Pasión por el trabajo bien hecho.',
  subTitle3: 'Respeto',
  text31: 'Valorar y apoyar a las personas con que trabajamos, creando un ambiente de confianza.',
  altTeam: 'Equipo'
});

// UPDATE THIS FOR SPAIN
i18next.addResources('es_ES', 'AboutUsOurValues', {
  title: 'Nuestros valores',
  subText:
    'Nuestros valores son el pilar de nuestra cultura, guían nuestro actuar y nos permiten tomar las mejores decisiones.',
  subTitle1: 'Servicio',
  text11: 'Poner a disposición todas nuestras capacidades para servir a nuestros clientes.',
  subTitle2: 'Excelencia',
  text21: 'Pasión por el trabajo bien hecho.',
  subTitle3: 'Respeto',
  text31: 'Valorar y apoyar a las personas con que trabajamos, creando un ambiente de confianza.',
  altTeam: 'Equipo'
});

i18next.addResources('pt_PT', 'AboutUsOurValues', {
  title: 'Nossos valores',
  subText:
    'Nossos valores são o pilar da nossa cultura, orientam nossas ações e nos permitem tomar as melhores decisões.',
  subTitle1: 'Serviço',
  text11: 'Disponibilizar todas as nossas capacidades para atender nossos clientes.',
  subTitle2: 'Excelência',
  text21: 'Paixão por um trabalho bem feito.',
  subTitle3: 'Respeito',
  text31: 'Valorizar e apoiar as pessoas com quem trabalhamos, criando um ambiente de confiança.',
  altTeam: 'Equipe'
});
