import i18next from 'i18next';

i18next.addResources('es_CL', 'PastOrders', {
  orderDescription: 'Pedido del {{- date}}',
  buttonRepeatOrder: 'Comprar ahora',
  buttonAddRepeatOrder: 'Agregar al carrito',
  lastOrderTitle: 'Último pedido',
  total: 'Total: {{quantity}}',
  loadingToCart: 'Tu pedido se está agregando al carrito',
  sorryText: 'Lo sentimos',
  warningText: 'Advertencia',
  withOutOrders: 'Ningún producto se encuentra en el menú actual',
  buttonConfirm: 'Confirmar',
  buttonAccept: 'Aceptar',
  buttonCancel: 'Cancelar',
  warningDescription:
    'Los siguientes productos no se encuentran en el menú actual y serán eliminados de tu carrito:',
  questionContinue: '¿Quieres continuar?',
  noCurrentSubsidiary: 'Debes seleccionar una tienda para poder repetir tu pedido',
  requestError: 'Lo sentimos! Ha ocurrido un error',
  retry: 'Reintentar'
});

i18next.addResources('es_ES', 'PastOrders', {
  orderDescription: 'Pedido del {{- date}}',
  buttonRepeatOrder: 'Comprar ahora',
  buttonAddRepeatOrder: 'Agregar al carrito',
  total: 'Total: {{quantity}}',
  lastOrderTitle: 'Último pedido',
  loadingToCart: 'Tu pedido se está añadiendo al carrito',
  sorryText: 'Lo sentimos',
  warningText: 'Advertencia',
  withOutOrders: 'Ningún producto se encuentra en el menú actual',
  buttonConfirm: 'Confirmar',
  buttonAccept: 'Aceptar',
  buttonCancel: 'Cancelar',
  warningDescription:
    'Los siguientes productos no se encuentran en el menú actual y serán eliminados de tu carrito:',
  questionContinue: '¿Quieres continuar?',
  noCurrentSubsidiary: 'Debes seleccionar una tienda para poder repetir tu pedido',
  requestError: 'Lo sentimos! Ha ocurrido un error',
  retry: 'Reintentar'
});

i18next.addResources('pt_PT', 'PastOrders', {
  orderDescription: 'Ordem de {{- date}}',
  buttonRepeatOrder: 'Comprar agora',
  buttonAddRepeatOrder: 'Adicionar ao carro',
  lastOrderTitle: 'Último pedido',
  total: 'Total: {{quantity}}',
  loadingToCart: 'Seu pedido está sendo adicionado ao carrinho',
  sorryText: 'Sentimos muito',
  warningText: 'Aviso',
  withOutOrders: 'Nenhum produto está no menu atual',
  buttonConfirm: 'Confirmar',
  buttonAccept: 'Aceitar',
  buttonCancel: 'Cancelar',
  warningDescription: 'Os seguintes produtos não estão no menu atual e serão removidos do seu carrinho:',
  questionContinue: 'Você quer continuar?',
  noCurrentSubsidiary: 'Você deve selecionar uma loja para poder repetir seu pedido',
  requestError: 'Sentimos muito! Ocorreu um erro',
  retry: 'Tente novamente'
});
