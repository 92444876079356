import OfferService from '../Offers/service';

import api from '~config/api';

const MENU_REQUESTS = 2;

const PriceService = {
  getPrices: async ({ storeId, dispatchMethod, offerId }) => {
    const responses = await Promise.all([
      api.get(`/v1/prices?store_id=${storeId}&dispatch_method=${dispatchMethod}`),
      OfferService.getPromotions(storeId, dispatchMethod, offerId)
    ]);

    if (responses.length === MENU_REQUESTS && responses.every(response => response.ok)) {
      return {
        menu: responses[0],
        combos: responses[1],
        ok: true
      };
    }

    return { ok: false };
  }
};

export default PriceService;
