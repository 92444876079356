import Immutable from 'seamless-immutable';
import {
  createReducer,
  completeState,
  completeReducer,
  onSetValue,
  onSuccess,
  onReadValue
} from 'redux-recompose';

import { actions as actionsModal } from '../Modal/actions';

import LocalStorageService from '~services/LocalStorageService';

import { actions } from './actions';
import {
  ADDRESS_TARGET,
  GUEST_USER_TARGET,
  CURRENT_USER_TARGET,
  CODE_TARGET,
  REGISTER_TARGET,
  RESEND_SMS_TARGET,
  RECOVER_PASSWORD_TARGET,
  VERIFY_RECOVER_PASSWORD_TARGET,
  ONBOARDING_USER_TARGET,
  IS_PASSWORD_UPDATED,
  IS_UPDATING_CURRENT_USER,
  IS_SOCIAL_USER,
  CALLCENTER_GUEST_USER_TARGET
} from './constants';

const initialStateDescription = {
  onInitialLoad: true,
  [ADDRESS_TARGET]: null,
  [GUEST_USER_TARGET]: null,
  [CURRENT_USER_TARGET]: null,
  [REGISTER_TARGET]: null,
  [CODE_TARGET]: null,
  [RESEND_SMS_TARGET]: null,
  [RECOVER_PASSWORD_TARGET]: null,
  [VERIFY_RECOVER_PASSWORD_TARGET]: null,
  [ONBOARDING_USER_TARGET]: null,
  [CALLCENTER_GUEST_USER_TARGET]: LocalStorageService.getCallCenterUser() || null,
  [IS_PASSWORD_UPDATED]: false,
  [IS_UPDATING_CURRENT_USER]: false,
  [IS_SOCIAL_USER]: false
};

const initialState = completeState(initialStateDescription, ['onInitialLoad', IS_SOCIAL_USER]);

initialState[`${GUEST_USER_TARGET}Loading`] = true;
initialState[`${CURRENT_USER_TARGET}Loading`] = true;
initialState[`${ADDRESS_TARGET}Loading`] = true;

const reducerDescription = {
  primaryActions: [
    actions.LOGIN,
    actions.LOGIN_ONBOARDING,
    actions.REGISTER,
    actions.SEND_CODE,
    actions.RESEND_SMS,
    actions.SET_ADDRESS,
    actions.CREATE_GUEST_USER,
    actions.GET_GUEST_USER,
    actions.GET_CURRENT_USER,
    actions.CLEAR_USER_DATA,
    actions.RECOVER_PASSWORD,
    actions.VERIFY_RECOVER_PASSWORD,
    actions.UPDATE_USER_DATA,
    actions.UPDATE_PASSWORD,
    actions.SET_CALLCENTER_GUEST_USER,
    actions.GET_CALLCENTER_GUEST_USER
  ],
  override: {
    [actions.CLEAR_USER_DATA]: onSuccess(),
    [actions.CHECK_AUTH]: (state, { payload }) =>
      state.merge({
        onInitialLoad: payload
      }),
    [actionsModal.CLOSE_MODAL]: state =>
      state.merge({
        [`${CURRENT_USER_TARGET}Error`]: null,
        [`${REGISTER_TARGET}Error`]: null,
        [`${CODE_TARGET}Error`]: null,
        [`${RESEND_SMS_TARGET}Error`]: null,
        [`${RECOVER_PASSWORD_TARGET}`]: null,
        [`${RECOVER_PASSWORD_TARGET}Error`]: null,
        [VERIFY_RECOVER_PASSWORD_TARGET]: null,
        [`${VERIFY_RECOVER_PASSWORD_TARGET}Error`]: null
      }),
    [actions.RESET_CODE]: onSetValue(null),
    [actions.RESET_CODE_VALIDATION]: state =>
      state.merge({
        [`${CODE_TARGET}Error`]: null,
        [`${VERIFY_RECOVER_PASSWORD_TARGET}Error`]: null
      }),
    [actions.RESET_AUTH]: state =>
      state.merge({
        [CURRENT_USER_TARGET]: null,
        [ONBOARDING_USER_TARGET]: null,
        [`${CURRENT_USER_TARGET}Error`]: null,
        [REGISTER_TARGET]: null,
        [`${REGISTER_TARGET}Error`]: null,
        [CODE_TARGET]: null,
        [`${CODE_TARGET}Error`]: null,
        [RESEND_SMS_TARGET]: null,
        [`${RESEND_SMS_TARGET}Error`]: null,
        [RECOVER_PASSWORD_TARGET]: null,
        [`${RECOVER_PASSWORD_TARGET}Error`]: null,
        [VERIFY_RECOVER_PASSWORD_TARGET]: null,
        [`${VERIFY_RECOVER_PASSWORD_TARGET}Error`]: null
      }),
    [actions.SET_VALUE]: onReadValue()
  }
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
