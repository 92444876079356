import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'ProductDetail', {
  detail: 'Detalle',
  add: 'con {{ingredient}}',
  remove: 'sin {{ingredient}}',
  whole: 'Todo:',
  left: 'Izquierda:',
  right: 'Derecha:'
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'ProductDetail', {
  detail: 'Detalhes',
  add: 'com {{ingredient}}',
  remove: 'sem {{ingredient}}',
  whole: 'Todo:',
  left: 'Esquerda:',
  right: 'Direita:'
});
