import React, { lazy, useEffect, useState } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Suspense from '../Suspense';
import { CanonicalAlternateHeadLink } from '../CanonicalAlternateHeadLink';

import { CLIENT_PAGES, CALL_CENTER_PAGES } from '~constants/pages';
import { history } from '~redux/store';
import { initializeIntercom } from '~services/IntercomService';
import { RENDER_CALL_CENTER } from '~constants/environment';
import { completeOgObject, getOgMetaTags } from '~utils/seo';

import styles from './styles.module.scss';
import { INDEX_PAGE } from './constants';

const Dashboard = lazy(() => import('~screens/Dashboard'));
const CallCenter = lazy(() => import('~screens/CallCenter'));

function AppRoutes() {
  const [currentPage, setCurrentPage] = useState({});

  useEffect(() => {
    let pathName = `/${window.location.pathname.split('/')[1]}`;

    if (pathName === INDEX_PAGE) {
      pathName = '/';
    }

    const pageFound = Object.keys(CLIENT_PAGES).find(
      page => CLIENT_PAGES[page].path === pathName || CLIENT_PAGES[page].basePath === pathName
    );

    pageFound && setCurrentPage(CLIENT_PAGES[pageFound]);
  }, [window.location.pathname]);

  const ogTags = getOgMetaTags(
    completeOgObject({
      title: currentPage.title,
      description: currentPage.description,
      path: currentPage.path
    })
  );

  return (
    <ConnectedRouter history={history}>
      <div className={`column center ${styles.container}`}>
        <Helmet>
          <title>{currentPage.title}</title>
          <meta name="description" content={currentPage.description} />
          {ogTags}
        </Helmet>
        <CanonicalAlternateHeadLink />
        <Suspense>
          <Helmet>
            <script>{initializeIntercom()}</script>
          </Helmet>
          {RENDER_CALL_CENTER ? (
            <Route path={CALL_CENTER_PAGES.LOGIN.path} component={CallCenter} />
          ) : (
            <Route path={CLIENT_PAGES.HOME.path} component={Dashboard} />
          )}
        </Suspense>
      </div>
    </ConnectedRouter>
  );
}

export default AppRoutes;
