import React from 'react';

import { PRODUCT_CATEGORIES } from '~screens/Dashboard/screens/Home/components/Menu/constants';
import { ReactComponent as Pizza } from '~assets/upselling/generic_pizza.svg';
import { ReactComponent as Star } from '~assets/upselling/star.svg';

import styles from './styles.module.scss';

export const MAX_HEIGHT = 47;

export const ICON_DECORATIONS_IDS = {
  YELLOW: 'yellow',
  GREEN: 'green',
  RED: 'red'
};

export const ICON_BY_CATEGORY = {
  [PRODUCT_CATEGORIES.PIZZA]: <Pizza className={styles.pizzaIcon} />
};

export const ICON_DECORATIONS = [
  {
    id: ICON_DECORATIONS_IDS.YELLOW,
    Component: Star,
    className: styles[`${ICON_DECORATIONS_IDS.YELLOW}Star`]
  },
  {
    id: ICON_DECORATIONS_IDS.GREEN,
    Component: Star,
    className: styles[`${ICON_DECORATIONS_IDS.GREEN}Star`]
  },
  {
    id: ICON_DECORATIONS_IDS.RED,
    Component: Star,
    className: styles[`${ICON_DECORATIONS_IDS.RED}Star`]
  }
];

export const VERBS_TENSES = {
  PRESENT: 'present',
  PAST: 'past'
};

export const NOUN_NUMBER = {
  SINGULAR: 'singular',
  PLURAL: 'plural'
};

export const VARIANT_TYPES = {
  SIZE: 'size',
  CRUST: 'crust',
  CRUST_AND_DOUGH: 'crustAndDough'
};

export const ITEM_CATEGORIES = {
  SINGLE: 'single',
  OFFER: 'offer'
};
