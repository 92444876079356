const base64 = require('~utils/base64');

const tempStorage = {};

const encodeKey = key => base64.encode(key);

const decodeKey = key => base64.decode(key);

const removeValue = key => {
  const encodedKey = encodeKey(key);

  try {
    window.localStorage.removeItem(encodedKey);
  } catch (e) {
    tempStorage[encodedKey] = undefined;
  }
};

const getValue = key => {
  const encodedKey = encodeKey(key);
  let encodedValue = undefined;

  try {
    encodedValue = window.localStorage.getItem(encodedKey);
  } catch (e) {
    encodedValue = tempStorage[encodedKey];
  }

  const stringValue = encodedValue && decodeKey(encodedValue);

  try {
    const parsedValue = JSON.parse(stringValue);
    if (parsedValue && parsedValue.expiration) {
      const now = new Date().getTime();
      if (now > parsedValue.expiration) {
        removeValue(key);
        return null;
      }
      return parsedValue.value;
    }
    return parsedValue.value || parsedValue;
  } catch (e) {
    return null;
  }
};

const setValue = (key, value, expirationMinutes = null) => {
  const encodedKey = encodeKey(key);
  const now = new Date().getTime();
  const expirationTime = expirationMinutes ? now + expirationMinutes * 60 * 1000 : null;
  const data = {
    value,
    expiration: expirationTime
  };
  const stringValue = JSON.stringify(data);
  const encodedValue = encodeKey(stringValue);

  try {
    window.localStorage.setItem(encodedKey, encodedValue);
  } catch (e) {
    tempStorage[encodedKey] = encodedValue;
  }
};

const defineProperty = (prop, defaultKey = '', tag = '') => {
  const projectName = 'pj-react'.replace(/-/g, '_').toUpperCase();
  const capitalizedKey = `${prop[0].toUpperCase()}${prop.substring(1)}`;

  module.exports[`set${capitalizedKey}`] = (val, key = defaultKey, expirationMinutes = null) => {
    const fullKey = `@@${projectName}:${prop}${tag}${key}`;
    setValue(fullKey, val, expirationMinutes);
  };
  module.exports[`get${capitalizedKey}`] = (key = defaultKey) =>
    getValue(`@@${projectName}:${prop}${tag}${key}`);
  module.exports[`remove${capitalizedKey}`] = (key = defaultKey) =>
    removeValue(`@@${projectName}:${prop}${tag}${key}`);
};

// ------------------------------ LOCAL STORAGE PROPERTIES ------------------------------
defineProperty('fakeStoreId');
defineProperty('sessionTokens');
defineProperty('currentAccountId', 'Any', 'User');
defineProperty('adviceSubmittedDate');
defineProperty('currentSubsidiary');
defineProperty('dispatchType');
defineProperty('shoppingCart');
defineProperty('upsellingVariants');
defineProperty('actualPromoSelling');
defineProperty('promoSelling');
defineProperty('optimizedShoppingCart');
defineProperty('addresses');
defineProperty('guestUser');
defineProperty('socialUser');
defineProperty('hideSnackbar');
defineProperty('hideTutorial');
defineProperty('callCenter');
defineProperty('callCenterUser');
defineProperty('cookiesAccept');
defineProperty('lastActiveOrder');
defineProperty('currentUser');
defineProperty('selectedDeliveryTime');
defineProperty('previousActionOpenAddressModal');
defineProperty('orderIdLastPurchaseEvent');
