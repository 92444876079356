import React from 'react';
import { string, node } from 'prop-types';
import { t } from 'i18next';

import { logoImage } from '~constants/assets';

import { ReactComponent as Maintenance } from './assets/ic_maintenance.svg';
import styles from './styles.module.scss';

function ErrorScreen({ children, title, description }) {
  return (
    <div className={`column start full-width ${styles.container}`}>
      <img className="m-top-2 m-bottom-4 pj-logo" src={logoImage} alt={t('App:papaJohnsLogo')} />
      <div className={`column center middle full-width ${styles.viewContainer}`}>
        <Maintenance className={`m-bottom-4 ${styles.maintenanceLogo}`} />
        {title && <span className={`m-bottom-2 bold subtitle-medium ${styles.titleText}`}>{title}</span>}
        {description && (
          <span className={`text-base text-dusty-gray ${styles.smallText}`}>{description}</span>
        )}
        {children}
      </div>
    </div>
  );
}

ErrorScreen.propTypes = {
  children: node,
  description: string,
  title: string
};

export default ErrorScreen;
