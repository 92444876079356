import { createSelector } from 'reselect';

import {
  SHOPPING_CART_TARGET,
  CORE_SETTINGS_TARGET,
  REDEEMED_CODE_TARGET,
  ORDER_SETTINGS_TARGET
} from '~screens/Dashboard/screens/Home/redux/constants';

export const shoppingCartSelector = state => state.home[SHOPPING_CART_TARGET];
export const coreSettingsSelector = state => state.home[CORE_SETTINGS_TARGET] || {};
export const redeemedCodeSelector = state => state.home[REDEEMED_CODE_TARGET] || {};
export const orderSettingsSelector = state => state.home[ORDER_SETTINGS_TARGET] || {};

export const getItemQuantity = item => (item.isGiftCard ? 1 : item.quantity);

export const getCartQuantity = createSelector(shoppingCartSelector, shoppingCart =>
  shoppingCart.items.reduce((result, item) => result + getItemQuantity(item), 0)
);

export const storeItemsSelector = createSelector(
  state => state.home?.flattenedMenu,
  state => state.home?.flattenedCombos,
  (flattenedMenu, flattenedCombos) => ({ ...flattenedMenu, ...flattenedCombos })
);
