import { t } from 'i18next';

export const TECHNICALS_COOKIE = 'technicalsCookie';
export const ANALYTICS_COOKIE = 'analyticsCookie';
export const MARKETING_COOKIE = 'marketingCookie';

export const COOKIE_TYPES = [
  {
    id: TECHNICALS_COOKIE,
    name: t('CookieSettings:technicalsCookie'),
    disabled: true
  },
  {
    id: ANALYTICS_COOKIE,
    name: t('CookieSettings:analyticsCookie'),
    disabled: false
  },
  {
    id: MARKETING_COOKIE,
    name: t('CookieSettings:marketingCookie'),
    disabled: false
  }
];
