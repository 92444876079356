import { buildPizzaSections } from '~constants/products';
import { DEFAULT_INGREDIENT_QUANTITY, DEFAULT_INGREDIENT_ACTION_TYPE } from '~constants/buildPizza';

export const INGREDIENT_API_TEMPLATE = {
  id: 0,
  category: buildPizzaSections.VEGETABLE,
  quantity: DEFAULT_INGREDIENT_QUANTITY,
  action: DEFAULT_INGREDIENT_ACTION_TYPE,
  name: undefined
};

export const INGREDIENTS = 'ingredients';
