import api from '~config/api';
import { serializer as baseSerializer } from '~services/baseSerializers';

const CallCenterService = {
  getUserByPhone: phone => api.get(`v1/call_center/users/filter_user?phone=${phone}`),
  updateUser: ({ externalId, data }) => api.patch(`v1/call_center/users/update_me#${externalId}`, data),
  getUserAddress: externalId => api.get(`/v1/call_center/addresses#${externalId}`),
  createUserAddress: ({ externalId, address }) => {
    const { apartmentDetails = null, dispatchNote = null, ...addressValues } = address;
    return api.post(
      `v1/call_center/addresses#${externalId}`,
      baseSerializer.serialize({ address: { ...addressValues, apartmentDetails, dispatchNote } })
    );
  },
  updateUserAddress: async ({ externalId, address }) => {
    const { apartmentDetails = null, dispatchNote = null, ...addressValues } = address;
    const response = await api.patch(
      `v1/call_center/addresses/${addressValues.id}#${externalId}`,
      baseSerializer.serialize({ ...addressValues, apartmentDetails, dispatchNote })
    );
    return response;
  },
  deleteUserAddress: async ({ externalId, id }) => {
    const response = await api.delete(`v1/call_center/addresses/${id}#${externalId}`);
    return response;
  },
  getUserOrders: async externalId => {
    const response = await api.get(`/v1/call_center/orders#${externalId}`);
    return response;
  }
};

export default CallCenterService;
