import { isEmptyArray } from '~utils/array';

import { getGiftCardCurrentPrice } from './giftCard';

export const onlyContainDigitalItems = order =>
  isEmptyArray(order?.items) && isEmptyArray(order?.offerItems) && !isEmptyArray(order?.giftCardItems);

export const getPaymentMethod = order =>
  [order.paymentMethod, !!order?.giftCard?.amount && 'giftcard'].filter(value => !!value).join('-');

export const getGiftCardAmountUsed = order => order?.giftCard?.amount;

export const getOrderTotalTaxes = order => order?.taxes?.reduce((acc, { charge }) => acc + charge, 0);

export const getOrderSubtotal = order =>
  order.totalPrice - order.delivery - getOrderTotalTaxes(order) + getGiftCardAmountUsed(order);

export const getOrderTotal = order => order?.totalPrice + order.tip;

export const updateGiftCardPrice = (giftCardsAmounts, discountsActive, giftCard) => ({
  ...giftCard,
  isGiftCard: true,
  price: discountsActive ? getGiftCardCurrentPrice(giftCardsAmounts, giftCard.amount) : giftCard.amount
});

const isValidGiftCard = (giftCardItem, giftCardsAmounts) =>
  giftCardsAmounts.map(giftCardAmount => giftCardAmount.showingPrice).includes(giftCardItem.amount);

export const getValidGiftCards = (giftCardItems = [], coreSettings = {}) => {
  const { giftCardsActive, giftCardsAmounts, giftCardsDiscountsActive } = coreSettings;
  return giftCardsActive
    ? giftCardItems
        .filter(giftCardItem => isValidGiftCard(giftCardItem, giftCardsAmounts))
        .map(giftCardItem => updateGiftCardPrice(giftCardsAmounts, giftCardsDiscountsActive, giftCardItem))
    : [];
};

const normalizeMissingGiftCards = (giftCardItems = []) =>
  giftCardItems.map((giftCard, index) => ({
    ...giftCard,
    id: `${giftCard.name}-${index}`
  }));

export const getMissingGiftCards = (giftCardItems = [], coreSettings = {}) => {
  const { giftCardsActive, giftCardsAmounts } = coreSettings;
  return normalizeMissingGiftCards(
    giftCardsActive
      ? giftCardItems.filter(giftCardItem => !isValidGiftCard(giftCardItem, giftCardsAmounts))
      : giftCardItems
  );
};

export const getOrderItems = order =>
  ['giftCardItems', 'items', 'offerItems'].map(key => order?.[key] || []).flat();

export const getOrderTotalItems = order =>
  getOrderItems(order).reduce((acc, item) => acc + (item?.quantity || 1), 0);
