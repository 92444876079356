import i18next from 'i18next';

i18next.addResources('es_CL', 'EmailStep', {
  title: 'Introduce tu mail a continuación',
  description:
    'Recibirás un código de verificación a la dirección de correo electrónico que proporciones para continuar.',
  emailFieldLabel: 'Email',
  sendCode: 'Enviar código',
  back: 'Volver',
  error: '¡Vaya! Algo no ha salido bien. Inténtalo de nuevo. Si el problema persiste, inténtalo más tarde.'
});

i18next.addResources('pt_PT', 'EmailStep', {
  title: 'Introduz o teu e-mail abaixo',
  description: 'Receberás um código de verificação no endereço de e-mail que forneceres para continuar.',
  emailFieldLabel: 'Email',
  sendCode: 'Enviar código',
  back: 'Voltar',
  error: 'Ups! Algo deu errado. Tenta novamente. Se o problema persistir, tenta mais tarde.'
});
