import qs from 'qs';

import { stripTrailingSlash } from '~utils/parseUrl';
import { formatDate } from '~utils/time';

import { PHONE_TAKEN } from './constants';
import { bodySerializer } from './serializers';

export const getSocialLoginBody = code => {
  const body = {
    grantType: 'authorization_code',
    clientId: process.env.REACT_APP_CLIENT_ID,
    code,
    redirectUri: stripTrailingSlash(window.location.href.split('?')[0])
  };

  return qs.stringify(bodySerializer.serialize(body), { encode: true });
};

export const getSocialTokens = ({ idToken, accessToken, refreshToken }) => ({
  idToken,
  accessToken,
  refreshToken
});

export const getPhoneTakenError = message => message && message.includes(PHONE_TAKEN);

export const getNewsletterBody = user => ({
  subscription: {
    email: user.email,
    accepted: user.subscribeToNewsletter
  }
});

export const getNewUserBody = user => ({
  user: {
    name: user.Name,
    email: user.Username,
    phone: user.Phone,
    password: user.Password,
    accepted: user.Suscribe,
    ...(user.birthdate && { birthdate: formatDate(user.Birthdate) })
  }
});
