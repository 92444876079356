import i18next from 'i18next';

i18next.addResources('es_ES', 'FranchisesInformation', {
  pagePath: '/franquicias',
  pageTitle: "Información de Franquicias ▷ Papa John's Chile",
  pageDescription:
    "¿Quieres poner tu propio negocio de pizzería Papa John's? Te contamos todo lo necesario sobre las franquicias Papa John's en Chile.",
  keywords: 'Franquicias papa johns'
});

i18next.addResources('es_ES', 'Franchises', {
  pagePath: '/franquicias',
  pageTitle: '▷ Información de Franquicias | Papa Johns España',
  pageDescription:
    "¿Quieres montar tu propia pizzería Papa John's? Aquí encontrarás toda la información sobre las franquicias Papa John's en España.",
  keywords: 'Franquicias papa johns',
  title1: "Franquicias Papa John's",
  title2: 'HITOS Y CURIOSIDADES DE LA MARCA',
  title3: '¿COMO SER FRANQUICIADO?',
  title4: 'TIPOLOGIA DE LOCAL Y CONDICIONES ECONOMICAS',
  title5: 'AREAS DE APOYO AL FRANQUICIADO',
  title6: 'CONTACTO',
  text1:
    'Todo comienza en 1984, cuando John Schnatter, que trabaja en una pizzería, sintió que el sabor de lo que hacía era muy artificial y no estaba satisfecho con la forma en la que se elaboraban las pizzas.',
  text2:
    'Por ello, vendió su Camaro por $1.600 para fundar su propio restaurante de pizzas, teniendo como prioridad su elaboración de forma artesanal, usando siempre la mejor masa y los mejores ingredientes. Este lema de “mejores ingredientes, mejores pizzas” es el eje central de nuestra marca y es lo que nos diferencia de nuestros competidores.',
  text3:
    'A día de hoy, Papa John´s está presente en 43 países, con más de 5.500 locales en el mundo, con un modelo basado en una operativa sencilla, en la alta calidad de sus productos y en el enfoque constante de satisfacer las necesidades de nuestros clientes.',
  text4:
    'Papa John´s abrió su primera tienda en España en 2015 y desde entonces no hemos dejado de crecer, con más de 70 restaurantes operativos en España, lo que demuestra el éxito de la marca.',
  text5:
    'Papa John´s se ha convertido en un modelo de éxito a nivel mundial, lo que fortalece la marca en la apertura y crecimiento de nuevos mercados y un modelo inversión seguro, con altos ratios de rentabilidad, que hacen de Papa John´s una garantía de éxito para sus franquiciados.',
  timeline1: '1986: Inicio de las Franquicias.',
  timeline2: '1998: Comienza la expansión internacional de la marca en Méjico y Puerto Rico.',
  timeline3:
    '2001: Se convierte en la primera cadena de Pizzerias en disponer de un servicio online en todo el territorio americano.',
  timeline4:
    '2015: El Reino Unido llega a 300 tiendas y lo celebra batiendo el récord GUINESS al mayor número de personas (337) lanzando la masa simultáneamente en el puente Potters Fields Park de Londres.',
  timeline5:
    '2019: Shaquille O´Neal entra como franquiciado con tiendas en Atlanta y Los Angeles. Es nombrado Embajador de la Marca y forma parte de la junta directiva de la compañía.',
  step1:
    'Solicita información a través de nuestro email infofranquicia@pjespana.es dejando tus datos, teléfono y zona de interés.',
  step2:
    'Concertaremos una reunión personal en la que valoraremos tu candidatura y analizaremos tus posibilidades de expansión.',
  step3: 'Proceso de validación del franquiciado.',
  step4: 'Firma de precontrato, donde quedaran definidas las zonas de expansión del franquiciado.',
  step5: 'Búsqueda del proyecto que se adapte a tus necesidades.',
  step6: 'Firma de contrato de franquicia y arrendamiento.',
  type1: 'Locales a partir de 150m2 en planta calle.',
  type2: 'Inversión estimada: 280.000 €.',
  type3: 'Canon de entrada: 30.000 €',
  type4: 'Royalty: 5%',
  type5: 'Aportación al fondo de MK: 4%',
  text6:
    'En el momento que eres franquiciado de Papa John´s, ponemos a disposición del franquiciado todos los departamentos con los mejores profesionales para dar apoyo durante toda la vida del negocio asegurando así sea un éxito.',
  text7:
    'Operaciones: podremos a tu disposición un asesor de operaciones específico para tu local que te asesorará en toda la vida de tu negocio. Además, pondremos a tu disposición personal propio para la apertura.',
  text8:
    'Marketing: desarrollará de Campañas de Marketing Nacional, del Plan de Apertura y Marketing Local de cada franquiciado, así como todas las acciones de comunicación definidas para la marca. También contamos con el apoyo de Papa Johns a nivel internacional con campañas en grandes eventos con repercusión a nivel mundial.',
  text9:
    'Formación: Se formará a toda la plantilla de trabajadores del franquiciado, con formación específica para los diferentes rangos de la plantilla. Formación también para el franquiciado.',
  text10:
    'Expansión: Análisis de las posibles oportunidades de desarrollo dentro de la zona de influencia del franquiciado. Apoyo y asesoramiento en la búsqueda de locales, así como en cierre de contratos de arrendamiento.',
  text11:
    'Si estas interesado en montar tu propia franquicia de Papa Johns, escribe un email a la dirección infofranquicia@pjespana.es indicando tus datos personales e información básica de tus zona de interés y comenzaremos el proceso que te llevara a ser un franquiciado de éxito dentro de la familia Papa John’s.',
  altImgInsideStore: 'imagen interior local papa john`s',
  altImgShaquille: "Imagen shaquille o'neal",
  altImgTeamPj: 'imagen equipo de trabajo papa john`s',
  titleTimeLine1: 'Inicio de las Franquicias',
  titleTimeLine2: 'Expansión Internacional',
  titleTimeLine3: 'Servicio Online',
  titleTimeLine4: '300 tiendas',
  titleTimeLine5: 'Shaquille O’Neal franquiciado'
});

i18next.addResources('pt_PT', 'FranchisesInformation', {
  pagePath: '/franquias',
  pageTitle: "Franquias | Papa John's Portugal",
  pageDescription: 'Franquias'
});

i18next.addResources('pt_PT', 'Franchises', {
  pagePath: '/franquias',
  pageTitle: "Franquias | Papa John's Portugal",
  pageDescription: 'franquias',
  title1: 'HISTÓRIA',
  title2: 'MARCOS E CURIOSIDADES DA MARCA',
  title3: '¿COMO SER FRANQUEADO?',
  title4: 'TIPOLOGIA DE LOJA E CONDIÇÕES ECONÓMICAS',
  title5: 'ÁREAS DE APOIO AO FRANQUEADO',
  title6: 'CONTACTO',
  text1:
    'Tudo começou em 1984, quando John Schnatter, que trabalhava numa pizaria, sentiu que o sabor do que fazia era muito artificial e não estava satisfeito com a forma como as pizas eram elaboradas.',
  text2:
    'Por isso, vendeu o seu Camaro por $1.600 para fundar o seu próprio restaurante de pizas, tendo como prioridade a sua elaboração de forma artesanal, usando sempre a melhor massa e os melhores ingredientes. Este lema de "melhores ingredientes, melhores pizas" é o eixo central da nossa marca e é o que nos distingue dos nossos concorrentes.',
  text3:
    'Atualmente, a Papa John´s está presente em 43 países, com mais de 5.500 lojas em todo o mundo, com um modelo baseado numa operação simples, na alta qualidade dos seus produtos e no enfoque constante na satisfação das necessidades dos seus clientes.',
  text4:
    'A Papa John´s abriu a sua primeira loja na Espanha em 2015 e desde então não deixou de crescer, com mais de 70 restaurantes operacionais na Espanha, o que demonstra o êxito da marca.',
  text5:
    'A Papa John´s converteu-se num modelo de êxito a nível mundial, o que reforça a marca na abertura e crescimento de novos mercados e um modelo de investimento seguro, com altos rácios de rentabilidade, que fazem com que a Papa John´s seja uma garantia de êxito para os seus franqueados.',
  timeline1: '1986: Início das Franquias.',
  timeline2: '1998: Começa a expansão internacional da marca no México e em Puerto Rico.',
  timeline3:
    '2001: Converte-se na primeira cadeia de Pizarias a dispor de um serviço online em todo o território americano.',
  timeline4:
    '2015: O Reino Unido chega a 300 lojas e fá-lo batendo o recorde GUINESS do maior número de pessoas (337) a lançar a massa simultaneamente na ponte Potters Fields Park de Londres.',
  timeline5:
    '2019: Shaquille O´Neal entra como franqueado com lojas em Atlanta e Los Angeles. É nomeado Embaixador da Marca e faz parte da junta diretiva da empresa.',
  step1:
    'Solicite informações através do nosso e-mail infofranquia@ppjportugal.pt  deixando os seus dados, telefone e zona de interesse.',
  step2:
    'Combinaremos uma reunião pessoal em que avaliaremos a sua candidatura e analisaremos as suas possibilidades de expansão.',
  step3: 'Processo de validação do franqueado.',
  step4: 'Assinatura de pré-contrato, onde ficarão definidas as zonas de expansão do franqueado.',
  step5: 'Procura do projeto que se adapte às suas necessidades.',
  step6: 'Assinatura de contrato de franquia e arrendamento.',

  type1: 'Lojas a partir de 150m2 em rés-do-chão.',
  type2: 'Investimento previsto: 280.000€ - 300.000€',
  type3: 'Taxa de entrada: 20.000 €',
  type4: 'Royalty: 5.5%',
  type5: 'Contribuição para o fundo de MK: entre 4% e 6%',
  text6:
    'No momento em que o franqueado passa a ser franqueado da Papa John´s, colocamos à disposição do mesmo todos os departamentos com os melhores profissionais para prestarem apoio durante toda a vida do negócio, garantindo assim que será um êxito',
  text7:
    'Operações: Colocaremos à sua disposição um assessor de operações específico para a sua loja, que lhe prestará assessoria ao longo de toda a vida do seu negócio. Além disso, colocaremos à sua disposição pessoal próprio para a abertura.',
  text8:
    'Marketing: Desenvolver-se-ão Campanhas de Marketing Nacional, do Plano de Abertura e Marketing Local de cada franqueado, assim como todas as ações de comunicação definidas para a marca. Também contamos com o apoio da Papa Johns a nível internacional com campanhas em grandes eventos com repercussão a nível mundial.',
  text9:
    'Formação: Formar-se-á todo o quadro de trabalhadores do franqueado, com formação específica para as diferentes gamas do pessoal. Formação também para o franqueado.',
  text10:
    'Expansão: Análise das eventuais oportunidades de desenvolvimento na zona de influência do franqueado. Apoio e assessoria na procura de espaços comerciais, assim como no fecho de contratos de arrendamento.',
  text11:
    "Se estiver interessado em montar a sua própria franquia da Papa Johns, envie um e-mail para o endereço infofranquia@ppjportugal.pt indicando os seus dados pessoais e informações básicas da sua zona de interesse e iniciaremos o processo que o levará a ser um franqueado de êxito no seio da família Papa John's.",
  altImgInsideStore: 'imagem interior local papa john`s',
  altImgShaquille: "imagem shaquille o'neal",
  altImgTeamPj: 'imagem da equipe de trabalho papa john`s',
  titleTimeLine1: 'Início das Franquias',
  titleTimeLine2: 'Expansão internacional',
  titleTimeLine3: 'Serviço on-line',
  titleTimeLine4: '300 lojas',
  titleTimeLine5: 'Shaquille O’Neal franquiciado'
});
