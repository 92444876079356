import { t } from 'i18next';

import { PHONE_TAKEN, NOT_FOUND, NETWORK_ERROR } from '~services/Auth/constants';

import { API_ERROR_CODES } from './apiErrors';

const cognitoErrors = {
  NotAuthorizedException: t('CognitoErrors:NotAuthorizedException'),
  COGNITO_INVALID_PASSWORD: t('CognitoErrors:InvalidPasswordException'),
  UsernameExistsException: t('CognitoErrors:UsernameExistsException'),
  UserNotFoundException: t('CognitoErrors:UserNotFoundException'),
  InvalidParameterException: t('CognitoErrors:InvalidParameterException'),
  InvalidParameterValue: t('CognitoErrors:InvalidParameterValue'),
  InvalidPasswordException: t('CognitoErrors:InvalidPasswordException'),
  UserNotConfirmedException: t('CognitoErrors:UsernameExistsException'),
  CodeDeliveryFailureException: t('CognitoErrors:CodeDeliveryFailureException'),
  CodeMismatchException: t('CognitoErrors:CodeMismatchException'),
  LimitExceededException: t('CognitoErrors:LimitExceededException'),
  NetworkError: t('CognitoErrors:NetworkError'),
  UserLambdaValidationException: t('CognitoErrors:UserLambdaValidationException'),
  [PHONE_TAKEN]: t('CognitoErrors:PhoneTaken'),
  [NOT_FOUND]: t('CognitoErrors:NotFound'),
  [NETWORK_ERROR]: t('CognitoErrors:NetworkError'),
  [API_ERROR_CODES.e1029]: t('CognitoErrors:UserNotFound'),
  [API_ERROR_CODES.e1028]: t('CognitoErrors:UsernameExistsException')
};

export default cognitoErrors;
