import i18next from 'i18next';

i18next.addResources('es_CL', 'BasePizzaSelector', {
  basePizza: 'Especialidad (opcional)',
  placeholder: 'Selecciona especialidad mitad {{half}}',
  noBasePizza: 'Arma tu pizza',
  noBasePizzaDescription: 'Debes elegir ente 1 y 10 ingredientes',
  labelHalfSelector: 'Selector de pizzas por mitades'
});

i18next.addResources('es_CR', 'BasePizzaSelector', {
  noBasePizza: 'Crea tu pizza'
});

i18next.addResources('es_ES', 'BasePizzaSelector', {
  basePizza: 'Especialidad (opcional)',
  placeholder: 'Selecciona especialidad mitad {{half}}',
  noBasePizza: 'Crea tu pizza'
});

i18next.addResources('pt_PT', 'BasePizzaSelector', {
  basePizza: 'Especialidade (opcional)',
  placeholder: 'Selecione meia especialidade {{half}}',
  noBasePizza: 'Crie sua pizza',
  noBasePizzaDescription: 'Deve escolher entre 1 e 10 ingredientes',
  labelHalfSelector: 'Selector de pizza para metade'
});
