import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'BannerPapaPoints', {
  useThem: '¡Úsalos!',
  join: '¡Únete!',
  youHave: 'Tienes',
  accumulate: 'Acumula',
  description: 'Gana puntos con tus compras y canjéalos por pizzas, acompañamientos y más.'
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'BannerPapaPoints', {
  useThem: 'Usa-os!',
  join: 'Juntar-se!',
  youHave: 'Tenho',
  accumulate: 'Acumular',
  description: 'Ganhe pontos em suas compras e troque-os por pizzas, acompanhamentos e muito mais.'
});
