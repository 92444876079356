import i18next from 'i18next';

i18next.addResources('es_CL', 'PurchaseOK', {
  pagePath: '/compra-exitosa',
  pageTitle: 'Pedido aceptado',
  pageDescription: 'Su pedido se ha realizado con exito.',
  title: '¡Compra exitosa!',
  mainDescription: '¡Felicidades! 🎉🍕',
  minorDescription: 'Tu pedido estará listo en un momento.',
  buttonRedirect: 'Ver pedido',
  altImageSuccess: 'Ícono de pedido exitoso'
});

i18next.addResources('pt_PT', 'PurchaseOK', {
  pagePath: '/compra-sucedida',
  pageTitle: 'Pedido aceito',
  pageDescription: 'Seu pedido foi feito com sucesso.',
  title: 'Compra com sucesso!',
  mainDescription: '¡Felicitações! 🎉🍕',
  minorDescription: 'Seu pedido estará pronto em breve.',
  buttonRedirect: 'Ver ordem',
  altImageSuccess: 'Ícone de sucesso da encomenda'
});
