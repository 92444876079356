import i18next from 'i18next';

i18next.addResources('es_CL', 'TermsAndConditions', {
  pagePath: '/terminos-y-condiciones',
  pageTitle: "Condiciones Generales de Venta | Papa John's Chile",
  pageDescription:
    "Condiciones generales de venta para los clientes de Papa John's Chile. Promociones, formas de pago y envíos."
});

// UPDATE THIS FOR SPAIN
i18next.addResources('es_ES', 'TermsAndConditions', {
  pagePath: '/condiciones-generales-de-venta',
  pageTitle: 'Condiciones Generales de Venta | Papa Johns España',
  pageDescription:
    "Condiciones generales de venta para los clientes de Papa John's. Promociones, formas de pago y envíos."
});

i18next.addResources('pt_PT', 'TermsAndConditions', {
  pagePath: '/termos',
  pageTitle: "Termos e Condições | Papa John's Portugal",
  pageDescription: "Lê os nossos Termos e condições Papa John's Portugal"
});

i18next.addResources('es_PA', 'TermsAndConditions', {
  pageTitle: "Términos y condiciones | Papa John's Panama",
  pageDescription: "Lee nuestros términos y condiciones Papa John's Panama."
});

i18next.addResources('es_CR', 'TermsAndConditions', {
  pageTitle: "Términos y condiciones | Papa John's Costa Rica",
  pageDescription: "Lee nuestros términos y condiciones Papa John's Costa Rica."
});

i18next.addResources('es_GT', 'TermsAndConditions', {
  pageTitle: "Términos y condiciones | Papa John's Guatemala",
  pageDescription: "Lee nuestros términos y condiciones Papa John's Guatemala."
});
