export const FB_APP_ID = null;
export const APP_URL = window.location.origin;

const locale = process.env.REACT_APP_TRANSLATION_COUNTRY;
const type = 'website';

export const DEFAULT_OG_OBJECT = {
  url: APP_URL,
  title: null,
  description: null,
  // TODO: Add new image based on the SEO document provided by PJ
  image: null,
  type,
  locale
};
