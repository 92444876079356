import { t } from 'i18next';

import { NOTIFICATION_DANGER, NOTIFICATION_SUCCESS } from '~redux/Notification/constants';
import { CURRENT_COUNTRY_ID, CARD_PAYMENT_TYPE, COUNTRIES } from '~constants/environment';
import { PAYMENT_METHODS_SERVER, CARD_PAYMENT_METHODS_ONLINE } from '~constants/paymentMethods';
import { arrayHasLength } from '~utils/array';

import { getQueryParams } from './parseUrl';

export const getCreditCardString = number => `* * * * ${number}`;

export const verifyAddCreditCardError = ({ search, showNotification, clearSearch }) => {
  if (search) {
    const { error, message } = getQueryParams(search);
    if (error === 'true') {
      showNotification({
        message: message.includes('cancelled')
          ? t('CheckoutPaymentMethodList:addCardErrorCancelled')
          : t('CheckoutPaymentMethodList:addCardError'),
        type: NOTIFICATION_DANGER
      });
    } else {
      showNotification({
        message: t('CheckoutPaymentMethodList:addCardSuccess'),
        type: NOTIFICATION_SUCCESS
      });
    }
    clearSearch();
  }
};

export const shouldShowCreditCards = (paymentMethods, isRegistered, paymentCardMethods) => {
  if ([COUNTRIES.es, COUNTRIES.pt].includes(CURRENT_COUNTRY_ID) && !arrayHasLength(paymentCardMethods)) {
    return false;
  }
  return (
    CARD_PAYMENT_TYPE &&
    isRegistered &&
    Object.keys(paymentMethods).some(
      payment =>
        payment === CARD_PAYMENT_TYPE ||
        (payment === PAYMENT_METHODS_SERVER.webOnline &&
          CARD_PAYMENT_TYPE === CARD_PAYMENT_METHODS_ONLINE.directClickToPay) ||
        payment === PAYMENT_METHODS_SERVER.mercantil
    )
  );
};

export const mercantilEnabled = currentSubsidiary => currentSubsidiary?.paymentMethods?.mercantil;
