import i18next from 'i18next';

i18next.addResources('es_CL', 'PromoSelling', {
  title: '¿Quieres pagar menos? Aplica una promo',
  discountText: '¡Ahorrarás {{value}} en tu compra!',
  discountTextPercentage: '¡Ahorrarás {{value}}% en esta promo!',
  discountPrice: '¡Ahorras {{value}}!',
  discountPriceSummary: '¡Estás ahorrando {{value}} con las promos!',
  discountPriceSummaryPercentage: '¡{{value}}% OFF!',
  existsPromos: '¡Promo disponible!',
  appliedPromo: '¡Promoción aplicada!',
  undoPromo: '¡Deshacer!'
});

i18next.addResources('pt_PT', 'PromoSelling', {
  title: 'Queres pagar menos? Aplica uma promoção',
  discountText: 'Poupa {{value}} en tua encomenda!',
  discountTextPercentage: 'Poupe {{value}}% nesta promo!',
  discountPrice: 'Poupas {{value}}!',
  discountPriceSummary: 'Está a poupar {{value}} em promoções!',
  existsPromos: 'Promo disponível!',
  appliedPromo: 'Promo aplicada!',
  undoPromo: 'Desfazer'
});
