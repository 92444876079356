import {
  COOKIE_TYPES,
  TECHNICALS_COOKIE
} from '~screens/Dashboard/components/CookiesPrivacy/components/CookieSettings/const';

export const initialCookieSetting = (userAcceptsCookies = false) =>
  COOKIE_TYPES.reduce((parseCookies, { id }) => {
    if (id === TECHNICALS_COOKIE) {
      return parseCookies;
    }
    return {
      ...parseCookies,
      [id]: userAcceptsCookies
    };
  }, {});

const GDPRCountries = ['pt', 'es'];

export const isGDPRCountry = () => GDPRCountries.includes(process.env.REACT_APP_ISO_COUNTRY);
