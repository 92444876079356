import Immutable from 'seamless-immutable';

/**
 * Receives an array of strings, and returns an obj with that strings as properties with that string as value.
 * E.G:
 * stringArrayToObject(['A', 'B', 'C']) // { A: 'A', B: 'B', C: 'C' }
 * @param {array} actionsArray array of values
 * @param {string} namespace prefix for the resulting values
 * @returns {object} (['A', 'B', 'C', 'name']) -> { A: '@name/A', B: '@name/B', C: '@name/C' }
 */
export function stringArrayToObject(actionsArray, namespace = '') {
  if (actionsArray.some(actionName => !actionName || typeof actionName !== 'string')) {
    throw new Error('Action names must be strings and must not be empty');
  }

  return new Immutable(actionsArray).asObject(actionName => [actionName, `${namespace}:${actionName}`]);
}

/**
 * Receives a length, and returns a new Array with indices on each position.
 * E. G:
 * arrayOfIndices(3) // [0, 1, 2]
 * This is for a reason: avoiding the use of for loops.
 * @param {number} length length of the array
 * @returns {array} [0, ..., length]
 *
 * @see https://github.com/airbnb/javascript#iterators--nope
 */
export function arrayOfIndices(length) {
  // .fill(<something not undefined>) is required for map, map ignore undefined indexes
  return Array(length)
    .fill(null)
    .map((_, index) => index);
}

export const isArray = object => object?.constructor?.name === 'Array';

export const SORT_KEYS = {
  ASCENDINGLY: 'ascendingly',
  DESCENDINGLY: 'descendingly'
};

const isHigher = (a, b) => a > b;
const isLower = (a, b) => a < b;

export const SORT_FUNCS = {
  ascendingly: isHigher,
  descendingly: isLower
};

// eslint-disable-next-line no-nested-ternary
const sort = (func, a, b) => (func(a, b) ? 1 : func(b, a) ? -1 : 0);

// Order should be 'ascendingly' or 'descendingly'
export const sortArrayByOrder = (array, order, key) => {
  const sortFunc = SORT_FUNCS[order];
  return array
    ? [...array]?.sort((a, b) => (key ? sort(sortFunc, a[key], b[key]) : sort(sortFunc, a, b)))
    : array;
};

export const arrayHasLength = (arr = []) => arr?.length > 0;

export const isLastItem = (array = [], index) => array?.length - 1 === index;

export const removeDuplicatesByKey = (array, key) =>
  array?.filter((elem, index) => array.findIndex(dupl => dupl?.[key] === elem?.[key]) === index);

export const removeByIndex = (array, index) => [...array.slice(0, index), ...array.slice(index + 1)];

export const addAtIndex = (array, index, element) => {
  const newArray = [...array];
  newArray[index] = {
    ...array[index],
    ...element
  };
  return newArray;
};

export const getFirstElement = array => array?.[0];

export const isEmptyArray = array => !array?.length;

export const getMaxValue = array => Math.max(...array);

const simpleCompare = (v1, v2) => v1 === v2;

export const sameLength = (arr1, arr2) => arr1?.length === arr2?.length;

export const containsAll = (arr1 = [], arr2 = [], compareItems = simpleCompare) =>
  arr2?.every(arr2Item => arr1?.some(arr1Item => compareItems(arr1Item, arr2Item)));

export const compareArrays = (arr1, arr2, compareItems = simpleCompare) =>
  sameLength(arr1, arr2) && containsAll(arr1, arr2, compareItems) && containsAll(arr2, arr1, compareItems);

export const mergeArrayItems = (items, compareItems, mergeStrategy) =>
  items.reduce((acc, item) => {
    const itemFoundIndex = acc.findIndex(accItem => compareItems(accItem, item));
    return itemFoundIndex >= 0
      ? addAtIndex(acc, itemFoundIndex, mergeStrategy(item, acc[itemFoundIndex]))
      : [...acc, item];
  }, []);

export const sum = array => array?.reduce((a, b) => a + b, 0);
