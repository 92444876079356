import api from '~config/api';

const SearchStoreService = {
  getNear: data => api.get('/v1/pickup_places/near_me', data),
  getStoreDelivery: data => api.get('v1/stores/delivery', data),
  getStores: data => api.get('v1/stores', data),
  getStore: ({ id, dispatchMethod = null }) =>
    api.get(`v1/stores/${id}${dispatchMethod ? `?dispatch_method=${dispatchMethod}` : ''}`),
  getUserAddress: () => api.get('/v1/addresses'),
  getUsedCoupons: () => api.get('/v1/users/used_coupons')
};

export default SearchStoreService;
