import i18next from 'i18next';

i18next.addResources('es_CL', 'CodeVerification', {
  title: 'Validación',
  titleRecoverPassword: 'Verificación',
  updatePassword: 'Actualizar contraseña',
  instructions: 'Ingresa el código que recibiste en tu {{verificationType}} para validar tu cuenta',
  instructionsRecoverPassword:
    'Ingresa el código que recibiste en tu {{verificationType}} y la nueva contraseña que quieres usar',
  resendSMSButton: 'No me llegó',
  resendCodeUpdatePass: 'No recibí el código',
  resendSMSMessage: 'El código fue reenviado. Si no lo recibes, espera unos minutos e intenta nuevamente.',
  resendSMSErrorMessage: 'Error al reenviar el código de verificación, intenta nuevamente',
  limitSMSExceedErrorMessage: 'Intenta más tarde',
  newPassword: 'Nueva contraseña',
  passwordContains: 'Tu contraseña debe contener',
  sendCodeButton: 'Enviar',
  confirm: 'Confirmar',
  verificationErrorMessage: 'Código incorrecto, intenta nuevamente',
  phone: 'celular',
  sms: 'celular',
  email: 'casilla de correo',
  lowercaseLetter: 'Una letra minúscula',
  unNumero: 'Un número',
  maxChar: 'Máximo 15 caracteres',
  uppercaseLetter: 'Una letra mayúscula',
  minChar: 'Mínimo 8 caracteres',
  specialChar: 'Inlcuir un caracter especial'
});

i18next.addResources('es_ES', 'CodeVerification', {
  title: 'Validación',
  titleRecoverPassword: 'Verificación',
  updatePassword: 'Actualizar contraseña',
  instructions: 'Introduce el código que recibiste en tu {{verificationType}} para validar tu cuenta',
  instructionsRecoverPassword:
    'Introduce el código que recibiste en tu {{verificationType}} y la nueva contraseña que quieres usar',
  resendSMSButton: 'No me ha llegado',
  resendSMSMessage: 'El código fue reenviado. Si no lo recibes, espera unos minutos e intenta nuevamente.',
  resendSMSErrorMessage: 'Error al reenviar el código de verificación, intenta nuevamente',
  limitSMSExceedErrorMessage: 'Intenta más tarde',
  sendCodeButton: 'Enviar',
  verificationErrorMessage: 'Código incorrecto, intenta nuevamente',
  phone: 'móvil',
  sms: 'móvil',
  email: 'casilla de correo'
});

i18next.addResources('pt_PT', 'CodeVerification', {
  title: 'Validação',
  titleRecoverPassword: 'Verificação',
  updatePassword: 'Atualizar senha',
  instructions: 'Digite o código que você recebeu em seu {{verificationType}} para validar sua conta',
  instructionsRecoverPassword:
    'Digite o código que você recebeu em seu {{verificationType}} e a nova senha que você deseja usar',
  resendSMSButton: 'Não me chegou',
  resendCodeUpdatePass: 'Não me chegou',
  resendSMSMessage:
    'O código foi encaminhado. Se você não receber, aguarde alguns minutos e tente novamente.',
  resendSMSErrorMessage: 'Erro ao reenviar o código de verificação, tente novamente',
  limitSMSExceedErrorMessage: 'Tente mais tarde',
  sendCodeButton: 'Enviar',
  newPassword: 'Nova senha',
  passwordContains: 'Sua senha deve conter',
  confirm: 'Confirme',
  verificationErrorMessage: 'Código incorreto, tente novamente',
  phone: 'móvel',
  sms: 'móvel',
  email: 'caixa postal',
  lowercaseLetter: 'Uma letra minúscula',
  unNumero: 'Um número',
  maxChar: 'Máximo de 15 caracteres',
  uppercaseLetter: 'Uma letra maiúscula',
  minChar: 'Mínimo de 8 caracteres',
  specialChar: 'Incluir um caractere especial'
});
