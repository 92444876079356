import i18next from 'i18next';

i18next.addResources('es_CL', 'CheckoutOrderDetail', {
  title: 'Resumen',
  minDeliveryFeeText: 'Delivery gratis desde {{minValue}}',
  subtotal: 'Subtotal:',
  total: 'Total:',
  confirm: 'Confirmar pedido',
  cancel: 'Deshacer Pedido',
  totalSaved: 'Cupón / Gift Card aplicado:',
  delivery: 'Delivery:',
  andOur: ' y nuestra ',
  tip: 'Propina:',
  papaPointsUsed: 'Papa Puntos utilizados',
  dontLosePoints: 'No pierdas tus ',
  hoverPointsMessage: 'Inicia sesión o regístrate para canjearlos en tu siguiente compra.',
  papaPointsNextDay: 'Los Papa Puntos serán sumados al día siguiente de su compra',
  toAddPapaPoints: ' por sumar',
  addMoreOrChangeToPickup:
    '¿Deseas <linkToHome>{{addMoreProducts}}</linkToHome> a tu pedido o <openChangeAddressModal>{{changeToPickup}}</openChangeAddressModal>?',
  tax: 'Impuesto {{taxName}} ({{taxPercentage}}%):',
  papaPointsBenefits: 'Beneficio Papa Puntos:',
  addMoreProducts: 'Añade más productos',
  changeToPickup: 'Cambiar a retiro',
  minimumAmountForDelivery: 'Te faltan <text>{{amount}}</text> para llegar al pedido mínimo de domicilio.',
  policiesAndConditionsBool:
    'Acepto las Política de privacidad <linkToPolicies><iconLink /></linkToPolicies> y declaro haber leído y aceptado nuestras condiciones generales de venta <sellingConditions><iconLink /></sellingConditions>.'
});

i18next.addResources('es_ES', 'CheckoutOrderDetail', {
  changeToPickup: 'Cambiar a recoger',
  delivery: 'Reparto:'
});

i18next.addResources('pt_PT', 'CheckoutOrderDetail', {
  title: 'Resumo',
  minDeliveryFeeText: 'Frete grátis a partir de {{minValue}}',
  subtotal: 'Subtotal',
  total: 'Total',
  confirm: 'Confirme o pedido',
  cancel: 'Desfazer o pedido',
  totalSaved: 'Cupão / Gift Card aplicado:',
  delivery: 'Delivery:',
  andOur: ' e nossa ',
  tip: 'Gorjeta:',
  addMoreOrChangeToPickup:
    'Deseja <linkToHome>{{addMoreProducts}}</linkToHome> em seu pedido ou <openChangeAddressModal>{{changeToPickup}}</openChangeAddressModal>?',
  tax: 'Imposto {{taxName}} ({{taxPercentage}}%):',
  dontLosePoints: 'Não perca seus papa pontos',
  hoverPointsMessage: 'Faça login ou cadastre-se para resgatá-los em sua próxima compra.',
  papaPointsNextDay: 'Papa Puntos são adicionados no dia seguinte à sua compra',
  toAddPapaPoints: ' adicionar',
  papaPointsBenefits: 'Papa Pontos de Benefício:',
  papaPointsUsed: 'Papa Pontos usados',
  addMoreProducts: 'Adicionar mais produtos',
  changeToPickup: 'Troque para take-away',
  minimumAmountForDelivery:
    'Faltam <text>{{amount}}</text> para atingir o valor mínimo de entrega em domicílio.',
  policiesAndConditionsBool:
    'Aceito a Política de Privacidade <linkToPolicies><iconLink /></linkToPolicies> e declaro que li e aceito as nossas condições gerais de venda <sellingConditions><iconLink /></sellingConditions>.'
});
