import { createTypes, completeTypes } from 'redux-recompose';

export const actions = createTypes(
  completeTypes([], ['SET_CURRENT_ITEM', 'SET_SELECTED_VARIANT']),
  '@@PRODUCT_OPTIONS_MODAL'
);

export const TARGETS = {
  CURRENT_ITEM: 'currentItem',
  SELECTED_VARIANT: 'selectedVariant'
};

export const actionCreators = {
  setCurrentItem: item => (dispatch, getState) => {
    const {
      home: { menu }
    } = getState();
    const menuItem = menu.find(({ menuableId }) => menuableId === item?.menuableId);
    const product = item?.menuable ? item : menuItem;
    dispatch({ type: actions.SET_CURRENT_ITEM, payload: product, target: TARGETS.CURRENT_ITEM });
  },
  setSelectedVariant: selectedVariant => ({
    type: actions.SET_SELECTED_VARIANT,
    target: TARGETS.SELECTED_VARIANT,
    payload: selectedVariant
  })
};
