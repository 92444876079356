import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

import { BALANCES } from './constants';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'GiftCardBalance', {
  [BALANCES.CURRENT]: 'Saldo disponible:',
  [BALANCES.NEXT]: 'Tu próximo saldo será de',
  useGiftCard: 'Utilizar Gift Card en esta compra'
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'GiftCardBalance', {
  [BALANCES.CURRENT]: 'Saldo disponível:',
  [BALANCES.NEXT]: 'O teu próximo saldo será de',
  useGiftCard: 'Utilizar Gift Card neste compra'
});
