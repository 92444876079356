import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { LOYALTIES_TARGET, CURRENT_POINTS_TARGET, REDEEMS_TARGET, LOYALTY_LEVELS_TARGET } from './constants';

const initialState = completeState(
  {
    [LOYALTIES_TARGET]: null,
    [CURRENT_POINTS_TARGET]: null,
    [REDEEMS_TARGET]: null,
    [LOYALTY_LEVELS_TARGET]: null
  },
  [CURRENT_POINTS_TARGET]
);

const reducerDescription = {
  primaryActions: [
    actions.GET_LOYALTIES,
    actions.SET_CURRENT_POINTS,
    actions.GET_LOYALTIES_REDEEM,
    actions.GET_LOYALTY_LEVELS
  ],
  override: {
    [actions.SET_CURRENT_POINTS]: onReadValue()
  }
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
