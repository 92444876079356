/* eslint-disable camelcase */
import { CURRENT_COUNTRY_ID } from '~constants/environment';

import { CONTACT_FORM_IDS, PART_TIME_VALUE } from './constants';

export const formatContactForm = data => ({
  request: {
    requester: {
      name: data.name,
      email: data.email
    },
    custom_fields: [
      {
        id: CONTACT_FORM_IDS[CURRENT_COUNTRY_ID].PHONE,
        value: data.phone
      },
      {
        id: CONTACT_FORM_IDS[CURRENT_COUNTRY_ID].STORE,
        value: data.storeZendeskId
      },
      {
        id: CONTACT_FORM_IDS[CURRENT_COUNTRY_ID].RECEIPT,
        value: data.receiptNumber
      },
      {
        id: CONTACT_FORM_IDS[CURRENT_COUNTRY_ID].DESCRIPTION,
        value: data.description
      },
      {
        id: CONTACT_FORM_IDS[CURRENT_COUNTRY_ID].EMAIL,
        value: data.email
      },
      {
        id: CONTACT_FORM_IDS[CURRENT_COUNTRY_ID].NAME,
        value: data.name
      },
      {
        id: CONTACT_FORM_IDS[CURRENT_COUNTRY_ID].LABEL,
        value: data.zendeskLabel
      },
      {
        id: CONTACT_FORM_IDS[CURRENT_COUNTRY_ID].PART_TIME,
        value: PART_TIME_VALUE
      }
    ],
    subject: data.description,
    comment: {
      type: 'Comment',
      body: data.description,
      uploads: data.attachments
    },
    email_ccs: [
      {
        user_name: data.name,
        user_email: data.email,
        action: 'put'
      }
    ]
  }
});

export const formatFileAttachment = file => ({
  attachment: {
    file_name: file.name,
    size: file.size,
    content_type: file.type
  }
});

export const formatFranchisesContactForm = data => ({
  contact: {
    first_name: data.firstName,
    last_name: data.lastName,
    phone: data.phone,
    email: data.email,
    age: data.age,
    organized_experience: data.organizedExperience,
    franchisee_other_brands: data.franchiseeOtherBrands,
    other_franchises: data.otherFranchises,
    geographical_areas_interest: data.geographicalAreasInterest,
    capital_available_investment: data.capitalAvailableInvestment,
    personally_operate: data.personallyOperate
  }
});
