import PropTypes from 'prop-types';
import cn from 'classnames';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as Ticket } from '~assets/ticket.svg';
import { formatPrice } from '~utils/number';

import styles from './styles.module.scss';

export const ActualPromos = ({ actualPromos, handleCheckPromo }) => {
  const shoppingCartLoading = useSelector(state => state.home?.shoppingCartLoading);
  const promosLoading = useSelector(state => state.promoSelling?.promosLoading);

  return actualPromos.map(promo => (
    <div
      key={promo.id}
      className={cn(styles.container, {
        [styles.loading]: shoppingCartLoading || promosLoading
      })}
      onClick={() => handleCheckPromo(promo, !promo.checked)}>
      <div className="row start">
        <span className={styles.iconContainer}>
          <Ticket />
        </span>
        <p>
          <div className={styles.promoTitle}>{promo.name}</div>
          <div>
            {promo.discount
              ? t('PromoSelling:discountTextPercentage', { value: `${promo.discount}` })
              : t('PromoSelling:discountText', { value: `${formatPrice(promo.savedPrice)}` })}
          </div>
        </p>
      </div>
      <input className={styles.checkbox} type="checkbox" checked={promo.checked} />
    </div>
  ));
};

ActualPromos.propTypes = {
  actualPromos: PropTypes.array,
  handleCheckPromo: PropTypes.func
};
