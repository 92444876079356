import i18next from 'i18next';

i18next.addResources('es_CL', 'ExistingUserModal', {
  title: 'Hemos detectado que tus datos ya existen',
  subtitle:
    'Para garantizar una experiencia de compra segura, por favor modifique los datos de contacto o inicie sesión para continuar.',
  changeData: 'Cambiar datos de compra',
  logIn: 'Iniciar sesión'
});

i18next.addResources('pt_PT', 'ExistingUserModal', {
  title: 'Detectamos que seus dados já existem',
  subtitle:
    'Para garantir uma experiência de compra segura, modifique seus dados de contato ou faça login para continuar.',
  changeData: 'Alterar dados de compra',
  logIn: 'Iniciar sessão'
});
