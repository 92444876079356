import { iceCream, pint, pizza as pizzaIcon, pricetag } from 'ionicons/icons';

import { ALL_PIZZA_KINDS } from '~constants/products';
import { COUNTRIES } from '~constants/environment';

import { side, extra } from './icons-inline';

export const SCROLL_DEBOUNCE_TIME = 2;
export const MOVE_ELEMENT_DEBOUNCE_TIME = 50;

export const HEIGHT_DIFFERENCE_SCROLL = 150;

export const PRODUCT_CATEGORIES_ICONS = {
  promo: pricetag,
  drink: pint,
  side,
  dessert: iceCream,
  extra,
  pizza: pizzaIcon
};

export const PRODUCT_TYPES = { pizza: 'Pizza', complement: 'Complement', offer: 'Offer' };

export const PRODUCT_CATEGORIES = {
  ...ALL_PIZZA_KINDS,
  DESSERT: 'dessert',
  SIDE: 'side',
  DRINK: 'drink',
  EXTRA: 'extra',
  PROMO: 'promo',
  BY_HALVES: 'by_halves',
  BYOP: 'byop',
  COMBOS: 'combos',
  SAUCE: 'sauce',
  TRADITIONAL: 'traditional',
  UPSELLING: 'upselling',
  CROSS_SELLING: 'crossSelling',
  OFFER: 'offer'
};

export const COUNTRIES_WITH_CHEAPEST_SIZE = [COUNTRIES.es, COUNTRIES.pt];

export const KEY_ID = 'id-menu-action-';
