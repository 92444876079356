export const parseLatLon = coors => {
  const { latitude, longitude } = coors;
  return {
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude)
  };
};

export function getAddressComponentByPriority(addressComponentTypes, addressComponents = []) {
  return addressComponents.find(addressComponent =>
    addressComponentTypes.find(type => addressComponent.types.includes(type))
  );
}
