import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer } from 'redux-recompose';

import { actions } from './actions';
import { ATTACHMENTS_TARGET, CONTACT_FORM_TARGET } from './constants';
import { onSetAttachment } from './effects';

const initialState = completeState(
  {
    [ATTACHMENTS_TARGET]: [],
    [CONTACT_FORM_TARGET]: null
  },
  []
);

const reducerDescription = {
  primaryActions: [actions.ADD_ATTACHMENT, actions.SEND_CONTACT_FORM],
  override: {
    [actions.ADD_ATTACHMENT]: onSetAttachment
  }
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
