import pizza from '../assets/loader_pizzabox_green.json';

export const SPINNER_TYPES = {
  pizza
};

export const getOptions = ({ type, loop = true, autoplay = true, rendererSettings }) => ({
  loop,
  autoplay,
  animationData: SPINNER_TYPES[type] || SPINNER_TYPES.pizza,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
    ...rendererSettings
  }
});
