import i18next from 'i18next';

i18next.addResources('es_CL', 'ErrorBoundary', {
  title: '¡Lo sentimos! Ha ocurrido un error',
  back: 'Regresar al menú'
});

// TODO: pending review for Spain
i18next.addResources('es_ES', 'ErrorBoundary', {
  title: '¡Lo sentimos! Ha ocurrido un error',
  back: 'Regresar al menú'
});

i18next.addResources('pt_PT', 'ErrorBoundary', {
  title: '¡Sentimos muito! Ocorreu um erro',
  back: 'Voltar ao menu'
});
