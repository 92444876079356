/* eslint-disable camelcase */
import i18next from 'i18next';

i18next.addResources('es_CL', 'SignInStep', {
  title: '¡Último paso!',
  description: 'Para terminar tu registro es necesario que completes la siguiente información:',
  nameField: 'Nombre',
  emailField: 'usuario@mail.com',
  phoneField: 'Teléfono',
  acceptPrivacyPolicies:
    'Acepto las Políticas de privacidad <link-privacy-policies><icon-link /></link-privacy-policies> y las Condiciones generales <link-term-and-conditions><icon-link /></link-term-and-conditions> de venta',
  subscription: 'Acepto recibir comunicaciones comerciales y ofertas personalizadas',
  acceptPoll: 'No deseo recibir correos para evaluar mi experiencia de compra',
  register: 'Continuar',
  continueAsGuest: 'Continuar como invitado'
});

i18next.addResources('pt_PT', 'SignInStep', {
  title: 'Um último passo!',
  description: 'Para completar o teu registo, tens de preencher as seguintes informações:',
  nameField: 'O teu nome',
  emailField: 'O teu e-mail',
  phoneField: 'O teu telefone',
  acceptPrivacyPolicies:
    'Aceito as Políticas de Privacidade <link-privacy-policies><icon-link /></link-privacy-policies> e as Condições Gerais <link-term-and-conditions><icon-link /></link-term-and-conditions> de Venda',
  subscription: 'Aceito receber comunicações comerciais e promoções personalizadas',
  acceptPoll: 'Não quero receber e-mails para avaliar a minha experiência de compra',
  register: 'Entrar',
  continueAsGuest: 'Continuar como convidado'
});
