import { completeTypes, createTypes, withPostSuccess, withPostFailure } from 'redux-recompose';
import { t } from 'i18next';

import ContactFormService from '~services/ContactForm/service';
import { actionCreators as notificationActions } from '~redux/Notification/actions';
import { NOTIFICATION_SUCCESS, NOTIFICATION_DANGER } from '~redux/Notification/constants';

import { ATTACHMENTS_TARGET, CONTACT_FORM_TARGET, FRANCHISES_CONTACT_FORM_TARGET } from './constants';

export const actions = createTypes(
  completeTypes([
    'SEND_CONTACT_FORM',
    'ADD_CONTACT_FORM',
    'ADD_ATTACHMENT',
    'SEND_ATTACHMENT',
    'SEND_FRANCHISES_FORM'
  ]),
  '@@CONTACT_FORM'
);

export const actionCreators = {
  sendContactForm: (json, successCallback) => ({
    type: actions.SEND_CONTACT_FORM,
    service: ContactFormService.sendContactForm,
    payload: json,
    target: CONTACT_FORM_TARGET,
    injections: [
      withPostFailure(dispatch =>
        dispatch(
          notificationActions.show({
            message: t('Contact:postFail'),
            type: NOTIFICATION_DANGER
          })
        )
      ),
      withPostSuccess((dispatch, response) => {
        if (response.ok) {
          dispatch(
            notificationActions.show({
              message: t('Contact:postSuccess'),
              type: NOTIFICATION_SUCCESS
            })
          );
          successCallback();
        } else {
          dispatch(
            notificationActions.show({
              message: t('Contact:postFail'),
              type: NOTIFICATION_DANGER
            })
          );
        }
      })
    ]
  }),
  sendAttachment: file => ({
    type: actions.SEND_ATTACHMENT,
    service: ContactFormService.sendAttachment,
    payload: file,
    injections: [
      withPostFailure(dispatch =>
        dispatch(
          notificationActions.show({
            message: t('Contact:attachFail'),
            type: NOTIFICATION_DANGER
          })
        )
      ),
      withPostSuccess((dispatch, response) => {
        dispatch(actionCreators.addAttachment(response?.upload?.token));
        if (response?.ok) {
          dispatch(
            notificationActions.show({
              message: t('Contact:attachSuccess'),
              type: NOTIFICATION_SUCCESS
            })
          );
        } else {
          dispatch(
            notificationActions.show({
              message: t('Contact:postFail'),
              type: NOTIFICATION_DANGER
            })
          );
        }
      })
    ]
  }),
  addAttachment: tokenFile => ({
    type: actions.ADD_ATTACHMENT,
    target: ATTACHMENTS_TARGET,
    payload: tokenFile
  }),
  sendFranchisesContactForm: (json, successCallback) => ({
    type: actions.SEND_FRANCHISES_FORM,
    service: ContactFormService.sendFranchisesContactForm,
    payload: json,
    target: FRANCHISES_CONTACT_FORM_TARGET,
    injections: [
      withPostFailure(dispatch =>
        dispatch(
          notificationActions.show({
            message: t('FranchisesContactForm:postFail'),
            type: NOTIFICATION_DANGER
          })
        )
      ),
      withPostSuccess((dispatch, response) => {
        if (response.ok) {
          dispatch(
            notificationActions.show({
              message: t('FranchisesContactForm:postSuccess'),
              type: NOTIFICATION_SUCCESS
            })
          );
          successCallback();
        } else {
          dispatch(
            notificationActions.show({
              message: t('FranchisesContactForm:postFail'),
              type: NOTIFICATION_DANGER
            })
          );
        }
      })
    ]
  })
};
