import { CURRENT_COUNTRY_ID, COUNTRIES } from '~constants/environment';

const NAME_VALIDATION = {
  min: 3,
  max: 60
};

const NUMBER_VALIDATION = {
  min: 3,
  max: 15
};

const PASSWORD_VALIDATION = {
  min: 8,
  max: 15
};

const PHONE_VALIDATION = {
  cl: { length: 12 },
  es: { length: 12 },
  pt: { length: 13 },
  pa: { length: 12 },
  cr: { length: 12 },
  gt: { length: 12 }
};

const CODE_VALIDATION = {
  min: 6,
  max: 6
};

const COUPON_VALIDATION = {
  min: 1,
  max: 50
};

const APARTMENT_VALIDATION = {
  cl: {
    min: 0,
    max: 25
  },
  cr: {
    min: 0,
    max: 25
  },
  es: {
    min: 0,
    max: 25
  },
  pt: {
    min: 0,
    max: 25
  },
  pa: {
    min: 0,
    max: 25
  },
  gt: {
    min: 0,
    max: 25
  }
};

const MAX_PLACES_ADDRESS_NOTE = {
  cl: {
    max: 45
  },
  cr: {
    max: 300
  },
  es: {
    max: 45
  },
  pt: {
    max: 45
  },
  pa: {
    max: 45
  },
  gt: {
    max: 45
  }
};

const LICENSE_PLATE_VALIDATION = {
  cl: {
    max: 6,
    min: 5
  },
  cr: {
    max: 6,
    min: 5
  },
  es: {
    max: 6,
    min: 5
  },
  pt: {
    max: 6,
    min: 5
  },
  pa: {
    max: 6,
    min: 5
  },
  gt: {
    max: 6,
    min: 5
  }
};

const PAPACAR_DESCRIPTION_VALIDATION = {
  cl: {
    max: 100
  },
  cr: {
    max: 100
  },
  es: {
    max: 100
  },
  pt: {
    max: 100
  },
  pa: {
    max: 100
  },
  gt: {
    max: 100
  }
};

const CAPITAL_AVAILABLE_VALIDATION = {
  min: 1,
  max: 10
};

const AGE_VALIDATION = {
  min: 18,
  max: 99
};

const REQUIRED_ADDRESS = [COUNTRIES.pa, COUNTRIES.cr, COUNTRIES.gt].includes(CURRENT_COUNTRY_ID);

const COUNTRY_CODE_SYMBOL = '+';

const CURRENT_COUNTRY_CODE = process.env.REACT_APP_CODE_COUNTRY || COUNTRY_CODE_SYMBOL;

const REGEX = {
  COUNTRY_CODE: new RegExp(`^(\\${CURRENT_COUNTRY_CODE})d?`),
  COUPON: new RegExp('^[a-zA-z1-9áéíóúÁÉÍÓÚ^$*.,:;¿?"!|@#%&><_~+-\\/-[]{}()]+$'),
  NORMAL_CHARACTERS: new RegExp('^([a-z A-Z ÑñáÁéÉíÍóÓúÚüÜçÇ]*)$'),
  NOT_ONLY_WHITE_SPACES: new RegExp('^(?!\\s*$).+'),
  ONLY_NUMBERS: new RegExp('^\\+?[0-9]*$'),
  PASSWORD: new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'),
  NOT_SPACES_AT_BEGIN_OR_END: new RegExp('^[^\\s]+(\\s+[^\\s]+)*$')
};

const CARD_TEST_STRING = 'card-test';

const DATE_TEST_STRING = 'date-test';

const CARD_CODE_TEST_STRING = 'card-code-test';

const CARD_CODE_VALIDATION = {
  min: 3,
  max: 4
};

const REGEX_CAR_OR_MOTO = new RegExp(
  '^[A-Za-z]{2}[1-9]{1}[0-9]{3}|[A-Za-z]{2}[0-9]{3}|[A-Za-z]{2}[0]{1}[0-9]{3}|[A-Za-z]{2}[O]{1}[0-9]{3}|[BCDFGHJKLPRSTVWXYZbcdfghjklprstvwxyz]{4}[0-9]{2}|[BCDFGHJKLPRSTVWXYZbcdfghjklprstvwxyz]{3}[0-9]{2}|[BCDFGHJKLPRSTVWXYZbcdfghjklprstvwxyz]{3}[0]{1}[0-9]{2}|[BCDFGHJKLPRSTVWXYZbcdfghjklprstvwxyz]{3}[O]{1}[0-9]{2}$'
);

export {
  NAME_VALIDATION,
  NUMBER_VALIDATION,
  PASSWORD_VALIDATION,
  PHONE_VALIDATION,
  CODE_VALIDATION,
  COUPON_VALIDATION,
  APARTMENT_VALIDATION,
  CURRENT_COUNTRY_CODE,
  COUNTRY_CODE_SYMBOL,
  REGEX,
  MAX_PLACES_ADDRESS_NOTE,
  CARD_TEST_STRING,
  DATE_TEST_STRING,
  CARD_CODE_VALIDATION,
  CARD_CODE_TEST_STRING,
  REQUIRED_ADDRESS,
  LICENSE_PLATE_VALIDATION,
  PAPACAR_DESCRIPTION_VALIDATION,
  REGEX_CAR_OR_MOTO,
  CAPITAL_AVAILABLE_VALIDATION,
  AGE_VALIDATION
};
