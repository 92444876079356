import i18next from 'i18next';

i18next.addResources('es_CL', 'CheckoutPersonalDataForm', {
  title: 'Completa tu perfil',
  name: 'Tu nombre',
  email: 'Tu correo',
  phone: 'Tu teléfono',
  login: 'Inicia sesión',
  register: 'Regístrate',
  idNumber: 'Rut (opcional)'
});

i18next.addResources('es_ES', 'CheckoutPersonalDataForm', {
  title: 'Completa tu perfil',
  email: 'Tu email',
  login: 'Inicia sesión',
  register: 'Regístrate'
});

i18next.addResources('pt_PT', 'CheckoutPersonalDataForm', {
  title: 'Complete seu perfil',
  name: 'O teu nome',
  email: 'O teu email',
  phone: 'O teu telefone',
  login: 'Inicia Sessão',
  register: 'Regista-Te'
});

i18next.addResources('es_CR', 'CheckoutPersonalDataForm', {
  title: 'Completá tu perfil',
  login: 'Iniciá sesión'
});
