import i18next from 'i18next';

i18next.addResources('es_CL', 'AboutUsOurCommitment', {
  title: 'Quiénes Somos',
  subTitle: 'Papa John’s pizza: mejores ingredientes, mejor pizza. <br>Eso es lo que nos hace diferentes.',
  text1:
    'El secreto del éxito se asemeja bastante a hacer una mejor pizza... cuanto más empeño pongas, mejor será el resultado. Hoy en día, buscamos la perfección como lo venimos haciendo desde el momento en que inauguramos nuestro primer restaurante hace más de 30 años. Hoy mantenemos el deseo de ser los mejores en preparar nuevos productos y nuevas e innovadoras recetas.',
  text2:
    'La calidad es esencial para nosotros. Ha sido nuestra base desde la primera pizza que preparamos en nuestro primer local en Jeffersonville, IN, hasta las que hacemos hoy en día en los más de 5,000 restaurantes en 44 países y territorios alrededor del mundo.',
  text3:
    'No usamos ingredientes baratos ni procesados. Desde nuestra salsa exclusiva, nuestros ingredientes, nuestra masa fresca original e incluso en la caja, invertimos para ofrecerte siempre la mejor pizza.',
  text4:
    'Sabemos que para tí, no es solo una mejor pizza. Es una reunión familiar, un cumpleaños inolvidable, una celebración de trabajo o simplemente una gran comida. Nuestro objetivo es garantizar que siempre tengas los mejores ingredientes para cada ocasión.',
  altIngredients: 'Ingredientes',
  altPizza: 'Pizza'
});

// UPDATE THIS FOR SPAIN
i18next.addResources('es_ES', 'AboutUsOurCommitment', {
  title: 'Quiénes Somos',
  subTitle: 'Papa John’s pizza: mejores ingredientes, mejor pizza. <br>Eso es lo que nos hace diferentes.',
  text1:
    'El secreto del éxito se asemeja bastante a hacer una mejor pizza... cuanto más empeño pongas, mejor será el resultado. Hoy en día, buscamos la perfección como lo venimos haciendo desde el momento en que inauguramos nuestro primer restaurante hace más de 30 años. Hoy mantenemos el deseo de ser los mejores en preparar nuevos productos y nuevas e innovadoras recetas.',
  text2:
    'La calidad es esencial para nosotros. Ha sido nuestra base desde la primera pizza que preparamos en nuestro primer local en Jeffersonville, IN, hasta las que hacemos hoy en día en los más de 5,000 restaurantes en 44 países y territorios alrededor del mundo.',
  text3:
    'No usamos ingredientes baratos ni procesados. Desde nuestra salsa exclusiva, nuestros ingredientes, nuestra masa fresca original e incluso en la caja, invertimos para ofrecerte siempre la mejor pizza.',
  text4:
    'Sabemos que para tí, no es solo una mejor pizza. Es una reunión familiar, un cumpleaños inolvidable, una celebración de trabajo o simplemente una gran comida. Nuestro objetivo es garantizar que siempre tengas los mejores ingredientes para cada ocasión.',
  altIngredients: 'Ingredientes',
  altPizza: 'Pizza'
});

i18next.addResources('pt_PT', 'AboutUsOurCommitment', {
  title: 'Nosso compromisso',
  subTitle: 'Papa John’s pizza: melhores ingredientes, melhor pizza. <br>Isso é o que nos faz diferentes.',
  text1:
    'O segredo do sucesso é muito semelhante a fazer uma pizza melhor ... quanto mais esforço você colocar, melhor será o resultado. Hoje, buscamos a perfeição como temos feito desde a inauguração do nosso primeiro restaurante há mais de 30 anos. Hoje mantemos o desejo de sermos os melhores na preparação de novos produtos e receitas novas e inovadoras.',
  text2:
    'Qualidade é essencial para nós. Foi a nossa base desde a primeira pizza que preparamos em nosso primeiro local em Jeffersonville, IN, até as que produzimos hoje em mais de 5.000 restaurantes em 44 países e territórios ao redor do mundo..',
  text3:
    'Nós não usamos ingredientes baratos ou processados. De nosso molho exclusivo, nossos ingredientes, nossa massa fresca original e até mesmo na caixa, investimos para oferecer sempre a melhor pizza.',
  text4:
    'Sabemos que para você não é apenas uma pizza melhor. É uma reunião de família, um aniversário inesquecível, uma comemoração de trabalho ou apenas uma excelente refeição. Nosso objetivo é garantir que você tenha sempre os melhores ingredientes para cada ocasião.',
  altIngredients: 'Ingredientes',
  altPizza: 'Pizza'
});
