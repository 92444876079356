import i18next from 'i18next';

i18next.addResources('es_CL', 'Unavailable', {
  title: 'Estamos trabajando para mejorar nuestros servicios',
  description:
    'No te preocupes, pronto estaremos de vuelta para que puedas seguir disfrutando de las mejores pizzas.'
});

// TODO: pending review for Spain
i18next.addResources('es_ES', 'Unavailable', {
  title: 'Estamos trabajando para mejorar nuestros servicios',
  description:
    'No te preocupes, pronto estaremos de vuelta para que puedas seguir disfrutando de las mejores pizzas.'
});

i18next.addResources('pt_PT', 'Unavailable', {
  subtitle: 'Estamos trabalhando para melhorar nossos serviços',
  comment:
    'Não se preocupe, nós estaremos de volta em breve para que você possa continuar a desfrutar das melhores pizzas.'
});
