import i18next from 'i18next';

import { PROMOTION_MODAL_NAME, PROMOTION_MODAL_COUPON } from '~constants/environment';

i18next.addResources('es_CL', 'PromotionModal', {
  modalTitle: 'Instrucciones PIZZA GRATIS',
  step1label: 'Paso 1: ',
  step2label: 'Paso 2: ',
  step3label: 'Paso 3: ',
  step4label: 'Paso 4: ',
  step5label: 'Paso 5: ',
  step1: `Selecciona la promoción <strong class="bold">"${PROMOTION_MODAL_NAME}"</strong>`,
  step2: 'Arma tu pizza XL de 3 ingredientes a tu gusto.',
  step3: 'Vuelve al menú y agrega una de nuestras pizzas medianas de especialidad en la sección "Pizzas".',
  step4: `Aplica el cupón <strong class="bold subtitle-small-medium">"${PROMOTION_MODAL_COUPON}"</strong> en la parte inferior del cuadro "Resumen"`,
  step5: 'Verifica que se haya hecho el descuento para finalizar la compra.',
  codeValidFor: '*Código válido para 1 canje por usuario.',
  closeButton: 'CERRAR'
});
