import { useDispatch, useSelector } from 'react-redux';

import { actionCreators as promoSellingActions } from '~redux/PromoSelling/actions';
import { actionCreators as homeActions } from '~screens/Dashboard/screens/Home/redux/actions';
import { shoppingCartSelector } from '~screens/Dashboard/screens/Home/redux/selectors';
import { addCartItem, removeCartItem } from '~utils/shoppingCart';
import { createPromoSellingEvent } from '~gtmEvents/promoSelling';

export const usePromoHandling = (actualPromos, isCheckout) => {
  const dispatch = useDispatch();
  const { items } = useSelector(shoppingCartSelector);

  const handleCheckPromo = (selectedPromo, checkedPromo) => {
    const matchedItemsID = selectedPromo.matchedItems.map(item => item.menuableId);
    const cartItemsReplaced = items.filter(item => matchedItemsID.includes(item.menuableId));

    let newCartItems = items;
    const newListActualPromos = actualPromos.map(promo => {
      if (promo.id === selectedPromo.id) {
        let backupItems = [];
        if (checkedPromo) {
          createPromoSellingEvent({ ...promo, checked: true }, isCheckout);

          const newPromosItems = selectedPromo.matchedItems.map(matchItem => {
            const cartItem = cartItemsReplaced.find(item => item.menuableId === matchItem.menuableId);
            newCartItems = removeCartItem(newCartItems, cartItem);
            backupItems = addCartItem(backupItems, cartItem);
            return {
              ...cartItem,
              offerGroupId: matchItem.offerGroupId
            };
          });
          const newOffer = {
            offerId: selectedPromo.id,
            offerType: selectedPromo.offerType,
            groupProducts: newPromosItems
          };
          newCartItems = addCartItem(newCartItems, newOffer, 0);
        } else {
          promo.backupItems.forEach(item => {
            newCartItems = addCartItem(newCartItems, item, 0, false, item.quantity);
          });
          const promoCreated = items.find(item => item.offerId === selectedPromo.id);
          newCartItems = removeCartItem(newCartItems, promoCreated);
        }
        return {
          ...promo,
          checked: checkedPromo,
          backupItems
        };
      }
      return promo;
    });

    dispatch(promoSellingActions.setActualPromos(newListActualPromos));
    dispatch(homeActions.getCartPrices(newCartItems));
  };

  return handleCheckPromo;
};
