import i18next from 'i18next';

i18next.addResources('es_CL', 'Footer', {
  maxIngredients: 'Puedes elegir entre {{minimum}} y {{maximum}} ingredientes'
});

i18next.addResources('es_ES', 'Footer', {
  maxIngredients: 'Puedes elegir entre {{minimum}} y {{maximum}} ingredientes'
});

i18next.addResources('pt_PT', 'Footer', {
  maxIngredients: 'Você pode escolher entre {{minimum}} e {{maximum}} ingredientes'
});
