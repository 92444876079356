export const NEAR_TARGET = 'near';
export const DELIVERY_TARGET = 'delivery';
export const CURRENT_SUBSIDIARY_TARGET = 'currentSubsidiary';
export const DISPATCH_METHOD_TARGET = 'dispatchType';
export const DISPATCH_TYPE_TAB_TARGET = 'dispatchTypeTab';
export const USER_ADDRESS = 'userAddress';
export const USER_ADRESSES = 'userAddresses';
export const USED_COUPONS = 'usedCoupons';
export const COMPANIES_TARGET = 'companies';
export const STORES_TARGET = 'stores';
export const EXTERNAL_STORE_TARGET = 'externalStoreId';
export const CREATE_USER_ADDRESSES = 'createUserAddresses';
export const SELECTED_DELIVERY_TIME = 'selectedDeliveryTime';
export const CURRENT_STORE_INFO = 'currentStoreInfo';
export const ACTUAL_RECEIPT_COMPANY = 'actualReceiptCompany';

export const QUANTITY_NEAR_STORES = 3;
export const GO_TO_CHECKOUT_FROM_ADDRESS = 'goToCheckoutFromAddress';
