import i18next from 'i18next';

i18next.addResources('es_CL', 'CartVariant', {
  modify: 'Modificar',
  btnDecreaseProductQuantity: 'Disminuir cantidad',
  btnIncreaseProductQuantity: 'Aumentar cantidad',
  personalize: 'Personalizar'
});

i18next.addResources('es_ES', 'CartVariant', {
  modify: 'Modificar'
});

i18next.addResources('pt_PT', 'CartVariant', {
  modify: 'Modificar',
  btnDecreaseProductQuantity: 'Diminuir quantidade',
  btnIncreaseProductQuantity: 'Aumentar quantidade'
});
