import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import {
  SEARCH_RESULTS_TARGET,
  CATEGORIES_TARGET,
  ARTICLES_TARGET,
  SELECTED_QUESTION_TARGET,
  SECTIONS_TARGET
} from './constants';

const initialState = completeState(
  {
    [SEARCH_RESULTS_TARGET]: null,
    [CATEGORIES_TARGET]: null,
    [ARTICLES_TARGET]: null,
    [SELECTED_QUESTION_TARGET]: null,
    [SECTIONS_TARGET]: null
  },
  [SELECTED_QUESTION_TARGET]
);

const reducerDescription = {
  primaryActions: [actions.SEARCH, actions.GET_CATEGORIES, actions.GET_ARTICLES, actions.GET_SECTIONS],
  override: {
    [actions.SELECT_QUESTION]: onReadValue()
  }
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
