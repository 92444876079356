import { createTypes, completeTypes } from 'redux-recompose';

import { MONITOR_VIEW } from './constants';

export const actions = createTypes(completeTypes([], ['SET_MONITOR_VIEW']), '@@MONITOR');

export const actionCreators = {
  setMonitorView: view => ({
    type: actions.SET_MONITOR_VIEW,
    target: MONITOR_VIEW,
    payload: view
  })
};
