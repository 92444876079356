import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

import { deserializer } from '~services/baseSerializers';
import { STATUS_CODES } from '~services/status';
import { API_ERRORS } from '~constants/apiErrors';
import { isGiftCardCode } from '~models/giftCard';

import { MENU_HELPERS, REDEEM_CODE_ERRORS } from './constants';

export const getHelpersAndMenu = menuData => {
  const helpers = [];
  const menu = [];

  menuData.forEach(menuItem => {
    if (MENU_HELPERS.includes(menuItem.menuable.category)) {
      helpers.push(menuItem);
    } else {
      menu.push(menuItem);
    }
  });

  return { helpers, menu };
};

export const checkErrorsInOrderBody = ({ user, address }) => {
  if (!user) {
    return t('Home:noUserData');
  }
  if (!address) {
    return t('Home:noUserAddress');
  }

  return false;
};

export const denormalizeHelpersAndMenu = response =>
  getHelpersAndMenu(deserializer.serialize(response.menu.data));

export const couponHasError = description =>
  description && REDEEM_CODE_ERRORS.some(error => error === description);

export const addWithLoyaltyToCart = cart => ({
  ...cart,
  items: cart?.items?.map(item => ({
    ...item,
    withLoyalty: !!item?.pointsSpent
  }))
});

export const orderErrorHandling = ({ code, message, metadata }, order) => {
  if (!code) {
    return t(`APIErrors:e${API_ERRORS.DEFAULT_ERROR}`);
  }

  if (code === STATUS_CODES.TIMEOUT) {
    return t('RequestErrors:timeout');
  }

  if (code === API_ERRORS.UNPROCESSABLE_ITEMS) {
    const items = order.shoppingCart.items
      .filter(item => message.items.find(errorItem => errorItem.menuable_id === item.menuableId))
      .map(item => item.name)
      .join(', ');

    return t(`APIErrors:e${code}`, { items });
  }

  if (code === API_ERRORS.XXL_PIZZAS_DISABLED) {
    const items = order.shoppingCart.items
      .filter(item => metadata.offers.find(errorItem => errorItem.offer_id === item.offerId))
      .map(item => item.name)
      .join(', ');
    return t(`APIErrors:e${code}`, { items });
  }

  return t(`APIErrors:e${code}`, { ...metadata });
};

export const persistProps = (responseCartItems, userCartItems) =>
  responseCartItems.map((item, responseItemIndex) => ({
    ...item,
    uuid: userCartItems[responseItemIndex].uuid || uuidv4(),
    ...(item.groupProducts && {
      groupProducts: item.groupProducts?.map((offerProduct, responseOfferProductIndex) => {
        const userOfferProduct = userCartItems[responseItemIndex].groupProducts[responseOfferProductIndex];
        return {
          ...offerProduct,
          allElements: userOfferProduct.allElements,
          stepId: userOfferProduct.stepId
        };
      })
    }),
    ...(userCartItems[responseItemIndex].addedFrom && {
      addedFrom: userCartItems[responseItemIndex].addedFrom
    }),
    ...(userCartItems[responseItemIndex].uuid && {
      uuid: userCartItems[responseItemIndex].uuid
    })
  }));

export const getRedeemCodeType = code =>
  code
    ? {
        [isGiftCardCode(code) ? 'giftCard' : 'coupon']: code
      }
    : null;

export const getRedeemedCodeInformation = ({ redeemedCode, amount, useGiftCard, isCampaign }) => ({
  code: redeemedCode,
  isGiftCard: isGiftCardCode(redeemedCode),
  useGiftCard,
  amount,
  errorMessage: null,
  loading: false,
  ok: true,
  isCampaign
});
