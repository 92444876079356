export const ADDRESS_TYPES = { HOME: 'home', WORK: 'work', COUPLE: 'couple', OTHER: 'other' };

export const DISPATCH_METHODS = {
  IN_STORE: 'in_store',
  PJ_DELIVERY: 'pj_delivery',
  HOTSPOT: 'hotspot',
  PAPACAR: 'papacar'
};

export const ORDER_METHODS = {
  BOTH: 'both',
  NEITHER: 'neither',
  PJ_DELIVERY: 'pj_delivery',
  IN_STORE: 'in_store'
};
