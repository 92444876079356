import i18next from 'i18next';

import { PATHS } from '~constants/paths';

i18next.addResources('es_CL', 'StoresLocation', {
  pagePath: PATHS.store.default,
  pageTitle: "Pizzeria {{store}}【A domicilio o recoger】| Papa John's",
  pageDescription:
    "Pizza Delivery o Recoger en tu Pizzería de {{location}} en {{direction}} → En Papa John's ✅ tu pizza casera, más cerca",
  title: 'Localiza tu Pizzería en tu ciudad',
  subtitle:
    'Cuéntanos dónde estás para que podamos presentarte el menú y las ofertas disponibles en la tienda más cercana. Incluye la dirección para entrega a delivery.',
  searchPlaceholder: 'Escribe tu dirección completa, región o comuna...'
});

// TODO: pending review for Spain
i18next.addResources('es_ES', 'StoresLocation', {
  pagePath: PATHS.store.default,
  pageTitle: "Pizzeria {{store}} domicilio o recoger】| Papa John's",
  pageDescription:
    "Pide a domicilio ✓ o recoger ✓ tu pizza en Papa John's {{location}} en {{direction}} ⇒ Disfruta del mejor sabor.",
  keywords: 'Pizza a Domicilio',
  title: 'Localiza tu Papa Johns más cercano',
  subtitle: 'Dinos dónde estás y te diremos qué pizzería te queda más cerca',
  searchPlaceholder: 'Escribe tu dirección completa, región o comuna...'
});

i18next.addResources('pt_PT', 'StoresLocation', {
  pagePath: PATHS.store.pt,
  pageTitle: "Nossos restaurantes | Papa John's Portugal",
  pageDescription:
    "Encontra o teu restaurante de Papa John's Portugal mais próximo para pedir a tua pizza favorita.",
  title: 'Localiza a tua Papa Johns mais próxima',
  subtitle: 'Diz-nos onde estás e nós dizemos-tequal é a loja mais perto de ti',
  searchPlaceholder: 'Digite seu endereço completo, com número de policía e andar...'
});

i18next.addResources('es_PA', 'StoresLocation', {
  pageTitle: "Nuestros restaurantes | Papa John's Panama",
  pageDescription: "Encuentra tu restaurante de Papa John's Panama más cercano para pedir tu pizza favorita."
});

i18next.addResources('es_CR', 'StoresLocation', {
  pageTitle: "Pizzeria {{store}} ᐅ Pizza cerca de mi | Papa John's",
  pageDescription:
    "Pizza Delivery y Recoger en Pizzería de {{location}} en {{direction}} → Papa John's ✅ Las mejores pizzas, más cerca de ti.",
  keywords: 'Pizzeria cerca de mi'
});

i18next.addResources('es_GT', 'StoresLocation', {
  pageTitle: "Nuestros restaurantes | Papa John's Guatemala",
  pageDescription:
    "Encuentra tu restaurante de Papa John's Guatemala más cercano para pedir tu pizza favorita."
});
