import i18next from 'i18next';

i18next.addResources('es_CL', 'Register', {
  createAccount: 'Crear cuenta',
  withAccount: '¿Ya tienes cuenta?',
  startSession: 'Inicia sesión',
  nameLabel: 'Nombre',
  phoneLabel: 'Teléfono celular',
  birthdayLabel: 'Fecha de nacimiento (opcional)',
  successRequest: 'Hola {{username}}, tu usuario fue creado correctamente',
  errorRegister: 'Contraseña errónea',
  phoneTakenErrorMessage: 'Ya existe una cuenta con ese teléfono celular',
  genericMessageError: 'Error al crear la cuenta, inténtalo de nuevo',
  subscribeToNewsletter: 'Acepto suscribirme al newsletter',
  npsDisagreement: 'No deseo evaluar la experiencia de esta compra',
  accept: 'Acepto ',
  privacyPolicies: 'las <span>políticas de privacidad</span>',
  messageRegisterModalActive: 'Registrate y comienza a disfrutar de nuestros beneficios.',
  birthdateBenefits: 'Podrás acceder a beneficios exclusivos en tu cumpleaños',
  invalidPhone: 'Telefono celular inválido'
});

i18next.addResources('es_CR', 'Register', {
  withAccount: '¿Ya tenés cuenta?',
  startSession: 'Iniciá sesión',
  phoneLabel: 'Teléfono Celular'
});

i18next.addResources('es_ES', 'Register', {
  phoneLabel: 'Teléfono móvil',
  phoneTakenErrorMessage: 'Ya existe una cuenta con ese teléfono móvil',
  subscribeToNewsletter: 'Acepto recibir comunicaciones comerciales y ofertas personalizadas'
});

i18next.addResources('pt_PT', 'Register', {
  createAccount: 'Criar Conta',
  withAccount: 'Já tens conta?',
  startSession: 'Inicía Sessão',
  nameLabel: 'Nome',
  phoneLabel: 'Telefone móvel',
  birthdayLabel: 'Data de nascimento (opcional)',
  successRequest: 'Ola {{username}}, o teu usuário foi criado correctamente',
  errorRegister: 'Senha errada',
  phoneTakenErrorMessage: 'Já existe uma conta com esse telefone móvel',
  genericMessageError: 'Erro ao criar a conta , tenta de novo!',
  subscribeToNewsletter: 'Eu concordo em receber comunicações comerciais e ofertas personalizadas',
  npsDisagreement: 'Não quero avaliar a experiência desta compra',
  accept: 'Eu concordo com ',
  privacyPolicies: 'a <span>política de privacidade</span>',
  messageRegisterModalActive: 'Cadastre-se e comece a aproveitar nossos benefícios.',
  birthdateBenefits: 'Você pode acessar benefícios exclusivos no seu aniversário',
  invalidPhone: 'Celular inválido'
});
