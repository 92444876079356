import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';
import { generateI18nResources } from '~utils/i18n';

generateI18nResources(Object.values(LANGUAGE_KEYS), languageKey =>
  i18next.addResources(languageKey, 'InputLabel', {
    characterCount: '{{charactersLeft}} caracteres restantes'
  })
);
