import { create } from 'apisauce';

import { CLIENT_PAGES } from '~constants/pages';
import { RENDER_CALL_CENTER } from '~constants/environment';

import { errorNormalizer } from './apiTransforms';

export const TOKEN_TYPE = 'Bearer';

const CALL_CENTER = '/call_center/';

const STATUS_CODES = {
  unauthorized: 401,
  unavailable: 503
};

const api = create({
  baseURL: process.env.REACT_APP_CORE_HOST,
  headers: { 'X-PLATFORM': 'web' }
});

export const callCenterApi = create({
  baseURL: process.env.REACT_APP_CORE_HOST,
  headers: { 'X-PLATFORM': 'call_center' }
});

export const getApi = () => (RENDER_CALL_CENTER ? callCenterApi : api);

export const socialLoginApi = create({
  baseURL: process.env.REACT_APP_SOCIAL_URL,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

export const helpCenterApi = create({
  baseURL: process.env.REACT_APP_ZENDESK_URL
});

export const contactFormApi = create({
  baseURL: process.env.REACT_APP_CONTACT_URL
});

export const setTokenHeader = token => {
  api.setHeaders({
    Authorization: `${TOKEN_TYPE} ${token}`
  });
};

// eslint-disable-next-line no-unused-vars, prettier/prettier
export const apiSetup = dispatch => {
  api.addResponseTransform(errorNormalizer);

  api.addMonitor(response => {
    if (response.status === STATUS_CODES.unavailable && location.pathname !== CLIENT_PAGES.UNAVAILABLE.path) {
      location.pathname = CLIENT_PAGES.UNAVAILABLE.path;
    }
  });

  api.addRequestTransform(request => {
    if (request?.url.includes(CALL_CENTER)) {
      api.setHeader('X-PLATFORM', 'call_center');

      if (request?.url.includes('#')) {
        const { url } = request;
        const splitUrl = url.split('#');
        request.headers['X-USERID'] = splitUrl[1];
        request.url = splitUrl[0];
      }
    }
  });
};

export const cdnApi = create({
  baseURL: process.env.REACT_APP_PUBLIC_CDN
});

export const getApiWithExternalUserId = externalUserId => {
  const externalUserIdApi = create({
    baseURL: process.env.REACT_APP_CORE_HOST,
    headers: { 'X-PLATFORM': 'web' }
  });
  externalUserIdApi.setHeaders({ 'X-USERID': externalUserId?.externalUserId });
  return externalUserIdApi;
};

export default api;
