import { t } from 'i18next';

import { formatPrice } from '~utils/number';

// eslint-disable-next-line id-length
const GIFT_CARD_CODE_INITIAL_CHARACTERS = 'GF';

export const isGiftCardCode = (code = '') => code.startsWith(GIFT_CARD_CODE_INITIAL_CHARACTERS);
export const giftCardHasDiscount = giftCard => giftCard.amount > (giftCard.price || giftCard.buyingPrice);

export const getGiftCardName = price => t('GiftCards:name', { price: formatPrice(price) });

export const getGiftCardCurrentPrice = (giftCardAmounts, amount) =>
  giftCardAmounts.filter(giftCardAmount => giftCardAmount.showingPrice === amount).price;
