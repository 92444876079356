import i18next from 'i18next';

i18next.addResources('es_CL', 'Ingredients', {
  maxIngredientsRange: 'Puedes elegir entre {{minimum}} y {{maximum}} ingredientes',
  ingredientsAmount: 'Puedes elegir {{count}} ingrediente',
  maxIngredients: 'El límite de ingredientes por pizza es de 10 ingredientes',
  freeIngredientsQuantity: 'Esta pizza tiene {{quantity}} ingredientes gratis para seleccionar',
  // eslint-disable-next-line camelcase
  ingredientsAmount_plural: 'Puedes elegir {{count}} ingredientes',
  maxIngredientsRangeSubtitle: 'Las opciones con * se consideran un ingrediente más'
});

i18next.addResources('es_ES', 'Ingredients', {
  maxIngredientsRange: 'Puedes elegir entre {{minimum}} y {{maximum}} ingredientes',
  ingredientsAmount: 'Puedes elegir {{count}} ingrediente',
  maxIngredients: 'El límite de ingredientes por pizza es de 10 ingredientes',
  freeIngredientsQuantity: 'Esta pizza tiene {{quantity}} ingredientes gratis para seleccionar',
  // eslint-disable-next-line camelcase
  ingredientsAmount_plural: 'Puedes elegir {{count}} ingredientes',
  maxIngredientsRangeSubtitle: 'Las opciones marcadas con un * se consideran como un ingrediente adicional'
});

i18next.addResources('pt_PT', 'Ingredients', {
  maxIngredientsRange: 'Você pode escolher entre {{minimum}} e {{maximum}} ingredientes',
  ingredientsAmount: 'Você pode escolher {{count}} ingrediente',
  maxIngredients: 'O limite de ingredientes por pizza é de 10 ingredientes.',
  freeIngredientsQuantity: 'Esta pizza tem {{quantity}} ingredientes grátis para selecionar',
  // eslint-disable-next-line camelcase
  ingredientsAmount_plural: 'Você pode escolher {{count}} ingredientes',
  maxIngredientsRangeSubtitle: 'As opções com * são consideradas mais um ingrediente'
});
