import Immutable from 'seamless-immutable';
import { createReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';

const initialState = {
  currentStepNumber: 0,
  steps: [],
  isStepperView: true
};

const reducerDescription = {
  [actions.CHANGE_STEP]: onReadValue(),
  [actions.SET_STEPS]: onReadValue(),
  [actions.SET_STEPPER_VIEW]: onReadValue()
};

const reducer = createReducer(new Immutable(initialState), reducerDescription);

export default reducer;
