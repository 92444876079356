import i18next from 'i18next';

i18next.addResources('es_CL', 'Home', {
  createOrderError:
    'Lo sentimos, pero no se ha podido crear la orden. Por favor intenta nuevamente en unos minutos',
  messageErrorOnlinePayRejected:
    'Se ha producido un error al efectuar su compra online. Pruebe de nuevo o intente otro medio de pago',
  messageErrorOnlinePayment: 'Problema al generar el pago online',
  messageErrorWebPay: 'Problema al generar el pago con Webpay',
  messageErrorSodexo: 'Problema al generar el pago con Sodexo',
  messageErrorCreditCard: 'Problema al generar el pago con tarjeta de crédito',
  noUserAddress: 'Parece que no hay ninguna dirección asociada al usuario',
  noUserToken: 'No se pudo generar las credenciales para hacer el pedido',
  noUserData: 'Parece que el usuario no tiene información',
  discountIsOverSubtotal: 'Aún tienes cupo en tu tarjeta, agrega otro producto para no perder tu saldo.',
  app: 'aplicación movil',
  web: 'web',
  messageErrorSinpeMovil: 'Problema al generar el pago con Sinpe Móvil'
});

i18next.addResources('es_ES', 'Home', {
  createOrderError:
    'Lo sentimos, pero no se ha podido crear la orden. Por favor intenta nuevamente en unos minutos',
  messageErrorOnlinePayRejected:
    'Se ha producido un error al efectuar su compra online. Pruebe de nuevo o intente otro medio de pago',
  messageErrorOnlinePayment: 'Problema al generar el pago online',
  noUserAddress: 'Parece que no hay ninguna dirección asociada al usuario',
  noUserToken: 'No se pudo generar las credenciales para hacer el pedido',
  noUserData: 'Parece que el usuario no tiene información',
  discountIsOverSubtotal: 'Aún tienes cupo en tu tarjeta, agrega otro producto para no perder tu saldo.'
});

i18next.addResources('pt_PT', 'Home', {
  createOrderError: 'Lamentamos, mas não foi possível criar o pedido. Tente novamente em alguns minutos',
  messageErrorOnlinePayRejected:
    'Houve um problema ao efectuar a compra online. Por favor tenta de novo ou utiliza outro cartão ou meio de pagamento.',
  messageErrorOnlinePayment: 'Problema ao gerar pagamento online',
  noUserAddress: 'Parece que não há endereço associado ao usuário',
  noUserToken: 'Não foi possível gerar credenciais para solicitar',
  noUserData: 'Parece que o usuário não tem informações',
  discountIsOverSubtotal:
    'Você ainda tem espaço no seu cartão, adicione outro produto para não perder o equilíbrio.',
  app: 'aplicação móvel',
  web: 'web'
});
