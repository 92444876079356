import i18next from 'i18next';

i18next.addResources('es_CL', 'OrderRule', {
  addToCart: 'Añadir al carrito',
  saving: 'Ahorraste {{savings}}',
  orderRuleMessageStart: 'Por solo {{price}} agrega '
});

i18next.addResources('pt_PT', 'OrderRule', {
  addToCart: 'Adicionar ao carrinho',
  savings: 'Poupaste {{savings}}',
  orderRuleMessageStart: 'Por apenas {{price}} adiciona '
});
