import { shape, number, string, array, arrayOf } from 'prop-types';

export const itemPropTypes = shape({
  menuableType: string.isRequired,
  quantity: number.isRequired,
  // TODO: this shape isn't defined yet
  appliedPromos: array,
  unitPrice: number,
  extras: number.isRequired,
  subtotal: number.isRequired,
  discount: number.isRequired,
  total: number.isRequired,
  name: string.isRequired,
  menuableId: number.isRequired,
  itemName: string,
  description: string.isRequired
});

export const shoppingCartPropTypes = shape({
  items: arrayOf(itemPropTypes)
});
