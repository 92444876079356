import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer } from 'redux-recompose';

import { actions } from './actions';
import { INGREDIENTS_SCREEN_TARGET } from './constants';

const initialState = completeState({ [INGREDIENTS_SCREEN_TARGET]: null });

const reducerDescription = {
  primaryActions: [actions.GET_INGREDIENTS_SCREEN]
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
