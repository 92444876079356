import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer, onReadValue, onSuccess } from 'redux-recompose';

import LocalStorageService from '~services/LocalStorageService';

import { actions } from './actions';
import {
  onGetOPTCartPricesSuccess,
  onGetMenuHelperSuccess,
  onClearCart,
  onGetBannerImages,
  onClearOptimizedItems,
  removeAlcoholProduct,
  onUpdateUpsellingVariants
} from './effects';
import {
  MENU_TARGET,
  SHOPPING_CART_TARGET,
  OPTIMIZED_SHOPPING_CART_TARGET,
  FIX_NAV_TARGET,
  CURRENT_ORDER_TARGET,
  COMBOS_TARGET,
  SUGGESTIVE_SELLS_TARGET,
  OFFER_COMPLETIONS_TARGET,
  OFFER_TARGET,
  REDEEMED_CODE_TARGET,
  BANNER_IMAGES_TARGET,
  CAN_ACCESS_CHECKOUT_TARGET,
  EXTERNAL_OFFER_TARGET,
  GIFTCARD_OVER_SUBTOTAL_TARGET,
  TIP_TARGET,
  INITIAL_TIP,
  PAYMENT_CARDS_TARGET,
  ADD_PAYMENT_CARD_TARGET,
  DELETE_PAYMENT_CARD_TARGET,
  ONECLICK_PARAMS,
  ORDER_RULE,
  ACTIVE_ORDERS_TARGET,
  ORDER_DATA_CONSUMING_TARGET,
  CORE_SETTINGS_TARGET,
  IS_DEFAULT_STORE,
  AGE_VERIFICATION,
  PROMOTED_PRODUCTS,
  FILTERED_MENU,
  IS_GUEST_CARD_TARGET,
  GUEST_PAYMENT_CARD_TARGET
} from './constants';

const initialStateDescription = {
  [CAN_ACCESS_CHECKOUT_TARGET]: LocalStorageService.getShoppingCart()?.items?.length > 0,
  [MENU_TARGET]: [],
  [SHOPPING_CART_TARGET]: LocalStorageService.getShoppingCart() || { items: [] },
  [OPTIMIZED_SHOPPING_CART_TARGET]: { items: [] },
  [FIX_NAV_TARGET]: false,
  [CURRENT_ORDER_TARGET]: null,
  [ACTIVE_ORDERS_TARGET]: [],
  [COMBOS_TARGET]: [],
  [SUGGESTIVE_SELLS_TARGET]: [],
  [OFFER_COMPLETIONS_TARGET]: [],
  [OFFER_TARGET]: null,
  [REDEEMED_CODE_TARGET]: null,
  [BANNER_IMAGES_TARGET]: null,
  [EXTERNAL_OFFER_TARGET]: null,
  [GIFTCARD_OVER_SUBTOTAL_TARGET]: false,
  [TIP_TARGET]: INITIAL_TIP,
  [PAYMENT_CARDS_TARGET]: [],
  [ADD_PAYMENT_CARD_TARGET]: null,
  [DELETE_PAYMENT_CARD_TARGET]: null,
  [ONECLICK_PARAMS]: null,
  [ORDER_RULE]: null,
  [ORDER_DATA_CONSUMING_TARGET]: null,
  [CORE_SETTINGS_TARGET]: {},
  [IS_DEFAULT_STORE]: false,
  [AGE_VERIFICATION]: null,
  [PROMOTED_PRODUCTS]: [],
  [FILTERED_MENU]: null,
  [IS_GUEST_CARD_TARGET]: false,
  [GUEST_PAYMENT_CARD_TARGET]: null
};

const initialState = completeState(initialStateDescription, [FIX_NAV_TARGET, REDEEMED_CODE_TARGET]);

initialState[`${MENU_TARGET}Loading`] = true;
const reducerDescription = {
  primaryActions: [
    actions.GET_MENU,
    actions.GET_MENU_HELPERS,
    actions.GET_CART_PRICES,
    actions.GET_SUGGESTIVE_PROMOS,
    actions.GET_OFFER_COMPLETIONS,
    actions.GET_OPTIMIZED_CART,
    actions.CREATE_ORDER,
    actions.GET_BANNER_IMAGES,
    actions.CHECK_EXTERNAL_OFFER,
    actions.ADD_PAYMENT_CARD,
    actions.GET_PAYMENT_CARDS,
    actions.DELETE_PAYMENT_CARD,
    actions.GET_ORDER_SETTINGS,
    actions.GET_CORE_SETTINGS,
    actions.START_ONECLICK_CARD_INSCRIPTION,
    actions.GET_ORDER_RULES,
    actions.SUBMIT_ELECTRONIC_RECEIPT,
    actions.GET_PROMOTED_PRODUCTS
  ],
  override: {
    [actions.SET_CHECKOUT_ACCESS]: onReadValue(),
    [actions.SET_EXTERNAL_OFFER]: onReadValue(),
    [actions.CLEAR_ORDER_DATA]: onSuccess(),
    [actions.GET_CART_PRICES_SUCCESS]: onGetOPTCartPricesSuccess,
    [actions.GET_OPTIMIZED_CART_SUCCESS]: onGetOPTCartPricesSuccess,
    [actions.SET_SHOULD_FIX_NAV]: onReadValue(),
    [actions.CLEAR_CART]: onClearCart,
    [actions.GET_MENU_HELPERS_SUCCESS]: onGetMenuHelperSuccess,
    [actions.SET_COMBOS]: onReadValue(action => action.payload),
    [actions.FLATTEN_MENU]: onReadValue(),
    [actions.FLATTEN_COMBOS]: onReadValue(),
    [actions.REDEEM_CODE]: onReadValue(),
    [actions.GET_BANNER_IMAGES_SUCCESS]: onGetBannerImages,
    [actions.RESET_TIP]: onReadValue(),
    [actions.CLEAR_PAYMENT_CARDS]: onReadValue(),
    [actions.GET_ORDER_RULES]: onSuccess(),
    [actions.CLEAN_ORDER_DATA_CONSUMING]: onReadValue(),
    [actions.SUBMIT_ELECTRONIC_RECEIPT]: onReadValue(),
    [actions.SET_ELECTRONIC_FORM_VALID]: onReadValue(),
    [actions.CLEAN_ORDER_DATA_CONSUMING]: onReadValue(),
    [actions.SET_VALUE]: onReadValue(),
    [actions.CLEAR_OPTIMIZED_ITEMS]: onClearOptimizedItems,
    [actions.SET_TIP]: onReadValue(),
    [actions.SET_AGE_VERIFICATION]: onReadValue(),
    [actions.REMOVE_ALCOHOL_CART]: removeAlcoholProduct,
    [actions.SET_UPSELLING_VARIANTS]: onUpdateUpsellingVariants,
    [actions.SET_FILTERED_MENU]: onReadValue(),
    [actions.CATALAN_LANGUAGE_ACTIVATED]: onReadValue(),
    [actions.SET_IS_GUEST_CARD]: onReadValue(),
    [actions.SET_GUEST_PAYMENT_CARD]: onReadValue()
  }
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
