import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'TimeUp', {
  title: '¡Tiempo agotado para esta promoción!',
  pluralTitle: '¡Tiempo agotado para estas promociones!',
  description:
    'La promoción <strong class="bold">{{product}}</strong> ha expirado. No es posible completar la compra con el descuento especial.',
  pluralDescription:
    'Las promociones <strong class="bold">{{product}}</strong> han expirado. No es posible completar la compra con el descuento especial.',
  btnDeleteProduct: 'Quitar producto del pedido',
  pluralBtnDeleteProduct: 'Quitar productos del pedido'
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'TimeUp', {
  title: 'O tempo acabou para esta promoção!',
  pluralTitle: 'O tempo acabou para essas promoções!',
  description:
    'A promoção <strong class="bold">{{product}}</strong> expirou. Não é possível concluir a compra com o desconto especial..',
  pluralDescription:
    'As promoções <strong class="bold">{{product}}</strong> expiraram. Não é possível concluir a compra com o desconto especial.',
  btnDeleteProduct: 'Remover produto do pedido',
  pluralBtnDeleteProduct: 'Remover produtos do pedido'
});
