import i18next from 'i18next';

i18next.addResources('es_CL', 'PurchaseControl', {
  pagePath: '/compra-en-revision',
  pageTitle: 'Pedido en revisión',
  pageDescription: 'Su pedido se esta validando.',
  validate: 'Validando su compra'
});

i18next.addResources('pt_PT', 'PurchaseControl', {
  pagePath: '/compra-em-revisao',
  pageTitle: 'Pedido em revisao',
  pageDescription: 'Seu pedido está sendo validado.',
  validate: 'Validando sua compra'
});
