import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers as CR, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { fetchMiddleware, configureMergeState, wrapCombineReducers } from 'redux-recompose';

/*
 * TODO Add this if you need it
 * import AnalyticsMiddleware from '../services/AnalyticsService';
 */
import tabs from '~components/Tabs/redux/reducer';
import wizard from '~components/Wizard/redux/reducer';
import home from '~screens/Dashboard/screens/Home/redux/reducer';
import offers from '~components/ModifyProductModals/screens/ComboCreation/redux/reducer';
import monitor from '~screens/Dashboard/screens/Home/components/OrderBuilder/components/Monitor/redux/reducer';

import auth from './Auth/reducer';
import modal from './Modal/reducer';
import order from './Orders/reducer';
import searchStore from './SearchStore/reducer';
import smartBanner from './SmartBanner/reducer';
import notification from './Notification/reducer';
import customPizza from './CustomPizza/reducer';
import papaPoints from './PapaPoints/reducer';
import helpCenter from './HelpCenter/reducer';
import ingredients from './Ingredients/reducer';
import contactForm from './ContactForm/reducer';
import statements from './Statements/reducer';
import upselling from './Upselling/reducer';
import giftCards from './GiftCards/reducer';
import promoSelling from './PromoSelling/reducer';
import productOptionsModal from './ProductOptionsModal/reducer';
import sizesAndCrusts from './SizesAndCrusts/reducer';

configureMergeState((state, diff) => state.merge(diff));

const combineReducers = wrapCombineReducers(CR);

export const history = createBrowserHistory();

// Add reducers here
const reducers = combineReducers({
  auth,
  customPizza,
  modal,
  searchStore,
  smartBanner,
  notification,
  home,
  order,
  router: connectRouter(history),
  tabs,
  wizard,
  papaPoints,
  helpCenter,
  ingredients,
  offers,
  contactForm,
  statements,
  monitor,
  upselling,
  giftCards,
  productOptionsModal,
  promoSelling,
  sizesAndCrusts
});

const middlewares = [thunk, fetchMiddleware, routerMiddleware(history)];
const enhancers = [];

// TODO Add this if you need it.
/* ------------- Analytics Middleware ------------- */
// Middlewares.push(AnalyticsMiddleware);

/* ------------- Assemble Middleware ------------- */
enhancers.push(applyMiddleware(...middlewares));

const composeEnhancers =
  // eslint-disable-next-line no-underscore-dangle
  (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const rootReducer = (state, action) =>
  /*
   * TODO Add this if you need it
   * if (action.type === authActions.SIGN_OUT) {
   *   return reducers(getGlobalState(state), action);
   * }
   */
  reducers(state, action);

const store = createStore(rootReducer, composeEnhancers(...enhancers));

export default store;
