import { RENDER_CALL_CENTER } from '~constants/environment';
import { EVENT, PLATFORM, sendGTMEvent } from '~utils/analytics';
import { isEmptyObject, omit } from '~utils/object';

export const isSingleProduct = quantity => quantity === 1;

export const replaceItemData = ({ items, index, data }) => {
  const newItems = [...items];
  newItems[index] = { ...newItems[index], ...data };
  return newItems;
};

export const replaceOfferData = ({ items = [], offerId, offerGroupId, data }) => {
  const { addedFrom, ...itemData } = data;
  return items.map(item =>
    item?.offerId === offerId
      ? {
          ...item,
          addedFrom: addedFrom || item.addedFrom,
          groupProducts: replaceItemData({
            items: item?.groupProducts,
            index: item?.groupProducts.findIndex(product => product?.offerGroupId === offerGroupId),
            data: itemData
          })
        }
      : item
  );
};

export const getItemFromOffer = (offer, offerGroupId) =>
  offer?.groupProducts.find(product => product?.offerGroupId === offerGroupId);

export const getGTMUpsellingEventData = ({ upsellingVariant, location, quantity, sizesAndCrusts }) => ({
  date: new Date(),
  platform: RENDER_CALL_CENTER ? PLATFORM.CALL_CENTER : PLATFORM.WEB,
  location,
  productId: upsellingVariant.menuableId,
  productName: `${upsellingVariant?.productName}`,
  productDescription: upsellingVariant?.productDescription,
  upgradeId: upsellingVariant?.variant?.menuableId,
  upgradeDescription:
    sizesAndCrusts.crusts?.[upsellingVariant?.variant?.crust] ||
    sizesAndCrusts.sizes?.[upsellingVariant?.variant?.size],
  upgradePrice: quantity * upsellingVariant.variant.deltaPrice
});

export const getCommonProps = ({ name, variantName, category, crust, size, addedFrom }) => ({
  productName: name,
  productDescription: variantName,
  category,
  crust,
  size,
  addedFrom
});

export const getOfferUpsellingVariant = offer => {
  const groupProduct = offer?.upsellingVariant?.groupProduct;
  const upgradableItem = getItemFromOffer(offer, groupProduct?.offerGroupId);
  return {
    menuableId: groupProduct?.menuableId,
    offerGroupId: groupProduct?.offerGroupId,
    isUpgraded: false,
    isOffer: true,
    variant: omit(offer?.upsellingVariant, ['groupProduct']),
    ...getCommonProps(upgradableItem)
  };
};

export const getItemUpsellingVariant = item => ({
  menuableId: item?.menuableId,
  isUpgraded: false,
  variant: item?.upsellingVariant,
  ...getCommonProps(item)
});

export const getUpsellingVariant = (item = {}, location, sizesAndCrusts) => {
  if (!item?.upsellingVariant) {
    return null;
  }
  const upsellingVariant = item?.menuableId ? getItemUpsellingVariant(item) : getOfferUpsellingVariant(item);
  sendGTMEvent(
    EVENT.SHOW_UPSELLING,
    getGTMUpsellingEventData({ upsellingVariant, location, quantity: item.quantity, sizesAndCrusts })
  );
  return upsellingVariant;
};

const isNecessaryUpdateVariant = (item = {}, upsellingVariant = {}) => {
  const { menuableId: itemMenuableId } = item;
  const { isUpgraded, menuableId, variant } = upsellingVariant;
  return (
    isEmptyObject(upsellingVariant) ||
    (!isUpgraded && itemMenuableId !== menuableId) ||
    (isUpgraded && itemMenuableId !== variant?.menuableId)
  );
};

export const updateUpsellingVariants = ({ upsellingVariants, items, location, sizesAndCrusts }) =>
  items.reduce((acc, item) => {
    const currentUpsellingVariant = upsellingVariants[item.uuid];
    const product = item?.offerType ? getItemFromOffer(item, currentUpsellingVariant?.offerGroupId) : item;
    const upsellingVariant = isNecessaryUpdateVariant(product, currentUpsellingVariant)
      ? getUpsellingVariant(item, location, sizesAndCrusts)
      : currentUpsellingVariant;
    return {
      ...acc,
      ...(upsellingVariant && { [item.uuid]: upsellingVariant })
    };
  }, upsellingVariants);

export const updateVariantsOnPageChange = ({ upsellingVariants, items, location, sizesAndCrusts }) =>
  items.reduce((acc, item) => {
    const upsellingVariant = upsellingVariants[item.uuid];
    if (item?.upsellingVariant && upsellingVariant?.isUpgraded) {
      return { ...acc, [item.uuid]: getUpsellingVariant(item, location, sizesAndCrusts) };
    }
    if (upsellingVariant && !upsellingVariant.isUpgraded) {
      return { ...acc, [item.uuid]: upsellingVariant };
    }
    return acc;
  }, {});
