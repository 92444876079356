import i18next from 'i18next';

i18next.addResources('es_CL', 'OrderConfirmation', {
  title: 'Tu pedido se ha realizado con éxito',
  subtitle: 'Disfruta tu Papa John’s',
  covidWarning:
    'Para tomar medidas de higiene respecto al COVID - 19, nuestro repartidor dejará tu pedido manteniendo una distancia prudente evitando el contacto físico.',
  youAdded: 'Sumaste',
  pointsText: '{{points}} Papa Puntos',
  goToOrderButton: 'Ir a mi pedido',
  clubInvitationText: '¡Únete al club!',
  benefitsTextLeft: 'Acumula',
  benefitsTextMiddle: 'Papa Puntos',
  benefitsTextRight: 'para canjear por comida, guardar tus direcciones y más!',
  pointsOfOrderLeft: 'Sumarás',
  pointsOfOrderRight: 'con esta compra',
  loginButton: 'Inicia sesión',
  hasAccount: '¿Aún no tienes cuenta?',
  registerLink: 'Regístrate',
  defaultErrorTitle: 'Ha ocurrido un error',
  defaultErrorMessage: 'No hemos podido generar tu pedido',
  backButton: 'Volver',
  confirmReceipt: 'Tu pedido se está generando',
  delivery: 'Delivery a {{address}}',
  pickUpAt: 'Retiro en {{address}} ',
  selectOnlinePayment: 'Disponible solo pago online',
  callText: '¿Necesitas ayuda con tu compra? Llámanos al <Strong>{{phoneNumber}}</Strong> y te ayudaremos.',
  writeToWhatsApp: 'Si prefieres <Link>escríbenos a Whatsapp</Link> para ayudarte a completar tu compra',
  contactByWsp: 'Contactar por WhatsApp',
  contactTheCallCenter: 'Llamar al call center',
  helpText: 'Elige una de las siguientes opciones y recibe asistencia inmediata con tu compra:'
});

i18next.addResources('es_CR', 'OrderConfirmation', {
  subtitle: 'Disfruta de tu pizza Papa John’s',
  clubInvitationText: '¡Únete!',
  benefitsTextLeft: 'Acumulá',
  pointsOfOrderLeft: 'Con tu compra actual acumulás',
  loginButton: 'Iniciá sesión'
});

i18next.addResources('es_ES', 'OrderConfirmation', {
  subtitle: 'Disfruta de tu Papa John’s',
  youAdded: 'Conseguiste',
  pointsOfOrderLeft: 'Con tu compra actual acumulas',
  defaultErrorTitle: 'Se ha producido un error',
  confirmReceipt: 'Estamos procesando tu pedido'
});

i18next.addResources('pt_PT', 'OrderConfirmation', {
  title: 'O teu pedido foi realizado com êxito',
  subtitle: "Disfruta do teu pedido Papa John's",
  covidWarning:
    'Considerando a situação actual em relação ao COVID-19, o nosso distribuidor entregará o teu pedido mantendo uma distância prudente e evitando o contacto.',
  youAdded: 'Conseguiste',
  pointsText: '{{points}} Papa Pontos',
  goToOrderButton: 'Ir para o meu pedido',
  clubInvitationText: 'Junta-te ao clube!',
  benefitsTextLeft: 'Acumula',
  benefitsTextMiddle: 'Papa Pontos',
  benefitsTextRight: 'para Trocar por Pizzas, guardar as tuas moradas e mais!',
  pointsOfOrderLeft: 'Com a tua compra actual acumulas',
  loginButton: 'Inicia Sessão',
  hasAccount: 'Ainda não tens conta?',
  registerLink: 'Regista-te!',
  defaultErrorTitle: 'Ocorreu um erro',
  defaultErrorMessage: 'Não foi possível efectuar o teu pedido',
  backButton: 'Voltar',
  confirmReceipt: 'Estamos a processar a tua encomenda',
  selectOnlinePayment: 'Disponível apenas pagamento online'
});
