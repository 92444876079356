import i18next from 'i18next';

i18next.addResources('es_CL', 'SmartBanner', {
  closeButton: 'Cerrar',
  downloadAppTxt: 'Descarga nuestra APP',
  closeSmartBanner: 'Cerrar banner'
});

i18next.addResources('pt_PT', 'SmartBanner', {
  closeButton: 'Fechar',
  downloadAppTxt: 'Baixe nosso aplicativo',
  closeSmartBanner: 'Fechar banner'
});
