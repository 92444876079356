import { createTypes, completeTypes } from 'redux-recompose';

import LocalStorageService from '~services/LocalStorageService';
import { TUTORIAL_ENABLED } from '~constants/features';

import { PIZZAS_TUTORIAL } from './constants';

export const actions = createTypes(
  completeTypes(
    [],
    [
      'OPEN_MODAL',
      'CLOSE_MODAL',
      'OPEN_MODAL_WITH_DATA',
      'CLOSE_MODAL_WITH_DATA',
      'CLEAR_MODALS',
      'OPEN_PIZZAS_MODAL'
    ]
  ),
  '@@MODAL'
);

const targetName = modalName => `${modalName}ModalIsOpen`;

const targetInfo = modalName => `${modalName}Info`;

export const actionCreators = {
  openModal: modalName => ({
    type: actions.OPEN_MODAL,
    target: targetName(modalName)
  }),
  closeModal: modalName => ({
    type: actions.CLOSE_MODAL,
    target: targetName(modalName)
  }),
  openModalWithData: (modalName, data) => ({
    type: actions.OPEN_MODAL_WITH_DATA,
    target: modalName,
    payload: { open: true, data }
  }),
  closeModalWithData: modalName => ({
    type: actions.CLOSE_MODAL_WITH_DATA,
    target: modalName,
    payload: { open: false }
  }),
  clearModals: () => ({
    type: actions.CLEAR_MODALS
  }),
  openPizzasModal: nextModal => dispatch => {
    dispatch(actionCreators.clearModals());
    if (TUTORIAL_ENABLED) {
      if (LocalStorageService.getHideTutorial()) {
        dispatch(actionCreators.openModal(nextModal));
      } else {
        dispatch(actionCreators.openModal(PIZZAS_TUTORIAL));
        dispatch({
          type: actions.OPEN_PIZZAS_MODAL,
          payload: nextModal,
          target: targetInfo(PIZZAS_TUTORIAL)
        });
      }
    } else {
      dispatch(actionCreators.openModal(nextModal));
    }
  }
};
