import i18next from 'i18next';

i18next.addResources('es_CL', 'OfficialStatement', {
  pagePath: '/comunicado-covid-19',
  pageTitle: "Comunicado Covid 19 | Papa John's Chile",
  pageDescription: "Información sobre las medidas Covid 19 en las pizzerías Papa John's de Chile."
});

i18next.addResources('es_CL', 'PrivacyPolicy', {
  pagePath: '/politica-de-privacidad',
  pageTitle: "Política de privacidad | Papa John's Chile",
  pageDescription: "Lee nuestra política de privacidad Papa John's Chile."
});

i18next.addResources('es_ES', 'PrivacyPolicy', {
  pageTitle: "Política de privacidad | Papa John's España",
  pageDescription: "Lee nuestra política de privacidad Papa John's España."
});

i18next.addResources('pt_PT', 'PrivacyPolicy', {
  pagePath: '/politicas',
  pageTitle: "Política de privacidade | Papa John's Portugal",
  pageDescription: "Leia nossa política de privacidade Papa John's Portugal."
});

i18next.addResources('es_PA', 'PrivacyPolicy', {
  pageTitle: "Política de privacidad | Papa John's Panama",
  pageDescription: "Lee nuestra política de privacidad Papa John's Panama."
});

i18next.addResources('es_CR', 'PrivacyPolicy', {
  pageTitle: "Política de privacidad | Papa John's Costa Rica",
  pageDescription: "Lee nuestra política de privacidad Papa John's Costa Rica."
});

i18next.addResources('es_GT', 'PrivacyPolicy', {
  pageTitle: "Política de privacidad | Papa John's Guatemala",
  pageDescription: "Lee nuestra política de privacidad Papa John's Guatemala."
});
