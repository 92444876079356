import i18next from 'i18next';

i18next.addResources('es_CL', 'UpSelling', {
  title: '¿Te faltó algo?',
  footerNoThanks: 'No, gracias',
  footerBtnCheckOut: 'Ir al Checkout'
});

i18next.addResources('pt_PT', 'UpSelling', {
  title: 'Você perdeu alguma coisa?',
  footerNoThanks: 'não, obrigado',
  footerBtnCheckOut: 'Vá para o checkout'
});
