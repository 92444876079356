import { init, use } from 'i18next';
import { i18nextPlugin } from 'translation-check';

import { BASE_URL, NON_PRODUCTIVE_BASE_URL_PATHS } from '~constants/environment';

function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
}

const enableTranslationChecker = NON_PRODUCTIVE_BASE_URL_PATHS.some(el => BASE_URL?.includes(el));

if (enableTranslationChecker) {
  use(i18nextPlugin);
}

init({
  lng: process.env.REACT_APP_TRANSLATION_COUNTRY,
  initImmediate: false,
  fallbackLng: 'es_CL',
  returnObjects: true
});

requireAll(require.context('..', true, /i18n\.js$/));
