import { getApi } from '~config/api';

const OfferService = {
  getPromotions: (storeId, dispatchMethod, offerId) => {
    const promoUrl = offerId
      ? `/v1/offers/promotions?store_id=${storeId}&dispatch_method=${dispatchMethod}&offer_id=${offerId}`
      : `/v1/offers/promotions?store_id=${storeId}&dispatch_method=${dispatchMethod}`;

    return getApi().get(promoUrl);
  },
  getOffer: ({ offerId, storeId, dispatchMethod }) => {
    const hasDispatchMethod = dispatchMethod ? `&dispatch_method=${dispatchMethod}` : '';
    return getApi().get(`/v1/offers/${offerId}?store_id=${storeId}${hasDispatchMethod}`);
  }
};

export default OfferService;
