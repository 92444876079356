const FIELDS = {
  EMAIL: 'email',
  PASSWORD: 'password',
  NAME: 'name',
  PHONE: 'phone',
  SUSCRIBE_TO_NEWSLETTER: 'subscribeToNewsletter',
  POLL_AGREEMENT: 'acceptPoll',
  BIRTHDATE: 'birthdate'
};

export default FIELDS;
