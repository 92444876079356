import i18next from 'i18next';

i18next.addResources('es_CL', 'CheckoutPersonalData', {
  title: 'Datos personales'
});

i18next.addResources('es_ES', 'CheckoutPersonalData', {
  title: 'Datos personales'
});

i18next.addResources('pt_PT', 'CheckoutPersonalData', {
  title: 'Dados pessoais'
});
