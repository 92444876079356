import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, shape, string } from 'prop-types';

import { userPropTypes } from '../../../propTypes/user';

import { actionCreators as authActions } from '~redux/Auth/actions';
import { getQueryParams } from '~utils/parseUrl';
import { getCategoriesConcat, sendDataLayer, setConsentMode } from '~utils/analytics';
import { getRouteName } from '~constants/pages';
import { shoppingCartPropTypes } from '~screens/Dashboard/screens/Home/propTypes';
import { actionCreators as homeActions } from '~screens/Dashboard/screens/Home/redux/actions';
import { COUNTRIES, CURRENT_COUNTRY_ID } from '~constants/environment';
import { actionCreators as sizesAndCrustsActions } from '~redux/SizesAndCrusts/actions';

import AppRoutes from './layout';

class AppRoutesContainer extends Component {
  componentDidMount() {
    this.props.loadAppData();
    const {
      location: { search },
      socialLogin
    } = this.props;

    if (search) {
      const code = getQueryParams(search)?.code;

      if (code) {
        socialLogin(code);
      }
    }

    window.dataLayer = window.dataLayer || [];
    if ([COUNTRIES.es, COUNTRIES.pt].includes(CURRENT_COUNTRY_ID)) {
      setConsentMode({ analyticsCookie: false, marketingCookie: false });
    }
    const routeName = getRouteName(location.pathname);
    this.callSendDataLayer(routeName);
    this.props.getCoreSettings();
    this.props.getSizesAndCrusts();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (prevProps.location !== location) {
      const routeName = getRouteName(location.pathname);
      this.callSendDataLayer(routeName);
    }
  }

  callSendDataLayer(routeName) {
    const { currentUser, store, shoppingCart } = this.props;
    if (routeName) {
      sendDataLayer({
        event: 'page-update',
        pageName: routeName,
        userId: currentUser?.id,
        isLogged: !!currentUser?.id,
        storeName: store?.name,
        storeId: store?.id,
        quantity: shoppingCart.items.length,
        categories: getCategoriesConcat(shoppingCart.items).slice(1)
      });
    }
  }

  render() {
    return <AppRoutes />;
  }
}

AppRoutesContainer.propTypes = {
  loadAppData: func.isRequired,
  socialLogin: func.isRequired,
  currentUser: userPropTypes,
  getCoreSettings: func,
  getSizesAndCrusts: func,
  location: shape({ pathname: string }),
  shoppingCart: shoppingCartPropTypes,
  store: shape({ pathname: string })
};

const mapStateToProps = state => {
  const { router, auth, searchStore, home } = state;

  return {
    location: router.location,
    currentUser: auth.currentUser,
    store: searchStore.currentSubsidiary,
    shoppingCart: home.shoppingCart
  };
};

const mapDispatchToProps = dispatch => ({
  loadAppData: () => dispatch(authActions.getLocalStorageData()),
  socialLogin: code => dispatch(authActions.socialLogin(code)),
  getCoreSettings: () => dispatch(homeActions.getCoreSettings()),
  getSizesAndCrusts: () => dispatch(sizesAndCrustsActions.getSizesAndCrusts())
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutesContainer);
