import i18next from 'i18next';

i18next.addResources('es_CL', 'Landing', {
  pageTitle: "Pizzas | Pide Online retiro en tienda o domicilio | Papa John's Chile",
  pageDescription:
    "Tus pizzas favoritas de Papa John's. Pide online retiro en local o domicilio. Mejores ingredientes mejor pizza.",
  headerTitle: '¡Pide online!',
  headerMessage: 'Indícanos tu dirección para ofrecerte opciones en los locales más cercanos',
  cellphones: 'Aplicación App Store y Google Play',
  imgAppStore: 'App Store',
  imgPlayStore: 'Google Play',
  titleHeadingSEO: "Papa John's - Pizzería Online",
  selectStore: 'Escribe tu dirección y selecciona una tienda para ver el menú'
});

i18next.addResources('es_ES', 'Landing', {
  pageTitle: "Pizzas | Pedir Online a domicilio | Papa John's España",
  pageDescription:
    "Pide tus pizzas favoritas de Papa John's. Usamos los mejores ingredientes para hacer la mejor pizza. Pedidos online a domicilio, o para recoger en el restaurante.",
  headerTitle: '¡Pide online!',
  headerMessage: 'Indícanos tu dirección para ofrecerte opciones en los locales más cercanos',
  cellphones: 'Aplicación App Store y Google Play',
  imgAppStore: 'App Store',
  imgPlayStore: 'Google Play',
  titleHeadingSEO: "Pizzas Papa John's",
  selectStore: 'Escribe tu dirección y selecciona una tienda para ver el menú'
});

i18next.addResources('pt_PT', 'Landing', {
  pageTitle: "Pizzas | Pedir Online ao domicílio | Papa John's Portugal",
  pageDescription:
    "Pede as tuas pizzas favoritas da Papa John's. Usamos os melhores ingredientes para fazer a melhor pizza. Pedidos online ao domicílio, ou para recolher no restaurante.",
  headerTitle: 'Pede Online!',
  headerMessage: 'Indica a tua morada para iniciarmos o teu pedido',
  cellphones: 'Pede apartir do teu telefone móvel 1, 2, 3',
  imgAppStore: 'App Store',
  imgPlayStore: 'Google Play',
  selectStore: 'Digite seu endereço e selecione uma loja para ver o menu'
});

i18next.addResources('es_PA', 'Landing', {
  pageTitle: "Pizzas | Pide Online retiro en tienda o domicilio | Papa John's Panama"
});

i18next.addResources('es_CR', 'Landing', {
  pageTitle: "Pizzas | Pide Online retiro en tienda o domicilio | Papa John's Costa Rica",
  titleHeadingSEO: "Papa John's - Pizzería para Comer o Delivery"
});

i18next.addResources('es_GT', 'Landing', {
  pageTitle: "Pizzas | Pide Online retiro en tienda o domicilio | Papa John's Guatemala"
});
