import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { WEGLOT_API_KEY } from '~utils/scripts';
import { actionCreators as homeActions } from '~screens/Dashboard/screens/Home/redux/actions';

import { CAT_LANGUAGE, WAIT_INITIALIZATION } from './constants';

function Weglot() {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!initialized) {
      const initializeTimeout = setInterval(() => {
        if (window.Weglot) {
          /* eslint-disable camelcase */
          window.Weglot.initialize({ api_key: WEGLOT_API_KEY });
          setInitialized(true);
          clearInterval(initializeTimeout);
        }
      }, WAIT_INITIALIZATION);

      return () => clearInterval(initializeTimeout);
    }
  }, [initialized]);

  window.Weglot?.on('languageChanged', (newLang, prevLang) => {
    /* eslint prefer-rest-params: 0 */
    if (prevLang.length !== 0) {
      /* eslint func-names: ["error", "never"] */
      if (typeof Node === 'function' && Node.prototype) {
        const originalRemoveChild = Node.prototype.removeChild;
        Node.prototype.removeChild = function (child) {
          if (child.parentNode !== this) {
            return child;
          }
          return originalRemoveChild.apply(this, arguments);
        };

        const originalInsertBefore = Node.prototype.insertBefore;
        Node.prototype.insertBefore = function (newNode, referenceNode) {
          if (referenceNode && referenceNode.parentNode !== this) {
            return newNode;
          }
          return originalInsertBefore.apply(this, arguments);
        };
      }
    }

    const isCatalanLanguage = newLang === CAT_LANGUAGE;
    dispatch(homeActions.catalanLanguageActivated(isCatalanLanguage));
    if (isCatalanLanguage) {
      dispatch(homeActions.setFilteredMenu(null));
    }
  });
  return <div id="container-weglot" />;
}

export default Weglot;
