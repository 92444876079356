import { productTypes, pizzaCategories } from '~constants/products';
import { isPizzaCategory } from '~utils/pizza';

export const productOfferGroupsFromOffer = offer =>
  offer.groups.reduce((result, group) => {
    result[group.id] = [...group.productOfferGroups];

    return result;
  }, {});

export const getPizzaIdsFromItem = (offer, item) => {
  const productOfferGroups = productOfferGroupsFromOffer(offer);

  return item.groupProducts.reduce((result, itemGroup) => {
    if (itemGroup.menuableType === productTypes.PIZZA_VARIANT) {
      const variants = productOfferGroups[itemGroup.offerGroupId];
      const variant = variants.find(v => v.menuableId === itemGroup.menuableId);

      result.push(variant.menuable.productId);
    }

    return result;
  }, []);
};

export const getDefaultPizzaIds = offer =>
  offer.groups.reduce((result, group) => {
    let initialPizza = group.productOfferGroups.find(
      productOfferGroup => productOfferGroup.menuable.category === pizzaCategories.MAKE_YOUR_PIZZA
    );

    if (!initialPizza) {
      initialPizza = group.productOfferGroups.find(productOfferGroup =>
        isPizzaCategory(productOfferGroup.menuable.category)
      );
    }

    if (initialPizza) {
      result.push(initialPizza.menuable.productId);
    }

    return result;
  }, []);
