import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer, onReadValue } from 'redux-recompose';

import LocalStorageService from '~services/LocalStorageService';
import { DISPATCH_METHODS } from '~constants/address';

import { actions } from './actions';
import { onGetStores } from './effects';
import {
  NEAR_TARGET,
  DELIVERY_TARGET,
  CURRENT_SUBSIDIARY_TARGET,
  DISPATCH_METHOD_TARGET,
  DISPATCH_TYPE_TAB_TARGET,
  USER_ADDRESS,
  USER_ADRESSES,
  USED_COUPONS,
  STORES_TARGET,
  EXTERNAL_STORE_TARGET,
  COMPANIES_TARGET,
  ACTUAL_RECEIPT_COMPANY,
  CREATE_USER_ADDRESSES,
  SELECTED_DELIVERY_TIME,
  CURRENT_STORE_INFO
} from './constants';

const initialState = completeState(
  {
    [NEAR_TARGET]: null,
    [DELIVERY_TARGET]: {},
    [CURRENT_SUBSIDIARY_TARGET]: LocalStorageService.getCurrentSubsidiary() || null,
    [DISPATCH_METHOD_TARGET]: LocalStorageService.getDispatchType() || DISPATCH_METHODS.IN_STORE,
    [DISPATCH_TYPE_TAB_TARGET]: DISPATCH_METHODS.IN_STORE,
    [USER_ADDRESS]: {},
    [USER_ADRESSES]: null,
    [USED_COUPONS]: null,
    [STORES_TARGET]: null,
    [EXTERNAL_STORE_TARGET]: null,
    [COMPANIES_TARGET]: null,
    [ACTUAL_RECEIPT_COMPANY]: { company: null, checkAllowReceiptCompany: false },
    [CREATE_USER_ADDRESSES]: null,
    [SELECTED_DELIVERY_TIME]: LocalStorageService.getSelectedDeliveryTime() || null,
    [CURRENT_STORE_INFO]: {}
  },
  [DISPATCH_METHOD_TARGET]
);

initialState[`${USED_COUPONS}Loading`] = true;
initialState[`${COMPANIES_TARGET}Loading`] = true;
initialState[`${NEAR_TARGET}Loading`] = true;
initialState[`${DELIVERY_TARGET}Loading`] = true;

const reducerDescription = {
  primaryActions: [
    actions.PICK_UP,
    actions.DELIVERY,
    actions.GET_USER_ADDRESS,
    actions.GET_USER_ADDRESSES,
    actions.GET_STORES,
    actions.GET_USED_COUPONS,
    actions.GET_COMPANIES,
    actions.CREATE_COMPANY,
    actions.UPDATE_COMPANY,
    actions.DELETE_COMPANY,
    actions.GET_NEAR,
    actions.CREATE_USER_ADDRESSES
  ],
  override: {
    [actions.SET_VALUE]: onReadValue(),
    [actions.GET_STORES_SUCCESS]: onGetStores
  }
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
