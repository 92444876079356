import half1Image from '~assets/customOptionIcons/ic_pizza_circle_half1.svg';
import half2Image from '~assets/customOptionIcons/ic_pizza_circle_half2.svg';
import wholeImage from '~assets/customOptionIcons/ic_pizza_circle_whole.svg';
import half1DoubleImage from '~assets/customOptionIcons/ic_pizza_circle_half1_x2.svg';
import half2DoubleImage from '~assets/customOptionIcons/ic_pizza_circle_half2_x2.svg';
import wholeDoubleImage from '~assets/customOptionIcons/ic_pizza_circle_whole_double.svg';

export const CUSTOM_HALF_OPTIONS = {
  half1: 'half1',
  half2: 'half2',
  whole: 'whole',
  double: 'double'
};

export const CUSTOM_AMOUNT = 'double';

export const CUSTOM_OPTION_IMAGES = {
  [CUSTOM_HALF_OPTIONS.half1]: half1Image,
  [CUSTOM_HALF_OPTIONS.half2]: half2Image,
  [CUSTOM_HALF_OPTIONS.whole]: wholeImage,
  [CUSTOM_HALF_OPTIONS.double]: wholeDoubleImage,
  [`${CUSTOM_HALF_OPTIONS.half1}${CUSTOM_AMOUNT}`]: half1DoubleImage,
  [`${CUSTOM_HALF_OPTIONS.half2}${CUSTOM_AMOUNT}`]: half2DoubleImage,
  [`${CUSTOM_HALF_OPTIONS.whole}${CUSTOM_AMOUNT}`]: wholeDoubleImage
};

export const SECONDS_IN_MILLISECONDS = 1000;
