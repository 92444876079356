import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';
import { INVALID_CODE_DETAILS_ERRORS } from '~screens/Dashboard/screens/Home/redux/constants';
import { CURRENCY_SYMBOL } from '~constants/environment';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'ReedeemCode', {
  sectionTitle: '¿Tienes un cupón o Gift Card?',
  placeholder: 'Ingresa aquí tu código',
  add: 'Aplicar',
  successMessage: '¡Cupón añadido correctamente!',
  campaignConditions: '(aplican condiciones de campaña)',
  errorMessage: 'Código no válido. Verifica que esté correcto e intenta de nuevo.',
  guestButtonInitSesion: 'Inicia sesión para canjearlo',
  terms: 'Al confirmar el pedido declaras haber leído y aceptas nuestros ',
  disabledMessage: 'No es posible ingresar un cupón o Gift Card para la compra de Gift Cards.',
  toCategory: 'categoria {{category}}',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_COUPON_CODE]:
    'Código no válido. Verifica que esté correcto e intenta de nuevo.',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_NOT_AVAILABLE]: 'Este código aún no está disponible',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_CANNOT_BE_USED_BY_USER]: 'No estás habilitado para canjear este código',
  [INVALID_CODE_DETAILS_ERRORS.GIFT_CARD_CODE_INVALID]: 'El código de la Gift Card no es válido',
  [INVALID_CODE_DETAILS_ERRORS.GIFT_CARD_EXPIRATED]: 'Esta Gift Card ya expiró',
  [INVALID_CODE_DETAILS_ERRORS.GIFT_CARD_ZERO_AMOUNT]: 'Esta Gift Card no dispone de saldo',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_DISPATCH_METHOD]:
    'No se puede utilizar este código con el método de envío seleccionado',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_EXPIRED]: 'Este código expiró el {{expiry_date}}',
  [INVALID_CODE_DETAILS_ERRORS.MAX_COUPON_USES_BY_USER_REACHED]:
    'El código ha alcanzado su límite de usos por usuario',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_PLATFORM_ERROR]:
    'Este código solo puede canjearse desde la aplicación móvil',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_MAX_REACHED]: 'El cupón ha alcanzado su límite de usos',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_WITH_MINIMUM_BILL]: `Para utilizar este código tu pedido debe ser superior a ${CURRENCY_SYMBOL}{{value}}`,
  [INVALID_CODE_DETAILS_ERRORS.COUPON_OFFER_NOT_PRESENT]:
    'Agrega la promoción correspondiente para poder canjear este código',
  [INVALID_CODE_DETAILS_ERRORS.CART_INCLUDE_ONLY_OFFERS]:
    'No es posible utilizar un código en un carrito que sólo contenga artículos en promoción',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_PAYMENT_METHOD_NOT_AVAILABLE]:
    'El método de pago {{coupon_payment_method}} para este cupón no está disponible para esta tienda.',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_MINIMUM_START_DATE]:
    'Este código estará disponible desde el {{start_date}}',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_MINIMUM_START_TIME]:
    'Este código estará disponible desde las {{start_time}}',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_MAXIMUM_EXPIRY_DATE]: 'Este código expiró el {{expiry_date}}',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_MAXIMUM_EXPIRY_TIME]: 'Este código expiró a las {{expiry_time}}',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_COUPON_AVAILABILITY_DAYS]:
    'Este código solo esta habilitado para los días {{availability_days}}',
  noLoyaltyWithCoupon:
    'Ten en cuenta que al aplicar el cupón, los descuentos de loyalty en tus productos se perderán',
  [INVALID_CODE_DETAILS_ERRORS.GIFT_CARD_WITH_OFFERS]:
    'La tarjeta regalo no se puede usar en productos con descuento',
  [INVALID_CODE_DETAILS_ERRORS.USER_ALREADY_DID_FIRST_PURCHASE]:
    'No puedes utilizar este código porque ya has realizado tu primera compra en otra orden',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_OFFER_ON_COUPON]: 'El cupón no es válido para {{invalid_offer}}',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_STORE_GROUP_ON_COUPON]:
    'El cupón no es válido para la tienda {{current_store}}',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_VARIANT_ON_COUPON]:
    'Este código no es válido para la pizza "{{variant_error.pizza}}", masa {{variant_error.crust}} y tamaño {{variant_error.size}}',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_INVALID_FOR_ANY_PRODUCT]:
    'El código no es válido para los productos de tu carrito.',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_CATEGORY_COUPON]:
    'Este código solo aplica para Pizzas tamaño {{variant}}',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_VALID_FOR_REGISTERED_USERS]:
    'El código "{{coupon_code}}" es válido solo para usuarios registrados.',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_VALID_FOR_GUEST_USERS]:
    'El código "{{coupon_code}}" es válido solo para usuarios invitados.',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_CATEGORY_COUPON_V2]:
    'Este código solo aplica para las categorias {{categories}}'
});

i18next.addResources(LANGUAGE_KEYS.SPAIN, 'ReedeemCode', {
  terms: 'Al confirmar el pedido declaras haber leído y aceptas nuestras ',
  sectionTitle: '¿Tienes un Código promocional?',
  placeholder: 'Introduce aquí tu código'
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'ReedeemCode', {
  sectionTitle: 'Tens um cupão ou Gift Card?',
  placeholder: 'Coloque o seu código aqui',
  disabledMessage: 'Não é possível usar um cupão ou Gift Card para a compra de Gift Cards.',
  successMessage: 'Cupom adicionado com sucesso!',
  errorMessage: 'Código inválido. Verifique se está correto e tente novamente.',
  add: 'Adicionar',
  guestButtonInitSesion: 'Iniciar sessão para redimir',
  terms: 'Confirmando a ordem que você declara ter lido e aceito nossos',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_COUPON_CODE]:
    'Código inválido. Verifique se está correto e tente novamente.',
  [INVALID_CODE_DETAILS_ERRORS.GIFT_CARD_CODE_INVALID]: 'O código do Gift Card não é válido',
  [INVALID_CODE_DETAILS_ERRORS.GIFT_CARD_EXPIRATED]: 'Este Gift Card já expirou',
  [INVALID_CODE_DETAILS_ERRORS.GIFT_CARD_ZERO_AMOUNT]: 'Este Gift Card não tem saldo',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_DISPATCH_METHOD]:
    'Este código não pode ser usado com o método de envio selecionado',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_EXPIRED]: 'Este código expirou em {{expiry_date}}',
  [INVALID_CODE_DETAILS_ERRORS.MAX_COUPON_USES_BY_USER_REACHED]:
    'O código atingiu seu limite de uso por usuário',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_PLATFORM_ERROR]:
    'O Código só pode ser aplicado a partir da aplicação móvel',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_MAX_REACHED]: 'O código atingiu o seu limite de utilizações',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_NOT_AVAILABLE]: 'Este código ainda não está disponível',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_CANNOT_BE_USED_BY_USER]: 'Você não pode resgatar este código',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_WITH_MINIMUM_BILL]: `Para usar este código, seu pedido deve ser maior que ${CURRENCY_SYMBOL}{{value}}`,
  [INVALID_CODE_DETAILS_ERRORS.COUPON_OFFER_NOT_PRESENT]:
    'Adicione a promoção correspondente para poder resgatar este código',
  [INVALID_CODE_DETAILS_ERRORS.CART_INCLUDE_ONLY_OFFERS]:
    'Não é possível usar um código em um carrinho que contenha apenas itens promocionais',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_PAYMENT_METHOD_NOT_AVAILABLE]:
    'O método de pagamento {{coupon_payment_method}} para este cupom não está disponível para esta loja.',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_MINIMUM_START_DATE]:
    'Este código estará disponível a partir de {{start_date}}',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_MINIMUM_START_TIME]:
    'Este código estará disponível a partir de {{start_time}}',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_MAXIMUM_EXPIRY_DATE]: 'Este código expirou em {{expiry_date}}',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_MAXIMUM_EXPIRY_TIME]: 'Este código expirou em {{expiry_time}}',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_COUPON_AVAILABILITY_DAYS]:
    'Este código está ativado apenas por {{availability_days}}',
  noLoyaltyWithCoupon:
    'Tenha em mente que ao aplicar o cupom, os descontos de fidelidade em seus produtos serão perdidos',
  [INVALID_CODE_DETAILS_ERRORS.GIFT_CARD_WITH_OFFERS]:
    'O cartão presente não pode ser utilizado em produtos com desconto',
  [INVALID_CODE_DETAILS_ERRORS.USER_ALREADY_DID_FIRST_PURCHASE]:
    'Não é possível usar este código, porque você fez sua primeira compra em outro pedido',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_OFFER_ON_COUPON]: 'Este código não é válido para {{invalid_offer}}',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_STORE_GROUP_ON_COUPON]:
    'Este código não é válido para a loja {{current_store}}',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_VARIANT_ON_COUPON]:
    'Este código não é válido para a pizza "{{variant_error.pizza}}", massa {{variant_error.crust}} y tamanho {{variant_error.size}}',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_INVALID_FOR_ANY_PRODUCT]:
    'O código não é válido para produtos no seu carrinho.',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_CATEGORY_COUPON]:
    'Este código aplica-se apenas à Pizzas tamanho {{variant}}',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_VALID_FOR_REGISTERED_USERS]:
    'O código "{{coupon_code}}" é válido para usuários registrados.',
  [INVALID_CODE_DETAILS_ERRORS.COUPON_VALID_FOR_GUEST_USERS]:
    'O código "{{coupon_code}}" é válido para usuários convidados.',
  [INVALID_CODE_DETAILS_ERRORS.INVALID_CATEGORY_COUPON_V2]:
    'Este código aplica-se apenas às categorias {{categories}}'
});
