import i18next from 'i18next';

i18next.addResources('es_PA', 'WorkWithUs', {
  pagePath: 'https://www.empleospjpa.com/',
  pageTitle: "Trabaja con nosotros | Papa John's Panamá",
  pageDescription: "En Papa John's, nuestro equipo es nuestra prioridad, ¿Quieres probarte a ti mismo?",
  title: '¡Trabaja con nosotros!',
  subtitle: "En Papa John's, nuestro equipo es nuestra prioridad, ¿Quieres probarte a ti mismo?",
  text1:
    "Cuando decimos 'Mejores Ingredientes. Mejor pizza.' no estamos hablando sólo de nuestra masa, la salsa y sus ingredientes. El ingrediente más importante en Papa John’s es nuestra gente. En Papa John’s, tu serás un valioso miembro del equipo desde el principio. Se te animará a contribuir con tus mejores ideas, compartir objetivos comunes y crear alianzas de colaboración. También se te dará la oportunidad de mejorar las habilidades que tienes, además de aprender nuevas.",
  text2:
    'Si quieres ser parte de nuestro equipo envía tu hoja de vida a: <a class="text-caribbean-green" href="mailto:reclutamiento@pjpanama.com">reclutamiento@pjpanama.com</a>.'
});

i18next.addResources('es_CR', 'WorkWithUs', {
  pagePath: 'https://www.empleospjcr.com/',
  pageTitle: "Trabaja con nosotros | Papa John's Costa Rica",
  pageDescription: "En Papa John's, nuestro equipo es nuestra prioridad, ¿Quieres probarte a ti mismo?",
  title: '¡Trabaja con nosotros!',
  subtitle: "En Papa John's, nuestro equipo es nuestra prioridad, ¿Quieres probarte a ti mismo?",
  text1:
    "Cuando decimos 'Mejores Ingredientes. Mejor pizza.' no estamos hablando sólo de nuestra masa, la salsa y sus ingredientes. El ingrediente más importante en Papa John’s es nuestra gente. En Papa John’s, tu serás un valioso miembro del equipo desde el principio. Se te animará a contribuir con tus mejores ideas, compartir objetivos comunes y crear alianzas de colaboración. También se te dará la oportunidad de mejorar las habilidades que tienes, además de aprender nuevas.",
  text2:
    'Si quieres ser parte de nuestro equipo envía tu hoja de vida a: <a class="text-caribbean-green" href="mailto:reclutamiento@pjcostarica.com">reclutamiento@pjcostarica.com</a>.'
});

i18next.addResources('pt_PT', 'WorkWithUs', {
  pagePath: '/trabalhe-connosco',
  pageTitle: "Trabalhe connosco | Papa John's Portugal",
  pageDescription: 'No Papa John, nossa equipe é nossa prioridade. Você quer provar a si mesmo?',
  title: 'Trabalha connosco!',
  subtitle: 'NA PAPA JOHNS A NOSSA EQUIPA É A NOSSA PRIORIDADE! DESAFIA-TE!',
  text1:
    '!Se consideras que tens a atitude dinâmica para fazer parte de uma equipa vencedora entra em contacto através do email <a class="text-caribbean-green" href="mailto:carreirasnapapajohns@ppjportugal.pt">carreirasnapapajohns@ppjportugal.pt</a> para mais informações!”',
  text2:
    '“Quaisquer dados pessoais disponibilizados estarão sob a égide da legislação em vigor referente à protecção de dados , sendo a empresa detentora do Master Franchise da Papa Johns ́s em Portugal responsável pelos mesmos, podendo serem acedidos , eliminados mediante informação do potencial candidato.”'
});

i18next.addResources('es_GT', 'WorkWithUs', {
  pagePath: '#',
  pageTitle: "Trabaja con nosotros | Papa John's Guatemala",
  pageDescription: "En Papa John's, nuestro equipo es nuestra prioridad, ¿Quieres probarte a ti mismo?",
  title: '¡Trabaja con nosotros!',
  subtitle: "En Papa John's, nuestro equipo es nuestra prioridad, ¿Quieres probarte a ti mismo?",
  text1:
    "Cuando decimos 'Mejores Ingredientes. Mejor pizza.' no estamos hablando sólo de nuestra masa, la salsa y sus ingredientes. El ingrediente más importante en Papa John’s es nuestra gente. En Papa John’s, tu serás un valioso miembro del equipo desde el principio. Se te animará a contribuir con tus mejores ideas, compartir objetivos comunes y crear alianzas de colaboración. También se te dará la oportunidad de mejorar las habilidades que tienes, además de aprender nuevas.",
  text2: ''
});
