import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'DigitalDispatch', {
  title: 'Entrega digital',
  description: 'Consulta los detalles más abajo en "Detalles del pedido"',
  estimatedTime: '{{minutes}} min. aprox.'
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'DigitalDispatch', {
  description: 'Consulta os detalhes mais abaixo em “Detalhes do pedido”'
});
