import i18next from 'i18next';

i18next.addResources('es_CL', 'MakePizza', {
  customize: 'Personaliza tu pizza',
  makeYourPizza: 'Arma tu pizza',
  selectIngredients: 'Selecciona los ingredientes de tu pizza',
  byopDescription:
    '¡Crea tu pizza con hasta {{maxIngredients}} ingredientes! (los primeros {{freeIngredients}} son gratis)'
});

i18next.addResources('es_CR', 'MakePizza', {
  makeYourPizza: 'Crea tu pizza'
});

i18next.addResources('es_ES', 'MakePizza', {
  makeYourPizza: 'Crea tu pizza'
});

i18next.addResources('pt_PT', 'MakePizza', {
  customize: 'Personalize sua pizza',
  makeYourPizza: 'Crie sua pizza',
  selectIngredients: 'Selecione os ingredientes da sua pizza',
  byopDescription:
    'Crie sua pizza com até {{maxIngredients}} ingredientes! (os {{freeIngredients}} primeiros são gratuitos)'
});
