import { ReactComponent as PapaPointsSilver } from '~assets/papa_points_silver.svg';
import { ReactComponent as PapaPointsBronze } from '~assets/papa_points_bronze.svg';
import { ReactComponent as PapaPointsGold } from '~assets/papa_points_gold.svg';
import { ReactComponent as BadgeBannerSilver } from '~assets/badge_banner_silver.svg';
import { ReactComponent as BadgeBannerGold } from '~assets/badge_banner_gold.svg';
import UserPapaPointsSilver from '~assets/user_silver.png';
import UserPapaPointsBronze from '~assets/user_bronze.png';
import UserPapaPointsGold from '~assets/user_gold.png';
import GoldBadge from '~assets/badge_gold.png';
import SilverBadge from '~assets/badge_silver.png';
import BronzeBadge from '~assets/badge_bronze.png';
import { getTodayString } from '~utils/time';
import { getMaxFromArray } from '~utils/number';

const LEVEL_KEYS = {
  BRONZE: 'bronze',
  SILVER: 'silver',
  GOLD: 'gold'
};

export const LOYALTY_LEVELS = {
  3: LEVEL_KEYS.GOLD,
  2: LEVEL_KEYS.SILVER,
  1: LEVEL_KEYS.BRONZE
};

export const getMaxLevel = levels => {
  const maxMinPoints = getMaxFromArray(levels?.map(({ minPoints }) => minPoints));

  return levels?.find(({ minPoints }) => minPoints === maxMinPoints);
};

export const LOYALTY_ICONS = {
  [LEVEL_KEYS.BRONZE]: { icon: PapaPointsBronze, userIcon: UserPapaPointsBronze, badge: BronzeBadge },
  [LEVEL_KEYS.SILVER]: {
    icon: PapaPointsSilver,
    userIcon: UserPapaPointsSilver,
    badge: SilverBadge,
    badgeBanner: BadgeBannerSilver
  },
  [LEVEL_KEYS.GOLD]: {
    icon: PapaPointsGold,
    userIcon: UserPapaPointsGold,
    badge: GoldBadge,
    badgeBanner: BadgeBannerGold
  }
};

export const getLoyaltyLevel = (level, levels) => levels?.find(({ id }) => level === id);

export const getMaxPoints = (levels, currentLevel) =>
  levels?.find(({ id }) => id === currentLevel?.id + 1)?.minPoints;

export const checkAvailability = deliveryDays => deliveryDays.includes(getTodayString());
