import i18next from 'i18next';

i18next.addResources('es_CL', 'Carousel', {
  previousButton: 'Anterior',
  nextButton: 'Siguiente',
  indicator: 'Indicador banner',
  openBanner: 'Abrir enlace del banner'
});

i18next.addResources('pt_PT', 'PizzaBuilder', {
  previousButton: 'Anterior',
  nextButton: 'Próximo',
  indicator: 'Indicador de banner',
  openBanner: 'Abrir enlace del banner'
});
