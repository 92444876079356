import { createTypes, completeTypes } from 'redux-recompose';

import { CURRENT_TAB_ID, TABS_TARGET } from './constants';

export const actions = createTypes(completeTypes(['SET_TABS'], ['CHANGE_TAB']), '@@TABS');

export const actionCreators = {
  changeTab: nextTab => ({
    type: actions.CHANGE_TAB,
    target: CURRENT_TAB_ID,
    payload: nextTab
  }),
  setTabs: tabs => ({
    type: actions.SET_TABS,
    target: TABS_TARGET,
    payload: tabs
  })
};
