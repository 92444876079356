import { STORES_TARGET } from './constants';

export const onGetStores = (state, action) => {
  const page =
    state[STORES_TARGET]?.page && action.payload.currentPage !== 1
      ? [...state[STORES_TARGET].page, ...action.payload.page]
      : action.payload.page;

  return state.merge({
    [`${STORES_TARGET}Loading`]: false,
    [STORES_TARGET]: {
      ...action.payload,
      page
    }
  });
};
