import i18next from 'i18next';

i18next.addResources('es_CL', 'Search', {
  placeholderSearch: 'Escribe tu dirección completa…'
});

i18next.addResources('es_ES', 'Search', {
  placeholderSearch: 'Escribe tu dirección completa…'
});

i18next.addResources('pt_PT', 'Search', {
  placeholderSearch: 'Digite seu endereço completo…'
});
