import i18next from 'i18next';

i18next.addResources('es_CL', 'SupportStep', {
  title: '¡Ups! Algo salió mal',
  subtitle: 'Parece que hubo un problema al obtener tu información.',
  description:
    'Puedes contactar a soporte para ayudarte a solucionarlo. Si prefieres, puedes continuar como usuario invitado o intentar con otro correo.',
  option: 'Elige una opción para continuar'
});

i18next.addResources('pt_PT', 'SupportStep', {
  title: 'Ups! Algo correu mal',
  subtitle: 'Parece que ocorreu um problema ao obter a tua informação',
  description:
    'Podes contactar o suporte para te ajudar a resolvê-lo. Se preferires, podes continuar como utilizador convidado ou tentar com outro e-mail.',
  option: 'Escolhe uma opção para continuar'
});
