import i18next from 'i18next';

i18next.addResources('es_CL', 'AboutUs', {
  pagePath: '/acerca-de-nosotros',
  pageTitle: "Quiénes somos | Pizzerías Papa John's",
  pageDescription:
    "Toda la información sobre las pizzerías Papa John's. Origen, equipo y cómo encontrar el local más cercano. Descubre la historia de Papa John's.",
  ourCommitment: 'Nuestro compromiso',
  ourValues: 'Nuestros valores'
});

// UPDATE THIS FOR SPAIN
i18next.addResources('es_ES', 'AboutUs', {
  pagePath: '/acerca-de-nosotros',
  pageTitle: 'Quiénes somos | Pizzerías Papa Johns',
  keywords: 'Pizza papa johns',
  pageDescription:
    "Toda la información sobre las pizzerías Papa John's. Origen, equipo y cómo encontrar tu Papa John's más cercano. Descubre la historia de Papa John's.",
  ourCommitment: 'Nuestro compromiso',
  ourValues: 'Nuestros valores'
});

i18next.addResources('pt_PT', 'AboutUs', {
  pagePath: '/sobrenos',
  pageTitle: "Sobre Nós | Papa John's Portugal",
  pageDescription:
    "Descobre a história Papa John's e o porquê da companhia estar construída sobre uma base de qualidade.",
  ourCommitment: 'Nosso compromisso',
  ourValues: 'Nossos valores'
});

i18next.addResources('es_PA', 'AboutUs', {
  pageTitle: "Acerca de nosotros | Papa John's Panama"
});

i18next.addResources('es_CR', 'AboutUs', {
  pageTitle: "Quiénes somos | Pizzerías Papa John's",
  pageDescription:
    "Toda la información sobre las pizzerías Papa John's. Origen, equipo y cómo encontrar el local más cercano. Descubre la historia de Papa John's.",
  keywords: 'Papa johns'
});

i18next.addResources('es_GT', 'AboutUs', {
  pageTitle: "Acerca de nosotros | Papa John's Guatemala"
});
