import i18next from 'i18next';

i18next.addResources('es_CL', 'CustomPizza', {
  offer: 'Promoción',
  combo: 'Combo'
});

i18next.addResources('es_ES', 'CustomPizza', {
  offer: 'Promoción',
  combo: 'Combo'
});

i18next.addResources('pt_PT', 'CustomPizza', {
  offer: 'Promoções',
  combo: 'Combo'
});
