import i18next from 'i18next';

i18next.addResources('es_CL', 'StoreDetail', {
  schedules: 'Horarios',
  monday: 'Lun',
  tuesday: 'Mar',
  wednesday: 'Mie',
  thursday: 'Jue',
  friday: 'Vie',
  saturday: 'Sab',
  sunday: 'Dom',
  noData: 'Elige un local',
  allDay: '24hs'
});

i18next.addResources('es_CR', 'StoreDetail', {
  noData: 'Elegí una tienda'
});

i18next.addResources('pt_PT', 'StoreDetail', {
  schedules: 'Horários',
  monday: '2ª Feira',
  tuesday: '3ª Feira',
  wednesday: '4ª Feira',
  thursday: '5ª Feira',
  friday: '6ª Feira',
  saturday: 'Sab',
  sunday: 'Dom',
  noData: 'Escolha um local',
  allDay: '24hs'
});
