import i18next from 'i18next';

// FIXME checking the i18next docs, we should use 'es-CL' not 'es_CL', 'cause the docs says so and 'cause we cant use functionalities such as *_plural
// FIXME we have to check how much time we could waste doing this refactor
i18next.addResources('es_CL', 'DisabledItemsModal', {
  modalTitle: 'Producto no disponible',
  modalDescription:
    'Algún producto de tu carrito no esta disponible para esta tienda. Vamos a borrar tu carrito para que puedas seleccionar otros productos para esta tienda.',
  modalRemoveProduct: 'Borrar carrito'
});

i18next.addResources('pt_PT', 'DisabledItemsModal', {
  modalTitle: 'Produto não disponível',
  modalDescription:
    'Algum produto do seu carrinho não está disponível para esta loja. Vamos limpar seu carrinho para que você possa selecionar outros produtos para esta loja.',
  modalRemoveProduct: 'Limpar carrinho'
});
