/* eslint-disable react/button-has-type */
import React from 'react';
import { string, func, bool, oneOfType, oneOf } from 'prop-types';
import cn from 'classnames';
import { IonIcon } from '@ionic/react';

import { BUTTON_TYPE } from '~components/CustomButton/constants';

import styles from './styles.module.scss';

function CustomButton({
  id,
  text,
  onClick,
  disabled = false,
  icon,
  ionIcon,
  imageIcon,
  btnClassType,
  size = 'medium',
  fullWidth = false,
  children,
  value,
  textClassName,
  iconStyles,
  fab,
  iconPosition,
  loading,
  ...extraProps
}) {
  const { textCapitalize, type: buttonType, className, selected, ariaLabel, ...restProps } = extraProps;
  const handleClick = e => buttonType === BUTTON_TYPE && onClick?.(e);

  const renderChildren = children || (
    <>
      {loading ? <div className={`${styles.loading} ${styles[size]}`} /> : null}
      {imageIcon && (
        <img src={imageIcon} alt={text || imageIcon} className={`${styles.icon} ${iconStyles}`} height={10} />
      )}
      {icon && <i className={`${styles.icon} ${iconStyles} ${icon}`} />}
      {ionIcon && <IonIcon className={`${styles.ionIcon} ${iconStyles}`} icon={ionIcon} />}
      {text && (
        <span
          className={cn(textClassName, {
            [styles.textCapitalize]: textCapitalize
          })}>
          {text}
        </span>
      )}
    </>
  );
  return (
    <button
      id={id}
      type={buttonType}
      className={cn(styles.newButton, styles[btnClassType], styles[size], styles[iconPosition], className, {
        [styles.selected]: selected,
        [styles.fullWidth]: fullWidth,
        [styles.fab]: fab
      })}
      onClick={handleClick}
      disabled={disabled}
      value={value}
      aria-label={ariaLabel || text}
      {...restProps}>
      {renderChildren}
    </button>
  );
}

CustomButton.propTypes = {
  btnClassType: string,
  disabled: bool,
  fab: bool,
  fullWidth: bool,
  icon: oneOfType([string, bool]),
  iconPosition: oneOf(['left']),
  iconStyles: string,
  id: string,
  imageIcon: string,
  ionIcon: string,
  loading: bool,
  size: oneOf(['small', 'medium', 'large']),
  text: oneOfType([string, bool]),
  textClassName: string,
  value: string,
  onClick: func
};

export default CustomButton;
