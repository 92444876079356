import { completeTypes, createTypes } from 'redux-recompose';

import { arrayHasLength } from '~utils/array';
import ShoppingCartService from '~services/ShoppingCart/service';
import { deserializer } from '~services/baseSerializers';
import LocalStorageService from '~services/LocalStorageService';

import { refreshActualPromos } from './utils';
import { PROMO_SELLING_TARGET } from './constants';

const completedTypes = completeTypes(
  ['GET_SUGGESTIVE_PROMOS'],
  ['SET_ACTUAL_PROMOS', 'CLEAR_SUGGESTIVE_PROMOS']
);

export const actions = createTypes(completedTypes, '@@PROMO_SELLING');

export const actionCreators = {
  getSuggestivePromos: (storeId, items, dispatchMethod) => (dispatch, getState) => {
    if (arrayHasLength(items)) {
      dispatch({
        type: actions.GET_SUGGESTIVE_PROMOS,
        target: PROMO_SELLING_TARGET.PROMOS,
        service: ShoppingCartService.getSuggestivePromos,
        payload: { storeId, items, dispatchMethod },
        successSelector: response => {
          const { actualPromos } = getState().promoSelling;
          const suggestedPromos = deserializer.serialize(response.data);
          const acualPromos = refreshActualPromos(actualPromos, suggestedPromos, items);
          dispatch(actionCreators.setActualPromos(acualPromos));
          LocalStorageService.setPromoSelling(suggestedPromos);
          return suggestedPromos;
        }
      });
    } else {
      dispatch(actionCreators.clearPromos());
      dispatch(actionCreators.setActualPromos([]));
    }
  },
  setActualPromos: value => ({
    type: actions.SET_ACTUAL_PROMOS,
    target: PROMO_SELLING_TARGET.ACTUAL_PROMOS,
    payload: value
  }),
  clearPromos: () => ({
    type: actions.CLEAR_SUGGESTIVE_PROMOS,
    target: PROMO_SELLING_TARGET.PROMOS,
    payload: {}
  })
};

export default actionCreators;
