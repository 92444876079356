import getRootDomain from 'get-root-domain';

export const COUNTRIES = {
  cl: 'cl',
  es: 'es',
  pt: 'pt',
  pa: 'pa',
  cr: 'cr',
  gt: 'gt'
};

export const CONFIG_COUNTRIES = {
  [COUNTRIES.cl]: {
    locale: 'es-CL',
    tdl: 'cl'
  },
  [COUNTRIES.es]: {
    locale: 'es-ES',
    tdl: 'es'
  },
  [COUNTRIES.pt]: {
    locale: 'pt-PT',
    tdl: 'pt'
  },
  [COUNTRIES.pa]: {
    locale: 'es-PA',
    tdl: 'com.pa'
  },
  [COUNTRIES.cr]: {
    locale: 'es-CR',
    tdl: 'cr'
  },
  [COUNTRIES.gt]: {
    locale: 'es-GT',
    tdl: 'com.gt'
  }
};

export const CONTINENTS = {
  EU: 'EU',
  LAT: 'LAT'
};

export const COUNTRY_CONTINENTS = {
  [COUNTRIES.es]: [CONTINENTS.EU],
  [COUNTRIES.pt]: [CONTINENTS.EU],
  [COUNTRIES.cl]: [CONTINENTS.LAT],
  [COUNTRIES.pa]: [CONTINENTS.LAT],
  [COUNTRIES.gt]: [CONTINENTS.LAT],
  [COUNTRIES.cr]: [CONTINENTS.LAT]
};

export const NON_PRODUCTIVE_BASE_URL_PATHS = ['web.dev', 'web.stage'];

export const TIP_ALLOWED = process.env.REACT_APP_TIP_ALLOWED === String(true);

export const SMARTBANNER_DOWNLOAD_APP = process.env.REACT_APP_SMARTBANNER_DOWNLOAD_APP === String(true);

export const CURRENT_COUNTRY_ID = process.env.REACT_APP_ISO_COUNTRY;

export const NEWSLETTER_CHECKED = process.env.REACT_APP_NEWSLETTER_CHECKED === String(true);

export const POLL_CHECKED = process.env.REACT_APP_POLL_CHECKED === String(true);

export const PRIVACY_POLICY = process.env.REACT_APP_PRIVACY_POLICY_CHECKED === String(true);

export const SHOW_BILLING_WARNING = process.env.REACT_APP_BILLING_TEXT_SHOW_BILLING_WARNING === String(true);

export const USE_SPECIAL_ENCODING = process.env.REACT_APP_USE_SPECIAL_ENCODING_DECODING === String(true);

export const CARD_PAYMENT_TYPE = process.env.REACT_APP_CARD_PAYMENT_TYPE;

export const RENDER_CALL_CENTER = process.env.REACT_APP_CALL_CENTER === String(true);

export const DEFAULT_STORE_ID = parseInt(process.env.REACT_APP_DEFAULT_STORE_ID);

export const EXTENDED_TIME_DELIVERY = process.env.REACT_APP_EXTENDED_TIME_DELIVERY === String(true);

export const PROMOTION_MODAL_NAME = process.env.REACT_APP_PROMOTION_MODAL_NAME;

export const PROMOTION_MODAL_COUPON = process.env.REACT_APP_PROMOTION_MODAL_COUPON;

export const PUBLIC_CDN = process.env.REACT_APP_PUBLIC_CDN;

export const CURRENCY_SYMBOL = process.env.REACT_APP_MONEY_SYMBOL;

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const BASE_URL_ROOT_DOMAIN = process.env.REACT_APP_BASE_URL
  ? getRootDomain(process.env.REACT_APP_BASE_URL)
  : '';

export const AMAZON_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

export const SOCIAL_URL = process.env.REACT_APP_SOCIAL_URL;

export const TRANSLATION_KEY = process.env.REACT_APP_TRANSLATION_COUNTRY;

export const GO_CTA_BELOW = process.env.REACT_APP_CTA_BELOW;

export const GO_HIDE_CROSS_SELLING = process.env.REACT_APP_HIDE_CROSS_SELLING;

export const OB_NEW_DESIGN = process.env.REACT_APP_OB_NEW_DESIGN;

export const OB_CONTINUE_BUTTON = process.env.REACT_APP_OB_CONTINUE_BUTTON;

export const CHE_SHOW_ADD_REMOVE_BUTTON = process.env.REACT_APP_CHECKOUT_ADD_REMOVE_PRODUCTS;

export const MERCANTIL_KEY = process.env.REACT_APP_MERCANTIL_API_KEY;

export const MERCANTIL_URL = process.env.REACT_APP_MERCANTIL_URL;

export const SAVE_GUEST_USER_DATA_ENABLED = process.env.REACT_APP_SAVE_GUEST_USER_DATA_ENABLED;

export const API_MP = process.env.REACT_APP_API_MP;

export const PUBLIC_KEY_MP = process.env.REACT_APP_PUBLIC_KEY_MP;

export const WEBLOYALTY_HASH = process.env.REACT_APP_WEBLOYALTY_HASH;

export const PASSWORDLESS_ENABLED = process.env.REACT_APP_PASSWORDLESS_ENABLED;

export const QUICK_ORDER_ENABLED = process.env.REACT_APP_QUICK_ORDER_ENABLED;

export const SAVE_CARD_ON_CHECKOUT_ENABLED = process.env.REACT_APP_SAVE_CARD_ON_CHECKOUT_ENABLED === 'true';
