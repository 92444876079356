import { isArray } from 'util';

import { Serializer } from 'cerealizr';

import { productTypes } from '~constants/products';

import { buildPizzaVariantName, buildPizzaDescription } from './pizza';
import { itemHasRulesAdded } from './shoppingCart';

const sideSerializer = new Serializer({
  descriptor: {
    menuableType: 'menuableType',
    menuableId: 'menuableId',
    name: 'name'
  },
  mapAllValues: false
});

const menuToCartVariantSerializer = new Serializer({
  descriptor: {
    value: 'menuableId',
    menuableType: 'menuableType',
    description: 'description',
    name: 'name',
    itemName: 'itemName',
    side: (key, value) => ({ [key]: sideSerializer.serialize(value) })
  },
  mapAllValues: true
});

export const menuVariantToCartVariant = menuVariant => menuToCartVariantSerializer.serialize(menuVariant);
export const menuItemToCartVariant = ({ description, name, menuableType, menuableId }) => ({
  id: menuableId,
  menuableId,
  menuableType,
  description,
  name
});

export const compareMenuableTypeAndId = (item1, item2) =>
  item1?.menuableId === item2?.menuableId &&
  item1?.menuableType === item2?.menuableType &&
  item1?.halves?.right?.menuableId === item2?.halves?.right?.menuableId &&
  item1?.halves?.left?.menuableId === item2?.halves?.left?.menuableId;

export const itemHasSameRule = (item1, item2) =>
  (!itemHasRulesAdded(item1) && !itemHasRulesAdded(item2)) ||
  (item1?.appliedPromos?.length &&
    item2?.appliedPromos?.length &&
    item1?.appliedPromos[0].id === item2?.appliedPromos[0].id);

// eslint-disable-next-line complexity
const compareIngredients = (ingredientsOne, ingredientsTwo) => {
  if (!ingredientsOne && !ingredientsTwo) {
    return true;
  }
  if ((!ingredientsOne && ingredientsTwo) || (ingredientsOne && !ingredientsTwo)) {
    return false;
  }
  // To avoid an N^2 algorithm for each item (checking for each elem of first array that it exists in the second)
  // I map each element id to the ingredient contents, then I check that map
  // This makes the algorithm 2N

  let ingredientsAreEqual = true;
  let leftIngredientsAreEqual = false;
  let rightIngredientsAreEqual = false;

  if (isArray(ingredientsOne) && isArray(ingredientsTwo)) {
    if (ingredientsOne.length !== ingredientsTwo.length) {
      return false;
    }
    const ingredients1Map =
      ingredientsOne?.reduce?.((accum, ingredient) => {
        accum[ingredient.id] = ingredient;

        return accum;
      }, {}) || {};

    ingredientsAreEqual = ingredientsTwo?.every(
      ingredient =>
        ingredients1Map?.[ingredient.id]?.quantity === ingredient.quantity &&
        ingredients1Map?.[ingredient.id]?.action === ingredient.action
    );
  }

  if (ingredientsAreEqual) {
    leftIngredientsAreEqual = compareIngredients(
      ingredientsOne.left?.ingredients,
      ingredientsTwo.left?.ingredients
    );
  }
  if (leftIngredientsAreEqual) {
    rightIngredientsAreEqual = compareIngredients(
      ingredientsOne.right?.ingredients,
      ingredientsTwo.right?.ingredients
    );
  }

  return ingredientsAreEqual && leftIngredientsAreEqual && rightIngredientsAreEqual;
};

const compareLoyalty = (item1, item2) =>
  item1.pointsSpent === item2.pointsSpent || !!item1.pointsSpent === !!item2.pointsSpent;

export const findProductFromVariant = (menu, variant) =>
  menu.find(menuItem =>
    menuItem.variants?.find(itemVariant => compareMenuableTypeAndId(itemVariant, variant))
  );

export const menuVariantAreSamePizza = (menuItem1, menuItem2) =>
  compareMenuableTypeAndId(menuItem1, menuItem2);

export const menuItemsAreEqual = (menuItem1, menuItem2) =>
  itemHasSameRule(menuItem1, menuItem2) &&
  compareMenuableTypeAndId(menuItem1, menuItem2) &&
  compareIngredients(menuItem1.ingredients, menuItem2.ingredients) &&
  compareIngredients(menuItem1.halves, menuItem2.halves) &&
  compareMenuableTypeAndId(menuItem1.side, menuItem2.side) &&
  compareLoyalty(menuItem1, menuItem2);

export const menuItemsWithPoints = (menuItem1, menuItem2) =>
  compareMenuableTypeAndId(menuItem1, menuItem2) && menuItem2.withLoyalty && menuItem1.pointsSpent > 0;

export const offersAreEqual = (offer1, offer2) => {
  if (offer1.offerId !== offer2.offerId) {
    return false;
  }

  return offer1.groupProducts.every(
    (group, index) =>
      compareMenuableTypeAndId(group, offer2.groupProducts[index]) &&
      ((!group.ingredients && !offer2.groupProducts[index].ingredients) ||
        group.ingredients?.every((ingredient, ingredientIndex) => {
          const offer2Ingredient = offer2.groupProducts[index].ingredients?.[ingredientIndex];

          return (
            ingredient === offer2Ingredient ||
            (ingredient?.id === offer2Ingredient?.id &&
              compareMenuableTypeAndId(ingredient, offer2Ingredient))
          );
        }))
  );
};

export const isSameItem = (item1, item2) =>
  item1.offerType ? offersAreEqual(item1, item2) : menuItemsAreEqual(item1, item2);

export const getVariantName = variant => {
  if (variant.side) {
    return variant.side.name;
  }

  return variant.variantName || variant.dropdownText || variant.name;
};

export const getVariantDescription = variant => {
  if (variant.side) {
    return variant.menuable.description || variant.description;
  }

  return variant.description || variant.productDescription;
};

export const getQuantityOnCart = (cartItems, item) =>
  cartItems.filter(
    cartItem =>
      cartItem.menuableId === item.menuableId &&
      cartItem.menuableType === item.menuableType &&
      cartItem.pointsSpent > 0
  )?.[0]?.quantity || 0;

export const checkSideCategory = item => !!item.menuable?.sideCategory;
export const checkItemOrVariantForCart = (item, isLoyaltyItem, selectedVariant) =>
  isLoyaltyItem && !item.variants?.length > 1
    ? menuItemToCartVariant(item)
    : menuVariantToCartVariant(selectedVariant);

export const itemToCartItem = ({
  item,
  selectedVariant,
  isLoyaltyItem,
  payingWithPoints,
  addedFrom,
  pizzaVariantsTranslator
}) => {
  let variant = {};
  const hasSideCategory = checkSideCategory(item);

  if (hasSideCategory) {
    variant = menuVariantToCartVariant({
      ...item,
      description: selectedVariant.description,
      side: selectedVariant
    });

    variant.name = item.menuable.name;
    variant.variantName = selectedVariant.name;
    variant.description = item.menuable.description;
  } else {
    variant = checkItemOrVariantForCart(item, isLoyaltyItem, selectedVariant);
    const base = isLoyaltyItem ? item : variant;

    // TODO: Change when all changes are made elsewhere in the app
    if (payingWithPoints) {
      variant.withLoyalty = true;
      variant.pointsSpent = base.points;
    }
    if (item.menuableType === productTypes.COMPLEMENT) {
      variant.name = base.name;
      variant.variantName = base.variantName || base.dropdownText;
      variant.description = base.description;
    } else if (item.menuableType === productTypes.COMPLEMENT_VARIANT) {
      variant.name = base.productName;
      variant.variantName = base.name;
      variant.description = base.description;
    } else if (item.menuableType === productTypes.PIZZA_VARIANT) {
      variant.name = item.name || item.productName;
      variant.variantName = buildPizzaVariantName(item, pizzaVariantsTranslator);
      variant.description = buildPizzaDescription(item);
    }
  }

  variant.addedFrom = [addedFrom];

  return variant;
};

// Used to create unique keys in cart elements, may be useful for other stuff
export const getItemUniqueId = (item, index) => {
  const idsToString = (ingredients, key) =>
    ingredients?.reduce((itemString, ingredient) => `${itemString}${ingredient.id}-`, `${key}:`) || '';
  const wholeIngredients = idsToString(item.ingredients, 'whole');
  const leftIngredients = idsToString(item.halves?.left.ingredients, 'left');
  const rightIngredients = idsToString(item.halves?.right.ingredients, 'right');
  const ingredientsString = `${wholeIngredients}${leftIngredients}${rightIngredients}`;

  const id = item.menuableId || item.offerId;
  const type = item.menuableType || item.offerType;

  return `${index}-pizzaId${id}-pizzaType${type}-${ingredientsString}${
    item.side ? `-${item.side.menuableId}` : ''
  }`;
};
