import Immutable from 'seamless-immutable';
import { createReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { MONITOR_VIEWS } from './constants';

const initialState = {
  monitorView: MONITOR_VIEWS.offerStatus
};

const reducerDescription = {
  [actions.SET_MONITOR_VIEW]: onReadValue()
};

const reducer = createReducer(new Immutable(initialState), reducerDescription);

export default reducer;
