import i18next from 'i18next';

// FIXME checking the i18next docs, we should use 'es-CL' not 'es_CL', 'cause the docs says so and 'cause we cant use functionalities such as *_plural
// FIXME we have to check how much time we could waste doing this refactor
i18next.addResources('es_CL', 'BlockedProductsModal', {
  modalTitleSingular: 'Producto no disponible',
  modalTitlePlural: 'Productos no disponibles',
  modalDescriptionSingular:
    'Este producto dejó de estar disponible mientras realizabas la compra. Te invitamos a seleccionar otro de nuestros productos en el menú',
  modalDescriptionPlural:
    'Estos productos dejaron de estar disponibles mientras realizabas la compra. Te invitamos a seleccionar otro de nuestros productos en el menú',
  modalRemoveProduct: 'Remover producto',
  modalRemoveProducts: 'Remover productos'
});

i18next.addResources('pt_PT', 'BlockedProductsModal', {
  modalTitleSingular: 'Produto não disponível',
  modalTitlePlural: 'Produtos não disponíveis',
  modalDescriptionSingular:
    'Este produto deixou de estar disponível enquanto você fazia a compra. Convidamos você a selecionar outro de nossos produtos no menu',
  modalDescriptionPlural:
    'Esses produtos não estavam mais disponíveis enquanto você fazia a compra. Convidamos você a selecionar outro de nossos produtos no menu',
  modalRemoveProduct: 'Remover produto',
  modalRemoveProducts: 'Remover produtos'
});
