import { mapGiftCartToGTMData } from '~models/shoppingCart';
import {
  EVENT,
  sendGTMEvent,
  eventCategory,
  eventAction,
  eventLabel,
  sendGTMAddtoCartEvent
} from '~utils/analytics';

export const sendGiftCardSectionEvent = () =>
  sendGTMEvent(EVENT.eventPJ, {
    eventActionCategory: eventCategory.interaction,
    action: eventAction.giftCard,
    label: eventLabel.menu
  });

export const sendGiftCardEditEvent = () =>
  sendGTMEvent(EVENT.eventPJ, {
    eventActionCategory: eventCategory.interaction,
    action: eventAction.giftCard,
    label: eventLabel.edit
  });

export const addToCartEvent = ({ amount, price, currency }) =>
  sendGTMAddtoCartEvent(mapGiftCartToGTMData(amount, price, currency));

export const useGiftCardGTMEvent = ({ giftCard, totalPrice, tip }) =>
  giftCard?.code &&
  sendGTMEvent(EVENT.USE_GIFT_CARD, {
    idGiftCard: giftCard.code,
    amountGiftCard: giftCard.amount,
    paymentGiftCard: totalPrice + tip === 0
  });
