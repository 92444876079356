import Immutable from 'seamless-immutable';
import {
  createReducer,
  completeState,
  completeReducer,
  onReadValue,
  onSuccess,
  onSetValue
} from 'redux-recompose';

import { actions } from './actions';
import {
  onSpreadValue,
  onGetIngredientsSuccess,
  onSetHelpers,
  onSetPizzaElement,
  onDeletePizzaElement,
  onSetPizzaIdConfig,
  onEditElementHalf,
  onEditElementQuantity,
  onGetOfferPizzas,
  onSetSelections,
  onSetPizzaSpecialtyData
} from './effects';
import {
  CUSTOM_PIZZA_CONFIG_TARGET,
  OFFER_TARGET,
  SELECTED_OFFER_ID_TARGET,
  PRICES_TARGET,
  SELECTIONS_TARGET,
  BASE_PIZZA_TARGET,
  SELECTED_VARIANT_TARGET,
  EDITING_CART_ITEM_INDEX_TARGET,
  BASE_PIZZA_DATA_TARGET,
  DISABLE_GET_PRICES_TARGET,
  PIZZA_SPECIALTY_DATA,
  PRICE_IS_UPDATED_TARGET
} from './constants';

const initialStateDescription = {
  [CUSTOM_PIZZA_CONFIG_TARGET]: { crusts: [], sizes: [], ingredients: [] },
  [SELECTIONS_TARGET]: null,
  [OFFER_TARGET]: null,
  [SELECTED_OFFER_ID_TARGET]: null,
  [PRICES_TARGET]: {},
  [BASE_PIZZA_TARGET]: null,
  [SELECTED_VARIANT_TARGET]: {},
  [EDITING_CART_ITEM_INDEX_TARGET]: null,
  [DISABLE_GET_PRICES_TARGET]: false,
  [PIZZA_SPECIALTY_DATA]: {},
  [PRICE_IS_UPDATED_TARGET]: true
};

const initialState = completeState(initialStateDescription, [EDITING_CART_ITEM_INDEX_TARGET]);

initialState[`${CUSTOM_PIZZA_CONFIG_TARGET}Loading`] = true;
initialState[`${PRICES_TARGET}Loading`] = false;

const reducerDescription = {
  primaryActions: [
    actions.GET_INGREDIENTS,
    actions.GET_PIZZA_PRICE,
    actions.GET_OFFER,
    actions.GET_BASE_PIZZA,
    actions.GET_OFFER_PIZZAS,
    actions.GET_PARSER_PIZZA_DATA,
    actions.GET_PIZZA_SPECIALTY_DATA
  ],
  override: {
    [actions.SET_HELPERS]: onSetHelpers,
    [actions.SET_CUSTOM_PIZZA_CONFIG]: onSpreadValue,
    [actions.GET_INGREDIENTS_SUCCESS]: onGetIngredientsSuccess,
    [actions.CLEAR_INGREDIENT_DATA]: onSetValue(null),
    [actions.CLEAR_PIZZA_PRICE]: onSuccess(),
    [actions.SET_SELECTED_OFFER_ID]: onReadValue(),
    [actions.CLEAR_ALL]: state =>
      state.merge({
        ...initialStateDescription,
        cancelNext: true,
        [CUSTOM_PIZZA_CONFIG_TARGET]: {
          ...state[CUSTOM_PIZZA_CONFIG_TARGET],
          [BASE_PIZZA_DATA_TARGET]: null
        },
        [PIZZA_SPECIALTY_DATA]: { ...state[PIZZA_SPECIALTY_DATA] }
      }),
    [actions.SET_SELECTIONS]: onSetSelections,
    [actions.ADD_ELEMENT]: onSetPizzaElement,
    [actions.REMOVE_ELEMENT]: onDeletePizzaElement,
    [actions.SET_CUSTOM_PIZZA_DATA]: onSetPizzaIdConfig,
    [actions.EDIT_ELEMENT_HALF]: onEditElementHalf,
    [actions.EDIT_ELEMENT_QUANTITY]: onEditElementQuantity,
    [actions.GET_OFFER_PIZZAS_SUCCESS]: onGetOfferPizzas,
    [actions.SET_SELECTED_VARIANT]: onReadValue(),
    [actions.SET_EDITING_CART_ITEM_INDEX]: onReadValue(),
    [actions.SET_DISABLE_GET_PRICES]: onReadValue(),
    [actions.FORCE_BASE_PIZZA]: onReadValue(),
    [actions.GET_PIZZA_SPECIALTY_DATA_SUCCESS]: onSetPizzaSpecialtyData,
    [actions.CLEAR_PARSER_PIZZA_DATA]: onSetValue(null),
    [actions.SET_PRICE_IS_UPDATED]: onReadValue()
  }
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
