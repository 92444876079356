import 'numeral/locales';
import numeral from 'numeral';
import { t } from 'i18next';

import { ISO_COUNTRY } from '~constants/search';
import { COUNTRIES, CURRENT_COUNTRY_ID, CURRENCY_SYMBOL } from '~constants/environment';

// switch between locales
numeral.locale('es');

const DECIMAL_COUNTRY_USE = {
  cl: 'round',
  es: 'decimal',
  pt: 'decimal',
  pa: 'decimal',
  cr: 'round',
  gt: 'round'
};

const DECIMAL_METHODS = {
  round: '0,0',
  decimal: '0,0.00'
};

export const parseNumber = string => numeral(string).value();

const suffixCurrencySign = [COUNTRIES.es, COUNTRIES.pt].includes(CURRENT_COUNTRY_ID);

export const subtotalPrice = (total, delivery) => total - delivery;

export const formatPrice = (price, lines) => {
  let result = '';
  if (price) {
    result = numeral(price).format(DECIMAL_METHODS[DECIMAL_COUNTRY_USE[ISO_COUNTRY]]);
  } else {
    result = lines ? '---' : 0;
  }

  return suffixCurrencySign ? `${result} ${CURRENCY_SYMBOL}` : `${CURRENCY_SYMBOL}${result}`;
};

export const deliveryPrice = value => (value === 0 ? t('Order:freeDelivery') : formatPrice(value));

export const deltaPrice = (value, closedPrice, quantity = 1) =>
  value ? `${closedPrice ? '' : '+'} ${formatPrice(value * quantity)}` : '';

export const roundDecimal = (number, decimals) => number.toFixed(decimals);

export const getMaxFromArray = arr => arr?.reduce((a, b) => Math.max(a, b), -Infinity);

export const getValidAmount = amount => amount || 0;
