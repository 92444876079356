import i18next from 'i18next';

i18next.addResources('es_CL', 'Cart', {
  emptyCartTitle: 'Maestros pizzeros están esperando tu pedido. ¿Qué te apetece hoy?',
  points: '{{points}} Papa Puntos'
});

i18next.addResources('pt_PT', 'Cart', {
  emptyCartTitle: 'As melhores pizzas estão à espera pelo seu pedido. O que vai-lhe apetecer hoje?',
  points: '{{points}} Papa Pontos'
});
