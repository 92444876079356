import { createTypes, completeTypes } from 'redux-recompose';

import {
  STEPPER_CONTAINER_ID,
  STEP_PROPERTIES
} from '../components/Header/components/StepIndicator/constants';

import { MONITOR_VIEWS } from '~screens/Dashboard/screens/Home/components/OrderBuilder/components/Monitor/redux/constants';
import { actionCreators as monitorActions } from '~screens/Dashboard/screens/Home/components/OrderBuilder/components/Monitor/redux/actions';
import { actionCreators as modalActions } from '~redux/Modal/actions';
import { OB_MONITOR_MODAL } from '~redux/Modal/constants';
import { isTablet as checkIsMobile } from '~constants/sizes';
import { MODAL_CLOSE_TIMEOUT } from '~components/ResizableMobileComponent/constants';
import { handleHorizontalSrolling } from '~utils/scroll';
import { isLastItem } from '~utils/array';

import { CURRENT_STEP_NUMBER_TARGET, STEPS_TARGET, STEPPER_VIEW } from './constants';

export const actions = createTypes(
  completeTypes([], ['CHANGE_STEP', 'SET_STEPS', 'SET_STEPPER_VIEW']),
  '@@WIZARD'
);

export const actionCreators = {
  changeStep: nextStep => ({
    type: actions.CHANGE_STEP,
    target: CURRENT_STEP_NUMBER_TARGET,
    payload: nextStep
  }),
  setSteps: steps => ({
    type: actions.SET_STEPS,
    target: STEPS_TARGET,
    payload: steps
  }),
  setStepperView: bool => ({
    type: actions.SET_STEPPER_VIEW,
    target: STEPPER_VIEW,
    payload: bool
  }),
  updateStepStatus: (stepNumber, key, status) => (dispatch, getState) => {
    const steps = [...getState().wizard?.steps];
    const updatedStep = { ...steps[stepNumber], [key]: status };

    steps[stepNumber] = updatedStep;

    dispatch({
      type: actions.SET_STEPS,
      target: STEPS_TARGET,
      payload: steps
    });
  },
  confirmStep: stepNumber => (dispatch, getState) => {
    const isMobile = checkIsMobile();
    const nextStep = stepNumber + 1;
    const steps = [...getState().wizard?.steps];
    const allStepsCompleted = steps?.every(({ isCompleted }) => isCompleted);
    const isLast = isLastItem(steps, stepNumber);

    const confirmStepActions = () => {
      dispatch(monitorActions.setMonitorView(MONITOR_VIEWS.offerStatus));
      if (nextStep < steps.length) {
        dispatch(actionCreators.updateStepStatus(nextStep, STEP_PROPERTIES.isDisabled, false));
        dispatch(actionCreators.updateStepStatus(stepNumber, STEP_PROPERTIES.hasBeenDeleted, false));
        dispatch(actionCreators.setStepperView(true));
        dispatch(actionCreators.changeStep(nextStep));
        handleHorizontalSrolling(`#${STEPPER_CONTAINER_ID}`, `#step${stepNumber + 1}`);
      }
      if (isLast) {
        dispatch(actionCreators.updateStepStatus(stepNumber, STEP_PROPERTIES.allStepsCompletedOnce, true));
      }
    };

    dispatch(actionCreators.updateStepStatus(stepNumber, STEP_PROPERTIES.isCompleted, true));

    if (allStepsCompleted || isLast) {
      dispatch(modalActions.openModal(OB_MONITOR_MODAL));
      dispatch(monitorActions.setMonitorView(MONITOR_VIEWS.offerStatus));
    } else {
      dispatch(modalActions.closeModal(OB_MONITOR_MODAL));
      if (isMobile) {
        setTimeout(() => {
          confirmStepActions();
        }, MODAL_CLOSE_TIMEOUT);
      } else {
        confirmStepActions();
      }
    }
  }
};
