import i18next from 'i18next';

i18next.addResources('es_CL', 'EditCards', {
  edit: 'Editar',
  terminate: 'Terminar'
});

i18next.addResources('pt_PT', 'EditCards', {
  edit: 'Editando'
});
