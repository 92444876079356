import { buildPizzaSections } from '~constants/products';
import { DEFAULT_INGREDIENT_QUANTITY, DEFAULT_INGREDIENT_ACTION_TYPE } from '~constants/buildPizza';
import { CUSTOM_HALF_OPTIONS } from '~components/CustomizableOption/constants';

export const CUSTOM_PIZZA_CONFIG_TARGET = 'config';
export const BASE_PIZZA_DATA_TARGET = 'basePizzaData';
export const HELPERS_TARGET = 'helpers';
export const PRICES_TARGET = 'prices';
export const PRICE_IS_UPDATED_TARGET = 'priceIsUpdated';
export const OFFER_TARGET = 'offer';
export const SELECTED_OFFER_ID_TARGET = 'selectedOfferId';
export const INGREDIENTS_TARGET = 'ingredients';
export const BASES_TARGET = 'bases';
export const CRUST_TARGET = 'crusts';
export const SIZE_TARGET = 'sizes';
export const SELECTIONS_TARGET = 'selections';
export const SELECTED_VARIANT_TARGET = 'selectedVariant';
export const EDITING_CART_ITEM_INDEX_TARGET = 'editingCartItemIndex';
export const DISABLE_GET_PRICES_TARGET = 'disableGetPrices';
export const PARSER_PIZZA_DATA = 'parserPizzaData';
export const PIZZA_SPECIALTY_DATA = 'pizzaSpecialtyData';

export const ELEMENT_UNIQUENESS = {
  CATEGORY: 'category',
  TYPE: 'type'
};
export const BASE_PIZZA_TARGET = 'basePizza';

export const INGREDIENT_ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove'
};

export const INGREDIENT_API_TEMPLATE = {
  id: 0,
  category: buildPizzaSections.VEGETABLE,
  quantity: DEFAULT_INGREDIENT_QUANTITY,
  action: DEFAULT_INGREDIENT_ACTION_TYPE,
  name: null
};

export const HALF_TO_SIDE = {
  [CUSTOM_HALF_OPTIONS.half1]: 'left',
  [CUSTOM_HALF_OPTIONS.half2]: 'right'
};

export const halfStoreIdentifier = half =>
  half === CUSTOM_HALF_OPTIONS.whole ? 'id' : `${HALF_TO_SIDE[half]}HalfId`;

export const QUANTITY_MAX = 2;

export const MAKE_PIZZA_STEP = 'makePizza';

export const BASE_ELEMENT = { stepId: MAKE_PIZZA_STEP, elementType: INGREDIENTS_TARGET };
