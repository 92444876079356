export const SIZES = {
  PERSONAL: 'personal',
  INDIVIDUAL: 'individual',
  REGULAR: 'regular',
  MEDIUM: 'medium',
  FAMILY: 'family',
  EXTRA_LARGE: 'extra_large'
};

export const CRUSTS = {
  ORIGINAL: 'original',
  TRADITIONAL: 'traditional',
  THIN: 'thin',
  STUFFED: 'stuffed',
  PARMESAN: 'parmesan',
  PHILADELPHIA: 'philadelphia',
  BRIE: 'brie',
  CAULIFLOWER: 'cauliflower',
  PARMESAN_BASE: 'parmesan_base',
  PEPPERONI: 'pepperoni'
};

export const COOKIE_EXPIRE_DAYS = 90;
export const URL_PATH = '/app';
export const FIRST_PAGE_PAST_ORDERS = 1;
