import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'PizzaByHalves', {
  title: 'Pizza por mitades',
  description: 'Debes elegir entre 1 y {{maxIngredients}} ingredientes',
  disclaimer: 'Las opciones con * se consideran un ingrediente más',
  sizeAndBase: 'Tamaño y masa',
  half: 'Mitad {{number}}',
  nextStep: 'Siguiente paso',
  addToCart: 'Añadir al carrito'
});

i18next.addResources(LANGUAGE_KEYS.SPAIN, 'PizzaByHalves', {
  disclaimer: 'Las opciones marcadas con un * se consideran como un ingrediente adicional'
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'PizzaByHalves', {
  title: 'Pizza por metades',
  description: 'Deve escolher entre 1 e {{maxIngredients}} ingredientes',
  disclaimer: 'As opções com * são consideradas como um ingrediente adicional.',
  sizeAndBase: 'Tamanho e massa',
  half: 'Metade {{number}}',
  nextStep: 'Passo seguinte',
  addToCart: 'Adicionar ao carrinho'
});
