import api from '~config/api';
import { RENDER_CALL_CENTER } from '~constants/environment';

import { shoppingCartPriceSerializer } from './serializers';
import { checkAvailablePoints, buildBodyWithRulesInItems } from './utils';

const userHeader = (headers, externalUserId) => ({
  'X-USERID': externalUserId,
  ...headers
});

const orderBuilderHeader = (headers, isOrderBuilder) => ({
  'X-ORDERBUILDER': isOrderBuilder,
  ...headers
});

const buildHeaders = ({ externalUserId, isOrderBuilder }) =>
  [
    { value: externalUserId, mapHeader: userHeader },
    { value: isOrderBuilder, mapHeader: orderBuilderHeader }
  ].reduce((headers, { value, mapHeader }) => (value ? mapHeader(headers, value) : headers), {});

const ShoppingCartService = {
  getPrices: ({
    storeId,
    dispatchMethod,
    items,
    externalUserId,
    coupon,
    giftCard,
    isOrderBuilder = false
  }) => {
    const body = {
      shoppingCart: {
        storeId,
        dispatchMethod,
        items: checkAvailablePoints(items),
        ...(coupon && { coupon }),
        ...(giftCard && { giftCard })
      }
    };

    const sBody = shoppingCartPriceSerializer().serialize(buildBodyWithRulesInItems(body));
    const headers = buildHeaders({ externalUserId, isOrderBuilder });

    return api.post('/v1/shopping_carts/prices', sBody, {
      headers: { ...headers, 'X-PLATFORM': RENDER_CALL_CENTER ? 'call_center' : 'web' }
    });
  },
  createOrder: ({ user, shoppingCart }) => {
    const body = buildBodyWithRulesInItems({ user, shoppingCart });

    return api.post('/v1/shopping_carts/prices', shoppingCartPriceSerializer(false).serialize(body));
  },
  getSuggestiveSells: ({ storeId, shoppingCart: { items }, dispatchMethod }) => {
    const body = buildBodyWithRulesInItems({ shoppingCart: { storeId, items, dispatchMethod } });

    return api.post(
      '/v1/shopping_carts/suggestive_sells',
      shoppingCartPriceSerializer(false).serialize(body)
    );
  },
  getSuggestivePromos: ({ storeId, items, dispatchMethod }) => {
    const body = buildBodyWithRulesInItems({ shoppingCart: { storeId, items, dispatchMethod } });

    return api.post(
      '/v1/shopping_carts/suggestive_promos',
      shoppingCartPriceSerializer(false).serialize(body)
    );
  },
  getOfferCompletions: ({ storeId, shoppingCart: { items }, dispatchMethod, externalUserId }) => {
    const body = buildBodyWithRulesInItems({ shoppingCart: { storeId, items, dispatchMethod } });
    return api.post(
      '/v1/shopping_carts/offer_completions',
      shoppingCartPriceSerializer(false).serialize(body),
      externalUserId && {
        headers: {
          'X-USERID': externalUserId
        }
      }
    );
  },
  getOptimizedCart: ({ storeId, shoppingCart: { items }, dispatchMethod, externalUserId }) => {
    const body = buildBodyWithRulesInItems({ shoppingCart: { storeId, items, dispatchMethod } });
    return api.post(
      '/v1/shopping_carts/cart_prices_optimizer',
      shoppingCartPriceSerializer(false).serialize(body),
      externalUserId && {
        headers: {
          'X-USERID': externalUserId
        }
      }
    );
  },
  getOrderRules: ({ storeId, shoppingCart: { items }, dispatchMethod, externalUserId }) => {
    const body = buildBodyWithRulesInItems({ shoppingCart: { storeId, items, dispatchMethod } });
    return api.post(
      '/v1/shopping_carts/cart_rule',
      shoppingCartPriceSerializer(false).serialize(body),
      externalUserId && {
        headers: {
          'X-USERID': externalUserId
        }
      }
    );
  },
  getPromotedProducts: ({ storeId, shoppingCart: { items }, dispatchMethod }) => {
    const body = buildBodyWithRulesInItems({ shoppingCart: { storeId, items, dispatchMethod } });

    return api.post(
      '/v1/shopping_carts/promoted_products',
      shoppingCartPriceSerializer(false).serialize(body)
    );
  }
};

export default ShoppingCartService;
