import i18next from 'i18next';

i18next.addResources('es_CL', 'Wizard', {
  stepNumber: 'Paso {{ number }}',
  ingredientsAmount: '{{selectionsQuantity}} de {{maxIngredients}} ingredientes',
  productNotAvailableTitle: 'Producto no disponible.',
  productNotAvailable:
    'El producto que seleccionaste no está disponible en la tienda que escogiste. Por favor, intenta con otro artículo o selecciona una tienda diferente'
});

i18next.addResources('es_ES', 'Wizard', {
  stepNumber: 'Paso {{ number }}',
  ingredientsAmount: '{{selectionsQuantity}} de {{maxIngredients}} ingredientes'
});

i18next.addResources('pt_PT', 'Wizard', {
  stepNumber: 'Passo {{ number }}',
  ingredientsAmount: '{{selectionsQuantity}} de {{maxIngredients}} ingredientes',
  productNotAvailableTitle: 'Produto indisponível.',
  productNotAvailable:
    'O produto que selecionaste não está disponível na loja que escolheste. Por favor, tenta outro artigo ou seleciona uma loja diferente.'
});
