import i18next from 'i18next';

i18next.addResources('es_CL', 'Store', {
  storeName: '. {{ name }}',
  onlineOrder: 'Retiro en tienda',
  indicationsDesktop: 'Cómo llegar',
  indicationsMobile: 'Indicaciones',
  dispatchMethodInStore: 'Retiro en Local',
  dispatchMethodDelivery: 'Delivery',
  seeStoreInfo: 'Ver información de la tienda'
});

// TODO: pending review for Spain
i18next.addResources('es_ES', 'Store', {
  storeName: '. {{ name }}',
  onlineOrder: 'Recoger en local',
  indicationsDesktop: 'Cómo llegar',
  indicationsMobile: 'Indicaciones',
  dispatchMethodInStore: 'Retiro en Local',
  dispatchMethodDelivery: 'Delivery'
});

i18next.addResources('pt_PT', 'Store', {
  storeName: '. {{ name }}',
  onlineOrder: 'Levantamento no local',
  indicationsDesktop: 'Como chegar',
  indicationsMobile: 'Indicações',
  dispatchMethodInStore: 'Para levantamento no local',
  dispatchMethodDelivery: 'Entrega',
  seeStoreInfo: 'Ver informações da tienda'
});

i18next.addResources('es_PA', 'Store', {
  dispatchMethodDelivery: 'Entrega',
  dispatchMethodInStore: 'Retiro en restaurante'
});
