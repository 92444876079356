import { Serializer, setSnakecaseKey } from 'cerealizr';

import { serializer as baseSerializer } from '../baseSerializers';

const sideSerializer = new Serializer({
  descriptor: {
    menuableId: setSnakecaseKey(),
    menuableType: setSnakecaseKey(),
    name: setSnakecaseKey()
  },
  mapAllValues: false
});

const ingredientsSerializer = new Serializer({
  descriptor: {
    id: setSnakecaseKey(),
    name: setSnakecaseKey(),
    quantity: setSnakecaseKey(),
    action: setSnakecaseKey(),
    freeIngredient: setSnakecaseKey()
  },
  mapAllValues: false
});

const halvesSerializer = new Serializer({
  descriptor: {
    left: (key, value) => setSnakecaseKey(v => itemsSerializer.serialize(v))(key, value), // eslint-disable-line no-use-before-define
    right: (key, value) => setSnakecaseKey(v => itemsSerializer.serialize(v))(key, value) // eslint-disable-line no-use-before-define
  },
  mapAllValues: false
});

const shoppingCartItemDescriptor = {
  menuableType: setSnakecaseKey(),
  menuableId: setSnakecaseKey(),
  quantity: setSnakecaseKey(),
  withLoyalty: setSnakecaseKey(),
  offerId: setSnakecaseKey(),
  offerType: setSnakecaseKey(),
  ruleId: setSnakecaseKey(),
  groupProducts: setSnakecaseKey(value => baseSerializer.serialize(value)),
  side: (key, values) => ({ [key]: values ? sideSerializer.serialize(values) : undefined }),
  ingredients: (key, values) => ({ [key]: values ? ingredientsSerializer.serialize(values) : null }),
  halves: (key, value) => setSnakecaseKey(v => (v ? halvesSerializer.serialize(v) : null))(key, value)
};

const itemsSerializer = new Serializer({
  descriptor: shoppingCartItemDescriptor,
  mapAllValues: false
});

const comboItemSerializer = new Serializer({
  descriptor: {
    menuableType: setSnakecaseKey(),
    menuableId: setSnakecaseKey(),
    offerGroupId: setSnakecaseKey(),
    ingredients: (key, values) => ({ [key]: values ? ingredientsSerializer.serialize(values) : null }),
    halves: (key, value) => setSnakecaseKey(v => (v ? halvesSerializer.serialize(v) : null))(key, value),
    side: (key, values) => ({ [key]: values ? sideSerializer.serialize(values) : undefined })
  }
});

export const offerSerializer = new Serializer({
  descriptor: {
    offerId: setSnakecaseKey(),
    quantity: setSnakecaseKey(),
    offerType: setSnakecaseKey(),
    groupProducts: setSnakecaseKey(value => comboItemSerializer.serialize(value))
  }
});

const giftCardSerializer = new Serializer({
  descriptor: {
    name: setSnakecaseKey(),
    buyer: setSnakecaseKey(),
    receiver: setSnakecaseKey(),
    amount: setSnakecaseKey(),
    message: setSnakecaseKey()
  }
});

const shoppingCartValueSerializer = innerItemSerializer =>
  new Serializer({
    descriptor: {
      storeId: setSnakecaseKey(),
      dispatchMethod: setSnakecaseKey(),
      coupon: setSnakecaseKey(),
      giftCard: setSnakecaseKey(),
      ruleId: setSnakecaseKey(),
      items: (key, values) => ({
        [key]: values.map(value => {
          if (value.menuableId) {
            return innerItemSerializer.serialize(value);
          }
          if (value.offerId) {
            return offerSerializer.serialize(value);
          }
          return giftCardSerializer.serialize(value);
        })
      })
    }
  });

export const shoppingCartPriceSerializer = () =>
  new Serializer({
    descriptor: {
      shoppingCart: (key, value) =>
        setSnakecaseKey(v => shoppingCartValueSerializer(itemsSerializer).serialize(v))(key, value)
    }
  });
