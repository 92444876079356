import i18next from 'i18next';

i18next.addResources('es_CL', 'Contact', {
  pageTitle: "Contacto | Papa John's Chile",
  pageDescription: 'Escríbenos para quejas o felicitaciones. Siempre queremos mejorar tu experiencia.',
  title: 'Enviar una solicitud',
  description:
    'Ingrese los detalles de esta solicitud. Un integrante de nuestro personal de soporte responderá en breve.',
  fieldName: 'Nombre',
  complain: 'Tengo un reclamo',
  question: 'Tengo una pregunta/solicitud',
  inputMotive: 'Motivo contacto (CF)',
  inputName: 'Nombre*',
  inputEmail: 'Email*',
  inputPhone: 'Teléfono',
  inputReceiptNumber: 'Número de boleta',
  inputStore: 'Seleccione tienda',
  inputDescription: 'Descripción*',
  archiveTitle: 'Archivos adjuntos',
  addArchive: 'Agregar un archivo',
  send: 'Enviar',
  postSuccess: 'Se ha enviado su petición de manera satisfactoria',
  postFail: 'No se ha podido enviar tu petición de manera satisfactoria. Intenta mas tarde.',
  attachSuccess: 'Tu imagen se subió exitosamente',
  attachFail: 'Un error ocurrió al subir tu imagen. Intenta más tarde.',
  motivePlaceholder: 'Seleccione una opción',
  rut: 'RUT (opcional)',
  invalidRut: 'RUT inválido'
});

i18next.addResources('es_ES', 'Contact', {
  pageTitle: 'Contacto | Papa Johns España',
  pageDescription:
    'Escríbenos para quejas o felicitaciones. Siempre queremos mejorar tu experiencia y servicio.',
  keywords: 'contacto Papa Johns',
  title: 'Enviar una solicitud',
  description:
    'Ingrese los detalles de esta solicitud. Un integrante de nuestro personal de soporte responderá en breve.',
  fieldName: 'Nombre',
  complain: 'Tengo un reclamo',
  question: 'Tengo una pregunta/solicitud',
  inputMotive: 'Motivo contacto (CF)',
  inputName: 'Nombre',
  inputEmail: 'Email*',
  inputPhone: 'Teléfono',
  inputReceiptNumber: 'Número de boleta',
  inputStore: 'Seleccione tienda',
  inputDescription: 'Descripción*',
  archiveTitle: 'Archivos adjuntos',
  addArchive: 'Agregar un archivo',
  send: 'Enviar',
  postSuccess: 'Se ha enviado su petición de manera satisfactoria',
  postFail: 'No se ha podido enviar tu petición de manera satisfactoria. Intenta mas tarde.',
  attachSuccess: 'Tu imagen se subió exitosamente',
  attachFail: 'Un error ocurrió al subir tu imagen. Intenta más tarde.',
  motivePlaceholder: 'Seleccione una opción'
});

i18next.addResources('pt_PT', 'Contact', {
  pageTitle: "Contacta-nos | Papa John's Portugal",
  pageDescription:
    'Contacta-nos para sugestões, reclamações ou felicitações. Queremos melhorar a vossa experiência e o nosso serviço. Sempre!',
  title: 'Enviar um pedido de ajuda',
  description:
    'Preenche todos os campos deste pedido de ajuda. Um elemento da nossa equipa de Apoio ao Cliente irá responder em breve.',
  fieldName: 'Primeiro nome',
  complain: 'Eu tenho uma reclamação',
  question: 'Eu tenho uma pergunta / solicitação',
  inputMotive: 'Razão para contato (CF)',
  inputName: 'Primeiro nome',
  inputEmail: 'Email*',
  inputPhone: 'Número de telefone',
  inputReceiptNumber: 'Número de cédula',
  inputStore: 'Selecione a loja',
  inputDescription: 'Descrição*',
  archiveTitle: 'Arquivos adjuntos',
  addArchive: 'Adicione um arquivo',
  send: 'Enviar',
  postSuccess: 'Sua solicitação foi enviada satisfatoriamente',
  postFail: 'Sua solicitação não pôde ser enviada com sucesso. Tente mais tarde',
  attachSuccess: 'Sua imagem foi enviada com sucesso',
  attachFail: 'Ocorreu um erro ao fazer o upload da sua imagem. Tente mais tarde',
  motivePlaceholder: 'Selecione uma opção'
});

i18next.addResources('es_CR', 'Contact', {
  pageTitle: "Contacto | Papa John's Costa Rica",
  description:
    'Ingresá los detalles de tu solicitud. Un agente de nuestro equipo de soporte te responderá en breve.',
  inputReceiptNumber: 'Número de orden',
  complain: 'Tengo un queja',
  question: 'Tengo una pregunta o solicitud',
  inputStore: 'Seleccione restaurante'
});

i18next.addResources('es_PA', 'Contact', {
  pageTitle: "Contáctanos | Papa John's Panama",
  description:
    'Ingresá los detalles de tu solicitud. Un agente de nuestro equipo de soporte te responderá en breve.'
});

i18next.addResources('es_GT', 'Contact', {
  pageTitle: 'Ordena MÁS RÁPIDO al Whatsapp 2500 0000 la pizza de tu elección',
  pageDescription: 'Ponte en contacto con Papa Johns, mejores ingredientes, mejor pizza',
  description:
    'Ingrese los detalles de esta solicitud. Un integrante de nuestro personal de soporte responderá en breve.'
});
