import i18next from 'i18next';

i18next.addResources('es_CL', 'RecoveryDone', {
  title: '¡Listo!',
  success: '¡Todo en orden!',
  subtitle: 'Tu contraseña ha sido actualizada con éxito',
  goToLoginButton: 'Ir al ingreso'
});

i18next.addResources('pt_PT', 'RecoveryDone', {
  title: 'Pronto!',
  success: '¡Tudo em ordem!',
  subtitle: 'Sua senha foi alterada com sucesso',
  goToLoginButton: 'Vá para a entrada'
});
