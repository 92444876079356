import i18next from 'i18next';

i18next.addResources('es_CL', 'StoreSearchBar', {
  placeholder: 'Escribe tu dirección completa…',
  gettingCurrentLocation: 'Obteniendo ubicación…',
  currentLocation: 'Usar mi ubicación actual',
  PERMISSION_DENIED: 'Para utilizar esta funcionalidad debes habilitar los permisos de geolocalización',
  POSITION_UNAVAILABLE: 'Información de localización no disponible',
  TIMEOUT: 'El requerimiento expiró',
  UNKNOWN: 'Error desconocido.'
});

i18next.addResources('es_ES', 'StoreSearchBar', {
  gettingCurrentLocation: 'Obteniendo ubicación…',
  placeholder: 'Escribe tu dirección completa…',
  currentLocation: 'Usar mi ubicación actual',
  PERMISSION_DENIED: 'Para utilizar esta funcionalidad debes habilitar los permisos de geolocalización',
  POSITION_UNAVAILABLE: 'Información de localización no disponible',
  TIMEOUT: 'El requerimiento expiró',
  UNKNOWN: 'Error desconocido.'
});

i18next.addResources('pt_PT', 'StoreSearchBar', {
  gettingCurrentLocation: 'Obtendo a localização…',
  placeholder: 'Digite seu endereço completo…',
  currentLocation: 'Use minha localização atual',
  PERMISSION_DENIED: 'Para usar essa funcionalidade, você deve ativar as permissões de geolocalização',
  POSITION_UNAVAILABLE: 'Informações de localização não disponíveis',
  TIMEOUT: 'O requisito expirou',
  UNKNOWN: 'Erro desconhecido.'
});
