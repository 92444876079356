/* eslint-disable camelcase */
import React from 'react';
import { string, bool, func } from 'prop-types';
import cn from 'classnames';

import { ReactComponent as Chevron } from '~assets/icons/chevron_up.svg';

import styles from './styles.module.scss';

function CollapsibleWrapperPromo({
  title,
  children,
  className,
  containerClassName,
  contentContainerClassName,
  titleContainerClassName,
  isOpen,
  toggle
}) {
  return (
    <section className={className}>
      <div
        className={cn(styles.titleContainer, titleContainerClassName, { [styles.active]: isOpen })}
        onClick={toggle}>
        <span className={titleContainerClassName}>{title}</span>
        <Chevron className={cn(styles.icon, { [styles.active]: isOpen })} />
      </div>
      <div className={cn(styles.container, containerClassName, { [styles.active]: isOpen })}>
        <div className={cn(styles.contentContainer, contentContainerClassName)}>{children}</div>
      </div>
    </section>
  );
}

CollapsibleWrapperPromo.propTypes = {
  className: string,
  containerClassName: string,
  contentContainerClassName: string,
  isOpen: bool,
  title: string,
  titleContainerClassName: string,
  toggle: func
};

CollapsibleWrapperPromo.defaultProps = {
  className: '',
  containerClassName: '',
  contentContainerClassName: '',
  titleContainerClassName: ''
};

export default CollapsibleWrapperPromo;
