/* eslint-disable max-lines */
/* eslint-disable max-nested-callbacks */
// eslint-disable-line max-lines
import { createSelector } from 'reselect';
import { t } from 'i18next';

import {
  MAX_INGREDIENTS,
  STEP_CATEGORY
} from '../../../../../../components/ModifyProductModals/screens/ComboCreation/constants';
import {
  getPizzaGroups,
  groupIsPizza,
  getNonPizzaGroupId,
  getSauceGroupId,
  mapGroupToOption,
  getDefaultMinIngredients
} from '../../../../../../components/ModifyProductModals/screens/ComboCreation/utils';

import { PHOTO_APP, PICTURE_TYPES, pizzaCategories, productTypes, BYOP_ICON } from '~constants/products';
import { isBase, getIngredientsWithPrice, allBaseCategoriesAreSelected, isPizzaCategory } from '~utils/pizza';
import { PRODUCT_CATEGORIES } from '~screens/Dashboard/screens/Home/components/Menu/constants';
import { sortArrayByOrder, SORT_KEYS } from '~utils/array';

export const offerSelector = state => state.offers.offer;
const menuSelector = state => state.home.menu;
const selectedSizeSelector = (state, ownProps) =>
  state.customPizza.selections?.[ownProps.stepId]?.sizes || [];
export const otherSelector = (state, ownProps) => state.customPizza.selections?.[ownProps.stepId] || [];
export const currentStepSelector = state => state.wizard?.currentStepNumber;
const currentSelectedPizzaId = state => state.offers.steps?.[`step${currentStepSelector(state) + 1}`];
const stepsPizzaIdsSelector = state => state.offers.steps;
export const byopSelector = state => state.customPizza.helpers?.byop;
export const selectedIngredientsSelector = state => state.customPizza.selections;
const pizzaAmountSelector = (_, pizzaAmount) => pizzaAmount;
export const basePizzaDataSelector = state => state.customPizza?.config?.basePizzaData;
const coreSettingsSelector = state => state.home.coreSettings;
const getGroupCategory = group => STEP_CATEGORY[group?.productOfferGroups[0]?.menuable?.category];
export const isStepperViewSelector = state => state.wizard.isStepperView;
export const selectionsSelector = state => state.customPizza?.selections;
export const stepsSelector = state => state.wizard?.steps;
export const loadingPriceSelector = state => state.customPizza?.pricesLoading;
export const priceSelector = state => state.customPizza?.prices;
export const basePizzaLoadingSelector = state => state.customPizza.basePizzaLoading;
export const monitorViewSelector = state => state.monitor.monitorView;
export const offerGroupMenuableTypeSelector = state =>
  state.offers?.offer?.groups[currentStepSelector(state)]?.productOfferGroups?.[0]?.menuableType;
// eslint-disable-next-line camelcase
export const priceItemsSelector = state => state.customPizza?.prices?.items?.[0]?.group_products;
export const isEditingCartItem = (state, ownProps) => !!ownProps?.editingId;
const pizzaVariantsTranslator = state => state.sizesAndCrusts.data.translations?.crusts;

const buildRestrictions = (groups, selectedPizzaId, byopId) =>
  groups.reduce((result, variant) => {
    if (
      variant.menuable.productId !== selectedPizzaId &&
      !(selectedPizzaId === byopId && variant.menuable.category === pizzaCategories.MAKE_YOUR_PIZZA)
    ) {
      return result;
    }

    const crustId = variant.menuable.crust;
    const sizeId = variant.menuable.size;

    if (!crustId || !sizeId) {
      return result;
    }

    if (result[sizeId]) {
      if (!result[sizeId].includes(crustId)) {
        result[sizeId].push(crustId);
      }
    } else {
      result[sizeId] = [crustId];
    }
    return result;
  }, {});

export const getRestrictions = createSelector(
  offerSelector,
  currentSelectedPizzaId,
  byopSelector,
  (offer, currentBasePizzaId, byop) => {
    if (!offer) {
      return {};
    }
    const pizzaGroups = getPizzaGroups(offer);

    return pizzaGroups.map(group =>
      buildRestrictions(group.productOfferGroups, currentBasePizzaId, byop?.id)
    );
  }
);

const getSizesAndCrustsFromPizza = ({
  pizzaGroup,
  selectedSize,
  restrictions,
  selectedPizzaId,
  byopId,
  crustsTranslator
}) => {
  const orderPizzaGroup = sortArrayByOrder(pizzaGroup.productOfferGroups, SORT_KEYS.ASCENDINGLY, 'delta');

  return orderPizzaGroup.reduce(
    (result, variant) => {
      if (
        variant.menuable.productId !== selectedPizzaId &&
        !(selectedPizzaId === byopId && variant.menuable.category === pizzaCategories.MAKE_YOUR_PIZZA)
      ) {
        return result;
      }

      const crustId = variant.menuable.crust;
      const sizeId = variant.menuable.size;

      if (!result.sizes.find(size => size.id === sizeId)) {
        result.sizes.push({
          id: sizeId,
          text: crustsTranslator ? crustsTranslator[sizeId] : t(`Home:${sizeId}`),
          disabled: false
        });
      }

      if (!result.crusts.find(crust => crust.id === crustId)) {
        result.crusts.push({
          id: crustId,
          text: crustsTranslator ? crustsTranslator[crustId] : t(`Home:${crustId}`),
          disabled: selectedSize && selectedSize.length && !restrictions[selectedSize[0]]?.includes(crustId)
        });
      }

      return result;
    },
    { crusts: [], sizes: [] }
  );
};

export const getPizzaSteps = createSelector(
  offerSelector,
  selectedSizeSelector,
  stepsPizzaIdsSelector,
  byopSelector,
  pizzaVariantsTranslator,
  // eslint-disable-next-line max-params
  (offer, selectedSize, stepsPizzaIds, byop, crustsTranslator) => {
    if (!offer) {
      return [];
    }

    const pizzaGroups = getPizzaGroups(offer);

    if (!pizzaGroups?.length) {
      return [];
    }

    return pizzaGroups.map((pizzaGroup, index) => {
      const basePizzaId = stepsPizzaIds[`step${index + 1}`] || byop?.id;
      let sizesAndCrusts = [];
      let sizeAndCrustRestrictions = {};

      sizesAndCrusts = getSizesAndCrustsFromPizza({
        pizzaGroup,
        selectedSize,
        restrictions: sizeAndCrustRestrictions,
        selectedPizzaId: basePizzaId,
        byopId: byop?.id,
        crustsTranslator
      });

      sizeAndCrustRestrictions = buildRestrictions(pizzaGroup.productOfferGroups, basePizzaId, byop?.id);

      return {
        offerGroupId: pizzaGroup.productOfferGroups[0].offerGroupId,
        menuableType: pizzaGroup.productOfferGroups[0].menuableType,
        menuableId: pizzaGroup.productOfferGroups[0].menuableId,
        category: pizzaGroup.productOfferGroups[0].menuable.category,
        restrictions: sizeAndCrustRestrictions,
        sizesAndCrusts,
        pizzaId: pizzaGroup.productOfferGroups[0].menuable.productId,
        groupAmount: pizzaGroup.productOfferGroups.length,
        groups: pizzaGroup.productOfferGroups,
        name: pizzaGroup.name,
        optionalStep: pizzaGroup.optionalStep
      };
    });
  }
);

export const otherStepsFromOffer = (offer, menu) =>
  offer
    ? offer.groups
        .filter(group => !groupIsPizza(group))
        .map(group => ({
          title: group.name,
          id: group.id,
          offerGroupId: group.productOfferGroups[0].offerGroupId,
          menuableType: group.productOfferGroups[0].menuableType,
          menuableId: group.productOfferGroups[0].menuableId,
          optionalStep: group.optionalStep,
          options: group.productOfferGroups.reduce((groupedOptions, option) => {
            const groupIndex = groupedOptions.findIndex(
              groupOpt => option.menuable.productId && groupOpt.productId === option.menuable.productId
            );

            if (groupIndex >= 0) {
              groupedOptions[groupIndex].variants.push(mapGroupToOption(option, menu));
              return groupedOptions;
            }

            groupedOptions.push({
              name: option.menuable.name,
              description: option.menuable.description,
              offerGroupId: option.offerGroupId,
              menuableType: option.menuableType,
              picture:
                option.menuable.pictures?.find(pic => pic.category === PHOTO_APP) ||
                option.menuable.pictures?.[0],
              productId: option.menuable.productId || option.menuable.id,
              category: option.menuable.category,
              delta: option.delta,
              sideCategory: option.menuable.sideCategory,
              variants: [mapGroupToOption(option, menu)]
            });

            return groupedOptions;
          }, [])
        }))
    : [];

export const getOtherSteps = createSelector(offerSelector, menuSelector, otherStepsFromOffer);

export const getOriginSteps = createSelector(offerSelector, isEditingCartItem, (offer, isEditing) =>
  offer
    ? offer.groups.map((group, index) => ({
        title: group.name,
        id: group.id,
        stepId: `step${index + 1}`,
        isPizzaStep: groupIsPizza(group),
        groupCategory: getGroupCategory(group),
        isCompleted: isEditing,
        isDisabled: !isEditing && index !== 0,
        isClicked: isEditing,
        isOptionalStep: group.optionalStep
      }))
    : []
);

export const getSelectedOthers = createSelector(otherSelector, offerSelector, (selections, offer) => {
  if (!offer) {
    return [];
  }

  const offerOtherGroups = offer.groups.filter(group => !groupIsPizza(group));

  return offerOtherGroups.reduce((result, group) => {
    const category = getNonPizzaGroupId(group.id);
    const sauce = getSauceGroupId(group.id);
    const selectionsForCategory = selections[category] || [];
    const currentCategoryResult = result[category] || [];
    const selectionsForSauce = selections[sauce] || [];
    const currentSauceResult = result[sauce] || [];

    result[category] = [...currentCategoryResult, ...selectionsForCategory];
    result[sauce] = [...currentSauceResult, ...selectionsForSauce];

    return result;
  }, {});
});

const currentStepPizzaIdSelector = state => state.offers.steps?.[`step${state.wizard.currentStepNumber + 1}`];

export const getBasePizzaOptions = createSelector(
  offerSelector,
  byopSelector,
  menuSelector,
  (offer, byop, menu) => {
    const groups = offer?.groups.filter(
      group => group.productOfferGroups?.[0].menuableType === productTypes.PIZZA_VARIANT
    );
    const basePizzaOptions =
      groups?.map(group => {
        const pizzas = group.productOfferGroups;

        if (!pizzas) {
          return null;
        }

        const baseOption = {
          name: t('BasePizzaSelector:noBasePizza'),
          value: byop?.menuableId
        };

        return pizzas.reduce((result, pizza) => {
          const existsInMenu = menu.some(
            menuItem =>
              isPizzaCategory(menuItem.menuable.category) && pizza.menuable.productId === menuItem.menuableId
          );
          const isBYOP = pizza.menuable.category === pizzaCategories.MAKE_YOUR_PIZZA;
          const pizzaExistsInResults = result.some(existingPizza =>
            isBYOP
              ? existingPizza.value === byop?.menuableId
              : existingPizza.value === pizza.menuable.productId
          );

          if (pizzaExistsInResults || (!existsInMenu && !isBYOP)) {
            const updateDelta = result.map(item => {
              if (item.value === pizza.menuable.productId && item.delta > pizza.delta) {
                item.delta = pizza.delta;
              }
              return item;
            });

            return updateDelta;
          }

          // If the pizza is BYOP, don't add the option.
          // Instead, it should have a default option which works as BYOP
          if (pizza.menuable.category === pizzaCategories.MAKE_YOUR_PIZZA) {
            return [
              { ...baseOption, productGroupId: pizza.id, category: pizza.menuable.category },
              ...result
            ];
          }
          const shouldDisableBaseCheese = menu.some(
            menuItem =>
              pizza.menuable.productId === menuItem.menuableId && menuItem.menuable.unpermittedModifyCheese
          );

          result.push({
            name: pizza.menuable.name,
            description: pizza.menuable.description,
            value: pizza.menuable.productId,
            category: pizza.menuable.category,
            delta: pizza.delta,
            picture:
              pizza.menuable.pictures?.find(pic => pic.category === PHOTO_APP) ||
              pizza.menuable.pictures?.[0],
            ...(shouldDisableBaseCheese && { shouldDisableBaseCheese })
          });

          return result;
        }, []);
      }) || [];

    return basePizzaOptions;
  }
);

export const getSelectedBasePizza = createSelector(
  getBasePizzaOptions,
  byopSelector,
  currentStepPizzaIdSelector,
  currentStepSelector,
  (pizzaOptions, byop, currentStepPizzaId = null, currentStep) => {
    if (currentStepPizzaId === byop?.menuableId) {
      return { value: byop.menuableId, name: t('PizzaCreation:makePizza') };
    }

    return pizzaOptions[currentStep]?.find(pizza => pizza.value === currentStepPizzaId);
  }
);

export const getMinIngredients = createSelector(
  currentSelectedPizzaId,
  byopSelector,
  coreSettingsSelector,
  (currentPizzaId, byop, coreSettings) =>
    currentPizzaId === byop?.menuableId
      ? coreSettings?.minimumIngredientsForByopPizza || getDefaultMinIngredients()
      : 1
);

export const currentIsByop = createSelector(
  currentSelectedPizzaId,
  byopSelector,
  (currentPizzaId, byop) => currentPizzaId === byop?.menuableId
);

export const getMaxIngredients = createSelector(
  currentSelectedPizzaId,
  byopSelector,
  basePizzaDataSelector,
  offerSelector,
  coreSettingsSelector,
  // eslint-disable-next-line max-params
  (currentPizzaId, byop, basePizzaData, offer, settings) => {
    if (currentPizzaId === byop?.menuableId) {
      return offer?.customizable
        ? MAX_INGREDIENTS
        : Number.isInteger(offer?.maxIngredients) && offer.maxIngredients > 0
        ? offer.maxIngredients
        : settings?.maximumIngredientsForByopPizza;
    }

    return offer?.customizable
      ? MAX_INGREDIENTS
      : basePizzaData?.defaultIngredients?.[currentPizzaId]?.whole?.filter(
          ingredient => !isBase(ingredient) || ingredient.priceModifier
        ).length;
  }
);

export const getDisableSubmit = createSelector(
  getMaxIngredients,
  selectedIngredientsSelector,
  currentStepSelector,
  pizzaAmountSelector,
  getMinIngredients,
  stepsSelector,
  // eslint-disable-next-line max-params
  (maxIngredients, selectedIngredients, currentStep, pizzaAmount = 1, minIngredients, steps) => {
    if (!steps?.[currentStep]?.isPizzaStep) {
      return false;
    }

    const stepKeys = selectedIngredients ? Object.keys(selectedIngredients) : [];
    const noMorePizzaSteps = pizzaAmount - 1 < currentStep;

    if (noMorePizzaSteps || !selectedIngredients || currentStep < 0) {
      return false;
    }
    const step = stepKeys.find(key => key === `step${[currentStep + 1]}`);
    const ingredients = getIngredientsWithPrice(selectedIngredients[step]);
    const selectedIngredientsCount = ingredients.reduce((total, ingredient) => {
      const quantity = ingredient.quantity || 1;

      return total + quantity;
    }, 0);

    const maxRestriction = selectedIngredientsCount > maxIngredients;
    const baseCategoriesAreSelected = allBaseCategoriesAreSelected(selectedIngredients[step]);

    return !baseCategoriesAreSelected || selectedIngredientsCount < minIngredients || maxRestriction;
  }
);

export const getMenuSauces = createSelector(menuSelector, menu =>
  menu?.reduce((result, menuItem) => {
    if (menuItem.menuable.category === PRODUCT_CATEGORIES.SAUCE) {
      result.push(menuItem.menuable);
    }

    return result;
  }, [])
);

export const getCurrentPizzaImg = createSelector(
  currentStepPizzaIdSelector,
  menuSelector,
  byopSelector,
  (pizzaId, menu, byop) => {
    if (byop?.menuableId === pizzaId) {
      return {
        type: PICTURE_TYPES.icon,
        icon: BYOP_ICON
      };
    }
    const pizzaData = menu.find(menuItem => menuItem.menuableId === pizzaId);
    const imgLink = pizzaData?.menuable?.pictures?.find(pic => pic.category === PHOTO_APP)?.url;

    if (imgLink) {
      return {
        type: PICTURE_TYPES.photo,
        link: imgLink
      };
    }

    return null;
  }
);

export const getCurrentPizzaSmallThumbnail = createSelector(
  currentStepPizzaIdSelector,
  menuSelector,
  byopSelector,
  (pizzaId, menu, byop) => {
    if (byop?.menuableId === pizzaId) {
      return {
        type: PICTURE_TYPES.icon,
        icon: BYOP_ICON
      };
    }
    const pizzaData = menu.find(menuItem => menuItem.menuableId === pizzaId);
    const imgLink = pizzaData?.menuable?.pictures?.find(pic => pic.category === PHOTO_APP)?.thumbnails?.small;

    if (imgLink) {
      return {
        type: PICTURE_TYPES.photo,
        link: imgLink
      };
    }

    return null;
  }
);

export const offerItemsSelectedSelector = createSelector(
  priceItemsSelector,
  stepsSelector,
  offerSelector,
  (items, steps, offer) =>
    steps.reduce((stepsArr, step, index) => {
      if (step.isCompleted && step.isClicked) {
        const item = items?.find(({ offer_group_id: id }) => id === step.id);
        if (!item) {
          return stepsArr;
        }
        const variantSelected = offer?.groups[index]?.productOfferGroups.find(
          // eslint-disable-next-line camelcase
          variant => variant.menuableId === item?.menuable_id
        );

        const completedItem = {
          ...item,
          stepId: step.stepId,
          isPizzaStep: step.isPizzaStep,
          variantDelta: variantSelected?.delta,
          isClicked: step.isClicked
        };
        stepsArr.push(completedItem);
      }
      return stepsArr;
    }, [])
);
