/* eslint-disable max-lines */
import moment from 'moment';
import timezone from 'moment-timezone';
import { t } from 'i18next';

import { deserializer } from '~services/baseSerializers';

export const DAY_ENDS = '2000-01-01T23:59:59.999Z';
export const DAY_STARTS = '2000-01-01T00:00:00.000Z';
export const TIMER_SECOND = 1_000;
export const TIMER_MINUTE = TIMER_SECOND * 60;
export const TIMER_HOUR = TIMER_MINUTE * 60;
export const TIMER_DAY = TIMER_HOUR * 24;
export const TIMER_ENDED = ' 00:00:00';
export const TEXT_DAY = 'día';
export const TEXT_DAYS = 'días';

export const DAYS_OF_THE_WEEK = {
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday'
};

const TIME_ZONE = process.env.REACT_APP_TIME_ZONE;

export const DAYS = Object.values(DAYS_OF_THE_WEEK).map(day => day);

export const dateToTime = date => moment.utc(date).format('HH:mm');

export const getDay = day =>
  ({
    [DAYS_OF_THE_WEEK.MONDAY]: t('StoreDetail:monday'),
    [DAYS_OF_THE_WEEK.TUESDAY]: t('StoreDetail:tuesday'),
    [DAYS_OF_THE_WEEK.WEDNESDAY]: t('StoreDetail:wednesday'),
    [DAYS_OF_THE_WEEK.THURSDAY]: t('StoreDetail:thursday'),
    [DAYS_OF_THE_WEEK.FRIDAY]: t('StoreDetail:friday'),
    [DAYS_OF_THE_WEEK.SATURDAY]: t('StoreDetail:saturday'),
    [DAYS_OF_THE_WEEK.SUNDAY]: t('StoreDetail:sunday')
  }[day]);

export const getTodayString = locale =>
  moment()
    .locale(locale || '')
    .format('dddd')
    .toLowerCase();

const getRange = (opening, closing) =>
  opening === closing ? t('StoreDetail:allDay') : `${dateToTime(opening)} - ${dateToTime(closing)}`;

export const mergeSchedules = schedules => {
  const sortedSchedules = [...schedules].sort((a, b) => a.position - b.position);

  const allSchedules = sortedSchedules?.reduce((newSchedules, entry) => {
    const currentEntry = newSchedules.find(_entry => _entry?.startDay === getDay(entry.day));
    const range = getRange(entry.openingTime, entry.closingTime);
    let newEntry = null;

    if (currentEntry) {
      currentEntry.range = `${currentEntry.range}, ${range}`;
    } else {
      newEntry = {
        range,
        day: entry.day,
        startDay: getDay(entry.day)
      };
    }

    newSchedules.push(newEntry);

    return newSchedules;
  }, []);

  return allSchedules.filter(Boolean);
};

export const normalizeBusinessHours = (data, waitTime, rawRanges = false) =>
  data?.reduce((businessHours, { day, id, openingTime, closingTime, dispatchMethod }) => {
    const currentDayIndex = DAYS.indexOf(day);
    const newEntries = businessHours?.[dispatchMethod] || [];
    const nextDayFirstEntry = data.find(
      entry =>
        DAYS.indexOf(entry.day) === currentDayIndex + 1 &&
        entry.openingTime === DAY_STARTS &&
        entry.dispatchMethod === dispatchMethod
    );
    const newClosingTime =
      closingTime === DAY_ENDS && nextDayFirstEntry?.openingTime === DAY_STARTS
        ? nextDayFirstEntry.closingTime
        : closingTime;

    const isDayEnds = newClosingTime === DAY_ENDS ? DAY_STARTS : newClosingTime;

    const newEntry = {
      id,
      day,
      openingTime,
      closingTime: rawRanges ? closingTime : isDayEnds,
      position: currentDayIndex
    };

    if ((openingTime === DAY_STARTS && closingTime === DAY_ENDS) || openingTime !== DAY_STARTS || rawRanges) {
      newEntries.push(newEntry);
    }

    businessHours[dispatchMethod] = newEntries;
    return businessHours;
  }, {});

export const formatDate = date => moment(date).format('DD/MM/YYYY');

export const buildDateES = date => {
  const verifiedDate = new Date(date);
  return `${verifiedDate.getDate()} de ${verifiedDate.toLocaleDateString('es-ES', {
    month: 'long'
  })} de ${verifiedDate.getFullYear()}`;
};

export const formatDateTimestamp = date => timezone.tz(date, TIME_ZONE).format('DD/MM/YYYY, HH:mm');

export const currentTimestamp = () => moment().utc(Date.now());

export const businessHoursDelivery = (data, waitTime, pickUp) => {
  const serializedHours = data && deserializer.serialize(normalizeBusinessHours(data, waitTime, true));
  const weekBusinessHours = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  };
  serializedHours?.[pickUp ? 'inStore' : 'pjDelivery']?.map(item =>
    weekBusinessHours[item.day].push({
      id: item.id,
      openingTime: item.openingTime,
      closingTime: item.closingTime
    })
  );
  return weekBusinessHours;
};

export const dayFormat = stringDateFormat => {
  const date = moment(stringDateFormat);
  return `${getDay(date.format('dddd').toLowerCase())}.${date.format('DD/MM')}`;
};

const RANGE = 10;

export const timeRangeFormat = stringDateFormat => {
  const date = moment(stringDateFormat);
  return `${date.format('LT')} - ${date.add(RANGE, 'minutes').format('LT')}`.toLowerCase();
};

const dateToShowFormat = (date, day, number) =>
  ({
    0: t('SelectTimeDispatch:today'),
    1: t('SelectTimeDispatch:tomorrow')
  }[number] || `${getDay(day)} ${date.format('DD/MM')}`);

const LAST_HOUR = 24;
const COUNT_DAYS_WEEK = 7;
const QUARTER_AFTER_HOUR = 15;
const QUARTER_TILL_HOUR = 45;

const normalizeTime = ({ date, isOpeningTime }) => {
  let minutes = date.getMinutes();
  let hours = date.getHours();
  if (minutes === QUARTER_AFTER_HOUR) {
    minutes = isOpeningTime ? RANGE : 0;
  }
  if (minutes === QUARTER_TILL_HOUR) {
    hours += isOpeningTime ? 1 : 0;
    minutes = isOpeningTime ? 0 : RANGE;
  }
  date.setHours(hours);
  date.setMinutes(minutes);
};

const validEndTime = (endTime, limitPermited) => {
  const endTimeHours = endTime.hours() === 0 ? LAST_HOUR : endTime.hours();
  const isSameHour =
    endTimeHours === limitPermited.hours() ? endTime.minutes() <= limitPermited.minutes() : false;
  return endTimeHours >= limitPermited.hours() ? isSameHour : true;
};

const validStartTime = (startTime, waitTimeOffset) => {
  const isSameHour =
    startTime.hours() === waitTimeOffset.hours() ? startTime.minutes() >= waitTimeOffset.minutes() : false;
  return startTime.hours() > waitTimeOffset.hours() ? true : isSameHour;
};

const getDeliveryRanges = ({
  businessHours,
  waitTimeOffset,
  hoursPermited,
  limitPermited,
  deliveryWaitTime
}) =>
  businessHours.reduce((ranges, { openingTime, closingTime }) => {
    const openingTimeNormalize = new Date(openingTime);
    normalizeTime({ date: openingTimeNormalize, isOpeningTime: true });

    const closingTimeNormalize = new Date(closingTime);
    normalizeTime({ date: closingTimeNormalize });

    const openingTimeHours = new Date(openingTimeNormalize).getUTCHours();

    const closingTimeHours = closingTime === DAY_ENDS ? LAST_HOUR : closingTimeNormalize.getUTCHours();
    const lastHourPermited = closingTimeHours > hoursPermited ? hoursPermited : closingTimeHours;

    if (lastHourPermited > openingTimeHours) {
      Array(Math.ceil(moment(closingTimeNormalize).diff(moment(openingTimeNormalize), 'minutes') / RANGE))
        .fill()
        // eslint-disable-next-line array-callback-return
        .map((_, index) => {
          const startTime = moment(openingTimeNormalize)
            .utc()
            .add(RANGE * index, 'minutes')
            .add(deliveryWaitTime, 'minutes');

          const endTime = moment(openingTimeNormalize)
            .utc()
            .add(RANGE * (index + 1), 'minutes')
            .add(deliveryWaitTime, 'minutes');

          if (
            (waitTimeOffset ? validStartTime(startTime, waitTimeOffset) : true) &&
            (hoursPermited === LAST_HOUR || validEndTime(endTime, limitPermited)) &&
            (moment(closingTime).utc().diff(endTime) >= 0 ||
              (closingTime === DAY_ENDS && endTime.hours() === 0 && endTime.minutes() === 0))
          ) {
            ranges.push({
              start: startTime,
              end: endTime,
              value: index,
              name: `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`.toLowerCase()
            });
          }
        });
    }
    return ranges;
  }, []);

export const getDaysRange = (hoursAmount, deliveryWaitTime, businessHours) => {
  const dateNow = moment();
  const limitPermited = moment().add(hoursAmount, 'hours');
  const normalizeDay =
    limitPermited.day() < dateNow.day() ? limitPermited.day() + COUNT_DAYS_WEEK : limitPermited.day();
  const daysDiff =
    normalizeDay - dateNow.day() === 0 && limitPermited.diff(dateNow, 'days') > 0 ? COUNT_DAYS_WEEK : 0;

  const daysRange = Array(Math.max(normalizeDay - dateNow.day(), daysDiff) + 1)
    .fill()
    .reduce((days, _, index) => {
      const date = moment().add(index, 'days');
      const day = date.format('dddd').toLowerCase();
      const diffDays = limitPermited.day() - date.day();
      const diffHours = Math.floor(moment.duration(limitPermited.diff(date)).asHours());

      const isSameDay = diffHours > LAST_HOUR ? LAST_HOUR : limitPermited.hours();
      const hoursPermited = diffDays === 0 ? isSameDay : LAST_HOUR;
      days.push({
        date,
        id: `${date}`,
        text: dateToShowFormat(date, day, index),
        deliveryRanges: getDeliveryRanges({
          businessHours: businessHours[day],
          waitTimeOffset: index === 0 ? date.minute(date.minute() + deliveryWaitTime) : null,
          hoursPermited,
          limitPermited,
          deliveryWaitTime
        })
      });
      return days;
    }, []);
  return daysRange.filter(item => !!item.deliveryRanges.length);
};

export const validateTimeRange = (startTime, endTime) => {
  const startTimeParse = moment(startTime, 'HH:mm');
  const endTimeParse = moment(endTime, 'HH:mm');
  const rangeTimeParse = moment(timezone.tz(TIME_ZONE)).format('HH:mm');
  return moment(rangeTimeParse, 'HH:mm').isBetween(startTimeParse, endTimeParse);
};

export const getMinutesDifference = (startTime, endTime) => {
  const start = moment(startTime);
  const end = moment(endTime);
  return moment.duration(start.diff(end)).asMinutes();
};

export const getDateValue = date => moment(date).valueOf();

export const getTimeToFinish = timespan => {
  const days = Math.floor(timespan / TIMER_DAY);
  const hours = Math.floor((timespan / TIMER_HOUR) % 24);
  const minutes = Math.floor((timespan / TIMER_MINUTE) % 60);
  const seconds = Math.floor((timespan / TIMER_SECOND) % 60);
  const daysToFinish = `${days} ${days === 1 ? TEXT_DAY : TEXT_DAYS}`;

  const daysUntil = days > 0 ? daysToFinish : '';
  const hoursUntil = hours.toString().padStart(2, '0');
  const minutesUntil = minutes.toString().padStart(2, '0');
  const secondsUntil = seconds.toString().padStart(2, '0');

  const hoursToFinish = `${hoursUntil}:${minutesUntil}:${secondsUntil}`;

  return ` ${daysUntil} ${hoursToFinish}`;
};

export function formatTimeToMinutesAndSeconds(time) {
  const minutes = `${Math.floor(time / 60)}`.padStart(2, '0');
  const seconds = `${time - minutes * 60}`.padStart(2, '0');
  return `${minutes}:${seconds}`;
}
