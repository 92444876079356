/* eslint-disable id-length */
import { t } from 'i18next';

import { PRODUCT_CATEGORIES } from '~screens/Dashboard/screens/Home/components/Menu/constants';

export const MENU_TARGET = 'menu';
export const FLATTENED_MENU_TARGET = 'flattenedMenu';
export const SHOPPING_CART_TARGET = 'shoppingCart';
export const OPTIMIZED_SHOPPING_CART_TARGET = 'optimizedShoppingCart';
export const FIX_NAV_TARGET = 'shouldFixNav';
export const MENU_HELPERS = [PRODUCT_CATEGORIES.BYOP, PRODUCT_CATEGORIES.BY_HALVES];
export const CURRENT_ORDER_TARGET = 'currentOrder';
export const ACTIVE_ORDERS_TARGET = 'activeOrders';
export const COMBOS_TARGET = 'combos';
export const FLATTENED_COMBOS_TARGET = 'flattenedCombos';
export const SUGGESTIVE_SELLS_TARGET = 'suggestiveSells';
export const OFFER_COMPLETIONS_TARGET = 'offerCompletions';
export const OFFER_TARGET = 'offer';
export const REDEEMED_CODE_TARGET = 'coupon';
export const BANNER_IMAGES_TARGET = 'bannerImages';
export const CAN_ACCESS_CHECKOUT_TARGET = 'canAccessCheckout';
export const EXTERNAL_OFFER_TARGET = 'externalOffer';
export const GIFTCARD_OVER_SUBTOTAL_TARGET = 'giftcardAmmountIsOverSubtotal';
export const PAYMENT_CARDS_TARGET = 'paymentCardMethods';
export const ADD_PAYMENT_CARD_TARGET = 'addPaymentCard';
export const DELETE_PAYMENT_CARD_TARGET = 'deletePaymentCard';
export const AGE_VERIFICATION = 'ageVerification';
export const PROMOTED_PRODUCTS = 'promotedProducts';
export const FILTERED_MENU = 'filteredMenu';
export const CATALAN_LANGUAGE_ACTIVATED = 'catalanLanguageActivated';
export const IS_GUEST_CARD_TARGET = 'isGuestCard';
export const GUEST_PAYMENT_CARD_TARGET = 'guestPaymentCard';

// REDEEM CODE ERRORS
// COUPON
export const INVALID_COUPON_ERROR = 'INVALID_COUPON_CODE';
export const INVALID_CATEGORY_COUPON = 'INVALID_CATEGORY_COUPON';
export const SELF_INVITATION_CODE = 'SELF_INVITATION_CODE';
export const NOT_FIRST_PURCHASE = 'NOT_FIRST_PURCHASE';
export const COUPON_DISPATCH_METHOD = 'COUPON_DISPATCH_METHOD';
export const COUPON_EXPIRED = 'COUPON_EXPIRED';
export const COUPON_NOT_AVAILABLE = 'COUPON_NOT_AVAILABLE';
export const MAX_COUPON_USES_BY_USER_REACHED = 'MAX_COUPON_USES_BY_USER_REACHED';
export const COUPON_PLATFORM_ERROR = 'COUPON_PLATFORM_ERROR';
export const COUPON_MAX_REACHED = 'COUPON_MAX_REACHED';
export const COUPON_CANNOT_BE_USED_BY_USER = 'COUPON_CANNOT_BE_USED_BY_USER';
export const COUPON_WITH_MINIMUM_BILL = 'COUPON_WITH_MINIMUM_BILL';
export const COUPON_OFFER_NOT_PRESENT = 'COUPON_OFFER_NOT_PRESENT';
export const CART_INCLUDE_ONLY_OFFERS = 'CART_INCLUDE_ONLY_OFFERS';
export const COUPON_PAYMENT_METHOD_NOT_AVAILABLE = 'COUPON_PAYMENT_METHOD_NOT_AVAILABLE';
export const USER_ALREADY_DID_FIRST_PURCHASE = 'USER_ALREADY_DID_FIRST_PURCHASE';
export const INVALID_MINIMUM_START_DATE = 'INVALID_MINIMUM_START_DATE';
export const INVALID_MINIMUM_START_TIME = 'INVALID_MINIMUM_START_TIME';
export const INVALID_MAXIMUM_EXPIRY_DATE = 'INVALID_MAXIMUM_EXPIRY_DATE';
export const INVALID_MAXIMUM_EXPIRY_TIME = 'INVALID_MAXIMUM_EXPIRY_TIME';
export const INVALID_COUPON_AVAILABILITY_DAYS = 'INVALID_COUPON_AVAILABILITY_DAYS';
export const INVALID_OFFER_ON_COUPON = 'INVALID_OFFER_ON_COUPON';
export const INVALID_STORE_GROUP_ON_COUPON = 'INVALID_STORE_GROUP_ON_COUPON';
export const INVALID_VARIANT_ON_COUPON = 'INVALID_VARIANT_ON_COUPON';
export const COUPON_INVALID_FOR_ANY_PRODUCT = 'COUPON_INVALID_FOR_ANY_PRODUCT';
export const COUPON_VALID_FOR_REGISTERED_USERS = 'COUPON_VALID_FOR_REGISTERED_USERS';
export const COUPON_VALID_FOR_GUEST_USERS = 'COUPON_VALID_FOR_GUEST_USERS';
export const INVALID_CATEGORY_COUPON_V2 = 'INVALID_CATEGORY_COUPON_V2';

// GIFT CARD
export const GIFT_CARD_CODE_INVALID = 'GIFT_CARD_CODE_INVALID';
export const GIFT_CARD_ZERO_AMOUNT = 'GIFT_CARD_ZERO_AMOUNT';
export const GIFT_CARD_EXPIRATED = 'GIFT_CARD_EXPIRATED';
export const GIFT_CARD_WITH_OFFERS = 'GIFT_CARD_WITH_OFFERS';

export const ORDER_RULE = 'orderRule';

export const ORDER_SETTINGS_TARGET = 'orderSettings';
export const CORE_SETTINGS_TARGET = 'coreSettings';
export const COUPON_KIND = {
  GIFTCARD: 'giftcard'
};
export const ONECLICK_PARAMS = 'oneclickParams';

export const IS_DEFAULT_STORE = 'isDefaultStore';

export const INITIAL_TIP = 0;
export const TIP_TARGET = 'tip';

export const BANNER_IMAGE_TYPES = {
  web: 'web',
  responsive: 'web_responsive'
};

export const INVALID_CODE_DETAILS_ERRORS = {
  'Max used of coupon reached': 'couponUsedMessage',
  'Coupon was used by the user': 'couponUsedMessage',
  'Has expired': 'couponExpiredMessage',
  'This coupon code is not valid yet': 'couponInvalidMessage',
  'This coupon is not available to the selected dispatch method': 'couponInvalidMessage',
  'Invalid code': 'couponInvalidMessage',
  '': 'couponInvalidMessage',
  [INVALID_COUPON_ERROR]: 'couponInvalidMessage',
  [COUPON_NOT_AVAILABLE]: 'couponNotAvailable',
  [NOT_FIRST_PURCHASE]: 'notFirstPurchaseMessage',
  [GIFT_CARD_ZERO_AMOUNT]: 'giftCardZeroAmount',
  [GIFT_CARD_CODE_INVALID]: 'giftCardCodeInvalid',
  [GIFT_CARD_EXPIRATED]: 'giftCardExpirated',
  [COUPON_DISPATCH_METHOD]: 'couponDispatchMethod',
  [COUPON_EXPIRED]: 'couponExpired',
  [MAX_COUPON_USES_BY_USER_REACHED]: 'maxCouponUsesByUserReached',
  [COUPON_PLATFORM_ERROR]: 'couponPlatformError',
  [COUPON_MAX_REACHED]: 'couponMaxReached',
  [COUPON_CANNOT_BE_USED_BY_USER]: 'couponCannotBeUsedByUser',
  [COUPON_WITH_MINIMUM_BILL]: 'couponWithMinimumBill',
  [COUPON_OFFER_NOT_PRESENT]: 'couponOfferNotPresent',
  [CART_INCLUDE_ONLY_OFFERS]: 'cartIncludeOnlyOffers',
  [COUPON_PAYMENT_METHOD_NOT_AVAILABLE]: 'couponPaymentMethodNotAvailable',
  [GIFT_CARD_WITH_OFFERS]: 'giftCardWithOffers',
  [USER_ALREADY_DID_FIRST_PURCHASE]: 'userAlreadyDidFirstPurchase',
  [INVALID_MINIMUM_START_DATE]: 'invalidMinimumStartDate',
  [INVALID_MINIMUM_START_TIME]: 'invalidMinimumStartTime',
  [INVALID_MAXIMUM_EXPIRY_DATE]: 'invalidMaximumExpiryDate',
  [INVALID_MAXIMUM_EXPIRY_TIME]: 'invalidMaximumExpiryTime',
  [INVALID_COUPON_AVAILABILITY_DAYS]: 'invalidCouponAvailabilityDays',
  [INVALID_OFFER_ON_COUPON]: 'invalidOfferOnCoupon',
  [INVALID_STORE_GROUP_ON_COUPON]: 'invalidStoreGroupOnCoupon',
  [INVALID_VARIANT_ON_COUPON]: 'invalidVariantOnCoupon',
  [COUPON_INVALID_FOR_ANY_PRODUCT]: 'couponInvalidForAnyProduct',
  [COUPON_VALID_FOR_REGISTERED_USERS]: 'couponValidForRegisteredUsers',
  [COUPON_VALID_FOR_GUEST_USERS]: 'couponValidForGuestUsers',
  [INVALID_CATEGORY_COUPON]: 'INVALID_CATEGORY_COUPON',
  [INVALID_CATEGORY_COUPON_V2]: 'invalidCategoryCouponV2'
};

export const ERROR_PLATFORM_TYPES = {
  app: {
    value: 'value',
    label: t('Home:app')
  },
  web: {
    value: 'web',
    label: t('Home:web')
  }
};

export const ERROR_METADATA_TYPES = {
  available: 'available',
  value: 'value'
};

export const PAYMENT_CARDS_ERRORS = {
  taken: 'paymentCardTaken'
};

export const ONECLICK_ADD_CARD_PARAM = 'TBK_TOKEN';

export const ERROR_RULE_NOT_COMPLETED = 'RULE_NOT_COMPLETED';
export const ORDER_DATA_CONSUMING_TARGET = 'orderDataForConsuming';
export const SUBMIT_ELECTRONIC_RECEIPT = 'submitElectronicReceipt';
export const DISABLED_ITEMS = 'DISABLED_ITEMS';

export const REDEEM_CODE_ERRORS = [
  INVALID_COUPON_ERROR,
  INVALID_CATEGORY_COUPON,
  SELF_INVITATION_CODE,
  NOT_FIRST_PURCHASE,
  COUPON_DISPATCH_METHOD,
  COUPON_EXPIRED,
  COUPON_PLATFORM_ERROR,
  COUPON_MAX_REACHED,
  MAX_COUPON_USES_BY_USER_REACHED,
  GIFT_CARD_CODE_INVALID,
  GIFT_CARD_ZERO_AMOUNT,
  GIFT_CARD_EXPIRATED,
  COUPON_NOT_AVAILABLE,
  COUPON_CANNOT_BE_USED_BY_USER,
  COUPON_WITH_MINIMUM_BILL,
  COUPON_OFFER_NOT_PRESENT,
  CART_INCLUDE_ONLY_OFFERS,
  COUPON_PAYMENT_METHOD_NOT_AVAILABLE,
  GIFT_CARD_WITH_OFFERS,
  USER_ALREADY_DID_FIRST_PURCHASE,
  INVALID_MINIMUM_START_DATE,
  INVALID_MINIMUM_START_TIME,
  INVALID_MAXIMUM_EXPIRY_DATE,
  INVALID_MAXIMUM_EXPIRY_TIME,
  INVALID_COUPON_AVAILABILITY_DAYS,
  INVALID_OFFER_ON_COUPON,
  INVALID_STORE_GROUP_ON_COUPON,
  INVALID_VARIANT_ON_COUPON,
  COUPON_INVALID_FOR_ANY_PRODUCT,
  COUPON_VALID_FOR_REGISTERED_USERS,
  COUPON_VALID_FOR_GUEST_USERS,
  INVALID_CATEGORY_COUPON_V2
];
