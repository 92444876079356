import Immutable from 'seamless-immutable';
import { createReducer } from 'redux-recompose';

import { actions } from './actions';
import { NOTIFICATION_DANGER } from './constants';

const initialState = { message: null, type: NOTIFICATION_DANGER };

const reducerDescription = {
  [actions.SHOW]: (state, action) => state.merge(action.payload),
  [actions.CLOSE]: state => state.merge({ message: null })
};

const reducer = createReducer(new Immutable(initialState), reducerDescription);

export default reducer;
