/* eslint-disable max-params */
import { isArray } from 'cerealizr/build/utils';
import { compose } from 'redux';

import PriceService from '~services/Price/service';
import { deserializer } from '~services/baseSerializers';
import { flattenCombos, flattenMenu } from '~utils/menu';
import {
  menuItemsAreEqual,
  menuVariantAreSamePizza,
  offersAreEqual,
  menuItemsWithPoints
} from '~utils/menuItem';

import { addAtIndex, removeByIndex } from './array';

const FIRST_ELEMENT = 0;

const ONE_COMBO = 1;

const RULE = 'Rule';

const removeRulesFromCart = items =>
  items.map(item => ({
    ...item,
    ruleId: undefined,
    appliedPromos: item.appliedPromos?.[0]?.type === RULE ? [] : item.appliedPromos
  }));

const mergeCommonItems = items => {
  const commonItemsReducer = (result, item) => {
    const resultTarget = result[item.menuableId];
    if (resultTarget && !resultTarget?.appliedPromos.length) {
      result[item.menuableId].quantity++;
    } else {
      result[item.menuableId] = item;
    }

    return result;
  };

  return Object.values(items.reduce(commonItemsReducer, {}));
};

export const ID_TYPES = {
  id: 'id',
  menuable: 'menuableId',
  offer: 'offerId'
};

export const checkSelectedItem = item => ({
  ...item,
  ...(item.pointsSpent > 0 && { withLoyalty: true })
});

// eslint-disable-next-line max-params
const mergeCartItem = ({
  selectedItems,
  newItem,
  quantityDiff,
  atIndex = selectedItems.length,
  canReplace,
  onRemoveItem,
  replaceCartQuantity = false
}) => {
  let newSelectedItems = null;
  const existingItemIndex = selectedItems.findIndex(item =>
    newItem?.offerType
      ? offersAreEqual(item, newItem)
      : menuItemsAreEqual(item, newItem) || menuItemsWithPoints(item, newItem)
  );

  if (existingItemIndex === -1) {
    if (quantityDiff > 0) {
      const replaceItem = replaceCartQuantity || canReplace;
      const selectedItemsCopy = [...selectedItems];
      selectedItemsCopy.splice(atIndex, replaceItem, { ...newItem, quantity: quantityDiff });
      return selectedItemsCopy;
    }

    // Should never happen, there's no way to remove an item that doesn't exist in the cart
    return selectedItems;
  }

  if (selectedItems[existingItemIndex].quantity + quantityDiff === 0) {
    onRemoveItem?.();
    newSelectedItems = removeByIndex(selectedItems, existingItemIndex);
  } else {
    const newQuantity = selectedItems[existingItemIndex].quantity + quantityDiff;
    newSelectedItems = addAtIndex(selectedItems, existingItemIndex, {
      quantity: replaceCartQuantity ? quantityDiff : newQuantity
    });
  }
  return newSelectedItems;
};

export const addCartItem = (
  selectedItems,
  newItem,
  atIndex,
  canReplace,
  quantity,
  replaceCartQuantity = false
) =>
  mergeCartItem({
    selectedItems,
    newItem,
    quantityDiff: quantity || 1,
    atIndex,
    canReplace,
    replaceCartQuantity
  });

export const removeCartItem = (selectedItems, newItem, atIndex, onRemoveItem) =>
  mergeCartItem({ selectedItems, newItem, quantityDiff: -1, atIndex, onRemoveItem });

export const removeAllWithValue = (items, ids, key) => {
  const parsedIds = isArray(ids) ? ids : [ids];

  if (!key) {
    return items.filter(item => !parsedIds.includes(item[ID_TYPES.menuable] || item[ID_TYPES.offer]));
  }

  return items.filter(item => !parsedIds.includes(item[key]));
};

export const findItemQuantityOnCart = (shoppingCart, menuItem) => {
  let quantity = 0;

  shoppingCart.items.forEach(item => {
    if (menuItem.variants) {
      quantity = menuItem.variants.reduce(
        (result, v) => (menuVariantAreSamePizza(v, item) ? result + item.quantity : result),
        quantity
      );
    } else {
      quantity = menuVariantAreSamePizza(item, menuItem) ? quantity + item.quantity : quantity;
    }
  });

  return quantity;
};

export const getMissingCartItems = async ({ storeId, dispatchMethod, offerId }, cartItems) => {
  const { menu, combos } = await PriceService.getPrices({ storeId, dispatchMethod, offerId });
  const foundMenuItems = [];
  const foundComboItems = [];
  const missingMenuItems = [];
  const missingComboItems = [];

  if (menu?.ok) {
    const flattenedMenu = flattenMenu(deserializer.serialize(menu).data);
    cartItems.forEach(item => {
      const UID = `${item.menuableType}${item.menuableId}`;

      if (!item.offerType && !item.offerId && !item.isGiftCard) {
        if (flattenedMenu[UID]) {
          const newItem = {
            ...flattenedMenu[UID].data,
            quantity: item.quantity,
            price: item.price,
            variant: item.halves ? item.name : item.variantName || item.variant
          };

          if (item.side) {
            newItem.side = item.side;
          }
          if (item.ingredients) {
            newItem.ingredients = item.ingredients;
          }
          if (item.halves) {
            newItem.halves = item.halves;
          }

          foundMenuItems.push(newItem);

          // foundMenuItems.push({
          //   ...flattenedMenu[UID].data,
          //   quantity: item.quantity,
          //   side: item.side,
          //   ingredients: item.ingredients,
          //   halves: item.halves
          // });
        } else {
          missingMenuItems.push(item);
        }
      }
    });
  }

  if (combos?.ok) {
    const flattenedCombos = flattenCombos(deserializer.serialize(combos).data);

    cartItems.forEach(item => {
      const UID = `${item.offerType}${item.offerId}`;

      if (item.offerType && item.offerId) {
        if (flattenedCombos[UID]) {
          foundComboItems.push({
            description: item.description,
            offerId: item.offerId,
            offerType: item.offerType,
            quantity: item.quantity,
            groupProducts: item.groupProducts,
            name: item.name,
            id: item.id,
            price: item.price
          });
        } else {
          missingComboItems.push(item);
        }
      }
    });
  }

  return {
    missing: [...missingMenuItems, ...missingComboItems],
    found: [...foundMenuItems, ...foundComboItems]
  };
};

export const manageUsedGroups = (offerCompletions, cartItems) => {
  let newCartItems = cartItems;
  let usedGroupProducts = [];
  offerCompletions?.usedGroups.forEach(usedGroup => {
    usedGroup?.matchingItems.every(matchingItem => {
      const itemFound = newCartItems.find(item => item.menuableId === matchingItem.menuableId);
      if (itemFound) {
        newCartItems = removeCartItem(newCartItems, itemFound);
        usedGroupProducts = [...usedGroupProducts, { ...itemFound, offerGroupId: usedGroup.offerGroup.id }];
        return false;
      }
      return true;
    });
  });
  return { usedGroupProducts, newCartItems };
};

const manageRemainingGroups = ({ remainingGroup }) => ({
  ...remainingGroup.matchingItems[FIRST_ELEMENT],
  offerGroupId: remainingGroup.group.id
});

export const replaceProductsForCombo = (offerCompletions, cartItems) => {
  const { usedGroupProducts, newCartItems } = manageUsedGroups(offerCompletions, cartItems);
  const remainingProduct = manageRemainingGroups(offerCompletions);
  const newComboBody = {
    offerId: offerCompletions.offer.id,
    offerType: offerCompletions.offer.offerType,
    quantity: ONE_COMBO,
    groupProducts: [...usedGroupProducts, remainingProduct]
  };
  return addCartItem(newCartItems, newComboBody);
};

export const itemHasRulesAdded = item =>
  item &&
  (item.ruleId ||
    (Array.isArray(item.appliedPromos) && item.appliedPromos.find(promo => promo.type === RULE)));

export const cartHasRuleApplied = shoppingCart => shoppingCart?.items.find(item => itemHasRulesAdded(item));

export const purgeCartItems = compose(mergeCommonItems, removeRulesFromCart);

export const getGiftCardTotalInCart = shoppingCart =>
  shoppingCart?.items
    .filter(item => item?.isGiftCard)
    .reduce((item1, item2) => (item1?.total || 0) + item2?.total, 0);

export const getTotal = shoppingCart => shoppingCart?.subtotal - shoppingCart?.discount;

export const getTotalWithoutGiftCards = shoppingCart =>
  getTotal(shoppingCart) - getGiftCardTotalInCart(shoppingCart);

export const hasACouponWithOverride = shoppingCart =>
  shoppingCart?.appliedPromos?.some(promo => promo?.appliedCoupon?.overrideMinimumPrice);
