import React from 'react';
import { t } from 'i18next';

import { DEFAULT_OG_OBJECT, APP_URL } from '~constants/seo';

export const completeOgObject = page => ({
  ...DEFAULT_OG_OBJECT,
  title: page.title,
  description: page.description,
  url: `${APP_URL}${page.path}`
});

export const getOgMetaTags = data => {
  const ogMetaTags = Object.keys(data).map(key => (
    <meta key={key} property={`og:${key}`} content={data[key]} />
  ));

  return ogMetaTags;
};

export const getMetaTags = data => {
  const ogMetaTags = Object.keys(data).map(key => <meta key={key} name={key} content={data[key]} />);
  return ogMetaTags;
};

export const getTitleAndMetaTags = store => {
  const htmlElements = [];
  const objTags = getMetaTags({
    title: t('StoresLocation:pageTitle', { store: store.name }),
    description: t('StoresLocation:pageDescription', {
      location: store.name,
      direction: store.textAddress
    }),
    keywords: `${t('StoreLanding:store')} ${store.name}`
  });
  htmlElements.push(<title>{t('StoresLocation:pageTitle', { store: store.name })}</title>);
  htmlElements.push(...objTags);
  return htmlElements;
};
