import { COUNTRIES, CURRENT_COUNTRY_ID } from '~constants/environment';
import Weglot from '~components/Weglot';
import { WEGLOT_API_KEY } from '~utils/scripts';

export const enabledCountries = [COUNTRIES.es];

export const isCountryAvaibleToShowWeglot = enabledCountries.includes(CURRENT_COUNTRY_ID);

export const weglotEnabler = mustShow => {
  if (mustShow && isCountryAvaibleToShowWeglot && WEGLOT_API_KEY.length > 0) {
    return <Weglot />;
  }
  return null;
};
