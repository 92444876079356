import { VALIDATIONS, buildValidation } from '~utils/inputValidations';
import { CLIENT_PAGES, CALL_CENTER_PAGES } from '~constants/pages';

import { FIELDS } from './constants';

export const validationSchema = buildValidation({
  [FIELDS.phone]: VALIDATIONS.phoneCreation
});

export const getRoute = callCenterUser => {
  const callCenterRoutes = {
    checkout: CALL_CENTER_PAGES.CHECKOUT.path,
    home: CALL_CENTER_PAGES.NEW_ORDER.path
  };

  const clientRoutes = {
    checkout: CLIENT_PAGES.CHECKOUT.path,
    home: CLIENT_PAGES.HOME.path
  };

  return callCenterUser ? callCenterRoutes : clientRoutes;
};
