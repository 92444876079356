import i18next from 'i18next';

import { SIZES } from '~screens/Dashboard/screens/Home/constants';

i18next.addResources('es_CL', 'MenuItem', {
  modify: 'Modificar',
  addToCart: 'Añadir al carrito',
  sizeOrVariantStartsFrom: '{{sizeOrVariant}}: ',
  freeItem: 'Gratis',
  chooseOneFree: 'Elige 1 gratis',
  discountInSize: '{{discount}}% OFF {{size}}',
  upToDiscount: '{{discount}}% OFF',
  [SIZES.INDIVIDUAL]: 'Individual',
  [SIZES.MEDIUM]: 'Mediana',
  [SIZES.FAMILY]: 'Familiar',
  [SIZES.EXTRA_LARGE]: 'XL',
  add: 'Añadir',
  discount: '{{discount}}% Off',
  onlyFirstProduct: ' en 1ª unidad',
  points: '{{points}} Papa Puntos',
  promoBuilderPrice: '+ {{price}}',
  nutritionalInfo: 'Información nutricional',
  grams: 'Gramos',
  calories: 'Calorías',
  howManyPeople: 'Alcanza para estas personas',
  mild: 'Ligeramente picante',
  spicy: 'Condimentado',
  hot: 'Picante',
  vegetarian: 'Vegetariano',
  vegan: 'Vegano',
  perPortion: 'p/ trozo',
  textOverlay: 'Para ver otros productos de esta sección debes ser mayor de edad.',
  validateAgeButton: 'Validar edad',
  infoAlcoholTime: 'Disponible entre',
  startsFrom: 'Desde ',
  exclusiveGold: 'Exclusivo Nivel Oro',
  exclusiveSilver: 'Exclusivo Nivel Plata',
  levelUpLoyaltyPromo: 'Sube de nivel y accede a esta promoción',
  addBy: 'Agregar por ',
  percentOfDiscount: '% de descuento',
  complementsWithVariants: 'Agregar desde '
});

i18next.addResources('es_ES', 'MenuItem', {
  spicy: 'Condimentado',
  addBy: 'Añadir por ',
  complementsWithVariants: 'Añadir desde '
});

i18next.addResources('es_CR', 'MenuItem', {
  spicy: 'Picante'
});

i18next.addResources('es_PA', 'MenuItem', {
  spicy: 'Picante'
});

i18next.addResources('es_GT', 'MenuItem', {
  spicy: 'Condimentado'
});

i18next.addResources('pt_PT', 'MenuItem', {
  modify: 'Modificar',
  addToCart: 'Adicionar ao Pedido',
  sizeOrVariantStartsFrom: '{{sizeOrVariant}}: ',
  freeItem: 'Gratuitamente',
  add: 'Adicionar',
  discount: '{{discount}}% Off',
  onlyFirstProduct: ' na 1ª unidade',
  points: '{{points}} Papa Pontos',
  nutritionalInfo: 'Informação nutricional',
  grams: 'Gramas',
  calories: 'Calorias',
  howManyPeople: 'Alcance essas pessoas',
  spicy: 'Temperado',
  perPortion: 'p/ porção',
  textOverlay: 'Para ver outros produtos nesta seção, você deve ser maior de idade.',
  validateAgeButton: 'Validar idade',
  infoAlcoholTime: 'Disponível entre',
  startsFrom: 'Desde os ',
  addBy: 'Adicionar por ',
  percentOfDiscount: '% de desconto',
  complementsWithVariants: 'Adicionar desde os '
});
