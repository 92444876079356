const LANGUAGES = {
  es: 'es',
  cl: 'es',
  pt: 'pt',
  pa: 'es',
  gt: 'es'
};

export const LANGUAGE = LANGUAGES[process.env.REACT_APP_ISO_COUNTRY];
export const REQUEST_OK_GOOGLE = 'OK';
export const ISO_COUNTRY = process.env.REACT_APP_ISO_COUNTRY;
