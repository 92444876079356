import api from '~config/api';
import { serializer as baseSerializer } from '~services/baseSerializers';

const PizzasService = {
  showPizza: ({ menuableId, storeId, dispatchMethod }) => {
    let extraProps = '?';
    if (storeId) {
      extraProps += `store_id=${storeId}`;
      if (dispatchMethod) {
        extraProps += `&dispatch_method=${dispatchMethod}`;
      }
    }
    return api.get(`/v1/pizzas/${menuableId}${extraProps}`);
  },
  showPizzas: async ({ pizzaIds, storeId, dispatchMethod }) => {
    const pizzaResponses = await Promise.all(
      pizzaIds.map(menuableId => PizzasService.showPizza({ menuableId, storeId, dispatchMethod }))
    );
    if (pizzaResponses.find(response => !response.ok)) {
      return { ok: false };
    }

    const data = pizzaResponses.reduce((result, { data: pizza }) => {
      result[pizza.id] = pizza;

      return result;
    }, {});

    return {
      ok: true,
      data
    };
  },
  parserPizza: ({ menuableId, storeId, menuableType, dispatchMethod }) => {
    const body = baseSerializer.serialize({
      customPrice: {
        menuableId,
        storeId,
        menuableType,
        dispatchMethod
      }
    });

    return api.post('/v1/price_parsers/custom_product', body);
  }
};

export default PizzasService;
