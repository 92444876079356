import { completeTypes, createTypes } from 'redux-recompose';

import SizesAndCrustsService from '~services/SizesAndCrusts/service';
import { deserializer } from '~services/baseSerializers';

import { SIZES_AND_CRUSTS_TARGETS } from './constants';

const completedTypes = completeTypes(['GET_SIZES_AND_CRUSTS']);

export const actions = createTypes(completedTypes, '@@SIZES_AND_CRUSTS');

export const actionCreators = {
  getSizesAndCrusts: () => ({
    type: actions.GET_SIZES_AND_CRUSTS,
    target: SIZES_AND_CRUSTS_TARGETS.SIZES_AND_CRUSTS,
    service: SizesAndCrustsService.getSizesAndCrusts,
    successSelector: response => deserializer.serialize(response.data)
  })
};
