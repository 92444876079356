import i18next from 'i18next';

i18next.addResources('es_CL', 'AgeVerificationModal', {
  title: '¿Eres mayor de edad?',
  subtitle: 'Por favor, para acceder a estos productos, primero debes confirmarnos que eres mayor de edad.',
  accept: 'Sí, soy mayor de 18 años',
  decline: 'No, soy menor de edad',
  logoAlt: 'Logo de mayor de edad',
  terms: 'Haz click aquí para conocer y aceptar nuestros ',
  termsLink: 'términos y condiciones.'
});

i18next.addResources('es_ES', 'AgeVerificationModal', {
  terms: 'Haz clic aquí para conocer y aceptar nuestros '
});

i18next.addResources('pt_PT', 'AgeVerificationModal', {
  title: 'É maior de idade?',
  subtitle: 'Por favor, para aceder a estes produtos, deves primeiro confirmar que és maior de idade',
  accept: 'Sim, tenho mais de 18 anos',
  decline: 'Não, eu sou menor',
  logoAlt: 'Logotipo da idade legal',
  terms: 'Clica aqui para ler e aceitar os nossos ',
  termsLink: 'termos e condições'
});
