import i18next from 'i18next';

i18next.addResources('es_CL', 'CallCenterNewOrder', {
  pageTitle: "Papa John's | Call Center",
  pageDescription: 'Dashboard para realización de pedidos a traves de llamadas.',
  namePlaceholder: 'Nombre completo i.e. Joan Watson',
  emailPlaceholder: 'cliente@mail.com',
  buttonSearch: 'Buscar',
  buttonSave: 'Guardar',
  buttonClear: 'Limpiar búsqueda',
  titleNewClient: 'Nuevo cliente',
  subtitleNewClient: 'Desde acá registra nuevos clientes',
  titleSearchClient: 'Buscar cliente',
  subtitleSearchClient: 'Buscar cliente por número de telefono',
  createNewCLient: 'CREAR NUEVO CLIENTE',
  nextStep: 'siguiente',
  prevStep: 'anterior',
  tabDeliveryMethod: 'Tipo de pedido',
  tabLastOrders: 'Historial de pedidos'
});
