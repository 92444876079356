import i18next from 'i18next';

i18next.addResources('es_CL', 'PizzasTutorial', {
  tutorial: 'Tutorial',
  subhead: 'Personalizar tu pedido es simple',
  skip: 'Saltar',
  start: 'Empezar',
  altTextImg1: 'Primer paso',
  text1: 'Ya sea quieras personalizar una pizza, editar una especialidad o armar un combo.',
  subText1: 'Sólo completa los pasos que se muestran.',
  altTextImg2: 'Segundo paso',
  text2: 'Posiciona el cursor sobre un ingrediente para ver más opciones',
  text2Responsive: 'Mantén presionado un ingrediente para ver más opciones',
  wholePizza: 'Toda la pizza',
  wholePizzaDouble: 'Cantidad doble',
  half: 'Sólo esa mitad',
  halfDouble: 'Esa mitad, doble',
  altTextImg3: 'Tercer paso',
  text3: 'Prueba distintas combinaciones, seguro hay algo que te gusta.',
  subText3: '¡Crea la pizza de tus sueños!'
});

i18next.addResources('es_ES', 'PizzasTutorial', {
  tutorial: 'Tutorial',
  subhead: 'Personalizar tu pedido es simple',
  skip: 'Saltar',
  start: 'Empezar',
  altTextImg1: 'Primer paso',
  text1: 'Ya sea quieras personalizar una pizza, editar una especialidad o armar un combo.',
  subText1: 'Sólo completa los pasos que se muestran.',
  altTextImg2: 'Segundo paso',
  text2: 'Posiciona el cursor sobre un ingrediente para ver más opciones',
  text2Responsive: 'Mantén presionado un ingrediente para ver más opciones',
  wholePizza: 'Toda la pizza',
  wholePizzaDouble: 'Cantidad doble',
  half: 'Sólo esa mitad',
  halfDouble: 'Esa mitad, doble',
  altTextImg3: 'Tercer paso',
  text3: 'Prueba distintas combinaciones, seguro hay algo que te gusta.',
  subText3: '¡Crea la pizza de tus sueños!'
});

i18next.addResources('pt_PT', 'PizzasTutorial', {
  tutorial: 'Tutorial',
  subhead: 'Personalizar o pedido é simples',
  skip: 'Próximo',
  start: 'Começar',
  altTextImg1: 'Primeiro passo',
  text1:
    'Seja personalizar uma pizza, alterar uma composição ou escolher um combo . É tão simples quanto seguir os passos do pedido.',
  subText1: 'Completa apenas os passos que se vêem.',
  altTextImg2: 'Segundo passo',
  text2: 'Faz click sobre o ingrediente desejado e mantêm-no para veres mais opções',
  text2Responsive: 'Faz click sobre o ingrediente desejado e mantêm-no para veres mais opçõess',
  wholePizza: 'Toda a pizza',
  wholePizzaDouble: 'Quantidade dupla',
  half: 'Apenas esta metade',
  halfDouble: 'Quant, dupla nesta metade',
  altTextImg3: 'Terceiro passo',
  text3: 'Compoem diferentes combinações, de certeza que haverá alguma que seja será do agrado.',
  subText3: '¡Cria a Pizza dos teus sonhos!'
});
