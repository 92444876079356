import i18next from 'i18next';

i18next.addResources('es_CL', 'ContactSupport', {
  problem: '¿No puedes acceder a tu correo? ',
  contactSupport: 'Contacta a soporte',
  help: ' para ayudarte a recuperar tu cuenta'
});

i18next.addResources('pt_PT', 'ContactSupport', {
  problem: 'Não consegues aceder ao teu e-mail? ',
  contactSupport: 'Contacta o suporte',
  help: ' para te ajudar a recuperar a tua conta.'
});
