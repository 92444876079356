import { createTypes, completeTypes } from 'redux-recompose';

import { LOYALTY_LEVELS } from '~models/loyalty';
import { deserializer } from '~services/SearchStore/serializers';
import PapaPointsService from '~services/PapaPoints/service';
import { actionCreators as homeActions } from '~screens/Dashboard/screens/Home/redux/actions';
import { sortArrayByOrder, SORT_KEYS } from '~utils/array';

import { LOYALTIES_TARGET, CURRENT_POINTS_TARGET, REDEEMS_TARGET, LOYALTY_LEVELS_TARGET } from './constants';
import { loyaltyEqualsMenuItem } from './utils';

export const actions = createTypes(
  completeTypes(['GET_LOYALTIES', 'SET_CURRENT_POINTS', 'GET_LOYALTIES_REDEEM', 'GET_LOYALTY_LEVELS']),
  '@@PAPA_POINTS'
);

const privateActionCreators = {
  getLoyalties: menu => ({
    type: actions.GET_LOYALTIES,
    target: LOYALTIES_TARGET,
    service: PapaPointsService.getLoyalties,
    successSelector: response => {
      // TODO: When backend implements this filter, remove this.
      const products = deserializer.serialize(response.data);

      return menu?.length > 0
        ? products.filter(product => menu.some(item => loyaltyEqualsMenuItem(product, item)))
        : products;
    }
  })
};

export const actionCreators = {
  getStoryLoyalties: () => ({
    type: actions.GET_LOYALTIES_REDEEM,
    target: REDEEMS_TARGET,
    service: PapaPointsService.getLoyaltiesRedeem,
    successSelector: response => deserializer.serialize(response.data)
  }),
  getLoyalties: () => (dispatch, getState) => {
    const state = getState();
    const { menu } = state.home;
    const { currentSubsidiary, dispatchType } = state.searchStore;

    // TODO: When backend implements this filter, remove this logic and just request loyalties.
    // This means the menu wasn't loaded yet, so I load it
    if (menu.length === 0 && currentSubsidiary?.id && dispatchType) {
      dispatch(
        homeActions.getMenu(currentSubsidiary.id, dispatchType, () => {
          const { menu: fetchedMenu } = getState().home;

          dispatch(privateActionCreators.getLoyalties(fetchedMenu));
        })
      );
    } else {
      // Menu was loaded, just get loyalties
      dispatch(privateActionCreators.getLoyalties(menu));
    }
  },
  setCurrentPoints: currentPoints => ({
    type: actions.SET_CURRENT_POINTS,
    target: CURRENT_POINTS_TARGET,
    payload: currentPoints > 0 ? currentPoints : 0
  }),
  getLoyaltyLevels: externalUserId => ({
    type: actions.GET_LOYALTY_LEVELS,
    service: PapaPointsService.getLoyaltyLevels,
    target: LOYALTY_LEVELS_TARGET,
    payload: { externalUserId },
    successSelector: response =>
      sortArrayByOrder(deserializer.serialize(response.data), SORT_KEYS.ASCENDINGLY, 'minPoints').map(
        level => ({ ...level, name: LOYALTY_LEVELS[level?.id] })
      )
  })
};
