import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

i18next.addResourceBundle(LANGUAGE_KEYS.CHILE, 'FreeProduct', {
  amountRequired: {
    start: '¡Agrega {{amount}} más en tu pedido para obtener un ',
    end: ' gratis!'
  },
  getFreeProduct: {
    start: '¡Tienes un ',
    end: ' gratis para tu pedido!'
  },
  addProduct: 'Agrégalo'
});

i18next.addResourceBundle(LANGUAGE_KEYS.PORTUGAL, 'FreeProduct', {
  amountRequired: {
    start: '¡Adicione mais {{amount}} à sua encomenda para obter um ',
    end: ' gratuito!'
  },
  getFreeProduct: {
    start: '¡Recebe um ',
    end: ' gratuito para a sua encomenda!'
  },
  addProduct: 'Adicioná-lo'
});
