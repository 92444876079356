export const CURRENT_USER_TARGET = 'currentUser';
export const ONBOARDING_USER_TARGET = 'onBoardingUser';
export const FORM_PASSWORD = 'password';
export const REGISTER_TARGET = 'register';
export const ADDRESS_TARGET = 'address';
export const CODE_TARGET = 'verification';
export const RESEND_SMS_TARGET = 'resendSMS';
export const RECOVER_PASSWORD_TARGET = 'recoverPassword';
export const VERIFY_RECOVER_PASSWORD_TARGET = 'recoverPasswordVerification';
export const GUEST_USER_TARGET = 'guestUser';
export const GUEST_USER_DATA_COOKIE = 'guestUserDataCookie';
export const X_GUEST_TOKEN = 'x-guest-token';
export const MAX_LAST_ADDRESSES = 4;
export const IS_PASSWORD_UPDATED = 'isPasswordUpdated';
export const IS_UPDATING_CURRENT_USER = 'updateCurrentUser';
export const UNCONFIRMED_USER_STATUS = 'UNCONFIRMED';
export const IS_SOCIAL_USER = 'isSocialUser';
export const PAYMENT_METHOD = 'payment_method';
export const CARD_PAYMENT_METHOD = 'card_payment_method';
export const CALLCENTER_GUEST_USER_TARGET = 'callCenterGuestUser';
