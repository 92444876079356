import { deserializer } from '~services/SearchStore/serializers';

const parsePublicSpace = ({ stores, id: hotspotId, ...publicSpace }) => {
  const { id, ...store } = stores[0];
  return {
    ...store,
    ...publicSpace,
    id,
    hotspotId,
    hotspot: true
  };
};

const parseStore = store => ({
  ...store,
  hotspot: false,
  hotspotId: null,
  deliveryInstructions: null,
  papacar: null
});

export const parseStoresAndHotspots = ({ pickupStores, publicSpaces }) => [
  ...(pickupStores ? pickupStores.map(parseStore) : []),
  ...(publicSpaces ? publicSpaces.map(parsePublicSpace) : [])
];

export const getSubsidiary = (response, currentSubsidiary) => {
  let result = response.data ? { ...deserializer.serialize(response.data), hotspot: false } : {};
  if (currentSubsidiary) {
    const {
      name,
      textAddress,
      latitude,
      longitude,
      deliveryInstructions,
      hotspot,
      hotspotId,
      open,
      papacar
    } = currentSubsidiary;

    result = {
      ...result,
      name,
      textAddress,
      latitude,
      longitude,
      ...(hotspot ? { hotspotId, hotspot: true, deliveryInstructions, open, papacar } : { hotspot: false })
    };
  }
  return result;
};
