import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'GiftCardsDetails', {
  title: 'Hemos enviado la Gift Card',
  description: 'El destinatario recibirá mediante correo electrónico su Gift Card.',
  details: 'Detalle'
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'GiftCardsDetails', {
  title: 'Enviámos o Gift Card',
  description: 'O destinatário receberá por e-mail o Gift Card.'
});
