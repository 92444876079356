import i18next from 'i18next';

import { LANGUAGE_KEYS } from '~constants/languages';

i18next.addResources(LANGUAGE_KEYS.CHILE, 'LastOrder', {
  title: 'Tu Último pedido',
  addToCart: 'Agregar al carrito',
  buyNow: 'Repetir pedido',
  description: '{{quantity}} artículos'
});

i18next.addResources(LANGUAGE_KEYS.SPAIN, 'LastOrder', {
  addToCart: 'Añadir al carrito',
  buyNow: 'Repetir pedido'
});

i18next.addResources(LANGUAGE_KEYS.PORTUGAL, 'LastOrder', {
  title: 'A tua última encomenda',
  addToCart: 'Adicionar ao carro',
  buyNow: 'Repetir encomenda',
  description: '{{quantity}} artigos'
});
