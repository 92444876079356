export const MESSAGE_MAX_LENGTH = 500;

export const BORDER_WIDTH = 2;

export const SECTIONS = {
  LOGO: 'logo',
  SELECT_PRICE: 'selectPrice',
  BANNER: 'banner',
  GIFT_CARD_DETAILS: 'giftCardDetails'
};

export const INPUTS = {
  PRICE: 'price',
  CUSTOMER_NAME: 'customerName',
  ADDRESEE_EMAIL: 'addreseeEmail',
  ADDRESEE_NAME: 'addreseeName',
  MESSAGE: 'message'
};

export const BUTTONS = {
  ADD_TO_CART: 'addToCart',
  BUY_NOW: 'buyNow',
  SAVE_AND_BACK: 'saveAndBack'
};
