import i18next from 'i18next';

i18next.addResources('es_CL', 'OptimizedCart', {
  title: '¡Desbloqueaste una promoción!',
  titlePlural: '¡Desbloqueaste varias promociones!',
  description: 'Cambiamos tu pizza {{products}} por una promoción que te hará un descuento.',
  adviseCoupon: 'Al aceptar, se invalida el uso de cupones.',
  weChanged: 'Cambiamos',
  intoAPromotion: 'por una promoción que te hará un descuento.',
  savings: 'Ahorrate {{savings}}',
  noThanks: 'No gracias'
});

i18next.addResources('pt_PT', 'OptimizedCart', {
  title: 'Desbloqueaste uma promoção!',
  titlePlural: 'Desbloqueaste várias promoções!',
  description: 'Temos uma promoção em vigor em que a tua pizza {{products}} te dará um desconto!',
  adviseCoupon: 'Ao aceitar, o uso de cupons é invalidado.',
  intoAPromotion: 'te darão um desconto!',
  savings: 'Poupaste {{savings}}',
  noThanks: 'Obrigado'
});
