import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer } from 'redux-recompose';

import { STATEMENTS_TARGET } from './constants';
import { actions } from './actions';

const initialStateDescription = {
  [STATEMENTS_TARGET]: null
};

const initialState = completeState(initialStateDescription);

const reducerDescription = {
  primaryActions: [actions.GET_STATEMENT]
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
