import i18next from 'i18next';

i18next.addResources('es_CL', 'PurchaseFail', {
  pagePath: '/compra-rechazada',
  pageTitle: 'Pedido rechazado',
  pageDescription: 'Su pedido no ha podido realizarse.',
  title: 'Se ha producido un error',
  description: 'Hubo un problema técnico con tu pago. Por favor inténtalo de nuevo.',
  buttonRedirect: 'Volver',
  cardErrorTitle: 'Ocurrió un problema con tu método de pago',
  cardErrorSubtitle: 'Por favor, inténtalo de nuevo o utiliza otro método de pago.',
  paymentErrorTitle: 'Ocurrió un problema con tu método de pago',
  paymentErrorSubtitle:
    'La tarjeta puede estar caducada, sin fondos o desactivada para el pago online. Inténtalo de nuevo con otra tarjeta o cambiando el método de pago.',
  notAuthorizedErrorTitle: 'Pago no autorizado',
  notAuthorizedErrorSubtitle: 'Por favor, inténtalo de nuevo o utiliza otro método de pago.',
  altImageError: 'Ícono de pedido fallido'
});

i18next.addResources('pt_PT', 'PurchaseFail', {
  pagePath: '/compra-rejeitada',
  pageTitle: 'Pedido rejeitado',
  pageDescription: 'Não foi possível fazer seu pedido.',
  title: 'Ocorreu um erro',
  description: 'Houve um problema técnico com seu pagamento. Por favor, tente novamente',
  buttonRedirect: 'Voltar',
  cardErrorTitle: 'Ocorreu um problema com o teu cartão',
  cardErrorSubtitle: 'Por favor volta a tentar, tenta com outro cartão ou altera a forma de pagamento.',
  paymentErrorTitle: 'Ocorreu um problema com o processo de pagamento',
  paymentErrorSubtitle: 'Por favor volta a tentar, tenta com outro cartão ou altera a forma de pagamento.',
  notAuthorizedErrorTitle: 'Pagamento não autorizado',
  notAuthorizedErrorSubtitle:
    'Por favor contacta a tua entidade bancária, tenta outro cartão ou altera a forma de pagamento.',
  altImageError: 'Ícone de encomenda falhada'
});
