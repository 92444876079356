import { isMobile } from '~constants/sizes';

const INTERCOM_ID = process.env.REACT_APP_INTERCOM_ID;
const IS_ENABLE_INTERCOM = process.env.REACT_APP_INTERCOM;

/* eslint-disable */
export function initializeIntercom() {
  const w = window;
  const ic = w.Intercom;

  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    var i = function () {
      i.c(arguments);
    };

    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      const s = d.createElement('script');

      s.type = 'text/javascript';
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${INTERCOM_ID}`;
      const x = d.getElementsByTagName('script')[0];

      x.parentNode.insertBefore(s, x);
    };

    if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
}

export const startIntercom = () => {
  if (IS_ENABLE_INTERCOM) {
    window.Intercom('boot', {
      app_id: INTERCOM_ID,
      ...(isMobile() && {
        hide_default_launcher: true,
        custom_launcher_selector: '#intercom-button'
      })
    });
  }
};

export const toggleIntercom = hide => {
  if (IS_ENABLE_INTERCOM) {
    window.Intercom('boot', {
      app_id: INTERCOM_ID,
      hide_default_launcher: hide
    });
  }
};

export const shutdownIntercom = () => {
  if (INTERCOM_ID) {
    window.Intercom('shutdown');
  }
};

export const onUnreadCountChange = handler => {
  if (INTERCOM_ID) {
    window.Intercom('onUnreadCountChange', handler);
  }
};
