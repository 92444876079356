import Immutable from 'seamless-immutable';
import { createReducer, completeState, completeReducer, onReadValue } from 'redux-recompose';

import { actions } from './actions';
import { STEPS_TARGET, PROMO_BUILDER_OFFER_TARGET, BYOP_GROUPABLE_ID } from './constants';

const initialStateDescription = {
  [STEPS_TARGET]: [],
  offer: null,
  [PROMO_BUILDER_OFFER_TARGET]: null
};

const initialState = completeState(initialStateDescription, [STEPS_TARGET]);

const reducerDescription = {
  primaryActions: [actions.GET_OFFER, actions.GET_OFFER_PIZZAS],
  override: {
    [actions.SET_BASE_PIZZA_FOR_STEP]: (state, action) =>
      state.merge({ [STEPS_TARGET]: { ...state[STEPS_TARGET], ...action.payload } }),
    [actions.CLEAR_OFFER]: state => state.merge(initialStateDescription),
    [actions.SET_PROMO_BUILDER_OFFER]: onReadValue(),
    [actions.SET_BYOP_GROUPABLE_ID]: (state, action) =>
      state.merge({ [BYOP_GROUPABLE_ID]: { ...state[BYOP_GROUPABLE_ID], ...action.payload } })
  }
};

const reducer = createReducer(new Immutable(initialState), completeReducer(reducerDescription));

export default reducer;
