/*
 * TODO Add this if you need it
 * export const sidebarSize = 100;
 */
import { scssVariableToNumber } from '~utils/screenSize';

export const MOBILE_SIZE = 550;
export const TABLET_SIZE = 768;

export const isMobile = () => window.innerWidth <= MOBILE_SIZE;
export const isTablet = () => window.innerWidth <= TABLET_SIZE;

// eslint-disable-next-line no-magic-numbers
export const TOP_BAR_SIZE = isMobile() ? 109 : scssVariableToNumber('60px');
